import {
  Button,
  Key,
  Wrapper,
  useBreadcrumbRoutes,
  InputNumber,
  FormContent,
  Modal,
  Empty,
} from '@maxtropy/components';
import { Form, Layout, Row, Col, Space } from 'antd';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { isNil } from 'lodash';
import EntityTree from './EntityTree';
import {
  apiV2EnergyConsumptionEvaluationAnalysisUnitGetConsumptionLimitSettingsPost,
  apiV2EnergyConsumptionEvaluationAnalysisUnitMeterTypeListPost,
  apiV2EnergyConsumptionEvaluationAnalysisUnitSaveConsumptionLimitSettingsPost,
  V2EnergyConsumptionEvaluationAnalysisUnitGetConsumptionLimitSettingsPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import ShowInput from '@/shared/components/ShowInput';
import { useNavigate } from 'react-router-dom';

const CarbonAccountUnits: React.FC = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  let id = urlSearchParams.get('id') || undefined;
  const navigator = useNavigate();
  const [form] = Form.useForm();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [fromData, setFormData] = useState<any>();
  const [limitsData, setLimitsData] =
    useState<V2EnergyConsumptionEvaluationAnalysisUnitGetConsumptionLimitSettingsPostResponse['list']>();

  const [treeSelectedKeys, setTreeSelectedKeys] = useState<Key[]>([]); // 选择树
  const [treeExpandedKeys, setTreeExpandedKeys] = useState<Key[]>([]);
  const [energyMediumIds, setEnergyMediumIds] = useState<number[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);

  function formatNumber(v: any) {
    if (isNil(v) || v === '') return '';
    let formattedNumber = parseFloat(v).toFixed(4);
    formattedNumber = formattedNumber.replace(/\.?0+$/, '');
    return formattedNumber as any;
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 2 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 8 },
      sm: { span: 14 },
    },
  };

  useEffect(() => {
    apiV2EnergyConsumptionEvaluationAnalysisUnitMeterTypeListPost({ ids: treeSelectedKeys.map(Number) }).then(res => {
      setFormData(res?.list ?? []);
      const idsArray = (res?.list ?? [])?.map(item => item.id as number);
      setEnergyMediumIds(idsArray);
    });
  }, [treeSelectedKeys]);

  useEffect(() => {
    if (isNil(fromData) || energyMediumIds?.length === 0) {
      setLimitsData([]);
      form.setFieldsValue({
        valueDetailDtos: null,
      });
      return;
    }
    apiV2EnergyConsumptionEvaluationAnalysisUnitGetConsumptionLimitSettingsPost({
      energyUnitId: treeSelectedKeys[0],
      energyMediumIds,
    }).then(res => {
      setLimitsData(res.list ?? []);
      form.setFieldsValue({
        valueDetailDtos: res.list?.map((item, index) => ({
          name: fromData[index]?.energyName,
          stopLineUnitConsumptionUpLimit: item?.limits?.find(limit => limit.status === 4)?.upperLimitValue ?? null,
          stopLineUnitConsumptionDownLimit: item?.limits?.find(limit => limit.status === 4)?.lowerLimitValue ?? null,
          leisureEnergyConsumptionUpLimit: item?.limits?.find(limit => limit.status === 2)?.upperLimitValue ?? null,
          leisureEnergyConsumptionDownLimit: item?.limits?.find(limit => limit.status === 2)?.lowerLimitValue ?? null,
          restEnergyConsumptionUpLimit: item?.limits?.find(limit => limit.status === 3)?.upperLimitValue ?? null,
          restEnergyConsumptionDownLimit: item?.limits?.find(limit => limit.status === 3)?.lowerLimitValue ?? null,
        })),
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromData]);

  const onFinish = () => {
    form.validateFields().then(res => {
      if (!isNil(fromData)) {
        const data = res.valueDetailDtos.map((item: any, index: number) => {
          const limits = [];
          limits.push(
            {
              status: 4,
              upperLimitValue: !isNil(item.stopLineUnitConsumptionUpLimit) ? item.stopLineUnitConsumptionUpLimit : null,
              lowerLimitValue: !isNil(item.stopLineUnitConsumptionDownLimit)
                ? item.stopLineUnitConsumptionDownLimit
                : null,
            },
            {
              status: 2,
              upperLimitValue: !isNil(item.leisureEnergyConsumptionUpLimit)
                ? item.leisureEnergyConsumptionUpLimit
                : null,
              lowerLimitValue: !isNil(item.leisureEnergyConsumptionDownLimit)
                ? item.leisureEnergyConsumptionDownLimit
                : null,
            },
            {
              status: 3,
              upperLimitValue: !isNil(item.restEnergyConsumptionUpLimit) ? item.restEnergyConsumptionUpLimit : null,
              lowerLimitValue: !isNil(item.restEnergyConsumptionDownLimit) ? item.restEnergyConsumptionDownLimit : null,
            }
          );
          return {
            energyMediumId: fromData[index].id,
            limits,
          };
        });
        apiV2EnergyConsumptionEvaluationAnalysisUnitSaveConsumptionLimitSettingsPost({
          energyUnitId: treeSelectedKeys[0],
          items: data,
        }).then(res => {
          Modal.success({ content: '保存成功' });
        });
      }
    });
  };

  return (
    <Wrapper className={styles.wrapperStyles} routes={[...(breadcrumbRoutes?.routes ?? [])]}>
      <FormContent title="用能单元能耗上下限配置" style={{ padding: 20 }}>
        <Layout className={styles.layout}>
          <Layout.Sider width={260} style={{ padding: '10px 0px', background: '#232324' }}>
            <EntityTree
              id={id}
              selectedKeys={treeSelectedKeys}
              setSelectedKeys={setTreeSelectedKeys}
              expandedKeys={treeExpandedKeys}
              setAutoExpandParent={setAutoExpandParent}
              autoExpandParent={autoExpandParent}
              setExpandedKeys={setTreeExpandedKeys}
            />
            <Space className={styles.stickyShadowFooter} style={{ paddingLeft: 0 }} size={'small'}>
              <Button
                className={styles.prevButtonStyle}
                onClick={() => {
                  form.resetFields();
                  navigator('/energy/consumption/analysis');
                }}
              >
                取消
              </Button>
              <Button className={styles.nextButtonStyle} type="primary" onClick={onFinish}>
                保存
              </Button>
            </Space>
          </Layout.Sider>
          <Layout.Content className={styles.content} style={{ marginLeft: 0, padding: '10px 0px' }}>
            <div className={styles.operationArea}>
              {!isNil(limitsData) && limitsData.length > 0 ? (
                <Form form={form} {...formItemLayout} labelCol={{ flex: '110px' }}>
                  <Form.List name="valueDetailDtos">
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {fields.map(({ key, name }, index) => (
                          <div className={styles.newlayout}>
                            <Row>
                              <Col span={24}>
                                <Form.Item name={[name, 'name']}>
                                  <ShowInput
                                    style={{
                                      fontSize: 16,
                                      fontWeight: 500,
                                      color: `rgba(255,255,255,0.85)`,
                                    }}
                                  ></ShowInput>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  name={[name, 'stopLineUnitConsumptionUpLimit']}
                                  {...formItemLayout}
                                  label="停线-能耗上限"
                                  rules={[
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        const lowLimit =
                                          getFieldValue('valueDetailDtos')[name].stopLineUnitConsumptionDownLimit;
                                        console.log(lowLimit);
                                        form.setFields([
                                          {
                                            name: ['valueDetailDtos', index, 'stopLineUnitConsumptionDownLimit'],
                                            value: lowLimit,
                                            errors: undefined,
                                          },
                                        ]);

                                        if (isNil(lowLimit) || value >= lowLimit || isNil(value)) {
                                          return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('单耗上限需要大于单耗下限'));
                                      },
                                    }),
                                    { pattern: /^-?\d{1,8}(\.\d{1,4})?$/, message: '请输入9位数字内，最大4位小数' },
                                  ]}
                                >
                                  <InputNumber
                                    className={styles.iptStyle}
                                    controls={false}
                                    placeholder="请输入"
                                    formatter={formatNumber}
                                    addonAfter={
                                      <span className={styles.suffixIcon}>
                                        {fromData[index]?.physicalUnitName
                                          ? `${fromData[index]?.physicalUnitName}/min`
                                          : '--'}
                                      </span>
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  name={[name, 'stopLineUnitConsumptionDownLimit']}
                                  {...formItemLayout}
                                  label="停线-能耗下限"
                                  rules={[
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        const upLimit =
                                          getFieldValue('valueDetailDtos')[name].stopLineUnitConsumptionUpLimit;
                                        form.setFields([
                                          {
                                            name: ['valueDetailDtos', index, 'stopLineUnitConsumptionUpLimit'],
                                            value: upLimit,
                                            errors: undefined,
                                          },
                                        ]);
                                        if (isNil(upLimit) || value <= upLimit || isNil(value)) {
                                          return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('单耗下限需要小于单耗上限'));
                                      },
                                    }),
                                    { pattern: /^-?\d{1,8}(\.\d{1,4})?$/, message: '请输入9位数字内，最大4位小数' },
                                  ]}
                                >
                                  <InputNumber
                                    className={styles.iptStyle}
                                    controls={false}
                                    formatter={formatNumber}
                                    placeholder="请输入"
                                    addonAfter={
                                      <span className={styles.suffixIcon}>
                                        {fromData[index]?.physicalUnitName
                                          ? `${fromData[index]?.physicalUnitName}/min`
                                          : '--'}
                                      </span>
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12}>
                                <Form.Item
                                  name={[name, 'leisureEnergyConsumptionUpLimit']}
                                  {...formItemLayout}
                                  label="空闲-能耗上限"
                                  rules={[
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        const lowLimit =
                                          getFieldValue('valueDetailDtos')[name].leisureEnergyConsumptionDownLimit;
                                        form.setFields([
                                          {
                                            name: ['valueDetailDtos', index, 'leisureEnergyConsumptionDownLimit'],
                                            value: lowLimit,
                                            errors: undefined,
                                          },
                                        ]);
                                        if (isNil(lowLimit) || value >= lowLimit || isNil(value)) {
                                          return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('能耗上限需要大于能耗下限'));
                                      },
                                    }),
                                    { pattern: /^-?\d{1,8}(\.\d{1,4})?$/, message: '请输入9位数字内，最大4位小数' },
                                  ]}
                                >
                                  <InputNumber
                                    className={styles.iptStyle}
                                    controls={false}
                                    formatter={formatNumber}
                                    placeholder="请输入"
                                    addonAfter={
                                      <span className={styles.suffixIcon}>
                                        {fromData[index]?.physicalUnitName
                                          ? `${fromData[index]?.physicalUnitName}/min`
                                          : '--'}
                                      </span>
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  name={[name, 'leisureEnergyConsumptionDownLimit']}
                                  {...formItemLayout}
                                  label="空闲-能耗下限"
                                  rules={[
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        const upLimit =
                                          getFieldValue('valueDetailDtos')[name].leisureEnergyConsumptionUpLimit;
                                        form.setFields([
                                          {
                                            name: ['valueDetailDtos', index, 'leisureEnergyConsumptionUpLimit'],
                                            value: upLimit,
                                            errors: undefined,
                                          },
                                        ]);
                                        if (isNil(upLimit) || value <= upLimit || isNil(value)) {
                                          return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('能耗下限需要小于能耗上限'));
                                      },
                                    }),
                                    { pattern: /^-?\d{1,8}(\.\d{1,4})?$/, message: '请输入9位数字内，最大4位小数' },
                                  ]}
                                >
                                  <InputNumber
                                    className={styles.iptStyle}
                                    controls={false}
                                    formatter={formatNumber}
                                    placeholder="请输入"
                                    addonAfter={
                                      <span className={styles.suffixIcon}>
                                        {fromData[index]?.physicalUnitName
                                          ? `${fromData[index]?.physicalUnitName}/min`
                                          : '--'}
                                      </span>
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12}>
                                <Form.Item
                                  name={[name, 'restEnergyConsumptionUpLimit']}
                                  {...formItemLayout}
                                  label="休息-能耗上限"
                                  rules={[
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        const lowLimit =
                                          getFieldValue('valueDetailDtos')[name].restEnergyConsumptionDownLimit;
                                        form.setFields([
                                          {
                                            name: ['valueDetailDtos', index, 'restEnergyConsumptionDownLimit'],
                                            value: lowLimit,
                                            errors: undefined,
                                          },
                                        ]);
                                        if (isNil(lowLimit) || value >= lowLimit || isNil(value)) {
                                          return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('能耗上限需要大于能耗下限'));
                                      },
                                    }),
                                    { pattern: /^-?\d{1,8}(\.\d{1,4})?$/, message: '请输入9位数字内，最大4位小数' },
                                  ]}
                                >
                                  <InputNumber
                                    className={styles.iptStyle}
                                    controls={false}
                                    placeholder="请输入"
                                    formatter={formatNumber}
                                    addonAfter={
                                      <span className={styles.suffixIcon}>
                                        {fromData[index]?.physicalUnitName
                                          ? `${fromData[index]?.physicalUnitName}/min`
                                          : '--'}
                                      </span>
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  name={[name, 'restEnergyConsumptionDownLimit']}
                                  {...formItemLayout}
                                  label="休息-能耗下限"
                                  rules={[
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        const upLimit =
                                          getFieldValue('valueDetailDtos')[name].restEnergyConsumptionUpLimit;
                                        form.setFields([
                                          {
                                            name: ['valueDetailDtos', index, 'restEnergyConsumptionUpLimit'],
                                            value: upLimit,
                                            errors: undefined,
                                          },
                                        ]);
                                        if (isNil(upLimit) || value <= upLimit || isNil(value)) {
                                          return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('能耗下限需要小于能耗上限'));
                                      },
                                    }),
                                    { pattern: /^-?\d{1,8}(\.\d{1,4})?$/, message: '请输入9位数字内，最大4位小数' },
                                  ]}
                                >
                                  <InputNumber
                                    className={styles.iptStyle}
                                    controls={false}
                                    formatter={formatNumber}
                                    placeholder="请输入"
                                    addonAfter={
                                      <span className={styles.suffixIcon}>
                                        {fromData[index]?.physicalUnitName
                                          ? `${fromData[index]?.physicalUnitName}/min`
                                          : '--'}
                                      </span>
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </>
                    )}
                  </Form.List>
                </Form>
              ) : (
                <Empty></Empty>
              )}
            </div>
          </Layout.Content>
        </Layout>
      </FormContent>
    </Wrapper>
  );
};

export default CarbonAccountUnits;
