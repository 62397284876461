import { InfoCircleOutlined } from '@ant-design/icons';
import { SubContent, useBreadcrumbRoutes, Wrapper, Button, Form, FormTitle } from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ShowInput from '@/shared/components/ShowInput';
import { SettlementCycleType, SettlementCycleTypeDisplay } from '@/shared/types';
import {
  ElectricitySaleContractMeterType,
  ElectricitySaleContractVo,
  getElectricitySaleContractById,
  getElectricitySaleContractMeterList,
} from '../../../api/electricitySalesContract';
import { ElectricitySaleContractSettlementMeterType } from '../../../api/electricitySettlementRules';
import EffectiveMeterTable from './components/EffectiveMeterTable';
import SettlementRulesTable from './components/SettlementRulesTable';
import styles from './index.module.scss';

const routes = [
  // { name: '运营配置' },
  // { name: '物业管理合同' },
  // { name: '物业合同管理列表' },
  { name: '查看合同信息' },
];

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const ShowSaleContractDetail: FC = () => {
  const { id } = useParams<{ id: string }>(); // 读取地址栏的id
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [data, setData] = useState<ElectricitySaleContractVo>(); // 当前id的数据
  // const [meterListData, setMeterListData] = useState<ElectricitySaleContractMeterType[]>([])
  const [useCustomizeBillTitle, setUseCustomizeBillTitle] = useState<boolean>(false);
  // 电量计量表计 详情
  const [electricityTableValue, setElectricityTableValue] = useState<ElectricitySaleContractMeterType[]>([]);
  // 需量
  const [demandTableValue, setDemandTableValue] = useState<ElectricitySaleContractMeterType[]>([]);
  // 光伏
  const [photovoltaicTableValue, setPhotovoltaicTableValue] = useState<ElectricitySaleContractMeterType[]>([]);
  // 扣除反向电量
  const [deductPhotovoltaicTableValue, setDeductPhotovoltaicTableValue] = useState<ElectricitySaleContractMeterType[]>(
    []
  );
  // 子表
  const [childrenTableValue, setChildrenTableValue] = useState<ElectricitySaleContractMeterType[]>([]);
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    if (id) {
      getElectricitySaleContractMeterList(id, ElectricitySaleContractSettlementMeterType.ELECTRICITY).then(
        setElectricityTableValue
      );
      getElectricitySaleContractMeterList(id, ElectricitySaleContractSettlementMeterType.DEMAND).then(
        setDemandTableValue
      );
      getElectricitySaleContractMeterList(id, ElectricitySaleContractSettlementMeterType.PHOTOVOLTAIC).then(
        setPhotovoltaicTableValue
      );
      getElectricitySaleContractMeterList(id, ElectricitySaleContractSettlementMeterType.DEDUCTPHOTOVOLTAIC).then(
        setDeductPhotovoltaicTableValue
      );
      getElectricitySaleContractMeterList(id, ElectricitySaleContractSettlementMeterType.SUBTABLE).then(
        setChildrenTableValue
      );
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      getElectricitySaleContractById(id)
        .then(res => {
          setData(res);
          setUseCustomizeBillTitle(res.useCustomizeBillTitle);
        })
        .catch(e => {
          console.error(e);
        });
    }
  }, [id]);

  useEffect(() => {
    const settlementTime = `${data?.settlementDay}/${data?.settlementHour}`;
    if (data) {
      form.setFieldsValue({
        customerName: data.customerName, // 关联组织
        propertyManagementConfigName: data.propertyManagementConfigName, //物业名称
        archiveCode: data.archiveCode, // 存档编号
        segmentAccountNumber: data.segmentAccountNumber, // 段户号
        code: data.code,
        name: data.name,
        saleClientName: data.saleClientName,
        saleClientAccountName: data.saleClientAccountName,
        saleClientId: data.saleClientId,
        // status: ElectricitySaleContractStatus[data.status],
        contractValidity: `${dayjs(data.startTime).format('YYYY-MM-DD')}~${dayjs(data.endTime).format('YYYY-MM-DD')}`,
        address: data.address,
        // meterListData: data.meterListData,
        settlementCycleType:
          data.settlementCycleType === SettlementCycleType.DAY
            ? SettlementCycleTypeDisplay[SettlementCycleType.DAY]
            : data.settlementCycleType === SettlementCycleType.MONTH
            ? SettlementCycleTypeDisplay[SettlementCycleType.MONTH]
            : '--',
        settlementTime: settlementTime
          ? data.settlementCycleType === SettlementCycleType.DAY
            ? '每日' + settlementTime + '点'
            : data.settlementCycleType === SettlementCycleType.MONTH
            ? `每月${settlementTime.split('/')[0] === '0' ? '月末' : settlementTime.split('/')[0] + '日'}${
                settlementTime.split('/')[1] ?? '--'
              }点`
            : '--'
          : '--',
        // levels: data.levels.map(i => AlarmLevelDisplay[i]).join('、'),
        // pushCenterStaffLinkVoList: data.pushCenterStaffLinkVoList ?? [],
        // deviceAlarmPushDeviceVoList: data.deviceAlarmPushDeviceVoList ?? [],
        billTitle: data.billTitle,
      });
    }
  }, [data, form]);

  const goList = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(`/operation/salesContract/electricity`);
    }
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <Form form={form} layout="vertical" {...formLayout}>
        <FormTitle title={routes[0].name} />
        <SubContent title="基础信息">
          <Row>
            <Col span={8}>
              <Form.Item name="archiveCode" label="存档合同编号">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="code" label="物业管理合同编号">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="name" label="合同名称">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="saleClientName" label="所属客户">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="saleClientAccountName" label="客户账户">
                <ShowInput />
              </Form.Item>
            </Col>
            {/* <Col span={8}>
                            <Form.Item
                                name="status"
                                label="状态"
                            >
                                <ShowInput />
                            </Form.Item>
                        </Col> */}
            <Col span={8}>
              <Form.Item name="contractValidity" label="合同有效期">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="address" label="位置/房号">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="segmentAccountNumber" label="段户号">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="customerName" label="所属组织">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="propertyManagementConfigName" label="所属物业管理">
                <ShowInput />
              </Form.Item>
            </Col>
            {useCustomizeBillTitle && (
              <Col span={8}>
                <Form.Item name="billTitle" label="自定义核查联表头">
                  <ShowInput />
                </Form.Item>
              </Col>
            )}
          </Row>
        </SubContent>
        <SubContent title="结算周期">
          <Row>
            <Col span={8}>
              <Form.Item name="settlementCycleType" label="周期频次">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="settlementTime" label="结算时间">
                <ShowInput />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <SubContent
          title={
            <>
              <span style={{ marginRight: '14px' }}>生效表计</span>
              <InfoCircleOutlined style={{ fontSize: '14px', marginRight: '6px', color: 'var(--mx-warning-color)' }} />
              <span style={{ fontSize: '14px', fontWeight: '400', color: 'var(--mx-text-desc-color)' }}>
                表计移除后，不影响已经生成的电费核查联。新加的表计，历史时间区间不重新计算和生成电费核查联。
              </span>
            </>
          }
        >
          <Row>
            <Col span={24}>
              <Form.Item name="electricityMetersListData" wrapperCol={{ span: 24 }}>
                <EffectiveMeterTable id={id} tableTitle={'电量-计量表计'} tableValue={electricityTableValue} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name="demandMetersListData" wrapperCol={{ span: 24 }}>
                <EffectiveMeterTable id={id} tableTitle={'需量-计量表计'} tableValue={demandTableValue} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name="childrenMetersListData" wrapperCol={{ span: 24 }}>
                <EffectiveMeterTable id={id} tableTitle={'子表-计量表计'} tableValue={childrenTableValue} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name="deductPhotovoltaicMetersListData" wrapperCol={{ span: 24 }}>
                <EffectiveMeterTable
                  id={id}
                  tableTitle={'扣除反向电量-光伏计量表计'}
                  tableValue={deductPhotovoltaicTableValue}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name="photovoltaicMetersListData" wrapperCol={{ span: 24 }}>
                <EffectiveMeterTable
                  id={id}
                  tableTitle={'不扣除反向电量光伏-计量表计'}
                  tableValue={photovoltaicTableValue}
                />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <SubContent title="结算规则">
          <Row>
            <Col span={24}>
              <Form.Item name="deviceAlarmPushDeviceVoList" wrapperCol={{ span: 24 }}>
                <SettlementRulesTable id={id!} />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <Space className="sticky-footer">
          <Button className={styles.button} onClick={goList}>
            返回
          </Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default ShowSaleContractDetail;
