import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { Key } from 'react';
import { ChannelAppIDType } from '@/shared/types';
import { BalanceAlarmStatusType } from './balanceAlarmManagement';
import { AlrmPushStaffPageResponse } from './deviceAlarmPushStrategy';
import { PageRequest, PageResponse } from './page';

export interface ServerAlarmPushConfigOutsiderRequest {
  contactPhone: string;
  contactName: string;
  pushChannels: ChannelAppIDType[];
}

export interface ServerAlarmPushConfigStaffsRequest {
  staffId: number;
  serverAlarmPushConfigStaffId?: number;
  pushChannel: ChannelAppIDType;
}

export interface CreateServerAlarmPushConfigRequest {
  name: string;
  ouId: number;
  sceneType: number;
  serverAlarmRuleIds: number[];
  pushStaffs: ServerAlarmPushConfigStaffsRequest[];
  pushExtContacts: ServerAlarmPushConfigOutsiderRequest[];
}

export function createServerAlarmPushConfig(request: CreateServerAlarmPushConfigRequest) {
  return fetch<void>(`/api/server-alarm/config`, {
    method: 'POST',
    body: JSON.stringify(request),
  });
}

export interface UpdateServerAlarmPushConfigRequest extends Omit<CreateServerAlarmPushConfigRequest, 'ouId'> {}

export function updateServerAlarmPushConfig(serverAlarmPushConfigId: Key, request: UpdateServerAlarmPushConfigRequest) {
  return fetch<void>(`/api/server-alarm/config/${serverAlarmPushConfigId}`, {
    method: 'PUT',
    body: JSON.stringify(request),
  });
}

export interface PushStaff extends AlrmPushStaffPageResponse {
  pushChannel: ChannelAppIDType;
  id: number;
}

export interface ServerAlarmPushConfigResponse {
  id: number;
  name: string;
  tenantName: string;
  rootMcid: string;
  ouId: number;
  ouName: string;
  sceneType: number;
  sceneName: string;
  serverAlarmRules: ServerAlarmPushRuleResponse[];
  pushStaffs: PushStaff[];
  pushExtContacts: ServerAlarmPushConfigOutsiderRequest[];
}

export function getServerAlarmPushConfig(serverAlarmPushConfigId: Key) {
  return fetch<ServerAlarmPushConfigResponse>(`/api/server-alarm/config/${serverAlarmPushConfigId}`);
}

export interface ServerAlarmConfigTableHeader {
  title: string;
  key: string;
  sequence: number;
}

export function getServerAlarmConfigTableHeader(sceneType: number) {
  return fetch<ServerAlarmConfigTableHeader[]>(`/api/server-alarm/config/table-header?sceneType=${sceneType}`);
}

export interface SceneTypeResponse {
  name: string;
  value: number;
}

export function getServerAlarmConfigSceneType() {
  return fetch<SceneTypeResponse[]>(`/api/server-alarm/config/scene/list`);
}

export interface ServerAlarmPushRuleResponse {
  id: number;
  code: string;
  name: string;
  status: BalanceAlarmStatusType;
  [key: string]: any;
}

export interface ServerAlarmPushRuleRequest extends PageRequest {
  name?: string;
  code?: string;
  ouId: Key;
  sceneType: number;
  [key: string]: any;
}

export function getServerAlarmPushRulePage(request: ServerAlarmPushRuleRequest) {
  return fetch<PageResponse<ServerAlarmPushRuleResponse>>(
    `/api/server-alarm/rule/dynamic/page?${qs.stringify(request, { indices: false })}`
  );
}
