import { PaymentVoucherRequest } from '@/api/electricity';
import { SaleVoucherVo, TransactionType, TransactionTypeDisplay } from '@/api/sale';
import { Button, EllipsisSpan, InputNumber, Key, Modal, Table } from '@maxtropy/components';

import { useEffect, useState } from 'react';
import ShowInput from '@/shared/components/ShowInput';
import styles from './index.module.scss';

const columns = [
  {
    title: '凭证名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '凭证类型',
    dataIndex: 'type',
    render: (v: TransactionType) => <EllipsisSpan value={TransactionTypeDisplay[v]} />,
  },
  {
    title: ' 剩余金额',
    dataIndex: 'balance',
    ellipsis: { showTitle: true },
    render: (v: string) => <ShowInput style={{ color: '#00ADFF' }} value={v} addonAfter="元" />,
  },
  {
    title: '凭证说明',
    dataIndex: 'description',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface DataSource extends SaleVoucherVo {
  voucherDeductionAmount?: number | null;
}

interface Props {
  onChange?: (v: PaymentVoucherRequest[]) => void;
  isWarn: boolean;
  setIsWarn: (v: boolean) => void;
  setDeductionAmount?: (v: number) => void;
  saleVoucher?: Array<SaleVoucherVo>;
  amount?: number;
}

function changeToVouchers(v: DataSource[]) {
  return v.map(i => {
    return {
      saleVoucherId: i.id,
      amount: i.voucherDeductionAmount ?? 0,
    };
  });
}

const TransactionVoucher: React.FC<Props> = ({ onChange, isWarn, setIsWarn, saleVoucher, amount }) => {
  const [modalData, setModalData] = useState<SaleVoucherVo[]>([]);
  const [dataSource, setDataSource] = useState<DataSource[]>([]);

  const [saleVoucherOpen, setSaleVoucherOpen] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<SaleVoucherVo[]>([]);

  useEffect(() => {
    if (saleVoucher) {
      setModalData(saleVoucher);
      if (amount && amount >= 0) {
        let data = calculateVoucherDeductionAmount(saleVoucher, amount);
        setDataSource(data);
        onChange?.(changeToVouchers(data));
        setSelectedRowKeys(data.map(i => i.id));
        setSelectedRows(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleVoucher, amount]);

  const calculateVoucherDeductionAmount = (source: SaleVoucherVo[], deductionAmount: number) => {
    let num = 0;
    let data = [];
    for (let i = 0; i < source.length; i++) {
      num += source[i].balance;
      if (num < deductionAmount) {
        data[i] = {
          ...source[i],
          voucherDeductionAmount: source[i].balance,
        };
        if (i === source.length - 1) {
          setIsWarn(true);
        }
        continue;
      }
      if (num >= deductionAmount) {
        setIsWarn(false);
        data[i] = {
          ...source[i],
          voucherDeductionAmount: deductionAmount - (num - source[i].balance),
        };
        break;
      }
    }
    return data;
  };

  const buildColumns = [
    ...columns,
    {
      title: '使用金额',
      dataIndex: 'voucherDeductionAmount',
      ellipsis: { showTitle: true },
      render: (v: number, record: DataSource) => {
        return (
          <InputNumber
            min={0}
            max={record?.balance}
            precision={2}
            value={v ?? 0}
            addonAfter="元"
            placeholder="请输入"
            onChange={v => {
              const data = dataSource.map(i => {
                if (i.id === record.id) {
                  return {
                    ...i,
                    voucherDeductionAmount: v,
                  };
                }
                return i;
              });
              setDataSource(data);
              onChange?.(changeToVouchers(data));
              setSelectedRowKeys(data.map(i => i.id));
            }}
          />
        );
      },
    },
  ];

  const rowSelection = {
    onChange: (newSelectedRowKeys: Key[], newSelectedRows: SaleVoucherVo[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
      setSelectedRows(newSelectedRows);
    },
    selectedRowKeys,
  };

  const onOK = () => {
    if (amount && amount >= 0) {
      let data = calculateVoucherDeductionAmount(selectedRows, amount);
      if (data.length < selectedRows.length) {
        const partSelectedRows = selectedRows.slice(data.length).map(i => {
          return {
            ...i,
            voucherDeductionAmount: 0,
          };
        });
        data = data.concat(partSelectedRows);
      }
      setDataSource(data);
      onChange?.(changeToVouchers(data));
    }
    setSaleVoucherOpen(false);
  };

  return (
    <>
      <div className={styles.formListWrapper}>
        <div className={styles.btnPosition}>
          <Button
            type="primary"
            onClick={() => {
              setSaleVoucherOpen(true);
            }}
          >
            更换交易凭证
          </Button>
        </div>
        {dataSource.length > 0 && (
          <Table
            size="small"
            dataSource={dataSource}
            style={{ paddingRight: 13, paddingTop: 8 }}
            rowKey="id"
            sticky
            columns={buildColumns}
            pagination={false}
          />
        )}
      </div>
      {isWarn && <p className={styles.warnInfo}>选择的收款凭证不足于本次扣款</p>}
      <Modal
        onOk={onOK}
        onCancel={() => {
          setSaleVoucherOpen(false);
          setSelectedRowKeys(dataSource.map(i => i.id));
        }}
        size="big"
        title="更换交易凭证"
        okText="保存"
        open={saleVoucherOpen}
      >
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          rowKey="id"
          scroll={{ y: 300 }}
          sticky
          dataSource={modalData}
          columns={columns}
        />
      </Modal>
    </>
  );
};
export default TransactionVoucher;
