import React, { useEffect } from 'react';
import { Modal, Form, Upload } from 'antd';
import ShowInput from '@/shared/components/ShowInput';
import { SaleVoucherVo, TransactionType } from '../../../api/sale';
import dayjs from 'dayjs';
import { getRealUrl, Button } from '@maxtropy/components';

export interface DetailModalProps {
  record?: SaleVoucherVo;
  onCancel?: (r?: SaleVoucherVo) => void;
}

export const transactionTypeDetailDisplay: Partial<Record<TransactionType, string>> = {
  [TransactionType.REFUND]: '退款',
  [TransactionType.RECHARGE]: '收款',
  [TransactionType.MANUAL_DEDYCTION]: '扣款',
};

async function downloadFile(name: string, url: string) {
  const res = await window.fetch(url);
  const blob = await res.blob();
  var link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = name;
  link.click();
  window.URL.revokeObjectURL(link.href);
}

const DetailModal: React.FC<DetailModalProps> = ({ record, onCancel }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        name: record.name,
        specifiedDate: dayjs(record.specifiedDate).format('YYYY-MM-DD HH:mm:ss'),
        description: record.description,
        files: (record.files ?? []).map(i => ({
          name: i.fileName,
          url: getRealUrl(i.fileKey),
          preview: getRealUrl(i.fileKey),
          uid: i.id,
          status: 'done',
        })),
      });
    } else {
      form.resetFields();
    }
  }, [record, form]);

  const onLocalCancel = () => {
    onCancel?.(undefined);
  };

  return (
    <Modal
      width={600}
      destroyOnClose
      open={!!record}
      onCancel={onLocalCancel}
      onOk={onLocalCancel}
      footer={null}
      title="凭证信息"
      bodyStyle={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Form style={{ width: 450 }} form={form} labelCol={{ flex: '110px' }} labelAlign="left">
        <Form.Item name="name" label="凭证名称">
          <ShowInput />
        </Form.Item>
        <Form.Item
          name="specifiedDate"
          label={
            record?.type !== undefined && record?.type !== null
              ? `${transactionTypeDetailDisplay[record.type] ?? ''}日期`
              : ''
          }
        >
          <ShowInput />
        </Form.Item>
        <Form.Item
          name="files"
          label={
            record?.type !== undefined && record?.type !== null
              ? `${transactionTypeDetailDisplay[record.type] ?? ''}凭证`
              : ''
          }
          valuePropName="fileList"
        >
          <Upload
            iconRender={() => null}
            showUploadList={{ showDownloadIcon: true, showRemoveIcon: false }}
            itemRender={(_, file) => {
              return (
                <Button
                  type="link"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                  onClick={() => {
                    downloadFile(file.name, file.url!);
                  }}
                >
                  {file.name}
                </Button>
              );
            }}
            // onDownload={(file) => {
            //   downloadFile(file.name, file.url!)
            // }}
          />
        </Form.Item>
        <Form.Item name="description" label="凭证说明">
          <ShowInput />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DetailModal;
