import { Button, Wrapper, Form, getRealUrl, useBreadcrumbRoutes, FormTitle, SubContent } from '@maxtropy/components';
import { Col, Row, Space, Spin, Image } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ShowInput from '@/shared/components/ShowInput';
import BorderBox from '../components/BorderBox';
import { EnergyAssetsBillDetail, TimeIntervalTableType, getEnergyAssetsBillDetail } from '@/api/energyAssetsBill';
import dayjs from 'dayjs';
import { DateDisplay } from '../utils';
import { ElectricitySaleContractSettlementType } from '@/api/electricitySettlementRules';
import TimeIntervalTable from '../components/TimeIntervalTable';

const routes = [{ name: '结算单详情' }];

const DetailEnergyAssetsBill: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const { id } = useParams<{ id: string }>();
  const [detail, setDetail] = useState<EnergyAssetsBillDetail>(); // 结算单详情
  const [settlementType, setSettlementType] = useState<ElectricitySaleContractSettlementType>(); // 结算类型
  const navigate = useNavigate();
  const { search } = useLocation();
  const [picList, setPicList] = useState<string[]>([]); // 抄表照片列表

  useEffect(() => {
    if (id) {
      setLoading(true);
      getEnergyAssetsBillDetail(id)
        .then(res => {
          if (res) {
            setDetail(res);
            setPicList(res.picKeys);
            setSettlementType(res.settlementType);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  return (
    <Spin spinning={loading}>
      <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className="page_wrapper">
        <FormTitle title="结算单详情"></FormTitle>

        <Form layout="vertical">
          <SubContent title="基础信息" className="mb-8">
            <Row>
              <Col span={8}>
                <Form.Item label="结算单编号">
                  <ShowInput value={detail?.code} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="结算周期">
                  <ShowInput
                    value={`${dayjs(detail?.settlementStartTime).format(DateDisplay)} - ${dayjs(
                      detail?.settlementEndTime
                    ).format(DateDisplay)}`}
                  />
                </Form.Item>
              </Col>
            </Row>
          </SubContent>
          <SubContent title="用户信息" className="mb-8">
            <Row>
              <Col span={8}>
                <Form.Item label="客户编号">
                  <Space>
                    <ShowInput value={detail?.saleClientCode} />
                    <Button type="link">
                      <Link to={`/operation/client-related/client/detail/${detail?.saleClientId}`}>查看客户详情</Link>
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="客户名称">
                  <ShowInput value={detail?.saleClientName} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="合同编号">
                  <Space>
                    <ShowInput value={detail?.saleElectricityContractCode} />
                    <Button type="link">
                      <Link to={`/operation/salesContract/electricity/detail/${detail?.saleElectricityContractId}`}>
                        查看合同详情
                      </Link>
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </SubContent>
          <SubContent title="总费用">
            <BorderBox detail={detail} settlementType={settlementType} />
          </SubContent>
          {settlementType === ElectricitySaleContractSettlementType.DISTRIBUTED_PV && (
            <>
              {/* 光伏发电量 */}
              <TimeIntervalTable detail={detail} tableType={TimeIntervalTableType.PV_OUTPUT} />
              {/* 光伏上网电量 */}
              <TimeIntervalTable detail={detail} tableType={TimeIntervalTableType.PV_ONLINE} />
              {/* 光伏消纳电量 */}
              <TimeIntervalTable detail={detail} tableType={TimeIntervalTableType.PV_CONSUMPTION} />
            </>
          )}
          {settlementType === ElectricitySaleContractSettlementType.DISTRIBUTED_BSA && (
            <>
              {/* 储能充电量 */}
              <TimeIntervalTable detail={detail} tableType={TimeIntervalTableType.BSA_CHARGE} />
              {/* 储能放电量 */}
              <TimeIntervalTable detail={detail} tableType={TimeIntervalTableType.BSA_DISCHARGE} />
            </>
          )}
          <SubContent title="电价信息">
            <BorderBox isElectricityBox detail={detail} settlementType={settlementType} />
          </SubContent>
          {picList.length > 0 && (
            <SubContent title="抄表照片">
              <Row
                style={{
                  overflowY: 'auto',
                  height: 700,
                }}
                gutter={[0, 34]}
              >
                {picList.map((item, index) => {
                  return (
                    <Col span={3} key={index}>
                      <div
                        style={{
                          borderRadius: 2,
                          border: '1px solid rgba(255,255,255,0.2)',
                          padding: 8,
                          width: 104,
                          height: 104,
                        }}
                      >
                        <Image src={getRealUrl(item)} width={88} height={88} />
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </SubContent>
          )}

          <Space className="sticky-footer">
            <Button
              onClick={() => {
                navigate(`/operation/settlements/energyAssetsBill${search}`);
              }}
            >
              返回
            </Button>
          </Space>
        </Form>
      </Wrapper>
    </Spin>
  );
};
export default DetailEnergyAssetsBill;
