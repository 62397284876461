import { RestType } from '@/api/shiftCalendar';
import { Button } from '@maxtropy/components';
import { Col, Divider, Form, Modal, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import ShowInput from '@/shared/components/ShowInput';
import ShiftTag from '../ShiftTag';
import TimeGrid from '../TimeGrid';
import styles from './index.module.scss';
import { colorsItems, createRangeTimeStr, formateRestType, options, ShiftInfo } from './ShiftOps';
import {
  apiV2WorkShiftCalendarDetailPost,
  V2WorkShiftCalendarDetailPostResponse,
} from '@maxtropy/device-customer-apis-v2';

const shiftViewsColors = [...colorsItems];

interface Iprops {
  op?: string;
  confirm?: () => void;
  cancel?: () => void;
  id?: number;
}

const ShiftView: React.FC<Iprops> = ({ cancel, confirm, id }) => {
  const [detail, setDetail] = useState<V2WorkShiftCalendarDetailPostResponse>();
  const [selectShiftList, setSelectShiftList] = useState<ShiftInfo[]>([]);
  useEffect(() => {
    if (id) {
      apiV2WorkShiftCalendarDetailPost({ id }).then(res => {
        setDetail(res);
        if (res.workShifts) {
          setSelectShiftList(
            res.workShifts.map((item, index) => {
              return {
                ...item,
                colors: shiftViewsColors[index],
              };
            })
          );
        }
      });
    }
  }, [id]);
  return (
    <>
      <Modal
        title="查看班次日历"
        open
        width={800}
        onCancel={() => cancel?.()}
        footer={
          <Space size={10}>
            <Button onClick={() => cancel?.()}>取消</Button>
            <Button type="primary" onClick={() => confirm?.()}>
              编辑
            </Button>
          </Space>
        }
      >
        <div className={styles.outer}>
          <div className={styles.form_content}>
            <Form labelAlign="left" labelCol={{ style: { width: 85 } }}>
              <Row>
                <Col span={16}>
                  <Form.Item label="班次日历">
                    <ShowInput value={detail?.name} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item label="休息日设置" shouldUpdate>
                    {() => {
                      let op = options[detail?.restType! as RestType];
                      let arr = detail?.restSeg?.split(',');
                      let filters = op?.filter(item => arr?.includes(item.value)).map(item => item.label) ?? [];

                      return (
                        <ShowInput
                          value={
                            <>
                              {formateRestType[detail?.restType! as RestType]}
                              <Divider type="vertical" />
                              {filters.toString()}
                            </>
                          }
                        />
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
          <div className={styles.drag_area}>
            <div className={styles.title}>工作日班次:</div>
            <div className={styles.drag_box}>
              <div className={styles.drag_right}>
                <div className={styles.drag_right_top}>
                  <div>
                    <Space size={[5, 8]} wrap>
                      {selectShiftList.map(item => (
                        <ShiftTag bg={item.colors?.bg} borderBg={item.colors?.border} showClose={false}>
                          {item.name + createRangeTimeStr(item.timeSeg as string)}
                        </ShiftTag>
                      ))}
                    </Space>
                  </div>
                </div>
                <div className={styles.drag_right_bottom}>
                  <TimeGrid timeArr={selectShiftList} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShiftView;
