import * as React from 'react';
import { ColumnType } from 'antd/es/table';
import { parseOnDisplay } from '../../../FormulaInput';
import { getRange } from '../../../../utils/utils';
import { EllipsisSpan } from '@maxtropy/components';

export const getColumns = (map: Map<string, string>, alterMode?: boolean): ColumnType<any>[] => [
  {
    title: '数据属性/标识符',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (_: any, record) => (
      <EllipsisSpan value={!!record.hasProperty ? record.dataPropertyName : record.identifier} />
    ),
  },
  {
    title: '单位',
    dataIndex: 'physicalUnitGeneralName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} defaultValue={alterMode ? '点击编辑操作修改' : undefined} />,
  },
  {
    title: '虚拟点类型',
    dataIndex: 'hasProperty',
    ellipsis: { showTitle: true },
    render: (value: boolean) => <EllipsisSpan value={value ? '建模虚拟点' : '非建模虚拟点'} />,
  },
  {
    title: '是否可写',
    dataIndex: 'writable',
    ellipsis: { showTitle: true },
    render: (v: string) => (
      <EllipsisSpan value={typeof v === 'boolean' ? (v ? '是' : '否') : alterMode ? '点击编辑操作修改' : undefined} />
    ),
  },
  {
    title: '公式',
    dataIndex: 'formula',
    ellipsis: { showTitle: true },
    render: (v: string) => (
      <EllipsisSpan value={v ? parseOnDisplay(v, map) : alterMode ? '点击编辑操作修改' : undefined} />
    ),
  },
  {
    title: '数值合理区间',
    dataIndex: 'valueRange',
    ellipsis: { showTitle: true },
    render: (_: any, record) => {
      const value = getRange(record.parameters?.valueFloor, record.parameters?.valueCeiling);
      return <EllipsisSpan value={value !== '--' ? value : alterMode ? '点击编辑操作修改' : undefined} />;
    },
  },
  ...(!alterMode
    ? [
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: { showTitle: true },
          render: (value: string) => <EllipsisSpan value={value} />,
        },
      ]
    : []),
];
