import { Form, Col, Row, Space, FormInstance } from 'antd';

import React, { useEffect, useState } from 'react';
import { Button, InputNumber, Modal } from '@maxtropy/components';

import ShowInput from '@/shared/components/ShowInput';
import { isNil } from 'lodash';
import styles from './index.module.scss';
import {
  apiV2CarbonFootprintCalculateAddFillPost,
  apiV2CarbonFootprintCalculateConsumptionPost,
} from '@maxtropy/device-customer-apis-v2';
import { Procedure } from '../../components/AddFilling/const';
import { InfoCircleOutlined } from '@ant-design/icons';
import { AutoFillSupport } from '@/pages/ProductCarbonFootprint/NewModel/utils';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  id?: number;
  updateFn: () => void;
  fillingData: Procedure;
  form: FormInstance;
}

const EnergyFillingModal: React.FC<Props> = ({ open, setOpen, id, updateFn, fillingData, form }) => {
  const [autoGatherVisible, setAutoGatherVisible] = useState<boolean>(false);
  const [autoGatherFlag, setAutoGatherFlag] = useState<number>(0);
  const [autoGatherFlagMsg, setAutoGatherFlagMsg] = useState<string>();
  const [autoGatherVisibleButton, setAutoGatherVisibleButton] = useState<boolean>(false);
  useEffect(() => {
    setAutoGatherFlag(isNil(fillingData.autoGatherFlag) ? 0 : fillingData?.autoGatherFlag);
  }, [fillingData]);

  const getConsumption = () => {
    setAutoGatherFlag(1);
    apiV2CarbonFootprintCalculateConsumptionPost({ id }).then(res => {
      form.setFieldsValue({ actualQuantity: res.value });
    });
    setAutoGatherVisible(true);
  };

  useEffect(() => {
    if (fillingData.isInput === 0) return;
    if (fillingData.autoFillSupport === AutoFillSupport.SUPPORT) {
      setAutoGatherVisibleButton(true);
      setAutoGatherFlagMsg(fillingData?.autoGatherFlagMsg);
      setAutoGatherVisible(true);

      // apiV2CarbonFootprintCalculateConsumptionPost({ id }).then(res => {
      //   if (fillingData.filledFlag === 0) {
      //     form.setFieldsValue({ actualQuantity: res.value });
      //     setAutoGatherFlag(isNil(res.value) ? 0 : 1);
      //     setAutoGatherVisible(isNil(res.value) ? false : true);
      //   } else {
      //     setAutoGatherFlag(isNil(res.value) ? 0 : 1);
      //     setAutoGatherVisible(isNil(res.value) ? false : res.value === form.getFieldValue('actualQuantity'));
      //   }
      // });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = () => {
    form.validateFields().then(res => {
      const params = {
        calculateInputOrOutputId: fillingData?.id,
        type: fillingData?.isInput,
        actualQuantity: res.actualQuantity,
        autoGatherFlag,
      };
      apiV2CarbonFootprintCalculateAddFillPost(params).then(result => {
        setOpen(false);
        updateFn();
        setAutoGatherFlag(0);
      });
    });
  };

  return (
    <Modal
      size="normal"
      title="填报"
      open={open}
      onOk={onFinish}
      onCancel={() => {
        setOpen(false);
        setAutoGatherFlag(0);
      }}
    >
      <div className={styles.content}>
        <Form form={form} labelCol={{ flex: '140px' }}>
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <Form.Item label="名称" labelAlign="left" name="name">
                <ShowInput></ShowInput>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="理论用量" labelAlign="left" name="theoreticalQuantity">
                <ShowInput></ShowInput>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Space size={'large'}>
                <Form.Item
                  label="实际用量"
                  labelAlign="left"
                  name="actualQuantity"
                  style={{ marginBottom: 0 }}
                  rules={[{ message: '请输入实际用量', required: true }]}
                >
                  <InputNumber
                    addonAfter={fillingData?.unitCode ?? '--'}
                    onChange={val => {
                      setAutoGatherFlag(0);
                      setAutoGatherVisible(false);
                    }}
                  ></InputNumber>
                </Form.Item>
                {fillingData?.isInput === 1 &&
                  (autoGatherVisibleButton ? (
                    <Button type="link" onClick={getConsumption} style={{ marginLeft: '32px' }}>
                      获取最新用量
                    </Button>
                  ) : null)}
              </Space>
            </Col>
            {autoGatherVisible ? (
              <div className={styles.autoTip}>
                <InfoCircleOutlined style={{ color: 'var(--warning-color)', fontSize: '14px', marginRight: '4px' }} />
                {autoGatherFlagMsg ?? '数据由数采设备自动采集'}
              </div>
            ) : (
              <div className={styles.emptyTip}></div>
            )}
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default EnergyFillingModal;
