import { EllipsisSpan, Paging, Table, usePaging, useAsync, Form, Modal, Input } from '@maxtropy/components';
import { Cascader, Col } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatOptionData, getChildNodesByParentIds } from '@/shared/components/CascadingMultipleSelector/utils';
import Filter from '@/shared/components/Filter';
import { useQuery } from '@/shared/utils/utils';
import { addDevice, checkDeviceBind, Device, DevicePageRequest, getDevicePage } from '../../api/ouDevice';
import styles from './index.module.scss';
import { DefaultOptionType } from 'antd/es/cascader';
import { getDeviceTypeTree } from '@/api/deviceType';

interface FilterParams {
  nameOrCode?: string | undefined;
  deviceType: Array<Array<number>> | undefined;
}

type SearchParams = Omit<DevicePageRequest, 'page' | 'size'>;

const columns: ColumnsType<Device> = [
  {
    title: '设备编号',
    dataIndex: 'code',
    width: 200,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备SN',
    dataIndex: 'sn',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    dataIndex: 'typeName',
    width: 300,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '型号',
    dataIndex: 'modelName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];
export interface AddDeviceProps {
  id: number;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onSuccess: () => void;
}

const AddDevice: React.FC<AddDeviceProps> = ({ visible, id, setVisible, onSuccess }) => {
  const pagingInfo = usePaging();
  const [form] = Form.useForm();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const deviceTypeData = useAsync(getDeviceTypeTree);

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        getDevicePage(id, {
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) setTotalCount(res.total);
          return res.list;
        }),
      [id, pageOffset, pageSize, searchParams, setTotalCount]
    )
  );

  const onReset = () => {
    form.resetFields();
    form.submit();
    setPageOffset(1);
  };

  const onFinish = (val: FilterParams) => {
    // const deviceType = getAllChild(val.deviceType, formatOptionData(deviceTypeData));
    const deviceType = getChildNodesByParentIds(val.deviceType, deviceTypeData?.tree);
    const params: SearchParams = {
      nameOrCode: val.nameOrCode,
      deviceType,
    };
    setSearchParams(params);
    setPageOffset(1);
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const onOk = async () => {
    if (!selectedRowKeys.length) return;

    const addDeviceAction = () => {
      addDevice(+id, { deviceIds: selectedRowKeys as number[] }).then(() => {
        onSuccess();
        setVisible(false);
      });
    };

    try {
      const bind = await checkDeviceBind(+id, {
        deviceIds: selectedRowKeys as number[],
      });
      if (bind) {
        Modal.confirm({
          centered: true,
          title: (
            <div className={styles.popConfirm}>
              <div className={styles.title}>所选的设备中，存在已经绑定到 其他同类型运营单元的设备</div>
              <div className={styles.gray}>
                <span className={styles.red}>重复绑定可能影响数据准确性</span>
                ，你还要继续吗？
              </div>
            </div>
          ),
          okText: '继续',
          onOk: () => {
            addDeviceAction();
          },
        });
      } else {
        addDeviceAction();
      }
    } finally {
    }
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  return (
    <>
      <Modal destroyOnClose width={1024} title="设备选择" open={visible} onOk={onOk} onCancel={() => setVisible(false)}>
        <div className={styles.deviceModal}>
          <Filter<FilterParams> form={form} onFinish={val => onFinish(val as FilterParams)} onReset={onReset}>
            <Col span={8}>
              <Form.Item name="nameOrCode" label="编号/名称">
                <Input placeholder={'请输入编号或名称查询'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="deviceType" label="所属类目">
                <Cascader
                  options={formatOptionData(deviceTypeData)}
                  allowClear={false}
                  fieldNames={{ children: 'child' }}
                  multiple
                  maxTagCount="responsive"
                  showSearch={{ filter }}
                  placeholder={'请选择所属类目'}
                />
              </Form.Item>
            </Col>
          </Filter>
          <div className={styles.table_text}>
            <div className={styles.tips}></div>
            <div className={styles.action}>
              <span className={styles.text} style={{ marginRight: 5 }}>
                点击前往
              </span>
              <Link to={`/device/manage/device/create`} target="_blank">
                添加设备
              </Link>
            </div>
          </div>
          <Table
            showSelectedCount
            selectedCount={selectedRowKeys.length}
            scroll={{ y: 400 }}
            rowKey="id"
            loading={isLoading}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
          >
            <Paging pagingInfo={pagingInfo} />
          </Table>
        </div>
      </Modal>
    </>
  );
};

export default AddDevice;
