import { Row, Space } from 'antd';
import { DataPointType } from '../../../types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { EdgeDeviceTemplatePoint } from './Static';
import { PointContext, PointContextProps } from '../../EdgeDevicePointInfo/contextTypes';
import { EllipsisSpan, Button, Tabs, Table } from '@maxtropy/components';
import { useQuery } from '../../../utils/utils';
import { getDataProperties } from '../../../api/options';
import AddDeletePoint from './AddDeletePoint';
import { PlusOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

interface DeleteEdgePointProps {
  onUpdate: (values: any) => void;
  onDelete: (values: any) => void;
}

const DeleteEdgePoint: React.FC<DeleteEdgePointProps> = props => {
  const [activeKey, setActiveKey] = useState<DataPointType>(DataPointType.BASE_POINT);

  return (
    <Tabs
      activeKey={`${activeKey}`}
      onChange={(key: string) => {
        setActiveKey(Number(key));
      }}
      destroyInactiveTabPane={true}
    >
      <TabPane tab="采集点" key={DataPointType.BASE_POINT} forceRender>
        <DeletePoint {...props} pointType={DataPointType.BASE_POINT} activeKey={activeKey} />
      </TabPane>

      <TabPane tab="虚拟点" key={DataPointType.VIRTUAL_POINT} forceRender>
        <DeletePoint {...props} pointType={DataPointType.VIRTUAL_POINT} activeKey={activeKey} />
      </TabPane>

      <TabPane tab="静态点" key={DataPointType.STATIC_POINT} forceRender>
        <DeletePoint {...props} pointType={DataPointType.STATIC_POINT} activeKey={activeKey} />
      </TabPane>
    </Tabs>
  );
};

const indexColumn: ColumnType<EdgeDeviceTemplatePoint> = {
  title: '序号',
  width: 70,
  render: (_, record, index) => index + 1,
};

interface DeletePointProps {
  activeKey: DataPointType;
  onUpdate: (values: any) => void;
  onDelete: (values: any) => void;
  pointType: DataPointType;
}

const DeletePoint: React.FC<DeletePointProps> = props => {
  const { activeKey, onUpdate, onDelete, pointType } = props;
  const { dataSource, editable, setRow, info } = useContext(PointContext) as PointContextProps<EdgeDeviceTemplatePoint>;

  const pointName = useMemo(() => {
    let name = '';
    switch (pointType) {
      case DataPointType.BASE_POINT:
        name = '采集点';
        break;
      case DataPointType.VIRTUAL_POINT:
        name = '虚拟点';
        break;
      case DataPointType.STATIC_POINT:
        name = '静态点';
        break;
    }
    return name;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: dataPropertiesAll = [] } = useQuery(
    useCallback(
      () =>
        info?.deviceTypeId
          ? getDataProperties(info.iotProtocol, info.deviceTypeId, info.physicalModelId)
          : Promise.resolve([]),
      [info?.iotProtocol, info?.deviceTypeId, info?.physicalModelId]
    )
  );

  const columns: ColumnType<any>[] = [
    {
      title: '数据属性/标识符',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (_: any, record) => (
        <EllipsisSpan
          value={
            !!record.hasProperty || record.pointType === DataPointType.STATIC_POINT
              ? record.dataPropertyName
              : record.identifier
          }
        />
      ),
    },
    {
      title: `${pointName}类型`,
      dataIndex: 'hasProperty',
      ellipsis: { showTitle: true },
      render: (value: boolean, record) => (
        <EllipsisSpan
          value={record.pointType === DataPointType.STATIC_POINT || value ? `建模${pointName}` : `非建模${pointName}`}
        />
      ),
    },
  ];

  const opColumns: ColumnsType<EdgeDeviceTemplatePoint> = [
    {
      title: '操作',
      width: 150,
      fixed: 'right',
      render: (_, record, index) => (
        <Space className="action-buttons">
          <Button type="link" onClick={() => onDelete(record)}>
            取消删除
          </Button>
        </Space>
      ),
    },
  ];

  const editColumns = [...(editable ? opColumns : [])];

  const onAdd = (values: any, shouldContinue?: boolean) => {
    onUpdate(values);
    if (shouldContinue) {
      setRow?.({ pointType });
    } else {
      setRow?.(undefined);
    }
  };

  return (
    <>
      <Row style={{ marginBottom: 20 }}>
        <Button type="primary" icon={<PlusOutlined />} onClick={() => setRow?.({ pointType })}>
          添加需要删除的数据点所绑定的数据属性/标识符
        </Button>
      </Row>
      <Table
        sticky
        rowKey="id"
        scroll={{ y: 500 }}
        dataSource={dataSource?.filter(item => item.pointType === pointType)}
        columns={[indexColumn, ...columns, ...(editColumns || [])]}
        pagination={false}
      />
      {activeKey === pointType && (
        <AddDeletePoint dataProperties={dataPropertiesAll} usedIdentifier={[]} submit={onAdd} />
      )}
    </>
  );
};

export default DeleteEdgePoint;
