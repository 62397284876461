import {
  AddEnergyPlanBody,
  ObjectType,
  ObjectTypeDisplay,
  addEnergyPlan,
  checkNameUnique,
  getEnergyPlanDetail,
  updateEnergyPlan,
} from '@/api/plustekEnergyPlan';
import { Form, DatePicker, Modal, TreeSelect } from '@maxtropy/components';
import { Input, Select, TreeSelectProps } from 'antd';
import { debounce, isNil } from 'lodash-es';
import { useEffect, useState } from 'react';
import { EnergyUnitTreeItem, getEnergyUnitTree } from '@/api/energyUnitMapping';
import dayjs from 'dayjs';
import {
  V2EnergyAnalysisListByOu2PostResponse,
  apiV2EnergyAnalysisListByOu2Post,
} from '@maxtropy/device-customer-apis-v2';

interface AddEnergyPlanModalProps {
  hasEditPlan: boolean; // 是否已经编辑计划
  editId?: number;
  open: boolean;
  setOpen: (open: boolean) => void;
  objectType: ObjectType;
  updateFn: () => void;
}

const formatToNodeData = (data: EnergyUnitTreeItem[]): TreeSelectProps['treeData'] => {
  return data.map(i => {
    return {
      title: i.name,
      value: i.energyUnitId,
      disabled: i.disabled,
      children: i.children && i.children.length > 0 ? formatToNodeData(i.children) : [],
    };
  });
};

export type GroupDetails = Exclude<V2EnergyAnalysisListByOu2PostResponse['list'], undefined>[number];

const AddEnergyPlanModal: React.FC<AddEnergyPlanModalProps> = props => {
  const { hasEditPlan, editId, open, setOpen, objectType, updateFn } = props;
  const [form] = Form.useForm();
  const [unitGroup, setUnitGroup] = useState<GroupDetails[]>([]);
  const [treeNodeData, setTreeNodeData] = useState<TreeSelectProps['treeData']>([]);
  const planObjectId = Form.useWatch('planObjectId', form);

  useEffect(() => {
    apiV2EnergyAnalysisListByOu2Post({}).then(res => {
      setUnitGroup(res.list ?? []);
    });
  }, []);

  // 获取用能单元树
  useEffect(() => {
    if (isNil(planObjectId)) return;
    getEnergyUnitTree(planObjectId).then(res => {
      const tree = formatToNodeData(res);
      setTreeNodeData(tree);
    });
  }, [planObjectId]);

  useEffect(() => {
    if (isNil(editId)) return;
    getEnergyPlanDetail(editId).then(res => {
      const { planName, planObjectId, energyUnitIds, energyYear } = res;
      form.setFieldsValue({
        planName,
        planObjectId,
        energyUnitIds,
        energyYear: dayjs(energyYear),
      });
    });
  }, [editId, form]);

  const onFinish = (v: AddEnergyPlanBody) => {
    const body = {
      ...v,
      energyUnitIds: v.energyUnitIds.map(i => (typeof i === 'number' ? i : Number(i.value))),
      energyYear: dayjs(v.energyYear).format('YYYY-MM-DD'),
      planObjectType: objectType,
    };
    if (editId) {
      updateEnergyPlan({
        ...body,
        id: editId,
      }).then(() => {
        setOpen(false);
        updateFn();
      });
      return;
    }
    addEnergyPlan(body).then(() => {
      setOpen(false);
      updateFn();
    });
  };

  return (
    <Modal
      open={open}
      title={`${!isNil(editId) || hasEditPlan ? '编辑' : '新建'}用能计划-${ObjectTypeDisplay[objectType]}`}
      onOk={() => {
        const cannotSubmit = form.getFieldsError(['planName'])[0].errors.length > 0;
        if (cannotSubmit) return;
        form.submit();
      }}
      onCancel={() => {
        setOpen(false);
      }}
    >
      <Form
        style={{ padding: '8px 48px' }}
        form={form}
        onFinish={onFinish}
        labelAlign="left"
        labelCol={{ flex: '128px' }}
      >
        <Form.Item
          label="用能计划名称"
          name="planName"
          rules={[
            {
              required: true,
              message: '请输入用能计划名称',
            },
          ]}
        >
          <Input
            onChange={debounce((e: any) => {
              if (isNil(e.target.value) || e.target.value === '') return;
              checkNameUnique({
                id: editId,
                planName: e.target.value,
              }).then(res => {
                if (isNil(res)) return;
                if (!res.unique) {
                  form.setFields([
                    {
                      name: 'planName',
                      errors: ['租户下用能计划名称重复，请重新输入！'],
                    },
                  ]);
                } else {
                  form.setFields([
                    {
                      name: 'planName',
                      errors: [],
                    },
                  ]);
                }
              });
            }, 500)}
          />
        </Form.Item>
        <Form.Item
          label="用能分析组"
          name="planObjectId"
          rules={[
            {
              required: true,
              message: '请选择用能分析组',
            },
          ]}
        >
          <Select
            placeholder="请选择"
            fieldNames={{ label: 'energyGroupName', value: 'energyGroupId' }}
            options={unitGroup}
            onChange={() => {
              form.setFieldsValue({ energyUnitIds: undefined });
            }}
            disabled={hasEditPlan}
          />
        </Form.Item>
        <Form.Item
          label="用能单元"
          name="energyUnitIds"
          rules={[
            {
              required: true,
              message: '请选择用能单元',
            },
          ]}
        >
          <TreeSelect
            treeData={treeNodeData}
            maxTagCount={3}
            treeCheckStrictly
            treeCheckable
            showCheckedStrategy="SHOW_ALL"
            treeDefaultExpandAll
            placeholder="请选择"
          />
        </Form.Item>
        <Form.Item
          label="年度选择"
          name="energyYear"
          rules={[
            {
              required: true,
              message: '请输入年度选择',
            },
          ]}
        >
          <DatePicker picker="year" disabled={hasEditPlan} style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEnergyPlanModal;
