import { Button, EllipsisSpan, FormTitle, SubContent, Table, useBreadcrumbRoutes, Wrapper } from '@maxtropy/components';
import { Col, Form, Row } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import ShowInput from '@/shared/components/ShowInput';
import styles from './index.module.scss';
import { apiV2WorkShiftCalendarConfigInfoPost } from '@maxtropy/device-customer-apis-v2';

const detailColumns = [
  {
    title: '用能分析组',
    dataIndex: 'energyGroupName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '应用单元',
    dataIndex: 'energyUnitName',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v} />,
  },
];

const routes = [{ name: `查看应用配置` }];
interface DetailTableData {
  energyUnitName?: string;
  energyGroupName?: string;
}

const DetailAppUnit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [detailForm] = Form.useForm();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [loading, setLoading] = useState<boolean>(false);
  const [detailTableData, setDetailTableData] = useState<DetailTableData[]>([]);
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const workShiftCalendarId = urlSearchParams.get('workShiftCalendarId') || undefined;

  useEffect(() => {
    if (id) {
      setLoading(true);
      apiV2WorkShiftCalendarConfigInfoPost({ id })
        .then(res => {
          detailForm.setFieldsValue({
            startDate: res.startDate,
            endDate: res.endDate,
          });
          const detailTable = (res?.groupDetails ?? []).map(i => {
            return {
              energyGroupName: i?.energyGroupName,
              energyUnitName: i?.energyUnits?.map(item => item?.energyUnitName).join('、'),
            };
          });
          setDetailTableData(detailTable);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, detailForm]);

  return (
    <Wrapper isLoading={loading} routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title={routes[0].name} />
      <SubContent>
        <Form form={detailForm}>
          <Row>
            <Col span={12}>
              <Form.Item label="起始时间" name="startDate">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="结束时间" name="endDate">
                <ShowInput />
              </Form.Item>
            </Col>
          </Row>
          <p style={{ color: 'rgba(255, 255, 255, 0.85)', fontSize: 14 }}>选择单元：</p>
          <Table style={{ marginBottom: 33 }} rowKey="id" columns={detailColumns} dataSource={detailTableData} />
        </Form>
      </SubContent>
      <div className="sticky-footer" style={{ paddingLeft: 32 }}>
        <Button>
          <Link to={`/energy/teamShift/shiftCalendar/configuration/${workShiftCalendarId}`}>返回</Link>
        </Button>
      </div>
    </Wrapper>
  );
};

export default DetailAppUnit;
