import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  SubContent,
  Wrapper,
  useBreadcrumbRoutes,
  Button,
  Table,
  useUpdate,
  EllipsisSpan,
  Modal,
  TreeSelect,
  InputNumber,
  Select,
  FormTitle,
  Form,
  Empty,
  Tag,
  BaseInfoContent,
} from '@maxtropy/components';
import { Col, Row, Space, Button as AntdButton, TreeDataNode, Spin } from 'antd';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ShowInput from '@/shared/components/ShowInput';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import AdaptedUnitModal, { flattenUnitsTreeData } from './AdaptedUnitModal';
import DemandMeterModal from './DemandMeterModal';
import { ColumnType } from 'antd/es/table';
import styles from './index.module.scss';
import {
  V2EnergyAnalysisUnitConfGroupListPostResponse,
  V2EnergyGroupCostConfigDetailPostResponse,
  V2EnergyGroupCostConfigElectricityContractListPostResponse,
  V2EnergyGroupCostConfigMediumContractListPostResponse,
  apiV2EnergyAnalysisDetailPost,
  apiV2EnergyAnalysisUnitConfGroupListPost,
  apiV2EnergyGroupCostConfigDetailPost,
  apiV2EnergyGroupCostConfigElectricityContractListPost,
  apiV2EnergyGroupCostConfigMediumContractListPost,
  apiV2EnergyGroupCostConfigSavePost,
} from '@maxtropy/device-customer-apis-v2';

type EnergyConsumptionUnitTreeNode = {
  value: number;
  title: string;
  disabled?: boolean;
  children?: EnergyConsumptionUnitTreeNode[];
};

export type ElectricalCostContract = Exclude<
  V2EnergyGroupCostConfigElectricityContractListPostResponse['list'],
  undefined
>[number];

export type OtherCostContract = Exclude<
  V2EnergyGroupCostConfigMediumContractListPostResponse['list'],
  undefined
>[number];

export type UnitConfGroupItem = Exclude<V2EnergyAnalysisUnitConfGroupListPostResponse['list'], undefined>[number];

// 格式化用能单元树, 变成 antd 树结构
const formatUnitsTreeData = (data: UnitConfGroupItem[]) => {
  return data.map(item => {
    const res: TreeDataNode = {
      ...item,
      key: item.id ?? '',
      title: item.name,
      children: item.childList ? formatUnitsTreeData(item.childList) : [],
    };
    return res;
  });
};

// 根据用能单元树得到用能单元下拉框树
const formatTreeDataToOptions = (data: UnitConfGroupItem[]) => {
  return data.map(item => {
    const res: EnergyConsumptionUnitTreeNode = {
      value: item.id as number,
      title: item.name as string,
      children: item.childList ? formatTreeDataToOptions(item.childList) : [],
    };
    return res;
  });
};

type ElectricityDemandConfig = {
  energyConsumptionUnit?: number;
  capacity?: number;
  demandMeters?: { id?: number; name?: string }[];
};

type CostFormValues = {
  electricityCost?: number;
  electricityDemandConfig?: ElectricityDemandConfig[];
  otherCost?: {
    contract?: number;
    energyConsumptionUnits?: number[];
    contractName?: string;
  }[];
};

export default function EnergyConsumptionCostConfig() {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const { id } = useParams<{ id: string }>();
  const [updateState, forceUpdate] = useUpdate();
  const [urlSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  // 最上面详情表单
  const [form] = Form.useForm();
  // 成本表单
  const [costForm] = Form.useForm<CostFormValues>();
  // 是否为编辑状态
  const [isEdit, setIsEdit] = useState<boolean>(false);
  // 用电成本合同下拉列表
  const [electricalCostContracts, setElectricalCostContracts] = useState<ElectricalCostContract[]>([]);
  // 用电需量配置用能单元下拉框
  const [electricalCostContractsEnergyConsumptionUnitOptions, setElectricalCostContractsEnergyConsumptionUnitOptions] =
    useState<EnergyConsumptionUnitTreeNode[]>([]);
  // 监听用电成本
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const electricityCost = Form.useWatch('electricityCost', costForm);
  // 监听用电需量配置表格表单
  const electricityDemandConfig = Form.useWatch('electricityDemandConfig', costForm);
  // 监听其他用能/工质成本
  const otherCost = Form.useWatch('otherCost', costForm);
  // 其他用能/工质成本下拉列表
  const [otherCostContracts, setOtherCostContracts] = useState<OtherCostContract[]>([]);
  // 适配单元模态框显示
  const [showAdaptedUnitModal, setShowAdaptedUnitModal] = useState<boolean>(false);
  // 适配单元对应的索引
  const [adaptedUnitsIndex, setAdaptedUnitsIndex] = useState<number | null>(null);
  // 用能单元树
  const [unitsTree, setUnitsTree] = useState<TreeDataNode[]>([]);
  // 扁平化的用能单元树
  const flattenedUnitsTree = useMemo(() => {
    return flattenUnitsTreeData(unitsTree);
  }, [unitsTree]);
  // 需量表(电表)模态框
  const [showDemandMeterModal, setShowDemandMeterModal] = useState<boolean>(false);
  // 用电成本下的需量表对应的索引
  const [demandMeterIndex, setDemandMeterIndex] = useState<number | null>(null);
  // 路由
  const navigate = useNavigate();
  // 已选择的用电成本
  const [selectedElectricityContract, setSelectedElectricityContract] = useState<
    V2EnergyGroupCostConfigDetailPostResponse['electricityContract']
  >({});

  // 其他用能/工质成本下拉框(与已经选择的互斥)
  const getOtherCostContractsOptions = useCallback(
    (currentValue?: React.Key) => {
      // 除自己以外已选的成本, 非空
      const exclusiveSelectedContracts = otherCost
        ?.filter(c => c?.contract)
        ?.filter(c => (currentValue ? String(c?.contract) !== String(currentValue) : true));

      const options = otherCostContracts.map(c => ({
        label: `${c.contractNumber}-${c.name}`,
        value: c.id,
        disabled: exclusiveSelectedContracts?.map(c => c.contract)?.includes(c.id),
      }));

      return options;
    },
    [otherCost, otherCostContracts]
  );

  // 用电成本配置里用能单元下拉框(与已经选择的互斥)
  const getElectricityContractUnitOptions = useCallback(
    (currentValue?: React.Key) => {
      // 除自己以外已选的用能单元, 非空

      const exclusiveSelectedUnits = electricityDemandConfig
        ?.filter(c => c?.energyConsumptionUnit)
        ?.filter(c => (currentValue ? String(c?.energyConsumptionUnit) !== String(currentValue) : true));

      // 查看是否需要禁用
      const formatTreeDataOptionsWithDisabled = (data: EnergyConsumptionUnitTreeNode[]) => {
        return data.map(item => {
          const res: EnergyConsumptionUnitTreeNode = {
            value: item?.value ?? '',
            title: item.title,
            disabled: exclusiveSelectedUnits?.map(u => u.energyConsumptionUnit)?.includes(item?.value),
            children: item.children ? formatTreeDataOptionsWithDisabled(item.children) : [],
          };
          return res;
        });
      };

      return formatTreeDataOptionsWithDisabled(electricalCostContractsEnergyConsumptionUnitOptions);
    },
    [electricalCostContractsEnergyConsumptionUnitOptions, electricityDemandConfig]
  );

  // 最上面的详情
  useEffect(() => {
    if (id) {
      apiV2EnergyAnalysisDetailPost({ energyUnitId: id }).then(res => {
        let { name, customerNameList } = res;
        form.setFieldsValue({ name: name ?? '--', mcidList: customerNameList ? customerNameList.join() : '--' });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, updateState]);

  // 用电成本下拉框
  useEffect(() => {
    apiV2EnergyGroupCostConfigElectricityContractListPost({ energyUnitGroupId: id! }).then(res => {
      setElectricalCostContracts(res.list ?? []);
    });
  }, [id, updateState]);

  // 获取用能单元下拉列表
  useEffect(() => {
    apiV2EnergyAnalysisUnitConfGroupListPost({ energyUnitId: id! }).then(res => {
      const options = formatTreeDataToOptions(res?.list ?? []);
      setUnitsTree(formatUnitsTreeData(res?.list ?? []));
      setElectricalCostContractsEnergyConsumptionUnitOptions(options);
    });
  }, [id, updateState]);

  // 获取其他用能/工质成本下拉列表
  useEffect(() => {
    apiV2EnergyGroupCostConfigMediumContractListPost({
      energyUnitGroupId: id!,
    }).then(res => {
      setOtherCostContracts(res.list ?? []);
    });
  }, [id, updateState]);

  // 获取已填的表单详情, 包括用电成本, 用电需量配置, 其他用能/工质成本
  useEffect(() => {
    setLoading(true);
    apiV2EnergyGroupCostConfigDetailPost({ energyUnitGroupId: id! }).then(res => {
      setLoading(false);
      // 如果是未生效的合同, 认为是脏数据, 后端在下拉列表不显示, 需要回显 name
      const electricityContractId =
        res?.electricityContract?.status === 0 ? res?.electricityContract?.number : res?.electricityContract?.id;
      if (res) {
        setSelectedElectricityContract(res.electricityContract);
        costForm.setFieldsValue({
          electricityCost: electricityContractId as number,
          electricityDemandConfig: res?.demandConfig?.map(c => ({
            energyConsumptionUnit: c?.energyConsumptionUnitId,
            capacity: c?.capacity,
            demandMeters: [{ id: c?.deviceId, name: c?.deviceName }],
          })),
          // @ts-ignore
          otherCost: res?.costRate?.map(r => ({
            contract: r?.mediumContractId,
            // 传入合同名称, 查看详情的时候直接返显
            contractName: r?.mediumContractName,
            energyConsumptionUnits: r?.rateUnitLink?.map(r => r?.energyConsumptionUnitId),
          })),
        });
      }
    });
  }, [id, updateState, costForm]);

  const columns: ColumnType<ElectricityDemandConfig>[] = [
    {
      title: '用能单元',
      dataIndex: 'energyConsumptionUnit',
      ellipsis: { showTitle: true },
      render: (text: number | undefined, record, index: number) => {
        if (isEdit) {
          return (
            <Form.Item noStyle name={['electricityDemandConfig', index, 'energyConsumptionUnit']}>
              <TreeSelect
                treeDefaultExpandAll
                allowClear
                treeData={getElectricityContractUnitOptions(electricityDemandConfig?.[index]?.energyConsumptionUnit)}
                placeholder="请选择用能单元"
                onChange={() => {
                  // 选择其他用能单元的时候清空容量和需量表
                  costForm.setFieldValue(['electricityDemandConfig', index, 'capacity'], undefined);
                  costForm.setFieldValue(['electricityDemandConfig', index, 'demandMeters'], []);
                }}
              />
            </Form.Item>
          );
        }
        return <EllipsisSpan value={flattenedUnitsTree.find(u => u.key === text)?.title ?? '--'} />;
      },
    },
    {
      title: '容量（kVA）',
      dataIndex: 'capacity',
      ellipsis: { showTitle: true },
      render: (text: number | undefined, record, index: number) => {
        if (isEdit) {
          return (
            <Form.Item noStyle name={['electricityDemandConfig', index, 'capacity']}>
              <InputNumber
                disabled={!electricityDemandConfig?.[index]?.energyConsumptionUnit}
                style={{ width: '100%' }}
                min={0}
                max={99999}
                precision={2}
                // 支持2位小数
                // formatter={value => (!isNaN(value!) ? String(value).replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') : '')}
                placeholder={`请输入容量`}
              />
            </Form.Item>
          );
        }
        return <EllipsisSpan value={text ?? '--'} />;
      },
    },
    {
      title: '需量表',
      dataIndex: 'demandMeters',
      ellipsis: { showTitle: true },
      render: (value: { id: number; name: string }[] | undefined, record, index) => {
        const device = value?.[0];
        if (device?.id && device?.name) {
          return (
            <Tag
              closable={isEdit}
              onClose={() => {
                costForm.setFieldValue(['electricityDemandConfig', index, 'demandMeters'], []);
              }}
            >
              {device?.name}
            </Tag>
          );
        }

        return <EllipsisSpan value={'--'} />;
      },
    },
    {
      title: '操作',
      render: (value: any, record, index: number) => {
        return (
          <Space size={16}>
            <Button
              disabled={!electricityDemandConfig?.[index]?.energyConsumptionUnit}
              type="link"
              onClick={() => {
                if (record.energyConsumptionUnit) {
                  setDemandMeterIndex(index);
                  setShowDemandMeterModal(true);
                }
              }}
            >
              选择需量表
            </Button>
            <Button
              type="link"
              onClick={() => {
                const newValue = electricityDemandConfig?.filter((v, i: number) => index !== i);
                costForm.setFieldValue('electricityDemandConfig', newValue);
              }}
            >
              删除
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <Wrapper className={styles.wrapper} routes={breadcrumbRoutes?.routes ?? []}>
      <FormTitle
        title="成本信息"
        extraContent={
          isEdit ? (
            <Space size="small">
              <Button
                type="primary"
                onClick={() => {
                  costForm.submit();
                }}
              >
                保存
              </Button>
              <Button
                onClick={() => {
                  Modal.confirm({
                    title: '存在编辑信息，确定丢弃未保存信息并返回？',
                    icon: <ExclamationCircleOutlined />,
                    onOk: () => {
                      setIsEdit(false);
                      forceUpdate();
                    },
                    okText: '丢弃',
                  });
                }}
              >
                取消
              </Button>
            </Space>
          ) : (
            <Space size="small">
              <Button
                type="primary"
                onClick={() => {
                  setIsEdit(true);
                }}
              >
                编辑
              </Button>
              <Button
                onClick={() => {
                  navigate(`/energy/consumption/analysis?${urlSearchParams.toString()}`);
                }}
              >
                返回
              </Button>
            </Space>
          )
        }
      />
      <SubContent title="用能成本配置">
        <Form form={form}>
          <BaseInfoContent colNum={3} layout="horizontal">
            <Form.Item name="name" label="名称">
              <ShowInput />
            </Form.Item>

            <Form.Item name="mcidList" label="所属组织">
              <ShowInput />
            </Form.Item>
          </BaseInfoContent>
        </Form>
      </SubContent>

      <Form
        form={costForm}
        onFinish={(values: CostFormValues) => {
          const electricityContractId =
            electricalCostContracts.map(c => c?.id).includes(values.electricityCost) || !values.electricityCost
              ? values.electricityCost
              : selectedElectricityContract?.id;
          apiV2EnergyGroupCostConfigSavePost({
            energyConsumptionGroupId: Number(id!),
            electricityContractId: electricityContractId,
            demandConfig: values.electricityDemandConfig?.length
              ? values?.electricityDemandConfig
                  ?.map(c => ({
                    capacity: c.capacity ?? undefined,
                    deviceId: c.demandMeters?.length ? c.demandMeters?.map(dm => dm.id)?.[0] : undefined,
                    energyConsumptionUnitId: c.energyConsumptionUnit ?? undefined,
                  }))
                  .filter(c => Object.values(c).some(el => el !== undefined))
              : undefined,
            costRate: values.otherCost?.length
              ? values.otherCost
                  .map(c => ({
                    mediumContractId: c.contract ?? undefined,
                    energyConsumptionUnitIds: c.energyConsumptionUnits?.length ? c.energyConsumptionUnits : undefined,
                  }))
                  .filter(c => Object.values(c).some(el => el !== undefined))
              : undefined,
          }).then(res => {
            setIsEdit(false);
            forceUpdate();
          });
        }}
      >
        <SubContent title="用电成本" style={{ marginBottom: 16 }}>
          <BaseInfoContent layout="horizontal">
            <Form.Item name="electricityCost" label="用电成本" wrapperCol={{ span: 6 }}>
              {isEdit ? (
                <Select
                  allowClear
                  options={electricalCostContracts.map(c => ({
                    label: c.number,
                    value: c.id,
                  }))}
                  placeholder="请选择用电合同"
                />
              ) : (
                <span>{selectedElectricityContract?.number ?? '--'}</span>
              )}
            </Form.Item>
          </BaseInfoContent>
          <Form.Item name="electricityDemandConfig" valuePropName="dataSource">
            <Table
              style={{ marginTop: 24 }}
              loading={loading}
              rowKey="id"
              columns={isEdit ? columns : columns.slice(0, columns.length - 1)}
            />
          </Form.Item>
          {isEdit ? (
            <Form.Item noStyle>
              <AntdButton
                // disabled={(electricityDemandConfig?.length ?? 0) >= (flattenedUnitsTree.length ?? 0) ? true : false}
                type="dashed"
                onClick={() => {
                  const electricityDemandConfigValues = costForm.getFieldValue('electricityDemandConfig') ?? [];
                  costForm.setFieldValue('electricityDemandConfig', [
                    ...electricityDemandConfigValues,
                    { id: Math.random(), energyConsumptionUnit: undefined, capacity: undefined, demandMeters: [] },
                  ]);
                }}
                block
                icon={<PlusOutlined />}
              >
                新增计算基本电费的单元
              </AntdButton>
            </Form.Item>
          ) : null}
        </SubContent>
        <SubContent title="其他用能/工质成本">
          <Spin spinning={loading}>
            <Form.List name="otherCost">
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map((field, index) => {
                      return (
                        <Row
                          key={field.key}
                          gutter={[32, 16]}
                          style={{
                            background: 'rgba(74,144,226,0.1)',
                            paddingTop: 24,
                            marginBottom: 16,
                            marginLeft: 0,
                            marginRight: 0,
                          }}
                        >
                          <Col span={8}>
                            <Form.Item name={[field.name, 'contract']} label={`成本${index + 1}`}>
                              {isEdit ? (
                                <Select
                                  allowClear
                                  options={getOtherCostContractsOptions(otherCost?.[field.name]?.contract)}
                                  placeholder="请选择工质合同"
                                  onChange={() => {
                                    // 选择不同的合同时清空适配单元
                                    costForm.setFieldValue(['otherCost', field.name, 'energyConsumptionUnits'], []);
                                  }}
                                />
                              ) : (
                                <span>{otherCost?.[field.name]?.contractName ?? '--'}</span>
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item name={[field.name, 'energyConsumptionUnits']} label="适配单元">
                              <Space size={8} wrap>
                                {otherCost?.[field.name]?.energyConsumptionUnits?.map((key: React.Key, i) => (
                                  <Tag
                                    style={{ marginInlineEnd: 0 }}
                                    closable={isEdit}
                                    onClose={() => {
                                      const units = otherCost?.[field.name]?.energyConsumptionUnits?.filter(
                                        u => u !== key
                                      );
                                      costForm.setFieldValue(
                                        ['otherCost', field.name, 'energyConsumptionUnits'],
                                        units
                                      );
                                    }}
                                    key={key}
                                  >
                                    {flattenedUnitsTree?.find(u => u.key === key)?.title ?? '--'}
                                  </Tag>
                                ))}
                              </Space>
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            {isEdit ? (
                              <Space>
                                <Button
                                  disabled={!otherCost?.[field.name]?.contract}
                                  type="link"
                                  onClick={() => {
                                    setShowAdaptedUnitModal(true);
                                    setAdaptedUnitsIndex(field.name);
                                  }}
                                >
                                  适配单元
                                </Button>
                                <Button
                                  type="link"
                                  onClick={() => {
                                    remove(field.name);
                                  }}
                                >
                                  删除
                                </Button>
                              </Space>
                            ) : (
                              <div />
                            )}
                          </Col>
                        </Row>
                      );
                    })}
                    {isEdit ? (
                      <Form.Item noStyle>
                        <AntdButton
                          // disabled={(otherCost?.length ?? 0) >= (otherCostContracts?.length ?? 0)}
                          type="dashed"
                          onClick={() => add({ contract: undefined, energyConsumptionUnits: [] })}
                          block
                          icon={<PlusOutlined />}
                        >
                          新增成本费率
                        </AntdButton>
                      </Form.Item>
                    ) : (
                      <Empty />
                    )}
                  </>
                );
              }}
            </Form.List>
          </Spin>
        </SubContent>

        {showDemandMeterModal && (
          <DemandMeterModal
            open={showDemandMeterModal}
            closeModal={() => {
              setShowDemandMeterModal(false);
            }}
            selectedDemandMeters={electricityDemandConfig?.[demandMeterIndex!]?.demandMeters?.filter(dm => dm.id) ?? []}
            setDemandMeters={(ds: { id?: number; name?: string }[]) => {
              if (demandMeterIndex !== null) {
                costForm.setFieldValue(['electricityDemandConfig', demandMeterIndex, 'demandMeters'], ds);
              }
            }}
            unitConfGroupId={electricityDemandConfig?.[demandMeterIndex!]?.energyConsumptionUnit}
          />
        )}

        {showAdaptedUnitModal && (
          <AdaptedUnitModal
            unitsTreeData={unitsTree}
            closeModal={() => setShowAdaptedUnitModal(false)}
            open={showAdaptedUnitModal}
            checkedKeys={adaptedUnitsIndex !== null ? otherCost?.[adaptedUnitsIndex].energyConsumptionUnits ?? [] : []}
            setAdaptedUnits={(keys: React.Key[]) => {
              if (adaptedUnitsIndex !== null) {
                costForm.setFieldValue(['otherCost', adaptedUnitsIndex, 'energyConsumptionUnits'], keys);
              }
            }}
          />
        )}
      </Form>
    </Wrapper>
  );
}
