import styles from './NewUnitConsumption/index.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { FormTitle, Wrapper, Button, useBreadcrumbRoutes, Form, InputNumber, Tag, Select } from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import {
  apiProductionEnergyConsumptionEnergyMediumByWorkCenterIdPost,
  apiUnitConsumptionDetailPost,
  ProductionEnergyConsumptionEnergyMediumByWorkCenterIdPostResponse,
  UnitConsumptionDetailPostResponse,
} from '@maxtropy/device-customer-apis';
import { isNil } from 'lodash-es';

const formItemLayout = {
  labelCol: {
    xs: { span: 2 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 8 },
    sm: { span: 14 },
  },
};
const ViewPage: React.FC = () => {
  const ThresholdSettingDefaultValue = [
    {
      energyMediumName: '标准煤',
      energyMediumId: 100,
      generalName: 'kgce',
    },
    {
      energyMediumName: '发热量',
      energyMediumId: 200,
      generalName: 'MJ',
    },
  ];

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [productSelectionVal, setProductSelectionVal] = useState<string>();
  const [centerSelectionVal, setCenterSelectionVal] = useState<string>();
  const [centerId, setCenterId] = useState<number>();

  const [energyThresholdData, setEnergyThresholdData] =
    useState<ProductionEnergyConsumptionEnergyMediumByWorkCenterIdPostResponse>(ThresholdSettingDefaultValue);

  const { id } = useParams<{ id: string }>();
  const [detailData, setDetailData] = useState<UnitConsumptionDetailPostResponse>();

  const [unit, setUnit] = useState<string[]>(['--']);

  const breadcrumbRoutes = useBreadcrumbRoutes();
  const routes = [
    {
      name: '查看单耗阈值',
    },
  ];

  const onReset = () => {
    form.resetFields();
    form.submit();
  };

  useEffect(() => {
    if (isNil(id)) return;
    apiUnitConsumptionDetailPost({ id: id }).then(res => {
      setDetailData(res);
      setProductSelectionVal(res.outputProductName);
      setCenterSelectionVal(res.workCenterName);
      setCenterId(res.workCenterId);
      // 编辑拿到阈值设定id去找对应单位 ，放到unit数组中维护
      apiProductionEnergyConsumptionEnergyMediumByWorkCenterIdPost({ workCenterId: String(res?.workCenterId!) }).then(
        resUnit => {
          const arr: string[] = (res.shopFlowUnitConsumptionThresholdValueDetailDtos ?? [])?.map(i => {
            const findUnit = resUnit.find(item => item.energyMediumId === i.energyMediumId);
            if (findUnit) {
              return findUnit.generalName ?? '--';
            } else {
              return '--';
            }
          });

          setUnit(arr);
        }
      );
    });
  }, [id]);

  useEffect(() => {
    if (isNil(detailData)) return;
    form.setFieldsValue({
      product: detailData.outputProductId,
      workCenter: detailData.workCenterId,
      valueDetailDtos: detailData?.shopFlowUnitConsumptionThresholdValueDetailDtos?.map(item => ({
        energyMediumId: item.energyMediumId,
        averageUnitConsumptionUpLimit: item.averageUnitConsumptionUpLimit,
        averageUnitConsumptionDownLimit: item.averageUnitConsumptionDownLimit,
        normalProductionUnitConsumptionUpLimit: item.normalProductionUnitConsumptionUpLimit,
        normalProductionUnitConsumptionDownLimit: item.normalProductionUnitConsumptionDownLimit,
        stopLineUnitConsumptionUpLimit: item.stopLineUnitConsumptionUpLimit,
        stopLineUnitConsumptionDownLimit: item.stopLineUnitConsumptionDownLimit,
        leisureEnergyConsumptionUpLimit: item.leisureEnergyConsumptionUpLimit,
        leisureEnergyConsumptionDownLimit: item.leisureEnergyConsumptionDownLimit,
        restEnergyConsumptionUpLimit: item.restEnergyConsumptionUpLimit,
        restEnergyConsumptionDownLimit: item.restEnergyConsumptionDownLimit,
      })),
    });
  }, [detailData, form]);

  // 能源阈值设定数据
  useEffect(() => {
    if (isNil(centerId)) return;
    apiProductionEnergyConsumptionEnergyMediumByWorkCenterIdPost({ workCenterId: String(centerId) }).then(res => {
      setEnergyThresholdData([...res]);
    });
  }, [centerId]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.contentView}>
      <Form
        form={form}
        name="dynamic_form_item"
        {...formItemLayout}
        onReset={onReset}
        style={{ width: 948 }}
        initialValues={{
          valueDetailDtos: [
            {
              energyMediumId: undefined,
              averageUnitConsumptionUpLimit: undefined,
              averageUnitConsumptionDownLim: undefined,
              normalProductionUnitConsumptionUpLimit: undefined,
              normalProductionUnitConsumptionDownLimit: undefined,
              stopLineUnitConsumptionUpLimit: undefined,
              stopLineUnitConsumptionDownLimit: undefined,
              leisureEnergyConsumptionUpLimit: undefined,
              leisureEnergyConsumptionDownLimit: undefined,
              restEnergyConsumptionUpLimit: undefined,
              restEnergyConsumptionDownLimit: undefined,
            },
          ],
        }}
      >
        <FormTitle title="查看单耗阈值" />
        <div className={styles.contentTop}>
          <Row>
            <Col span={12}>
              <Form.Item
                name="product"
                label="产出物"
                {...formItemLayout}
                rules={[{ required: true, message: '请选择产出物' }]}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button type="link" disabled={true}>
                    选择
                  </Button>
                  <Tag border="solid" color="#2D8DFF">
                    {productSelectionVal}
                  </Tag>
                </div>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="workCenter"
                label="工作中心"
                rules={[{ required: true, message: '请选择工作中心' }]}
                {...formItemLayout}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button disabled={true} type="link">
                    选择
                  </Button>
                  <Tag border="solid" color="#2D8DFF">
                    {centerSelectionVal}
                  </Tag>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </div>
        <Form.List name="valueDetailDtos">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name }, index) => (
                <div className={styles.newlayout}>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        label="能源阈值设定"
                        rules={[{ required: true, message: '请选择阈值' }]}
                        name={[name, 'energyMediumId']}
                        {...formItemLayout}
                      >
                        <Select
                          disabled
                          onChange={val => {
                            let unitValue = energyThresholdData.filter(id => val === id.energyMediumId)[0]?.generalName;
                            const newUnit = [...unit];
                            newUnit.splice(index, 1, unitValue ? unitValue : '--');
                            setUnit(newUnit);
                          }}
                          style={{ width: 280 }}
                          placeholder="请选择能源介质"
                          options={energyThresholdData?.map(i => ({
                            label: i.energyMediumName,
                            value: i.energyMediumId,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name={[name, 'averageUnitConsumptionUpLimit']}
                        {...formItemLayout}
                        label="平均单耗上限"
                        required
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const lowLimit = getFieldValue('valueDetailDtos')[name].averageUnitConsumptionDownLimit;
                              form.setFields([
                                {
                                  name: ['valueDetailDtos', index, 'averageUnitConsumptionDownLimit'],
                                  value: lowLimit,
                                  errors: undefined,
                                },
                              ]);

                              if (isNil(lowLimit) || value > lowLimit) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('单耗上限需要大于单耗下限'));
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          disabled
                          className={styles.iptStyle}
                          controls={false}
                          placeholder="请输入"
                          addonAfter={<span className={styles.suffixIcon}>{unit[index] ? unit[index] : '--'}</span>}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={[name, 'averageUnitConsumptionDownLimit']}
                        {...formItemLayout}
                        label="平均单耗下限"
                        required
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const upLimit = getFieldValue('valueDetailDtos')[name].averageUnitConsumptionUpLimit;
                              form.setFields([
                                {
                                  name: ['valueDetailDtos', index, 'averageUnitConsumptionUpLimit'],
                                  value: upLimit,
                                  errors: undefined,
                                },
                              ]);
                              if (isNil(upLimit) || value < upLimit) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('单耗下限需要小于单耗上限'));
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          disabled
                          className={styles.iptStyle}
                          controls={false}
                          style={{ border: 'none' }}
                          placeholder="请输入"
                          addonAfter={<span className={styles.suffixIcon}>{unit[index] ? unit[index] : '--'}</span>}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name={[name, 'normalProductionUnitConsumptionUpLimit']}
                        {...formItemLayout}
                        label="正常生产-单耗上限"
                        required
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                value > getFieldValue('valueDetailDtos')[name].normalProductionUnitConsumptionDownLimit
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('单耗上限需要大于单耗下限'));
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          disabled
                          className={styles.iptStyle}
                          controls={false}
                          placeholder="请输入"
                          addonAfter={<span className={styles.suffixIcon}>{unit[index] ? unit[index] : '--'}</span>}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={[name, 'normalProductionUnitConsumptionDownLimit']}
                        {...formItemLayout}
                        label="正常生产-单耗下限"
                        required
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                value < getFieldValue('valueDetailDtos')[name].normalProductionUnitConsumptionUpLimit
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('单耗下限需要小于单耗上限'));
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          disabled
                          className={styles.iptStyle}
                          controls={false}
                          placeholder="请输入"
                          addonAfter={<span className={styles.suffixIcon}>{unit[index] ? unit[index] : '--'}</span>}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name={[name, 'stopLineUnitConsumptionUpLimit']}
                        {...formItemLayout}
                        label="停线-单耗上限"
                        required
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (value > getFieldValue('valueDetailDtos')[name].stopLineUnitConsumptionDownLimit) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('单耗上限需要大于单耗下限'));
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          disabled
                          className={styles.iptStyle}
                          controls={false}
                          placeholder="请输入"
                          addonAfter={<span className={styles.suffixIcon}>{unit[index] ? unit[index] : '--'}</span>}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={[name, 'stopLineUnitConsumptionDownLimit']}
                        {...formItemLayout}
                        required
                        label="停线-单耗下限"
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (value < getFieldValue('valueDetailDtos')[name].stopLineUnitConsumptionUpLimit) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('单耗下限需要小于单耗上限'));
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          disabled
                          className={styles.iptStyle}
                          controls={false}
                          placeholder="请输入"
                          addonAfter={<span className={styles.suffixIcon}>{unit[index] ? unit[index] : '--'}</span>}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name={[name, 'leisureEnergyConsumptionUpLimit']}
                        {...formItemLayout}
                        label="空闲-能耗上限"
                        required
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (value > getFieldValue('valueDetailDtos')[name].leisureEnergyConsumptionDownLimit) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('能耗上限需要大于能耗下限'));
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          disabled
                          className={styles.iptStyle}
                          controls={false}
                          placeholder="请输入"
                          addonAfter={<span className={styles.suffixIcon}>{unit[index] ? unit[index] : '--'}</span>}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={[name, 'leisureEnergyConsumptionDownLimit']}
                        {...formItemLayout}
                        label="空闲-能耗下限"
                        required
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (value < getFieldValue('valueDetailDtos')[name].leisureEnergyConsumptionUpLimit) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('能耗下限需要小于能耗上限'));
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          disabled
                          className={styles.iptStyle}
                          controls={false}
                          placeholder="请输入"
                          addonAfter={<span className={styles.suffixIcon}>{unit[index] ? unit[index] : '--'}</span>}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name={[name, 'restEnergyConsumptionUpLimit']}
                        {...formItemLayout}
                        label="休息-能耗上限"
                        required
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (value > getFieldValue('valueDetailDtos')[name].restEnergyConsumptionDownLimit) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('能耗上限需要大于能耗下限'));
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          disabled
                          className={styles.iptStyle}
                          controls={false}
                          placeholder="请输入"
                          addonAfter={<span className={styles.suffixIcon}>{unit[index] ? unit[index] : '--'}</span>}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={[name, 'restEnergyConsumptionDownLimit']}
                        {...formItemLayout}
                        label="休息-能耗下限"
                        required
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (value < getFieldValue('valueDetailDtos')[name].restEnergyConsumptionUpLimit) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('能耗下限需要小于能耗上限'));
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          disabled
                          className={styles.iptStyle}
                          controls={false}
                          placeholder="请输入"
                          addonAfter={<span className={styles.suffixIcon}>{unit[index] ? unit[index] : '--'}</span>}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ))}
            </>
          )}
        </Form.List>
        <Form.Item>
          <Space className="sticky-footer">
            <Button
              style={{ marginLeft: 20, position: 'fixed', bottom: 50 }}
              onClick={() => navigate('/productionManagement/production/unitConsumptionSetting')}
            >
              返回
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

export default ViewPage;
