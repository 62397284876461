import { UploadOutlined } from '@ant-design/icons';
import {
  SubContent,
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  Form,
  Modal,
  DatePicker,
  Input,
  FormTitle,
} from '@maxtropy/components';
import { Col, Row, Space, Upload } from 'antd';
import { FC, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import styles from './index.module.scss';
import {
  accountRefund,
  getSaleClientAccount,
  SaleClientAccountInfo,
  SaleClientAccountRefundRequest,
  TransactionType,
  VoucherFileRequest,
  VoucherRefundRequest,
} from '../../../api/sale';
import { RcFile } from 'antd/es/upload';
import { UploadFile } from 'antd/es/upload/interface';
import ShowInput from '@/shared/components/ShowInput';
import {
  clentAccountFileUplodaMaxCount,
  generateLimitFileSize,
  normFile,
  timeFormatString,
  useUploadMaxDisabled,
} from '../util';
import { useNavigate, useParams } from 'react-router-dom';
import CollectionCard from './components/CollectionCard';

export interface ManualDebitionFormRequest
  extends Omit<SaleClientAccountRefundRequest, 'files' | 'type' | 'specifiedDate'> {
  specifiedDate: Dayjs;
  files: RcFile[];
}

const ManualDebition: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const routesContext = useBreadcrumbRoutes();

  const routes = [{ name: '手动扣款' }];

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const { id } = useParams<{ id?: string }>();

  const [data, setData] = useState<SaleClientAccountInfo>();

  const [vouchers, setVouchers] = useState<VoucherRefundRequest[]>([]);

  useEffect(() => {
    if (id) {
      getSaleClientAccount(id).then(setData);
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data.name,
        saleClientName: data.saleClientName,
        balance: (data.balance ?? undefined) !== undefined ? `${data.balance}元` : undefined,
      });
    }
  }, [data, form]);

  const goList = () => {
    navigate('/operation/client-related/client-account');
  };

  const [uploadDisabled, uploadChange] = useUploadMaxDisabled(clentAccountFileUplodaMaxCount);

  const [isWarn, setIsWarn] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [deductionAmount, setDeductionAmount] = useState<number>(0);

  const onFinish = (value: ManualDebitionFormRequest) => {
    const finish = () => {
      if (id !== undefined) {
        const files: VoucherFileRequest[] = value.files.map((i: UploadFile) => ({
          fileKey: i.response.key,
          fileName: i.name,
        }));
        setLoading(true);
        accountRefund(id, {
          ...value,
          vouchers,
          specifiedDate: dayjs(value.specifiedDate).format(timeFormatString),
          type: TransactionType.MANUAL_DEDYCTION,
          files,
        })
          .then(() => {
            goList();
          })
          .finally(() => {
            setLoading(false);
          });
      }
    };
    if (vouchers.length === 0) {
      Modal.warning({
        title: '未选择收款凭证或无收款凭证，无法保存',
      });
      return;
    }
    // if (isWarn && vouchers.length > 0) {
    //   const justPay = vouchers.map(i => i.amount).reduce((x, y) => x + y, 0);
    //   const num = Number(deductionAmount) - Number(justPay);
    //   Modal.confirm({
    //     title: `所选收款凭证可用剩余金额不足，还需要扣款${num}元，本次只能扣款${justPay}元，是否继续本次扣款？`,
    //     onOk: finish,
    //   });
    // } else {
    // }
    finish();
  };

  return (
    <Wrapper routes={[...(routesContext?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title={routes[0].name} />
      <SubContent title="基础信息">
        <Form className={styles.formWrapper} form={form}>
          <Row>
            <Col span={6}>
              <Form.Item name="saleClientName" label="所属客户">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="name" label="账户名称">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="balance" label="账户余额">
                <ShowInput style={{ color: '#FF0000' }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </SubContent>
      <Form layout="vertical" onFinish={onFinish}>
        <SubContent title="需要扣款的收款凭证">
          <Form.Item wrapperCol={{ span: 24 }}>
            <CollectionCard
              setVouchers={setVouchers}
              isWarn={isWarn}
              setIsWarn={setIsWarn}
              setDeductionAmount={setDeductionAmount}
            />
          </Form.Item>
        </SubContent>
        <SubContent title="扣款详情">
          <Form.Item label="扣款日期" name="specifiedDate" rules={[{ required: true, message: '请选择扣款日期' }]}>
            <DatePicker style={{ width: 500 }} showTime placeholder="请选择" />
          </Form.Item>

          <Form.Item
            label="凭证文件"
            name="files"
            rules={[{ required: true, message: '请选择凭证文件' }]}
            valuePropName="fileList"
            getValueFromEvent={normFile}
            style={{ width: 500 }}
            extra={
              <Row>
                <Col span={24}>支持扩展名：.doc .docx .pdf .jpg .png .jpeg</Col>
                <Col span={24}>单个文件大小不超过5MB，最多上传十个文件。</Col>
              </Row>
            }
          >
            <Upload
              action="/api/file-center/upload"
              accept=".doc,.docx,.pdf,.jpg,.png,.jpeg"
              multiple
              maxCount={clentAccountFileUplodaMaxCount}
              beforeUpload={generateLimitFileSize(5)}
              style={{ width: 500 }}
              onChange={uploadChange}
            >
              <Button disabled={uploadDisabled} icon={<UploadOutlined />}>
                请选择
              </Button>
            </Upload>
          </Form.Item>

          <Form.Item label="凭证说明" name="description">
            <Input.TextArea style={{ width: 500 }} placeholder="请输入" maxLength={200} />
          </Form.Item>
        </SubContent>
        <Space size={8} className="sticky-footer-c">
          <Button loading={loading} htmlType="submit" type="primary">
            保存
          </Button>
          <Button loading={loading} onClick={goList}>
            取消
          </Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default ManualDebition;
