import { TimeIntervalType } from '@/api/energyAssetsSettlementsRules';
import { Col, Row } from 'antd';
import { Form } from '@maxtropy/components';
import ShowInput from '@/shared/components/ShowInput';

const CustomTariffDetail: React.FC = () => {
  return (
    <>
      <div
        style={{
          backgroundColor: 'rgba(var(--base-text-color), 0.04)',
          padding: '20px 20px 0px 20px',
          marginBottom: '20px',
          fontSize: '14px',
          fontWeight: 700,
        }}
      >
        电度电价：
        <Row>
          <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'priceType']}>
            {({ getFieldValue }) => {
              return (
                (getFieldValue('timeOfDayRateChecked') ?? []).includes(TimeIntervalType.SHARP) && (
                  <>
                    <Col span={23} offset={1} style={{ paddingLeft: '20px' }}>
                      <Form.Item name="summitPrice" label="尖时段电价">
                        <ShowInput addonAfter="元/kWh" />
                      </Form.Item>
                    </Col>
                  </>
                )
              );
            }}
          </Form.Item>
          <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'priceType']}>
            {({ getFieldValue }) => {
              return (
                (getFieldValue('timeOfDayRateChecked') ?? []).includes(TimeIntervalType.PEAK) && (
                  <>
                    <Col span={23} offset={1} style={{ paddingLeft: '20px' }}>
                      <Form.Item name="peakPrice" label="峰时段电价">
                        <ShowInput addonAfter="元/kWh" />
                      </Form.Item>
                    </Col>
                  </>
                )
              );
            }}
          </Form.Item>
        </Row>
        <Row>
          <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'priceType']}>
            {({ getFieldValue }) => {
              return (
                (getFieldValue('timeOfDayRateChecked') ?? []).includes(TimeIntervalType.AVERAGE) && (
                  <>
                    <Col span={23} offset={1} style={{ paddingLeft: '20px' }}>
                      <Form.Item name="plainPrice" label="平时段电价">
                        <ShowInput addonAfter="元/kWh" />
                      </Form.Item>
                    </Col>
                  </>
                )
              );
            }}
          </Form.Item>

          <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'priceType']}>
            {({ getFieldValue }) => {
              return (
                (getFieldValue('timeOfDayRateChecked') ?? []).includes(TimeIntervalType.VALLEY) && (
                  <>
                    <Col span={23} offset={1} style={{ paddingLeft: '20px' }}>
                      <Form.Item name="valleyPrice" label="谷时段电价">
                        <ShowInput addonAfter="元/kWh" />
                      </Form.Item>
                    </Col>
                  </>
                )
              );
            }}
          </Form.Item>
        </Row>
      </div>
    </>
  );
};

export default CustomTariffDetail;
