import { FC, useEffect, useState } from 'react';
import { Row, Col, Space } from 'antd';
import { Input, Select, Radio, Button, useUpdate, message, Form, SubContent } from '@maxtropy/components';
import ShowInput from '@/shared/components/ShowInput';
import { YearsOptions, MainDataSourcesTypeMap, validateText, BoundaryType, BoundaryTypeMap } from '../../utils';
import { Rule } from 'antd/lib/form';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import {
  apiV2CarbonFootprintLcaBaseDetailPost,
  apiV2CarbonFootprintLcaUpdatePost,
  V2CarbonFootprintLcaBaseDetailPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { useSearchParams } from 'react-router-dom';
import { isNil } from 'lodash-es';
import { FinishModelType } from '../CreateModel/const';
import { getUnits, OutputUnitType } from '@/api/outputConfig';
const BasicInformation: FC = () => {
  const [form] = Form.useForm();
  const [urlSearchParams] = useSearchParams();
  let id = urlSearchParams.get('id') || undefined;
  const [updateState, updateFn] = useUpdate();
  const [unitName, setUnitName] = useState<string>('');
  const [lcaDetail, setLcaDetail] = useState<V2CarbonFootprintLcaBaseDetailPostResponse>();
  const { TextArea } = Input;
  const formLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  useEffect(() => {
    if (isNil(id)) return;
    apiV2CarbonFootprintLcaBaseDetailPost({ id: Number(id) }).then(res => {
      form.setFieldsValue({ ...res });
      setLcaDetail(res);
      getUnits().then(result => {
        const unitName =
          res.unitTypeCode === OutputUnitType.PIECE
            ? result.list
                .find(u => u.unitTypeCode === OutputUnitType.PIECE)
                ?.outputProductUnitList?.find(e => e?.unitCode === res.unitCode)?.unitName
            : res.unitCode;
        setUnitName(unitName as string);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, updateState]);

  const onFinish = (values: FinishModelType) => {
    const params = {
      ...values,
      id: Number(id),
    };

    apiV2CarbonFootprintLcaUpdatePost({ ...params }).then(res => {
      message.success({
        content: '保存成功',
      });
      updateFn();
    });
  };
  return (
    <SubContent>
      <Form
        layout="vertical"
        form={form}
        {...formLayout}
        onFinish={onFinish}
        initialValues={{ standardYear: dayjs().year() }}
      >
        <div className={styles.content}>
          <Row gutter={[48, 0]}>
            <Col span={8}>
              <Form.Item name="code" label="产出物编码">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="name" label="产出物名称">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="spec" label="规格型号">
                <ShowInput />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[48, 0]}>
            <Col span={8}>
              <Form.Item label="数量" style={{ display: 'inline-block' }}>
                <ShowInput value={`${lcaDetail?.quantity}${unitName}`} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="dataOrigin" label="主要数据来源" rules={[{ message: '请选择', required: true }]}>
                <Select
                  placeholder={'请选择'}
                  options={Object.entries(MainDataSourcesTypeMap).map(([k, v]) => ({
                    label: v,
                    value: +k,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="standardYear" label="基准年" rules={[{ message: '请选择', required: true }]}>
                <Select placeholder={'请选择'} options={YearsOptions}></Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={48}>
            <Col span={8}>
              <Form.Item name="boundary" label="核算边界">
                <Radio.Group>
                  <Radio value={BoundaryType.GateToGate}>{BoundaryTypeMap[BoundaryType.GateToGate]}</Radio>
                  <Radio value={BoundaryType.CradleToGate}>{BoundaryTypeMap[BoundaryType.CradleToGate]}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="company" label="生产公司" rules={[validateText(100) as Rule]}>
                <TextArea placeholder="请输入生产公司 1~100 字符" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="madeIn" label="产品产地" rules={[validateText(100) as Rule]}>
                <TextArea placeholder="请输入产品产地 1~100 字符" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="remark" label="补充说明" rules={[validateText(200) as Rule]}>
                <TextArea placeholder="请输入补充说明 1~200 字符" />
              </Form.Item>
            </Col>
          </Row>

          <Space className="sticky-footer" size="small" style={{ paddingLeft: 0 }}>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
          </Space>
        </div>
      </Form>
    </SubContent>
  );
};

export default BasicInformation;
