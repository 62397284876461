// import { useAsync } from "@maxtropy/central-commons-ui";
import {
  Table,
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  Modal,
  SubContent,
  FormTitle,
  BaseInfoContent,
  Form,
} from '@maxtropy/components';
import { Space, Spin } from 'antd';
import React, { MutableRefObject, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@/shared/utils/utils';
import { getImplementAdminList, getImplementDetail, ImplementAdmin, saveOuAdmin } from '../../api/implement';
import { PermissionsType } from '../../common/permissionsConst';
import { useHasPermission } from '../../utils/utils';
import styles from './index.module.scss';
import StaffAdminModal, { StaffAdminModalRef } from './StaffAdminModal';
import { PlusOutlined } from '@ant-design/icons';

export interface TableData {
  humanCode: string;
  id: number;
  name: string;
  orgName: string;
  phone: number;
  staffCode: string;
  staffType: number;
  username: string;
  staffId?: number;
}

function formatTableData(res: Array<ImplementAdmin>): Array<TableData> {
  return res.map(item => ({ ...item }));
}

const routes = [{ name: '成员管理' }];

const Adjust: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [tableLoading, setTableLoading] = React.useState(false);
  const [adminItems, setAdminItems] = React.useState<Array<TableData>>([]);
  const [tableData, setTableData] = React.useState<Array<TableData>>([]);
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const onRemove = (record: TableData) => {
    const _tableData = tableData.filter(item => item.id !== record.id);
    setTableData(_tableData);
    const _adminItem = adminItems.filter(item => _tableData.map(item => item.id).includes(item.id));
    setAdminItems(_adminItem);
  };

  const addAdminManage = useHasPermission(PermissionsType.B_OU_IMPLEMENT_ADD_MANAGER);

  const columns = [
    {
      title: '用户ID',
      dataIndex: 'humanCode',
    },
    {
      title: '员工工号',
      dataIndex: 'staffCode',
    },
    {
      title: '用户登录账号',
      dataIndex: 'username',
    },
    {
      title: '管理员',
      render: (record: TableData) => (record.staffType === 0 ? '成员' : '管理员'),
    },
    {
      title: '姓名',
      dataIndex: 'name',
    },
    {
      title: '手机号',
      dataIndex: 'phone',
    },
    {
      title: '所在组织',
      dataIndex: 'customerName',
    },
    {
      title: '操作',
      width: 120,
      render: (record: TableData) => (
        <Button type="link" onClick={() => onRemove(record)}>
          取消绑定
        </Button>
      ),
    },
  ];

  const staffAdminRef: MutableRefObject<StaffAdminModalRef | null> = React.useRef(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { data: detail, isLoading: detailLoading } = useQuery(useCallback(() => getImplementDetail(Number(id)), []));

  const fetchInitTableData = useCallback(async () => {
    const page = await getImplementAdminList(Number(id));
    setTableLoading(false);
    const data = formatTableData(page);
    setTableData(data);
    setAdminItems(data);
    return page;
  }, [id]);

  useEffect(() => {
    fetchInitTableData();
  }, [fetchInitTableData]);

  const onShowModal = () => {
    setIsModalVisible(true);
  };

  const onHideModal = () => {
    setIsModalVisible(false);
  };

  const onOk = () => {
    onHideModal();
    const _ouItems = staffAdminRef.current?.selectedRows || [];
    setAdminItems(_ouItems);
    const hashMap = new Map();
    const data = tableData.concat(_ouItems);
    data.forEach(item => hashMap.set(item.id, item));
    const _tableData = Array.from(new Set(data.map(item => item.id))).map(item => hashMap.get(item));
    setTableData(_tableData);
  };

  const onSave = async () => {
    // const initDataIds = initData!.map(item => item.staffId!).filter(item => item);
    const currentDataIds = tableData.map(item => item.id);
    // const cancelStaffIds = initDataIds.filter((id) => !currentDataIds?.includes(id));
    await saveOuAdmin({
      ouId: Number(id),
      adminIds: currentDataIds,
    });
    navigate(-1);
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className="page_wrapper">
      <FormTitle title="成员管理" />
      <SubContent title={'项目信息'} className="mb-8">
        <Spin spinning={detailLoading}>
          <BaseInfoContent>
            <Form.Item label="项目编号">{detail?.serialNumber ?? '--'}</Form.Item>
            <Form.Item label="项目名称">{detail?.name ?? '--'}</Form.Item>
            <Form.Item label="所属组织">{detail?.tenantName ?? '--'}</Form.Item>
            <Form.Item label="负责人">{detail?.directorName ? detail?.directorName : '--'}</Form.Item>
          </BaseInfoContent>
        </Spin>
      </SubContent>
      <SubContent title={'管理员列表'}>
        {addAdminManage && (
          <Button icon={<PlusOutlined />} wrapStyle={{ marginBottom: 10 }} onClick={onShowModal} type={'dashed'}>
            添加管理员
          </Button>
        )}
        <div className={styles.table}>
          <Table<TableData> rowKey="id" loading={tableLoading} columns={columns} dataSource={tableData} />
        </div>
      </SubContent>
      <Space className={styles.footer} size={8}>
        <Button type={'primary'} onClick={onSave}>
          保存
        </Button>
        <Button onClick={() => navigate(-1)}>返回</Button>
      </Space>
      <Modal destroyOnClose width={1000} title="管理员选择" open={isModalVisible} onOk={onOk} onCancel={onHideModal}>
        <StaffAdminModal selectedItems={adminItems} ref={staffAdminRef} />
      </Modal>
    </Wrapper>
  );
};

export default Adjust;
