import {
  EnergyWorkingProcessType,
  EnergyWorkingProcessTypeLabel,
  EnergyWorkingProcessTypeLabelColor,
  UetEMTPreviewResponse,
} from '@/api/uet';
import G6, { Graph } from '@antv/g6';
import { Button, FormContent, getRealUrl, useUpdate } from '@maxtropy/components';
import { MediumIndicatorDashboardDashboardConfigPostRequest } from '@maxtropy/device-customer-apis';
import { Drawer, message, Space } from 'antd';
import React, { FC, Key, useEffect, useMemo, useRef, useState } from 'react';
import { basicConfig, EnergyWorkingProcessPermissions, fitScreenView, formatNodesAndEdges } from './basicConfig';
import BothConfigCard from './components/BothConfigCard';
import DashBoardModalConfig, { BtnConfigAlarmSwitchType, IFieldForm } from './components/DashBoardModalConfig';
import ItemProcessDetail from './components/ItemProcessDetail';
import styles from './index.module.scss';
import { useSize } from 'ahooks';
import { DoubleRightOutlined } from '@ant-design/icons';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { useRequest } from 'ahooks';
import classNames from 'classnames';
import {
  apiV2MediumIndicatorDashboardDashboardConfigEditPost,
  apiV2MediumIndicatorDashboardGetDashboardConfigPost,
  apiV2MediumIndicatorDashboardGetProcessStatusPost,
  apiV2UetEmtPreviewDashboardPost,
} from '@maxtropy/device-customer-apis-v2';
import { isNil } from 'lodash-es';

export interface IViewEnergyMediumTopoProps {
  energyMediumTopoId?: number;
  getKeyAndInfo?: (key: Key) => void;
  energyMediumTopoName?: string;
  sceneId: number;
}

export enum AlarmStatusType {
  ENABLE = 0,
  UNABLE = 1,
}

export const pollingInterval = 1000 * 60 * 1; // 1分钟轮询一次

const ViewEnergyMediumTopo: FC<IViewEnergyMediumTopoProps> = props => {
  const hasLiquidPermission = useHasPermission(PermissionsType.B_LIQUIDDBCONF); // 工业液体看板配置
  const hasHvacAirPermission = useHasPermission(PermissionsType.B_HVACAIRDBCONF); // HVAC看板配置
  const hasIndustryGasPermission = useHasPermission(PermissionsType.B_INDUSTRYGASDBCONF); // 工业气体看板配置
  const hasCompressedAirPermission = useHasPermission(PermissionsType.B_COMPRESSEDAIRDBCONF); // 空压看板配置
  const hasGasPermission = useHasPermission(PermissionsType.B_GASDBCONF); // 用气看板配置
  const hasSteamPermission = useHasPermission(PermissionsType.B_STEAMDBCONF); // 热蒸汽看板配置
  const hasWaterPermission = useHasPermission(PermissionsType.B_WATERDBCONF); // 用水看板配置

  const { energyMediumTopoId, getKeyAndInfo, energyMediumTopoName, sceneId } = props;
  const [open, setOpen] = useState(false); // 抽屉的弹窗
  const [itemDetailId, setItemDetailId] = useState<number>(); // 每个节点的详情id
  const graph = useRef<Graph>(); // graph图的实例
  const [graphState, setGraphState] = useState<Graph>();
  const [item, setItem] = useState<any>(); // 文档与版本脱离，所以用any 目标节点的实例
  const cFormRef = useRef<IFieldForm>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [updateState, updateFn] = useUpdate();
  const [topoPreviewData, setTopoPreviewData] = useState<UetEMTPreviewResponse>();
  const ref = useRef<HTMLDivElement>(); // ref考虑自适应问题

  const [topoLoading, setTopoLoading] = useState<boolean>(true);

  // 查询卡片与配置（轮询）
  const { data: configData } = useRequest(
    () => {
      return apiV2MediumIndicatorDashboardGetDashboardConfigPost({
        id: energyMediumTopoId!.toString(),
      });
    },
    {
      pollingInterval,
      refreshDeps: [energyMediumTopoId, updateState],
      ready: !!energyMediumTopoId,
    }
  );

  const { data: alarmStatus, loading: alarmLoading } = useRequest(
    () => {
      return apiV2MediumIndicatorDashboardGetProcessStatusPost({ id: energyMediumTopoId!.toString() });
    },
    {
      pollingInterval,
      refreshDeps: [energyMediumTopoId],
      ready: !!energyMediumTopoId,
    }
  );

  const size = useSize(ref);
  // 自适应dom的变化
  useEffect(() => {
    if (graph.current && size) {
      fitScreenView(graph.current, size.width, size.height);
    }
  }, [size]);
  // 自适应屏幕缩放
  const handleResize = () => {
    if (ref.current && graph.current) {
      fitScreenView(graph.current, ref.current.clientWidth, ref.current.clientHeight);
    }
  };
  // 自适应窗口变化
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // 查询拓扑图
  useEffect(() => {
    if (graph.current) {
      graph.current.clear();
      graph.current.destroy();
      graph.current = undefined;
      setOpen(false);
    }
    if (energyMediumTopoId) {
      setTopoLoading(true);
      apiV2UetEmtPreviewDashboardPost({ id: +energyMediumTopoId }).then(res => {
        const resTemp = JSON.parse(JSON.stringify(res));
        for (let i = 0; i < (resTemp.nodes ?? []).length; i++) {
          resTemp.nodes[i].info!.pictureKey = getRealUrl(resTemp.nodes?.[i].info?.pictureKey);
        }
        setTopoPreviewData(resTemp);
      });
    }
  }, [energyMediumTopoId]);

  const formatData = useMemo(() => {
    if (topoPreviewData) {
      // topoPreviewData.nodes.forEach(i => {
      //   i.entryVos = i.entryIds?.map((c, i) => ({ id: c, hasDevice: i % 2 === 0 ? true : false }));
      //   i.exitVos = i.exitIds?.map((c, i) => ({ id: c, hasDevice: i % 2 === 0 ? true : false }));
      // });
      return formatNodesAndEdges(topoPreviewData);
    }
    // 对返回数据进行处理
    // if (topoPreviewData) {
    //   return formatNodesAndEdges(topoPreviewData);
    // }
  }, [topoPreviewData]);

  useEffect(() => {
    if (formatData) {
      const tooltip = new G6.Tooltip({
        offsetX: 10,
        offsetY: 10,
        className: 'tooltip',
        // 允许出现 tooltip 的 item 类型
        itemTypes: ['node'],
        // 自定义 tooltip 内容
        getContent: e => {
          const outDiv = document.createElement('div');
          outDiv.style.width = 'fit-content';
          outDiv.style.zIndex = '99';
          outDiv.style.position = 'relative';
          outDiv.style.color = 'black';
          outDiv.style.backgroundColor = '#fff';
          outDiv.style.borderRadius = '3px';
          outDiv.innerHTML = `
            <h4>无权限</h4>
            `;
          return outDiv;
        },
        shouldBegin: e => {
          const item: any = e?.item?.getModel();
          let res = false;
          if (item.info.permissions === EnergyWorkingProcessPermissions.UNABLED) {
            res = true;
          }
          return res;
        },
      });
      // 创建实例
      graph.current = new G6.Graph(basicConfig(tooltip));
      // 读取数据
      graph.current.data(formatData);
      // 渲染图
      graph.current.render();

      setGraphState(graph.current);

      // 绑定事件，打开抽屉
      graph.current.on('nodeselectchange', ev => {
        const getNodes = (ev.selectedItems as any).nodes;
        if (getNodes.length) {
          const itemDto: any = getNodes[0].getModel();
          if (itemDto && itemDto.info.permissions === EnergyWorkingProcessPermissions.ENABLED) {
            showInfoByDrawer(ev.target, ev.select as boolean);
          } else {
            setOpen(false);
          }
        } else {
          setOpen(false);
        }
      });
    }
    setTopoLoading(false);
  }, [formatData]);

  const getAlarmTopoShape = (i: { processId?: number; alarmStatus?: number | string }) => {
    const itemNode = graph.current!.findById(i.processId!.toString());
    const group = itemNode.getContainer();
    const textShape = group.get('children')[group.get('children').length - 5];
    const imageShape = group.get('children')[group.get('children').length - 4];
    const animate1Shape = group.get('children')[group.get('children').length - 1];
    const animate2Shape = group.get('children')[group.get('children').length - 2];
    const animate3Shape = group.get('children')[group.get('children').length - 3];
    return { imageShape, animate1Shape, animate2Shape, animate3Shape, textShape };
  };

  useEffect(() => {
    if (!topoLoading && !alarmLoading && alarmStatus && graphState) {
      (alarmStatus.list ?? []).forEach(i => {
        if (!isNil(i?.alarmStatus) && (i?.alarmStatus as unknown as AlarmStatusType) === AlarmStatusType.UNABLE) {
          const { imageShape, animate1Shape, animate2Shape, animate3Shape, textShape } = getAlarmTopoShape(i);
          imageShape.attr('opacity', 1);
          textShape.attr('fill', '#FF4D4F');
          animate1Shape.attr('fill', 'red');
          animate2Shape.attr('fill', 'red');
          animate3Shape.attr('fill', 'red');
        } else {
          const { imageShape, animate1Shape, animate2Shape, animate3Shape, textShape } = getAlarmTopoShape(i);
          imageShape.attr('opacity', 0);
          animate1Shape.attr('fill', 'transparent');
          animate2Shape.attr('fill', 'transparent');
          animate3Shape.attr('fill', 'transparent');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alarmLoading, topoLoading, graphState]);

  const showInfoByDrawer = (item: any, selected: boolean) => {
    if (item && item._cfg.id) {
      // graph.current?.setItemState(item, 'selected', true);
      setItem(item); // 保存antv G6 的node实例
      // 通过过程id查询详细信息
      setItemDetailId(item._cfg.id);
      setOpen(selected);
    }
  };

  // 重置
  const init = () => {
    setModalOpen(false);
    cFormRef.current?.reset();
  };

  const handleEditBtn = (v: MediumIndicatorDashboardDashboardConfigPostRequest | undefined) => {
    if (v) {
      apiV2MediumIndicatorDashboardDashboardConfigEditPost(v).then(_ => {
        updateFn();
        message.success('配置成功');
        init();
      });
    } else {
      init();
    }
  };

  const renderBtn = (
    <Button type="primary" onClick={() => setModalOpen(true)}>
      拓扑看板配置
    </Button>
  );

  const topoConfigBtnRender = (
    <div className={styles.dashBoardConfig}>
      {sceneId === 500 && hasWaterPermission && renderBtn}
      {sceneId === 501 && hasGasPermission && renderBtn}
      {sceneId === 502 && hasSteamPermission && renderBtn}
      {sceneId === 503 && hasCompressedAirPermission && renderBtn}
      {sceneId === 504 && hasIndustryGasPermission && renderBtn}
      {sceneId === 505 && hasHvacAirPermission && renderBtn}
      {sceneId === 506 && hasLiquidPermission && renderBtn}
    </div>
  );

  return (
    <>
      <FormContent
        title={<span>{energyMediumTopoName}</span>}
        style={{ width: '100%', padding: '20px', height: '95%', overflowY: 'hidden' }}
      >
        {topoConfigBtnRender}
        <div
          className={styles.bodyDivSty}
          ref={d => {
            if (d) {
              ref.current = d;
            }
          }}
        >
          {configData?.usageCountSwitch === BtnConfigAlarmSwitchType.ENABLE &&
          configData?.alarmSwitch === BtnConfigAlarmSwitchType.ENABLE ? (
            <div>
              <BothConfigCard configData={configData} />
            </div>
          ) : configData?.usageCountSwitch === BtnConfigAlarmSwitchType.ENABLE ? (
            <div className={styles.oneDashboardSty}>
              <div className={styles.eachDivSty} style={{ width: 300 }}>
                <div className={styles.dashboardTitleSty}>当日总用量</div>
                {!configData.right ? (
                  <div className={classNames(styles.dashboardInnerContentSty, styles.noPermissionSty)}>
                    无对应过程权限
                  </div>
                ) : (
                  <div className={styles.dashboardInnerContentSty}>
                    {!configData.energyMediumData || configData.energyMediumData?.length === 0 ? (
                      <div className={styles.noPermissionSty}>暂无数据</div>
                    ) : (
                      configData.energyMediumData?.map(i => (
                        <div key={i.energyMediumId} className={styles.dashboardItemSty}>
                          <div className={styles.dashboardItemTitleSty}>
                            {i.energyMediumName} ({i.indicatorName})
                          </div>
                          <div className={styles.dashboardItemContentSty}>
                            {i.value ? i.value.toFixed(2) + i.unit : '--'}
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : configData?.alarmSwitch === BtnConfigAlarmSwitchType.ENABLE ? (
            <div className={styles.oneDashboardSty}>
              <div className={styles.eachDivSty}>
                <div className={styles.alarmTitleSty}>
                  <div className={styles.dashboardTitleSty}>报警信息</div>
                  <div>
                    <Button
                      type="link"
                      style={{ padding: 0 }}
                      onClick={() =>
                        window.open(`/device/alarm/record?deviceAssetCode=${configData.deviceAssetCode}&state=1`)
                      }
                    >
                      查看详情
                    </Button>
                  </div>
                </div>
                <div className={styles.dashboardItemSty}>
                  <div className={styles.dashboardItemTitleSty}>设备未恢复报警数</div>
                  <div className={styles.dashboardItemContentSty}>{configData.unRecoverDeviceNum ?? '--'}</div>
                </div>
                <div className={styles.dashboardItemSty}>
                  <div className={styles.dashboardItemTitleSty}>近7日设备报警数</div>
                  <div className={`${styles.dashboardItemContentSty} ${styles.alarmColor}`}>
                    {configData.deviceAlarmNum ?? '--'}
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <Space className={styles.block}>
            {Object.entries(EnergyWorkingProcessTypeLabel).map(([value, key]) => {
              const color = `${EnergyWorkingProcessTypeLabelColor[+value as EnergyWorkingProcessType]}`;
              return (
                <div key={key} className={styles.item}>
                  <div
                    style={{
                      background: color,
                    }}
                    className={styles.pipe}
                  />
                  <div>{key}</div>
                </div>
              );
            })}
          </Space>

          <div
            className={styles.bgImg}
            style={{ width: '100%', height: '100%', backgroundColor: '#232324' }}
            id="mountNode"
          ></div>
        </div>
        <div className={styles.right} style={{ width: open ? 400 : 0, height: '100%', backgroundColor: '#232324' }}>
          <Drawer
            className={styles.drawerStyle}
            title={
              <div className={styles.drawertitleStyle}>
                <div className={styles.drawertitleLeftStyle}>数据概览</div>
                <Button type="link" onClick={() => getKeyAndInfo?.(itemDetailId + 'process')}>
                  查看详情
                  <DoubleRightOutlined className={styles.translationStyles} />
                </Button>
              </div>
            }
            placement="right"
            width={400}
            mask={false}
            onClose={() => {
              graph.current?.setItemState(item, 'selected', false);
              setOpen(false);
            }}
            open={open}
          >
            <ItemProcessDetail itemDetailId={itemDetailId} />
          </Drawer>
        </div>
        <DashBoardModalConfig modalOpen={modalOpen} cref={cFormRef} row={configData} handleEditBtn={handleEditBtn} />
      </FormContent>
    </>
  );
};

export default ViewEnergyMediumTopo;
