import {
  EllipsisSpan,
  Paging,
  Table,
  useBreadcrumbRoutes,
  usePaging,
  useUpdate,
  Wrapper,
  Button,
  Form,
  Input,
  Modal,
  CustomFilter,
} from '@maxtropy/components';
import { FC, useEffect, useState } from 'react';

import { Col, Divider, Row, Space } from 'antd';
import DeviceModal from './DeviceModal';
import { useParams } from 'react-router-dom';
import { ColumnType } from 'antd/es/table';

import styles from './index.module.scss';
import { useAdcodeToName } from '../../../../utils/utils';
import {
  getThermalBoundDevicePage,
  getThermalInfo,
  thermalBindDevices,
  ThermalBoundDeviceRequest,
  ThermalResponse,
  thermalUnBindDevices,
} from '../../../../api/thermal';
import { Device } from '../../../../api/device';
import { PlusOutlined } from '@ant-design/icons';

const routes = [{ name: '用汽账户配置' }, { name: '设备管理' }];

const columns: ColumnType<Device>[] = [
  {
    title: '设备编号',
    dataIndex: 'code',
    width: 180,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'name',
    width: 180,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '标签',
    dataIndex: 'tags',
    ellipsis: { showTitle: true },
    render: (v?: string[]) => <EllipsisSpan value={v ? v.join(',') : v} />,
  },
  {
    title: '关联组织',
    dataIndex: 'customerFullName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export interface SearchParams extends Omit<ThermalBoundDeviceRequest, 'page' | 'size'> {}

const ElectricityAccountDevice: FC = () => {
  const { id } = useParams<{ id: string }>();

  const [updateState, updateFn] = useUpdate();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [devices, setDevices] = useState<Device[]>([]);

  const [thermal, setThermal] = useState<ThermalResponse>();

  const locationName = useAdcodeToName(thermal?.regionCode);

  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    if (id) {
      getThermalInfo(id).then(setThermal);
    }
  }, [id]);

  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  useEffect(() => {
    if (id) {
      setLoading(true);
      getThermalBoundDevicePage(id, {
        ...searchParams,
        page: pageOffset,
        size: pageSize,
      })
        .then(res => {
          setDevices(res.list);
          setTotalCount(res.total);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, pageOffset, pageSize, searchParams, setTotalCount, updateState]);

  const onFinish = (val: SearchParams) => {
    setSearchParams(val);
    setPageOffset(1);
  };

  const onReset = () => {
    setPageOffset(1);
    setSearchParams(undefined);
  };

  const unBind = (record: Device) => {
    Modal.confirm({
      title: '是否解除绑定？',
      okText: '确认解除',
      onOk: () => {
        if (thermal) {
          thermalUnBindDevices(thermal.id, record.id).then(() => {
            setPageOffset(1);
            updateFn();
          });
        }
      },
    });
  };

  const bildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 280,
      render: (record: Device) => (
        <Space>
          <Button
            type="link"
            onClick={() => {
              unBind(record);
            }}
          >
            解除绑定
          </Button>
        </Space>
      ),
    },
  ];

  const filter = (
    <CustomFilter<SearchParams> form={form} onFinish={val => onFinish(val)} onReset={onReset}>
      <Form.Item label="设备编码" name="deviceCode">
        <Input placeholder=" 请输入" />
      </Form.Item>
      <Form.Item label="设备名称" name="deviceName">
        <Input placeholder=" 请输入" />
      </Form.Item>
    </CustomFilter>
  );

  const [deviceIds, setDeviceIds] = useState<number[]>([]);

  const [addVisible, setAddVisible] = useState<boolean>(false);

  const onAddCancel = () => {
    setDeviceIds([]);
    setAddVisible(false);
  };

  const onAddSubmit = () => {
    if (thermal && Array.isArray(deviceIds) && deviceIds.length > 0) {
      thermalBindDevices(thermal.id, deviceIds).then(() => {
        updateFn();
        onAddCancel();
      });
    }
  };

  return (
    <>
      <Wrapper
        routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}
        filters={
          <>
            {!!thermal && (
              <Row gutter={48} wrap={false} style={{ marginBottom: 10 }}>
                <Col>
                  <span className={styles.top_label}> 用电账户户号:</span> {thermal.number}
                </Col>
                <Col>
                  <span className={styles.top_label}>所在区域:</span> {locationName}
                </Col>
              </Row>
            )}

            {!!thermal && <Divider style={{ marginBottom: 24 }} />}
            {filter}
          </>
        }
        className="page_wrapper"
      >
        <div className={styles.operationArea}>
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setAddVisible(true);
              }}
            >
              新增绑定
            </Button>
          </Space>
        </div>
        <Table sticky scroll={{ x: 1300 }} loading={loading} columns={bildColumns} dataSource={devices} />
        <Paging pagingInfo={pagingInfo} />
      </Wrapper>
      <Modal
        size="big"
        title="设备选择"
        okText="保存"
        destroyOnClose
        open={addVisible}
        onCancel={onAddCancel}
        onOk={onAddSubmit}
      >
        <DeviceModal value={deviceIds} onChange={setDeviceIds} thermalId={thermal?.id} />
      </Modal>
    </>
  );
};

export default ElectricityAccountDevice;
