import { CircuitDetailProps, CircuitRelationTypeFormat, CircuitType, getCircuitLink } from '@/api/circuit';
import { searchParent } from '@/pages/Circuit/Create';
import { Col, Row } from 'antd';
import { FC, useEffect, useState } from 'react';
import ShowInput from '@/shared/components/ShowInput';
import { Form } from '@maxtropy/components';

interface CircuitRelationFormProps {
  data?: CircuitDetailProps;
}

const CircuitRelationForm: FC<CircuitRelationFormProps> = props => {
  const { data } = props;
  const [linkObjectName, setLinkObjectName] = useState<string>();

  useEffect(() => {
    if (data?.linkType) {
      getCircuitLink({ circuitLinkType: data?.linkType, circuitId: data ? data.id : undefined }).then(res => {
        let allName = searchParent(res, data?.linkObjectId!)
          .map(i => i.name)
          .join('/');
        setLinkObjectName(allName);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.linkType]);

  return (
    <>
      <Row>
        {data?.type === CircuitType.GRID_BILLING_CIRCUIT ? (
          <></>
        ) : data?.type !== CircuitType.BUS_COUPLER_CIRCUIT ? (
          <Col span={8}>
            <Form.Item label={'上游回路'}>
              <ShowInput value={(data?.upstreamCircuitOuName ?? '--') + '/' + (data?.upstreamCircuitName ?? '--')} />
            </Form.Item>
          </Col>
        ) : (
          <>
            <Col span={8}>
              <Form.Item label={'左上游回路'}>
                <ShowInput
                  value={(data?.upstreamCircuitLeftOuName ?? '--') + '/' + (data?.upstreamCircuitLeftName ?? '--')}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'右上游回路'}>
                <ShowInput
                  value={(data?.upstreamCircuitRightOuName ?? '--') + '/' + (data?.upstreamCircuitRightName ?? '--')}
                />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col span={8}>
          <Form.Item label="关联户号">
            <ShowInput value={data?.electricityAccountNumber} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Form.Item label="所属UET">
            <ShowInput value={data?.uetName} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="回路关联">
            <ShowInput value={data?.linkType ? CircuitRelationTypeFormat[data?.linkType] : '--'} />
          </Form.Item>
        </Col>
        {data?.linkType && (
          <Col span={8}>
            <Form.Item label="关联对象">
              <ShowInput value={linkObjectName} />
            </Form.Item>
          </Col>
        )}
      </Row>
    </>
  );
};

export default CircuitRelationForm;
