import { InfoCircleOutlined } from '@ant-design/icons';
import {
  SubContent,
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  Form,
  Tooltip,
  Radio,
  FormTitle,
} from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ShowInput from '@/shared/components/ShowInput';
import {
  CreditLimitType,
  CreditLimitTypeDisplay,
  getSaleClientAccount,
  SaleClientAccountInfo,
  SaleClientAccountStatusDisplay,
} from '../../../api/sale';
import { formLayout } from '../util';
import EstimateBalance from './components/EstimateBalance';

import styles from './index.module.scss';

const routes = [{ name: `查看账户` }];

const DetailClientAccount: FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { id } = useParams<{ id?: string }>();

  const [data, setData] = useState<SaleClientAccountInfo>();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    if (id) {
      getSaleClientAccount(id).then(setData);
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data.name,
        creditLimitType: data.creditLimitType,
        creditLimitTypeName: CreditLimitTypeDisplay[data.creditLimitType],
        creditLimitAmount:
          (data.creditLimitAmount ?? undefined) !== undefined ? `${data.creditLimitAmount}元` : undefined,
        description: data.description,
        saleClientName: data.saleClientName,
        companyName: data.companyName,
        uscc: data.uscc,
        ouName: data.ouName,
        voucherNumber: data.voucherNumber,
        status: SaleClientAccountStatusDisplay[data.status],
        balance: (data.balance ?? undefined) !== undefined ? `${data.balance}元` : undefined,
        estimateBalance: (data.estimateBalance ?? undefined) !== undefined ? `${data.estimateBalance}` : undefined,
      });
    }
  }, [data, form]);

  const goList = () => {
    navigate('/operation/client-related/client-account');
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title={routes[0].name} />
      <Form layout="vertical" form={form} {...formLayout}>
        <SubContent className="mb-8">
          <Row>
            <Col span={8}>
              <Form.Item name="name" label="账户名称">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="balance" label="账户余额">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="estimateBalance"
                label={
                  <>
                    <span style={{ marginRight: '8px' }}>暂估余额</span>
                    <Tooltip title="暂估余额根据账户当前金额与未结算电费计算（未结算电费包括未缴费核查联与当前周期内用电），结果仅供参考">
                      <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
                    </Tooltip>
                  </>
                }
              >
                <EstimateBalance clientId={data?.saleClientId} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="voucherNumber" label="交易凭证数">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="status" label="账户状态">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="saleClientName" label="所属客户">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="companyName" label="企业名称">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="uscc" label="统一信用代码">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="ouName" label="所属运营单元">
                <ShowInput />
              </Form.Item>
            </Col>
            <Form.Item hidden name="creditLimitType" label="信用额度类型">
              <Radio.Group>
                <Radio value={CreditLimitType.NONE}>{CreditLimitTypeDisplay[CreditLimitType.NONE]}</Radio>
                <Radio value={CreditLimitType.LIMITED}>{CreditLimitTypeDisplay[CreditLimitType.LIMITED]}</Radio>
                <Radio value={CreditLimitType.UNLIMITED}>{CreditLimitTypeDisplay[CreditLimitType.UNLIMITED]}</Radio>
              </Radio.Group>
            </Form.Item>
            <Col span={8}>
              <Form.Item name="creditLimitTypeName" label="信用额度类型">
                <ShowInput />
              </Form.Item>
            </Col>
            <Form.Item noStyle dependencies={['creditLimitType']}>
              {({ getFieldValue }) => {
                return (
                  getFieldValue('creditLimitType') === CreditLimitType.LIMITED && (
                    <Col span={8}>
                      <Form.Item name="creditLimitAmount" label="信用额度">
                        <ShowInput />
                      </Form.Item>
                    </Col>
                  )
                );
              }}
            </Form.Item>
            <Col span={8}>
              <Form.Item name="description" label="备注">
                <ShowInput />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <Space className="sticky-footer-c">
          <Button onClick={goList}>返回</Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default DetailClientAccount;
