import { FC, useEffect, useState } from 'react';
import { Wrapper, Button, Form, Input, Tag, SubContent, FormTitle } from '@maxtropy/components';
import styles from './index.module.scss';
import { Row, Col, Space } from 'antd';
import ShowInput from '@/shared/components/ShowInput';
import { useNavigate, useParams } from 'react-router-dom';
import { createAlarmWorkOrder, getAlarmWorkOrderDetail, WorkOrderDetailProps } from '../../../api/alarm';
import { AlarmLevelColorDisplay, AlarmLevelDisplay } from '@/shared/types';
import dayjs from 'dayjs';
import { qingflowWorkDetailPath } from '../const';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const dateFormat = 'YYYY/MM/DD HH:mm:ss';

const WorkOrder: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isView, setIsView] = useState<boolean>(false);

  const [data, setData] = useState<WorkOrderDetailProps>();

  useEffect(() => {
    if (id) {
      getAlarmWorkOrderDetail(id!).then(res => {
        setData(res);
      });
    }
  }, [id]);

  const submitBtn = () => {
    form.validateFields().then(valid => {
      createAlarmWorkOrder({ name: valid.name, ruleLogId: id! }).then(_ => {
        setIsView(true);
        // 跳转到轻流工单详情
        window.open(qingflowWorkDetailPath, '_blank');
      });
    });
  };

  return (
    <Wrapper className={styles.wrapper}>
      <Form layout="vertical" form={form} {...formLayout}>
        <FormTitle title="创建工单" />
        <SubContent className="mb-8">
          <Row>
            <Col span={8}>
              {isView ? (
                <Form.Item label="工单名称">
                  <ShowInput value={data?.name} />
                </Form.Item>
              ) : (
                <Form.Item
                  label="工单名称"
                  name="name"
                  rules={[
                    { required: true, message: '请输入' },
                    { max: 15, message: '最多输入15个字符' },
                  ]}
                >
                  <Input placeholder="请输入" maxLength={15}></Input>
                </Form.Item>
              )}
            </Col>
            <Col span={8}>
              <Form.Item label="设备名称">
                <ShowInput value={data?.deviceName} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="报警等级">
                <ShowInput
                  value={
                    <Tag border="solid" color={AlarmLevelColorDisplay[data?.alarmLevel!]}>
                      {AlarmLevelDisplay[data?.alarmLevel!]}
                    </Tag>
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="报警规则">
                <Space>
                  <ShowInput value={data?.ruleName} />
                </Space>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="报警时间">
                <ShowInput value={dayjs(data?.alarmTime).format(dateFormat)} />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
      </Form>
      {!isView && (
        <Space size={8} className="sticky-footer">
          <Button type="primary" onClick={submitBtn}>
            提交
          </Button>
          <Button
            className={styles.button}
            // onClick={() => {
            //   window.location.href = `${window.DMEPLATFORMSORIGIN}/assets/alarm/overview`;
            // }}
            onClick={() => {
              navigate(-1);
            }}
          >
            取消
          </Button>
        </Space>
      )}
    </Wrapper>
  );
};

export default WorkOrder;
