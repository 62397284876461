import { Empty } from '@maxtropy/components';
import { Skeleton } from 'antd';
import React, { FC } from 'react';
import ReactEcharts from 'echarts-for-react';
import useEchartsTheme from '@/shared/hooks/useEchartsTheme';
import { EnergyCompareGetBatchMediumIndicatorProcessPointDataPostRequest } from '@maxtropy/device-customer-apis';
import { V2EnergyCompareGetBatchMediumIndicatorProcessPointHisDataPostRequest } from '@maxtropy/device-customer-apis-v2';

export interface ChartProps {
  loading?: boolean;
  chartData?: any;
  option?: any;
  height?: number;
  searchParams?: EnergyCompareGetBatchMediumIndicatorProcessPointDataPostRequest;
  hisSearchParams?: V2EnergyCompareGetBatchMediumIndicatorProcessPointHisDataPostRequest;
}

const Chart: FC<ChartProps> = props => {
  const { option, chartData, height, searchParams, hisSearchParams } = props;
  const echartsTheme = useEchartsTheme();

  return (
    <>
      <Skeleton style={{ padding: '120px 80px' }} loading={props.loading} paragraph={{ rows: 6 }}>
        {!searchParams && !hisSearchParams ? (
          <Empty description="请配置完成后点击对比" style={{ marginTop: '20vh' }} />
        ) : chartData && chartData.length ? (
          <ReactEcharts
            option={option}
            style={{ height: height ?? 'calc(100% - 16px)' }}
            notMerge
            lazyUpdate={false}
            theme={echartsTheme}
          />
        ) : (
          <Empty style={{ marginTop: '20vh' }} />
        )}
      </Skeleton>
    </>
  );
};

export default Chart;
