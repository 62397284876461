import { Button, EllipsisSpan, FormContent, Table, useBreadcrumbRoutes, Wrapper } from '@maxtropy/components';
import { Col, Form, Row } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import styles from './index.module.scss';
import { apiV2WorkShiftCalendarConfigProcedureInfoPost } from '@maxtropy/device-customer-apis-v2';
import ShowInput from '@/shared/components/ShowInput';

const detailColumns = [
  {
    title: '工作中心',
    dataIndex: 'workCenterName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '应用工序',
    dataIndex: 'workProcedureName',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v} />,
  },
];

const routes = [{ name: `查看应用配置` }];
interface DetailTableData {
  workProcedureName?: string;
  workCenterName?: string;
}

const DetailAppProcess: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [detailForm] = Form.useForm();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [loading, setLoading] = useState<boolean>(false);
  const [detailTableData, setDetailTableData] = useState<DetailTableData[]>([]);
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const workShiftCalendarId = urlSearchParams.get('workShiftCalendarId') || undefined;

  useEffect(() => {
    if (id) {
      setLoading(true);
      apiV2WorkShiftCalendarConfigProcedureInfoPost({ id: Number(id) })
        .then(res => {
          detailForm.setFieldsValue({
            startDate: res.startDate,
            endDate: res.endDate,
          });
          const detailTable = (res?.workCenterList ?? []).map(i => {
            return {
              workCenterName: i?.workCenterName,
              workProcedureName: i?.workProcedureList?.map(item => item?.workProcedureName).join('、'),
            };
          });
          setDetailTableData(detailTable);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, detailForm]);

  return (
    <Wrapper isLoading={loading} routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormContent title="查看应用配置">
        <Form form={detailForm} style={{ width: '98%', paddingLeft: 70, paddingTop: 54 }}>
          <Row>
            <Col span={12}>
              <Form.Item label="起始时间" name="startDate">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="结束时间" name="endDate">
                <ShowInput />
              </Form.Item>
            </Col>
          </Row>
          <p style={{ color: 'rgba(255, 255, 255, 0.85)', fontSize: 14 }}>选择工序：</p>
          <Table style={{ marginBottom: 33 }} rowKey="id" columns={detailColumns} dataSource={detailTableData} />
          <Button>
            <Link to={`/energy/teamShift/shiftCalendar/configuration/${workShiftCalendarId}`}>返回</Link>
          </Button>
        </Form>
      </FormContent>
    </Wrapper>
  );
};

export default DetailAppProcess;
