import { Button } from '@maxtropy/components';
import { Col, Form, Row, Space } from 'antd';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import ShowInput from '@/shared/components/ShowInput';
import {
  BaseElectricityBillResponse,
  BillStatusEnum,
  CapitalContributionRateRequest,
  DiscountChangeAmountRequest,
  DiscountRequest,
  ElectricityBillAmountResponse,
  electricityBillDiscountChangeAmount,
  OperatePasswordRequest,
} from '../../../../api/electricity';
import { ElectricitySaleContractSettlementType } from '../../../../api/electricitySettlementRules';
import { borderStyle } from '../utils';
import styles from '../utils/utils.module.scss';
import DiscountModal from './DiscountModal';

export interface ElectricityBillAmountProps {
  data?: BaseElectricityBillResponse;
  capitalContributionRateRequest?: CapitalContributionRateRequest;
  onChange?: (v: DiscountRequest) => void;
}

const ElectricityBillAmount: FC<ElectricityBillAmountProps> = ({ data, capitalContributionRateRequest, onChange }) => {
  const [form] = Form.useForm();

  const [discountRequest, setDiscountRequest] = useState<DiscountRequest>();

  const [localData, setLocalData] = useState<ElectricityBillAmountResponse>();

  useEffect(() => {
    if (discountRequest) {
      onChange?.(discountRequest);
    }
  }, [discountRequest, onChange]);

  useEffect(() => {
    if (localData) {
      form.setFieldsValue({
        totalAmount: localData.totalAmount?.toFixed(2),
        discountTotalAmount: localData.discountTotalAmount?.toFixed(2),
        discountAmount: localData.discountAmount?.toFixed(2),
        changeDiscountTotalAmount: localData.changeDiscountTotalAmount?.toFixed(2),
        changeDiscountAmount: localData.changeDiscountAmount?.toFixed(2),
        amount: localData.amount?.toFixed(2),
        amountWords: localData.amountWords,
        payedAmount: localData.payedAmount?.toFixed(2),
        oweAmount: localData.oweAmount?.toFixed(2),
        postPaymentAmount: localData.postPaymentAmount?.toFixed(2),
      });
    }
  }, [localData, form]);

  useEffect(() => {
    if (data) {
      setDiscountRequest(undefined);
      setLocalData(data.amount);
    }
  }, [data]);

  const changeAmount = useCallback(
    (v: DiscountChangeAmountRequest, operate: OperatePasswordRequest = { isPasswordCheck: false }) => {
      if (data) {
        electricityBillDiscountChangeAmount({
          ...v,
          ...operate,
          id: data.id,
        }).then(res => {
          setLocalData(res);
        });
      }
    },
    [data]
  );

  useEffect(() => {
    if (capitalContributionRateRequest) {
      changeAmount({
        ...capitalContributionRateRequest,
        ...discountRequest,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capitalContributionRateRequest, changeAmount]);

  const [visible, setVisible] = useState<boolean>(false);

  const onClose = () => {
    setVisible(false);
  };

  const disabled = useMemo(() => {
    if (data) {
      if (
        data.settlementType === ElectricitySaleContractSettlementType.UNITARYSYSTEM &&
        data.status === BillStatusEnum.DRAFT
      ) {
        if (data?.config && data?.config.hasCapitalContributionRate) {
          return !capitalContributionRateRequest;
        }
      }
      return false;
    }
  }, [data, capitalContributionRateRequest]);

  const onChangeAmount = (v: ElectricityBillAmountResponse, formData: DiscountRequest) => {
    setLocalData(v);
    setDiscountRequest(formData);
  };

  return (
    <>
      <Form layout="vertical" form={form}>
        <Space direction="vertical" size={12} style={{ width: '100%' }}>
          <Row
            gutter={8}
            style={{
              ...borderStyle,
              paddingBottom: 24,
            }}
          >
            <Col span={4}>
              <Form.Item name="totalAmount" label="优惠前总金额" className={styles.formItem}>
                <ShowInput addonAfter="元" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="优惠后总金额" name="discountTotalAmount" className={styles.formItem}>
                <ShowInput addonAfter="元" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="优惠金额" name="discountAmount" className={styles.formItem}>
                <ShowInput addonAfter="元" />
              </Form.Item>
            </Col>
            <Col
              span={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              {data &&
                [BillStatusEnum.DRAFT, BillStatusEnum.CHECK_PENDING, BillStatusEnum.REVIEW_PENDING].includes(
                  data.status
                ) && (
                  <Button
                    type="link"
                    disabled={disabled}
                    onClick={() => {
                      setVisible(true);
                    }}
                  >
                    优惠调整
                  </Button>
                )}
            </Col>
          </Row>
          <Row gutter={8} style={borderStyle}>
            <Col span={4}>
              <Form.Item label="调整优惠后金额" name="changeDiscountTotalAmount">
                <ShowInput addonAfter="元" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="调整优惠金额" name="changeDiscountAmount">
                <ShowInput addonAfter="元" />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={8}
            style={{
              ...borderStyle,
              backgroundColor: 'rgba(var(--base-text-color),0.03)',
            }}
          >
            <Col span={4}>
              <Form.Item label="小计金额" name="amount">
                <ShowInput addonAfter="元" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="金额合计" name="amount">
                <ShowInput addonAfter="元" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="金额(大写)" name="amountWords">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="payedAmount" label="已收金额">
                <ShowInput addonAfter="元" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="oweAmount" label="应补金额">
                <ShowInput addonAfter="元" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="postPaymentAmount" label="预计缴后余额">
                <ShowInput addonAfter="元" />
              </Form.Item>
            </Col>
          </Row>
        </Space>
      </Form>
      <DiscountModal
        capitalContributionRateRequest={capitalContributionRateRequest}
        visible={visible}
        onClose={onClose}
        onChange={onChangeAmount}
        data={data}
        amountData={localData}
      />
    </>
  );
};

export default ElectricityBillAmount;
