import { Empty } from '@maxtropy/components';
import { Skeleton } from 'antd';
import { FC } from 'react';
import ReactEcharts from 'echarts-for-react';
import { MediumIndicatorStatisticsRes } from '@/api/energyMedium';
import useEchartsTheme from '@/shared/hooks/useEchartsTheme';

export interface ChartProps {
  loading?: boolean;
  chartData?: MediumIndicatorStatisticsRes;
  option?: any;
  height?: number;
}

const Charts: FC<ChartProps> = props => {
  const { option, chartData, height } = props;
  const echartsTheme = useEchartsTheme();
  const filteredChartData = chartData?.data?.filter(i => !!i);

  return (
    <>
      <Skeleton style={{ padding: '120px 80px' }} loading={props.loading} paragraph={{ rows: 6 }}>
        {filteredChartData && filteredChartData.length ? (
          <ReactEcharts
            option={option}
            style={{ height: height ?? 'calc(100% - 16px)' }}
            notMerge
            lazyUpdate={false}
            theme={echartsTheme}
          />
        ) : (
          <Empty />
        )}
      </Skeleton>
    </>
  );
};

export default Charts;
