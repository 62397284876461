import { FC, useEffect, useState } from 'react';
import { Row, Col, Layout, Space } from 'antd';
import { useUpdate, Key, Button, Table, Tag, EllipsisSpan, PopConfirm, Form } from '@maxtropy/components';
import ShowInput from '@/shared/components/ShowInput';
import ModelTree from '../ModelTree';
import styles from './index.module.scss';
import type { ColumnsType } from 'antd/es/table';
import { ProductDtoType, InputDtoType, OutputDtoType, lcaDetailType } from './const';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
  InputTypeMap,
  InputType,
  OutputType,
  OutputTypeMap,
  MainDataSourcesTypeMap,
  MainDataSources,
} from '../../utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getUnits, UnitTypeWithUnitList, OutputUnitType } from '@/api/outputConfig';
import {
  apiV2CarbonFootprintLcaDeleteInputPost,
  apiV2CarbonFootprintLcaDeleteOutputPost,
  apiV2CarbonFootprintLcaDeleteSubPost,
  apiV2CarbonFootprintLcaDetailPost,
} from '@maxtropy/device-customer-apis-v2';
import { isNil } from 'lodash-es';
import ByProductModal from '../ByProductModal';
import CoefficientModal from '../CoefficientModal';
import BasicInfoModal from '../BasicInfoModal';

const LifeCycleModel: FC = () => {
  const [selectTreeKey, setSelectTreeKey] = useState<Key[]>([]);
  const [treeSelectedKeys, setTreeSelectedKeys] = useState<Key[]>([]); // 选择树
  const [treeExpandedKeys, setTreeExpandedKeys] = useState<Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true); // 左部树父节点是否自动展开
  const [urlSearchParams] = useSearchParams();
  const id = urlSearchParams.get('id') || undefined;
  const procedureId = urlSearchParams.get('procedureId') || undefined;

  const navigate = useNavigate();
  const [updateState, updateFn] = useUpdate();

  const [lifeCycleDetail, setLifeCycleDetail] = useState<lcaDetailType>({});
  const [units, setUnits] = useState<UnitTypeWithUnitList[]>([]);

  const [basicInfoModalOpen, setBasicInfoModalOpen] = useState<boolean>(false);
  const [byProductModalOpen, setByProductModalOpen] = useState<boolean>(false);
  const [coefficientModalOpen, setCoefficientModalOpen] = useState<boolean>(false);
  const [openSider, setOpenSider] = useState(true); // 左侧树形toggle

  const [currentProduct, setCurrentProduct] = useState<ProductDtoType>();
  const [inputLength, setInputLength] = useState<number>();
  const [outputLength, setOutputLength] = useState<number>();

  // 获取所有单位
  useEffect(() => {
    getUnits().then(res => {
      setUnits(res.list);
    });
  }, []);

  useEffect(() => {
    if (selectTreeKey.length === 0) return;
    apiV2CarbonFootprintLcaDetailPost({ id: Number(selectTreeKey[0]) }).then((res: any) => {
      setLifeCycleDetail(res);
      setInputLength(res.inputDtos.length ?? 0);
      setOutputLength(res.outputDtos.length ?? 0);
    });
  }, [selectTreeKey, updateState]);

  useEffect(() => {
    if (isNil(procedureId)) return;
    setSelectTreeKey([procedureId as string]);
  }, [procedureId]);

  // 左部树选择
  const onTreeSelect = (v: Key[]) => {
    setSelectTreeKey(v);
  };

  const productionColumns: ColumnsType<ProductDtoType> = [
    {
      title: '产品名称',
      dataIndex: 'name',
      render(value, record, index) {
        return (
          <>
            <EllipsisSpan value={value}></EllipsisSpan>
            {record.isMaster === 1 && (lifeCycleDetail?.productDtos?.length as number) > 1 && (
              <Tag border="solid" color="warning" style={{ marginLeft: 15 }}>
                主产品
              </Tag>
            )}
          </>
        );
      },
    },
    {
      title: '数量',
      dataIndex: 'quantity',
      ellipsis: true,
      width: '30%',
      render(value, record, index) {
        return (
          <EllipsisSpan
            value={`${value} ${
              (record.unitTypeCode === OutputUnitType.PIECE
                ? units
                    .find(u => u.unitTypeCode === OutputUnitType.PIECE)
                    ?.outputProductUnitList?.find(e => e?.unitCode === record.unitCode)?.unitName
                : record.unitCode) ?? '--'
            }`}
          />
        );
      },
    },
    {
      title: '规格型号',
      dataIndex: 'spec',
      ellipsis: true,
      width: '30%',
    },
    {
      title: '操作',
      ellipsis: true,
      render(value, record, index) {
        return (
          <>
            {record.isMaster === 0 && (
              <Space size={16}>
                <Button
                  type="link"
                  onClick={() => {
                    setCurrentProduct(record);
                    setByProductModalOpen(true);
                  }}
                >
                  编辑
                </Button>
                <PopConfirm
                  placement="top"
                  title={'您是否确认删除?'}
                  onConfirm={() => {
                    apiV2CarbonFootprintLcaDeleteSubPost({ id: record.id }).then(() => {
                      updateFn();
                    });
                  }}
                >
                  <Button type="link">删除</Button>
                </PopConfirm>
              </Space>
            )}
          </>
        );
      },
    },
  ];

  const entryColumns: ColumnsType<InputDtoType> = [
    {
      title: '产品名称',
      dataIndex: 'name',
    },
    {
      title: '数量',
      dataIndex: 'quantity',
      ellipsis: true,
      render(value, record, index) {
        return (
          <EllipsisSpan
            value={`${value} ${
              record.type !== InputType.ENERGY
                ? (record.unitTypeCode === OutputUnitType.PIECE
                    ? units
                        .find(u => u.unitTypeCode === OutputUnitType.PIECE)
                        ?.outputProductUnitList?.find(e => e?.unitCode === record.unitCode)?.unitName
                    : record.unitCode) ?? '--'
                : record.unitCode
            }`}
          />
        );
      },
    },
    {
      title: '输入类型',
      dataIndex: 'type',
      ellipsis: true,
      render(value: InputType) {
        return <EllipsisSpan value={InputTypeMap[value]}></EllipsisSpan>;
      },
    },
    {
      title: '碳足迹',
      dataIndex: 'carbonFootPrint',
      ellipsis: true,
      render(value: InputType, record) {
        return (
          <>
            {record.isProcedure === 1 ? (
              <EllipsisSpan value={'引用上游过程数据'}></EllipsisSpan>
            ) : (
              <EllipsisSpan
                value={`${value ?? '--'} kgCO2/${
                  record.type !== InputType.ENERGY
                    ? (record.unitTypeCode === OutputUnitType.PIECE
                        ? units
                            .find(u => u.unitTypeCode === OutputUnitType.PIECE)
                            ?.outputProductUnitList?.find(e => e?.unitCode === record.unitCode)?.unitName
                        : record.unitCode) ?? '--'
                    : record.unitCode
                }`}
              ></EllipsisSpan>
            )}
          </>
        );
      },
    },
    {
      title: '操作',
      ellipsis: true,
      render(value, record, index) {
        return (
          <Space size="middle">
            <Button
              type="link"
              onClick={() => {
                if (record.isBom || record.isProcedure) {
                  navigate(
                    `/productCarbonFootPrint/basic/modelManage/editInput?id=${record.id}&&isProcedure=${record.isProcedure}&&procedureName=${lifeCycleDetail?.procedureDto?.name}&&lcaId=${id}&&procedureId=${selectTreeKey[0]}`
                  );
                } else {
                  navigate(
                    `/productCarbonFootPrint/basic/modelManage/normalInput?id=${record.id}&&procedureId=${selectTreeKey[0]}&&lcaId=${id}&&name=${lifeCycleDetail?.procedureDto?.name}`
                  );
                }
              }}
            >
              编辑
            </Button>
            <PopConfirm
              placement="top"
              title={'您是否确认删除?'}
              onConfirm={() => {
                apiV2CarbonFootprintLcaDeleteInputPost({ id: record.id }).then(() => {
                  updateFn();
                });
              }}
              onCancel={() => {}}
            >
              <Button type="link" disabled={record.isBom === 1}>
                删除
              </Button>
            </PopConfirm>
          </Space>
        );
      },
    },
  ];

  const outputColumns: ColumnsType<OutputDtoType> = [
    {
      title: '输出名称',
      dataIndex: 'name',
    },
    {
      title: '输出数量',
      dataIndex: 'quantity',
      ellipsis: true,
      render(value, record, index) {
        return (
          <EllipsisSpan
            value={`${value} ${
              (record.unitTypeCode === OutputUnitType.PIECE
                ? units
                    .find(u => u.unitTypeCode === OutputUnitType.PIECE)
                    ?.outputProductUnitList?.find(e => e?.unitCode === record.unitCode)?.unitName
                : record.unitCode) ?? '--'
            }`}
          />
        );
      },
    },
    {
      title: '输出类型',
      dataIndex: 'type',
      ellipsis: true,
      render(value: OutputType) {
        return <EllipsisSpan value={OutputTypeMap[value]}></EllipsisSpan>;
      },
    },
    {
      title: '碳足迹',
      dataIndex: 'carbonFootPrint',
      ellipsis: true,
      render(value, record) {
        return (
          <EllipsisSpan
            value={`${value} kgCO2/${
              (record.unitTypeCode === OutputUnitType.PIECE
                ? units
                    .find(u => u.unitTypeCode === OutputUnitType.PIECE)
                    ?.outputProductUnitList?.find(e => e?.unitCode === record.unitCode)?.unitName
                : record.unitCode) ?? '--'
            }`}
          ></EllipsisSpan>
        );
      },
    },
    {
      title: '操作',
      ellipsis: true,
      render(value, record, index) {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => {
                navigate(
                  `/productCarbonFootPrint/basic/modelManage/addOutput?id=${record.id}&&procedureId=${selectTreeKey[0]}&&lcaId=${id}`
                );
              }}
            >
              编辑
            </Button>
            <PopConfirm
              placement="top"
              title={'您是否确认删除?'}
              onConfirm={() => {
                apiV2CarbonFootprintLcaDeleteOutputPost({ id: record.id }).then(() => {
                  updateFn();
                });
              }}
            >
              <Button type="link">删除</Button>
            </PopConfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <Layout style={{ background: '#232324' }}>
      <Layout.Sider
        width={openSider ? 260 : 0}
        style={{
          paddingTop: '12px',
          background: '#232324',
          marginRight: 32,
          borderRadius: 2,
          border: openSider ? '1px solid rgba(255, 255, 255, 0.2)' : 'none',
          opacity: openSider ? 1 : 0,
        }}
      >
        <ModelTree
          selectedKeys={treeSelectedKeys}
          setSelectedKeys={setTreeSelectedKeys}
          expandedKeys={treeExpandedKeys}
          setExpandedKeys={setTreeExpandedKeys}
          onTreeSelect={onTreeSelect}
          autoExpandParent={autoExpandParent}
          setAutoExpandParent={setAutoExpandParent}
          id={id}
        />
      </Layout.Sider>
      <Layout.Content className={styles.content}>
        <div
          className={styles.toggleBtn}
          style={{ left: openSider ? -52 : -26 }}
          onClick={() => setOpenSider(!openSider)}
        >
          {openSider ? <LeftOutlined /> : <RightOutlined />}
        </div>
        <div className={styles.rightContent}>
          <>
            <div className={styles.descriptionTitle}>过程描述</div>
            <Row>
              <Col span={10}>
                <Form.Item label="过程名称">
                  <ShowInput value={lifeCycleDetail?.procedureDto?.name} />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item label="主要数据来源">
                  <ShowInput
                    value={MainDataSourcesTypeMap[lifeCycleDetail?.procedureDto?.dataOrigin as MainDataSources]}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <div style={{ float: 'right' }}>
                  <Button type="primary" onClick={() => setBasicInfoModalOpen(true)}>
                    编辑
                  </Button>
                </div>
              </Col>
            </Row>
          </>
          <>
            <div className={styles.msgTitle}>
              产品信息
              <Space size="middle">
                <Button
                  type="primary"
                  onClick={() => {
                    setCurrentProduct(undefined);
                    setByProductModalOpen(true);
                  }}
                >
                  添加副产品
                </Button>
                {(lifeCycleDetail?.productDtos?.length as number) > 1 && (
                  <Button
                    type="primary"
                    onClick={() => {
                      setCoefficientModalOpen(true);
                    }}
                  >
                    设置分配系数
                  </Button>
                )}
              </Space>
            </div>
            <Row>
              <Col span={24}>
                <Table
                  rowKey={record => record.id}
                  columns={productionColumns}
                  dataSource={lifeCycleDetail.productDtos}
                  pagination={false}
                ></Table>
              </Col>
            </Row>
          </>
          <div className={styles.tablePadding}>
            <div className={styles.msgTitle}>
              输入表{`(${inputLength})`}
              <Button
                type="primary"
                onClick={() => {
                  navigate(
                    `/productCarbonFootPrint/basic/modelManage/normalInput?name=${lifeCycleDetail?.procedureDto?.name}&&lcaId=${id}&&procedureId=${selectTreeKey[0]}`
                  );
                }}
              >
                添加输入
              </Button>
            </div>
            <Row>
              <Col span={24}>
                <Table
                  rowKey={record => record.id}
                  columns={entryColumns}
                  dataSource={lifeCycleDetail.inputDtos}
                  pagination={false}
                ></Table>
              </Col>
            </Row>
          </div>
          <div className={styles.tablePadding}>
            <div className={styles.msgTitle}>
              输出表{`(${outputLength})`}
              <Button
                type="primary"
                onClick={() => {
                  navigate(
                    `/productCarbonFootPrint/basic/modelManage/addOutput?lcaId=${id}&&procedureId=${selectTreeKey[0]}`
                  );
                }}
              >
                添加输出
              </Button>
            </div>
            <Row>
              <Col span={24}>
                <Table
                  rowKey={record => record.id}
                  columns={outputColumns}
                  dataSource={lifeCycleDetail.outputDtos}
                  pagination={false}
                ></Table>
              </Col>
            </Row>
          </div>
        </div>
      </Layout.Content>
      {basicInfoModalOpen && (
        <BasicInfoModal
          open={basicInfoModalOpen}
          basicInfo={lifeCycleDetail?.procedureDto}
          setOpen={setBasicInfoModalOpen}
          updateData={updateFn}
        />
      )}
      {byProductModalOpen && (
        <ByProductModal
          open={byProductModalOpen}
          basicInfo={lifeCycleDetail?.procedureDto}
          productInfo={currentProduct}
          setOpen={setByProductModalOpen}
          updateData={updateFn}
        />
      )}
      {coefficientModalOpen && (
        <CoefficientModal
          basicInfo={lifeCycleDetail?.procedureDto}
          productList={lifeCycleDetail.productDtos}
          open={coefficientModalOpen}
          setOpen={setCoefficientModalOpen}
          updateData={updateFn}
        />
      )}
    </Layout>
  );
};

export default LifeCycleModel;
