import { getSaleAccountVoucherList, SaleVoucherVo, VoucherRefundRequest } from '@/api/sale';
import { timeFormatString } from '@/pages/ClientAccount/util';
import { Button, EllipsisSpan, Form, Key, Modal, Table } from '@maxtropy/components';

import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ShowInput from '@/shared/components/ShowInput';
import styles from './index.module.scss';
import { InputNumber } from 'antd';

const columns = [
  {
    title: '收款凭证',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: ' 剩余可用金额',
    dataIndex: 'balance',
    ellipsis: { showTitle: true },
    render: (v: string) => <ShowInput style={{ color: '#00ADFF' }} value={v} addonAfter="元" />,
  },
  {
    title: '凭证收款日期',
    dataIndex: 'specifiedDate',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format(timeFormatString)} />,
  },
  {
    title: '凭证说明',
    dataIndex: 'description',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface DataSource extends SaleVoucherVo {
  voucherDeductionAmount?: number | null;
}

interface Props {
  setVouchers: (v: VoucherRefundRequest[]) => void;
  isWarn: boolean;
  setIsWarn: (v: boolean) => void;
  setDeductionAmount: (v: number) => void;
}

function changeToVouchers(v: DataSource[]) {
  return v.map(i => {
    return {
      saleVoucherId: i.id,
      amount: i.voucherDeductionAmount ?? 0,
    };
  });
}

const CollectionCard: React.FC<Props> = ({ setVouchers, isWarn, setIsWarn, setDeductionAmount }) => {
  const [form] = Form.useForm();
  const [saleVoucher, setSaleVoucher] = useState<SaleVoucherVo[]>([]);
  const [dataSource, setDataSource] = useState<DataSource[]>([]);
  const { id } = useParams<{ id?: string }>();

  const [saleVoucherOpen, setSaleVoucherOpen] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<SaleVoucherVo[]>([]);

  useEffect(() => {
    if (id) {
      getSaleAccountVoucherList({ saleClientAccountId: id, softField: 1 }).then(res => {
        setSaleVoucher(res.list ?? []);
      });
    }
  }, [id]);

  const calculateVoucherDeductionAmount = (source: SaleVoucherVo[], deductionAmount: number) => {
    let num = 0;
    let data = [];
    for (let i = 0; i < source.length; i++) {
      num += source[i].balance;
      if (num < deductionAmount) {
        data[i] = {
          ...source[i],
          voucherDeductionAmount: source[i].balance,
        };
        if (i === source.length - 1) {
          setIsWarn(true);
        }
        continue;
      }
      if (num >= deductionAmount) {
        setIsWarn(false);
        data[i] = {
          ...source[i],
          voucherDeductionAmount: deductionAmount - (num - source[i].balance),
        };
        break;
      }
    }
    return data;
  };

  const buildColumns = [
    ...columns,
    {
      title: '凭证扣除金额',
      dataIndex: 'voucherDeductionAmount',
      ellipsis: { showTitle: true },
      render: (v: number, record: DataSource) => {
        return (
          <InputNumber
            min={0}
            // max={record?.balance}
            precision={2}
            value={v ?? 0}
            addonAfter="元"
            placeholder="请输入"
            onChange={v => {
              const allAmount =
                dataSource
                  .filter(i => i.id !== record.id)
                  .map(i => i.voucherDeductionAmount ?? 0)
                  .reduce((x, y) => x + y, 0) + (v ?? 0);
              const deductionAmount = form.getFieldValue('deductionAmount');
              if (allAmount > deductionAmount) return;
              if (allAmount < deductionAmount) setIsWarn(true);
              if (allAmount === deductionAmount) setIsWarn(false);
              const data = dataSource.map(i => {
                if (i.id === record.id) {
                  return {
                    ...i,
                    voucherDeductionAmount: v,
                  };
                }
                return i;
              });
              setDataSource(data);
              setVouchers(changeToVouchers(data));
              setSelectedRowKeys(data.map(i => i.id));
            }}
          />
        );
      },
    },
  ];

  const rowSelection = {
    onChange: (newSelectedRowKeys: Key[], newSelectedRows: SaleVoucherVo[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
      setSelectedRows(newSelectedRows);
    },
    selectedRowKeys,
  };

  const onOK = () => {
    let data = calculateVoucherDeductionAmount(selectedRows, form.getFieldValue('deductionAmount'));
    if (data.length < selectedRows.length) {
      const partSelectedRows = selectedRows.slice(data.length).map(i => {
        return {
          ...i,
          voucherDeductionAmount: 0,
        };
      });
      data = data.concat(partSelectedRows);
    }
    setDataSource(data);
    setVouchers(changeToVouchers(data));
    setSaleVoucherOpen(false);
  };

  return (
    <>
      <div className={styles.formListWrapper}>
        {/* <Form form={form} layout="vertical">
          <Form.Item name="deductionAmount" label="扣款金额" rules={[{ required: true, message: '请输入扣款金额' }]}>
            <InputNumber
              min={0}
              precision={2}
              addonAfter="元"
              placeholder="请输入"
              onChange={v => {
                if (v) {
                  setDeductionAmount(v);
                  const data = calculateVoucherDeductionAmount(saleVoucher, v);
                  setDataSource(data);
                  setVouchers(changeToVouchers(data));
                  setSelectedRowKeys(data.map(i => i.id));
                  setSelectedRows(data);
                }
              }}
            />
          </Form.Item>
        </Form> */}

        <div className={styles.selectBtn}>
          <Button
            type="primary"
            onClick={() => {
              setSaleVoucherOpen(true);
            }}
          >
            选择收款凭证
          </Button>
        </div>
        {dataSource.length > 0 && (
          <Table
            size="small"
            dataSource={dataSource}
            style={{ paddingRight: 20 }}
            rowKey="id"
            sticky
            columns={buildColumns}
            pagination={false}
          />
        )}
      </div>
      {/* {isWarn && <p className={styles.warnInfo}>选择的收款凭证不足于本次扣款</p>} */}
      <Modal
        onOk={onOK}
        onCancel={() => {
          setSaleVoucherOpen(false);
          setSelectedRowKeys(dataSource.map(i => i.id));
        }}
        size="large"
        title="更换收款凭证"
        okText="保存"
        open={saleVoucherOpen}
      >
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          scroll={{ y: 300 }}
          rowKey="id"
          sticky
          dataSource={saleVoucher}
          columns={columns}
        />
      </Modal>
    </>
  );
};
export default CollectionCard;
