import React, { FC, useEffect, useState, useMemo } from 'react';
import { Modal, Button, Form, BaseInfoContent, Upload } from '@maxtropy/components';
import ShowInput from '@/shared/components/ShowInput';
import {
  getDeviceProperty,
  deviceAssetTypeEmun,
  deviceAssetTypeItem,
  DevicePropertyResponse,
} from '../../../../api/device';
import dayjs from 'dayjs';
import TagDisplayForm from '@/shared/components/MultiModalSelect/TagDisplayForm';
import DeviceTagsDisplayForm from '@/shared/components/DeviceTags/TagsDisplayForm';

interface PropertyDetailProps {
  id: string;
}

const PropertyDetail: FC<PropertyDetailProps> = ({ id }) => {
  const [form] = Form.useForm();
  const [showEnergyAssetInfoVisible, setShowEnergyAssetInfoVisible] = useState<boolean>(false);
  const [deviceAsset, setDeviceAsset] = useState<deviceAssetTypeItem>();
  const [data, setData] = useState<DevicePropertyResponse>();
  const showEnergyAssetInfo = (val: deviceAssetTypeItem) => {
    setShowEnergyAssetInfoVisible(true);
  };

  const isShowTag = useMemo(() => {
    return (
      deviceAsset?.[deviceAssetTypeEmun.PVSTATION]?.length ||
      deviceAsset?.[deviceAssetTypeEmun.ENERGYSTORAGEARRAY]?.length ||
      deviceAsset?.[deviceAssetTypeEmun.NEW_ENERGYSTORAGEARRAY]?.length ||
      deviceAsset?.[deviceAssetTypeEmun.NETENERGY]?.length ||
      deviceAsset?.[deviceAssetTypeEmun.CHARGING]?.length ||
      deviceAsset?.[deviceAssetTypeEmun.TOPOLOGY]?.length ||
      deviceAsset?.[deviceAssetTypeEmun.GAS_PREPARATION_STATION]?.length ||
      deviceAsset?.[deviceAssetTypeEmun.HVAC]?.length
    );
  }, [deviceAsset]);

  // 光伏站编号
  const pvstation = useMemo(() => {
    return deviceAsset?.[deviceAssetTypeEmun.PVSTATION]?.map(m => m.deviceAssetCode).join();
  }, [deviceAsset]);

  // 储能编号
  const energyStorageArray = useMemo(() => {
    return [
      ...(deviceAsset?.[deviceAssetTypeEmun.ENERGYSTORAGEARRAY] ?? []),
      ...(deviceAsset?.[deviceAssetTypeEmun.NEW_ENERGYSTORAGEARRAY] ?? []),
    ]
      .map(m => m.deviceAssetCode)
      .join();
  }, [deviceAsset]);

  // 微网能源资产编号
  const netEnergyArray = useMemo(() => {
    return deviceAsset?.[deviceAssetTypeEmun.NETENERGY]?.map(m => m.deviceAssetCode).join();
  }, [deviceAsset]);

  // 充电站能源资产编号
  const chargeEnergyArray = useMemo(() => {
    return deviceAsset?.[deviceAssetTypeEmun.CHARGING]?.map(m => m.deviceAssetCode).join();
  }, [deviceAsset]);

  // 能源介质拓扑编号
  const topologyCodeArrary = useMemo(() => {
    return deviceAsset?.[deviceAssetTypeEmun.TOPOLOGY]?.map(m => m.deviceAssetCode).join();
  }, [deviceAsset]);

  // 气体制备站编号
  const gPSArray = useMemo(() => {
    return deviceAsset?.[deviceAssetTypeEmun.GAS_PREPARATION_STATION]?.map(m => m.deviceAssetCode).join();
  }, [deviceAsset]);

  // 暖通空调
  const hvacEnergyArray = useMemo(() => {
    return deviceAsset?.[deviceAssetTypeEmun.HVAC]?.map(m => m.deviceAssetCode).join();
  }, [deviceAsset]);

  useEffect(() => {
    if (id) {
      getDeviceProperty(id).then(res => {
        setData(res);
        form.setFieldsValue({
          id: res.id,
          name: res.name,
          sn: res.sn,
          typeId: res.typeName,
          modelId: res.modelName,
          manufactureDate: res.manufactureDate ? dayjs(res.manufactureDate).format('YYYY-MM-DD') : undefined,
          installationDate: res.installationDate ? dayjs(res.installationDate).format('YYYY-MM-DD') : undefined,
          pic: res.pic,
          customerMcid: res.customerName,
          assetCode: res.assetCode,
          ouIds: res.ouIds,
          tags: res.tags,
          code: res.code,
          thirdParty: res.thirdParty,
          objectModalType: res.physicalModelId
            ? `${res.physicalModelNo ?? res.modelNo}-${res.manufacturerName}`
            : res.physicalModelId,
        });
        setDeviceAsset(res.deviceAsset);
      });
    }
  }, [id, form]);

  return (
    <>
      <Form form={form} style={{ marginRight: 50 }}>
        <BaseInfoContent layout="horizontal">
          <Form.Item name="sn" label="设备S/N码">
            <ShowInput />
          </Form.Item>
          <Form.Item name="typeId" label="所属类目">
            <ShowInput />
          </Form.Item>
          <Form.Item name="objectModalType" label="物模型型号">
            <ShowInput />
          </Form.Item>
          <Form.Item name="modelId" label="所属型号">
            <ShowInput />
          </Form.Item>
          <Form.Item name="manufactureDate" label="出厂日期">
            <ShowInput />
          </Form.Item>
          <Form.Item name="installationDate" label="安装日期">
            <ShowInput />
          </Form.Item>
          <Form.Item name="customerMcid" label="所属组织">
            <ShowInput />
          </Form.Item>
          <Form.Item name="assetCode" label="资产编码">
            <ShowInput />
          </Form.Item>
          <Form.Item name="ouIds" label="运营单元">
            <TagDisplayForm />
          </Form.Item>
          <Form.Item name="tags" label="设备标签">
            <DeviceTagsDisplayForm />
          </Form.Item>
          <Form.Item label="能源资产信息">
            {isShowTag ? (
              <Button type="link" onClick={() => showEnergyAssetInfo(deviceAsset!)}>
                点击查看
              </Button>
            ) : (
              '暂无'
            )}
          </Form.Item>
          <Form.Item label="第三方对接">
            <ShowInput value={data?.thirdParty ? '是' : '否'} />
          </Form.Item>
          <Form.Item name="pic" label="设备图片">
            <Upload disabled />
          </Form.Item>
        </BaseInfoContent>
      </Form>
      <Modal
        title="能源资产信息"
        open={showEnergyAssetInfoVisible}
        onCancel={() => setShowEnergyAssetInfoVisible(false)}
        footer={[
          <Button key={'cancel'} onClick={() => setShowEnergyAssetInfoVisible(false)}>
            关闭
          </Button>,
        ]}
      >
        {pvstation && (
          <>
            <h3 style={{ fontWeight: 700 }}>光伏能源资产编号</h3>
            <p>{pvstation}</p>
          </>
        )}
        {energyStorageArray && (
          <>
            <h3 style={{ fontWeight: 700 }}>储能能源资产编号</h3>
            <p> {energyStorageArray}</p>
          </>
        )}
        {netEnergyArray && (
          <>
            <h3 style={{ fontWeight: 700 }}>微网能源资产编号</h3>
            <p> {netEnergyArray}</p>
          </>
        )}
        {chargeEnergyArray && (
          <>
            <h3 style={{ fontWeight: 700 }}>充电站能源资产编号</h3>
            <p> {chargeEnergyArray}</p>
          </>
        )}
        {topologyCodeArrary && (
          <>
            <h3 style={{ fontWeight: 700 }}>能源介质拓扑编号</h3>
            <p> {topologyCodeArrary}</p>
          </>
        )}
        {gPSArray && (
          <>
            <h3 style={{ fontWeight: 700 }}>气体制备站编号</h3>
            <p> {gPSArray}</p>
          </>
        )}
        {hvacEnergyArray && (
          <>
            <h3 style={{ fontWeight: 700 }}>暖通空调编号</h3>
            <p> {hvacEnergyArray}</p>
          </>
        )}
      </Modal>
    </>
  );
};

export default PropertyDetail;
