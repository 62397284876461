import React, { useState } from 'react';
import { CommonUserInfo } from './interface';
import { Row, Space } from 'antd';
import ModalTemplate from './ModalTemplate';
import { columns, MemberSelectProps } from './index';
import { Table, Button } from '@maxtropy/components';
import { OuStaffType } from '../../const';
import useControlledState from '../../hooks/useMergedState';
import { PlusOutlined } from '@ant-design/icons';

interface CommonMemberSelectProps extends Omit<MemberSelectProps, 'disabled'> {}

const CommonMemberSelect: React.FC<CommonMemberSelectProps> = props => {
  const [value, onChange] = useControlledState([], {
    value: props.value,
    onChange: props.onChange,
  });
  const [visible, setVisible] = useState(false);
  const [modalType, setModalType] = useState<OuStaffType>(OuStaffType.MEMBER);

  const render = (record: CommonUserInfo) => {
    const unbind = () => {
      onChange(value.filter(item => item.staffCode !== record.staffCode));
    };

    return (
      <div className="action-buttons">
        <Button type="link" onClick={unbind} style={{ paddingLeft: 0 }} disabled={!!record.unbindable}>
          取消绑定
        </Button>
      </div>
    );
  };

  const openModal = (value: OuStaffType) => {
    setVisible(true);
    setModalType(value);
  };

  const handleOk = (_value: CommonUserInfo[]) => {
    const staffCodes = _value.map(item => item.staffCode);
    const fixed = value.filter(item => item.unbindable);
    const unfixed = value.filter(item => !item.unbindable);
    onChange([...fixed, ..._value, ...unfixed.filter(item => !staffCodes.includes(item.staffCode))]);
    setVisible(false);
  };

  const selectedStaffCodes = value.filter(item => item.staffType === modalType).map(item => item.staffCode);
  const disabledStaffCodes = value.filter(item => item.unbindable).map(item => item.staffCode);

  return (
    <Row>
      <Row style={{ marginBottom: 10 }}>
        <Space size={8}>
          <Button icon={<PlusOutlined />} type="dashed" onClick={() => openModal(OuStaffType.ADMINISTRATOR)}>
            添加管理员
          </Button>
          <Button icon={<PlusOutlined />} type="dashed" onClick={() => openModal(OuStaffType.MEMBER)}>
            添加成员
          </Button>
        </Space>
      </Row>
      <Row>
        <Table
          dataSource={value}
          columns={[...columns, { title: '操作', width: 220, fixed: 'right', render }]}
          rowKey="staffCode"
        />
      </Row>
      <ModalTemplate
        open={visible}
        onOk={handleOk}
        onCancel={() => setVisible(false)}
        type={modalType}
        dataSource={props.userDataSource}
        selectedStaffCodes={selectedStaffCodes}
        disabledStaffCodes={disabledStaffCodes}
      />
    </Row>
  );
};

export default CommonMemberSelect;
