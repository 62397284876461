import { Button, Form, Input, InputNumber, Modal, Radio } from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import { FC, useEffect, useState } from 'react';
import ShowInput from '@/shared/components/ShowInput';
import {
  BaseElectricityBillResponse,
  CapitalContributionRateRequest,
  DiscountRequest,
  ElectricityBillAmountResponse,
  electricityBillDiscountChangeAmount,
} from '../../../../api/electricity';
import { HasPreferentialAmount, HasPreferentialAmountType } from '../../../../api/electricitySettlementRules';
import { borderStyle } from '../utils';

export interface DiscountModalProps {
  visible?: boolean;
  onClose?: () => void;
  data?: BaseElectricityBillResponse;
  amountData?: ElectricityBillAmountResponse;
  capitalContributionRateRequest?: CapitalContributionRateRequest;
  onChange?: (v: ElectricityBillAmountResponse, formData: DiscountRequest) => void;
}

const DiscountModal: FC<DiscountModalProps> = ({
  visible = false,
  onClose,
  data,
  amountData,
  capitalContributionRateRequest,
  onChange,
}) => {
  const [form] = Form.useForm();
  const [invalidForm] = Form.useForm();

  const [existData, setExistData] = useState<ElectricityBillAmountResponse>();

  useEffect(() => {
    if (data && amountData && visible) {
      form.setFieldsValue({
        discountType: amountData.discountType,
        discountValue: amountData.discountValue,
        totalRateDiscountedQuantity: data.totalRateDiscountedQuantity,
      });
    }
  }, [amountData, data, form, visible]);

  const confirmSubmit = () => {
    if (data) {
      form.validateFields().then((v: DiscountRequest) => {
        Modal.confirm({
          icon: null,
          title: null,
          content: (
            <>
              <Form form={invalidForm} layout="vertical">
                <Form.Item
                  label="请输入操作密码"
                  name="password"
                  rules={[{ required: true, message: '请输入操作密码' }]}
                >
                  <Input.Password autoComplete="new-password" placeholder="请输入操作密码" />
                </Form.Item>
              </Form>
            </>
          ),
          onOk: () => {
            return invalidForm.validateFields().then(value => {
              electricityBillDiscountChangeAmount({
                ...capitalContributionRateRequest,
                ...v,
                id: data.id,
                isPasswordCheck: true,
                password: value.password,
              }).then(res => {
                // setExistData(res)
                onChange?.(res, v);
                localClose();
              });
            });
          },
          afterClose: () => {
            invalidForm.resetFields();
          },
        });
      });
    }
  };

  const onFinish = (v: DiscountRequest) => {
    if (data) {
      electricityBillDiscountChangeAmount({
        ...capitalContributionRateRequest,
        ...v,
        id: data.id,
        isPasswordCheck: false,
      }).then(res => {
        setExistData(res);
      });
    }
  };

  const formatValue = () => {
    if (amountData) {
      if (amountData.discountType === undefined) {
        return undefined;
      } else {
        if (amountData.discountType === HasPreferentialAmountType.TOTALDISCOUNTRATIO) {
          return `${HasPreferentialAmount[amountData.discountType]}: ${amountData.discountValue?.toFixed(2)} %`;
        }
        if (amountData.discountType === HasPreferentialAmountType.TOTALPREFERENTIALAMOUNT) {
          return `${HasPreferentialAmount[amountData.discountType]}: ${amountData.discountValue?.toFixed(8)} 元`;
        }
        if (amountData.discountType === HasPreferentialAmountType.UNITPRICEPREFERENTIALAMOUNT) {
          return `${HasPreferentialAmount[amountData.discountType]}: 每度减 ${amountData.discountValue} 分`;
        }
      }
    }
    return undefined;
  };

  const onInputChange = () => {
    setExistData(undefined);
  };

  const localClose = () => {
    form.resetFields();
    setExistData(undefined);
    onClose?.();
  };

  return (
    <>
      <Modal
        open={visible}
        title="优惠调整"
        width={700}
        onCancel={localClose}
        onOk={confirmSubmit}
        maskClosable={false}
        destroyOnClose
      >
        <>
          <Space size={12} style={{ width: '100%' }} direction="vertical">
            <Form layout="vertical">
              <Row gutter={8} style={borderStyle}>
                <Col span={6}>
                  <Form.Item label="优惠前应收金额">
                    <ShowInput value={amountData?.totalAmount?.toFixed(2)} addonAfter="元" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="当前优惠政策">
                    <ShowInput value={formatValue()} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="当前优惠后应收金额">
                    <ShowInput value={amountData?.discountTotalAmount?.toFixed(2)} addonAfter="元" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="当前优惠金额">
                    <ShowInput value={amountData?.discountAmount?.toFixed(2)} addonAfter="元" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Form onFinish={onFinish} form={form} labelAlign="left">
              <Form.Item label="调整后优惠模式" name="discountType" rules={[{ required: true, message: '请选择' }]}>
                <Radio.Group
                  onChange={e => {
                    onInputChange();
                    form.setFieldsValue({
                      discountValue: undefined,
                    });
                  }}
                  options={[
                    {
                      value: HasPreferentialAmountType.TOTALDISCOUNTRATIO,
                      label: HasPreferentialAmount[HasPreferentialAmountType.TOTALDISCOUNTRATIO],
                    },
                    {
                      value: HasPreferentialAmountType.TOTALPREFERENTIALAMOUNT,
                      label: HasPreferentialAmount[HasPreferentialAmountType.TOTALPREFERENTIALAMOUNT],
                    },
                    {
                      value: HasPreferentialAmountType.UNITPRICEPREFERENTIALAMOUNT,
                      label: HasPreferentialAmount[HasPreferentialAmountType.UNITPRICEPREFERENTIALAMOUNT],
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item noStyle dependencies={['discountType']}>
                {({ getFieldValue }) => {
                  const discountType: HasPreferentialAmountType | undefined = getFieldValue('discountType');
                  return (
                    discountType !== undefined &&
                    discountType !== null && (
                      <>
                        {discountType === HasPreferentialAmountType.TOTALPREFERENTIALAMOUNT ? (
                          <Form.Item
                            labelCol={{ flex: '162px' }}
                            wrapperCol={{ flex: '210px' }}
                            name="totalRateDiscountedQuantity"
                            label="优惠电度数"
                            rules={[{ required: true, message: '请输入' }]}
                          >
                            <InputNumber
                              style={{ width: '100%' }}
                              placeholder="请输入"
                              max={99999999.9999}
                              min={0}
                              addonAfter="kWh"
                              step="0.01"
                              precision={2}
                            />
                          </Form.Item>
                        ) : null}
                        <Form.Item
                          style={{ marginBottom: 0 }}
                          labelCol={{ flex: '162px' }}
                          label={HasPreferentialAmount[discountType]}
                          required
                        >
                          <Space>
                            {discountType === HasPreferentialAmountType.TOTALDISCOUNTRATIO ? (
                              <Form.Item
                                name="discountValue"
                                extra="例: 输80%，等于打8折"
                                rules={[{ required: true, message: '请输入' }]}
                              >
                                <InputNumber
                                  onChange={onInputChange}
                                  placeholder="请输入"
                                  max={99.99}
                                  min={0}
                                  addonAfter="%"
                                  precision={2}
                                />
                              </Form.Item>
                            ) : discountType === HasPreferentialAmountType.TOTALPREFERENTIALAMOUNT ? (
                              <Form.Item name="discountValue" rules={[{ required: true, message: '请输入' }]}>
                                <InputNumber
                                  onChange={onInputChange}
                                  placeholder="请输入"
                                  max={99999999.99999999}
                                  min={0}
                                  addonAfter="元"
                                  step="0.00000001"
                                  precision={8}
                                />
                              </Form.Item>
                            ) : discountType === HasPreferentialAmountType.UNITPRICEPREFERENTIALAMOUNT ? (
                              <Form.Item name="discountValue" rules={[{ required: true, message: '请输入' }]}>
                                <InputNumber
                                  onChange={onInputChange}
                                  placeholder="请输入"
                                  max={100}
                                  min={0}
                                  addonAfter="分"
                                  addonBefore="每度减"
                                  precision={0}
                                />
                              </Form.Item>
                            ) : null}
                            {discountType !== undefined && discountType !== null && (
                              <Form.Item>
                                <Button type="link" htmlType="submit">
                                  重新计算
                                </Button>
                              </Form.Item>
                            )}
                          </Space>
                        </Form.Item>
                      </>
                    )
                  );
                }}
              </Form.Item>
              <Form.Item labelCol={{ flex: '162px' }} label="优惠后应收电费">
                <ShowInput value={existData?.changeDiscountTotalAmount?.toFixed(2)} addonAfter="元" />
              </Form.Item>
              <Form.Item labelCol={{ flex: '162px' }} label="调整后优惠金额">
                <ShowInput value={existData?.changeDiscountAmount?.toFixed(2)} addonAfter="元" />
              </Form.Item>
            </Form>
          </Space>
        </>
      </Modal>
    </>
  );
};

export default DiscountModal;
