import {
  EllipsisSpan,
  SubContent,
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  useUpdate,
  Form,
  Table,
  BaseInfoContent,
  FormTitle,
} from '@maxtropy/components';
import { Space } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import ShowInput from '@/shared/components/ShowInput';
import QuotaEditModal from './components/QuotaEditModal';
import DataLinkModal from './components/DataLinkModal';
import {
  ConfigurtionListItem,
  getConfigurationList,
  getQuotaDetail,
  IndicatorEnum,
  IndicatorEnumDisplay,
} from '@/api/quality-monitor';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { EnergyMediumTreeFormatType, EnergyMediumTreeFormatTypeDisplay } from '@/api/energyMedium';

const Configure: FC = () => {
  const routesContext = useBreadcrumbRoutes();

  const [form] = Form.useForm(); // 搜索的表单

  const { id, type } = useParams<{ type: string; id: string }>();

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [dataModalOpen, setDataModalOpen] = useState(false);
  const [update, setUpdate] = useUpdate();
  // 获取详情
  const { data: detail } = useRequest(
    () => {
      return getQuotaDetail({ importAndExportType: type, importAndExportId: id });
    },
    { refreshDeps: [type, id], ready: !!(type && id) }
  );
  // 获取列表
  const { data, loading } = useRequest(
    async () => {
      if (detail) {
        const res = await getConfigurationList({
          importAndExportId: detail.id,
          energyMediumId: detail.energyMediumId,
          importAndExportType: detail.type,
        });
        // 接口报错后黑屏，做兼容处理
        if (res.length) {
          return res;
        } else {
          return [];
        }
      }
      return Promise.resolve([]);
    },
    {
      refreshDeps: [detail, update],
    }
  );
  useEffect(() => {
    if (detail) {
      form.setFieldsValue(detail);
    }
  }, [detail, form]);

  const [currentRow, setCurrentRow] = useState<ConfigurtionListItem>();
  const columns = [
    {
      title: '监测指标',
      dataIndex: 'indicatorName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '指标类型',
      dataIndex: 'type',
      ellipsis: { showTitle: true },
      render: (v: IndicatorEnum) => <EllipsisSpan value={IndicatorEnumDisplay[v]} />,
    },
    {
      title: '采集设备',
      dataIndex: 'deviceName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '数据属性',
      dataIndex: 'dataPropertyName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '生效时间',
      dataIndex: 'startTime',
      ellipsis: { showTitle: true },
      render: (v: any) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm') : '--'} />,
    },
    {
      title: '操作',
      width: 160,
      fixed: 'right' as const,
      render: (_: any, record: ConfigurtionListItem) => (
        <Space size={16}>
          <Button
            type="link"
            onClick={() => {
              setEditModalOpen(true);
              setCurrentRow(record);
            }}
          >
            编辑
          </Button>
          <Button
            type="link"
            onClick={() => {
              setDataModalOpen(true);
              setCurrentRow(record);
            }}
          >
            数据链路
          </Button>
        </Space>
      ),
    },
  ];
  const routes = [{ name: '输入/节点内/输出指标配置' }];
  const navigate = useNavigate();
  return (
    <>
      <Wrapper routes={[...(routesContext?.routes ?? []), ...routes]} className="page_wrapper">
        <FormTitle title={routes[0].name} />
        <SubContent className="mb-8">
          <Form form={form}>
            <BaseInfoContent>
              <Form.Item name="importAndExportName" label="名称">
                <ShowInput />
              </Form.Item>

              <Form.Item name="energyName" label="能源介质">
                <ShowInput />
              </Form.Item>

              <Form.Item name="processName" label="所属节点">
                <ShowInput />
              </Form.Item>

              <Form.Item name="emtName" label="所属拓扑">
                <ShowInput />
              </Form.Item>

              <Form.Item label="类型">
                <ShowInput value={EnergyMediumTreeFormatTypeDisplay[detail?.type as EnergyMediumTreeFormatType]} />
              </Form.Item>
            </BaseInfoContent>
          </Form>
        </SubContent>
        <SubContent>
          <Table
            rowKey="indicatorId"
            sticky
            scroll={{ x: 1000 }}
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={false}
          />
        </SubContent>
        <div className="sticky-footer">
          <Button onClick={() => navigate(-1)}>返回</Button>
        </div>
      </Wrapper>

      {editModalOpen && (
        <QuotaEditModal
          data={currentRow}
          cancel={() => setEditModalOpen(false)}
          confirm={() => {
            setEditModalOpen(false);
            setUpdate();
          }}
        />
      )}
      {dataModalOpen && (
        <DataLinkModal
          data={currentRow}
          cancel={() => setDataModalOpen(false)}
          confirm={() => setDataModalOpen(false)}
        />
      )}
    </>
  );
};

export default Configure;
