import { Col, Row } from 'antd';
import { Form } from '@maxtropy/components';
import styles from './index.module.scss';
import ShowInput from '@/shared/components/ShowInput';
import { ElectricitySaleContractSettlementType } from '@/api/electricitySettlementRules';
import { EnergyAssetsBillDetail } from '@/api/energyAssetsBill';

interface BorderBoxProps {
  isElectricityBox?: boolean; // 是否是电价信息
  settlementType?: ElectricitySaleContractSettlementType; // 结算类型
  detail?: EnergyAssetsBillDetail;
}

const BorderBox: React.FC<BorderBoxProps> = ({ detail, isElectricityBox = false, settlementType }) => {
  return (
    <>
      <div className={styles.box}>
        <div className={styles.boxBorder}>
          {isElectricityBox ? (
            <Row>
              <Col span={3}>
                <Form.Item label="尖电价">
                  <ShowInput value={detail?.summitPrice} addonAfter={'元'} />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="峰电价">
                  <ShowInput value={detail?.peakPrice} addonAfter={'元'} />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="平电价">
                  <ShowInput value={detail?.plainPrice} addonAfter={'元'} />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="谷电价">
                  <ShowInput value={detail?.valleyPrice} addonAfter={'元'} />
                </Form.Item>
              </Col>
              {settlementType === ElectricitySaleContractSettlementType.DISTRIBUTED_PV && (
                <>
                  <Col span={3}>
                    <Form.Item label="上网电价">
                      <ShowInput value={detail?.gridPrice} addonAfter={'元'} />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item label="电价折扣">
                      <ShowInput value={detail?.discountForOwner} />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item label="上网分成比例">
                      <ShowInput value={detail?.gridPercentageForOwner} />
                    </Form.Item>
                  </Col>
                </>
              )}
              {settlementType === ElectricitySaleContractSettlementType.DISTRIBUTED_BSA && (
                <Col span={3}>
                  <Form.Item label="放电折扣">
                    <ShowInput value={detail?.discountForOwner} />
                  </Form.Item>
                </Col>
              )}
            </Row>
          ) : (
            <Row>
              <Col span={3}>
                <Form.Item
                  label={
                    settlementType === ElectricitySaleContractSettlementType.DISTRIBUTED_PV ? '消纳电费' : '充电成本'
                  }
                >
                  <ShowInput
                    value={
                      settlementType === ElectricitySaleContractSettlementType.DISTRIBUTED_PV
                        ? detail?.payFee
                        : detail?.incomeFee
                    }
                    addonAfter={'元'}
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item
                  label={
                    settlementType === ElectricitySaleContractSettlementType.DISTRIBUTED_PV ? '上网分成' : '放电电费'
                  }
                >
                  <ShowInput
                    value={
                      settlementType === ElectricitySaleContractSettlementType.DISTRIBUTED_PV
                        ? detail?.incomeFee
                        : detail?.payFee
                    }
                    addonAfter={'元'}
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="总电费">
                  <ShowInput value={detail?.totalFee} addonAfter={'元'} />
                </Form.Item>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </>
  );
};

export default BorderBox;
