import { FC, useEffect, useState } from 'react';
import { Row, Col, Space } from 'antd';
import {
  Input,
  Select,
  Radio,
  Wrapper,
  useBreadcrumbRoutes,
  Button,
  InputNumber,
  Key,
  Form,
  FormTitle,
  SubContent,
} from '@maxtropy/components';
import {
  MainDataSourcesTypeMap,
  validateText,
  OutputTypeMap,
  TransportTypeMap,
  TransportType,
  CarbonFootPrintSettingType,
  CarbonFootPrintSettingTypeMap,
} from '../../utils';
import { Rule } from 'antd/lib/form';
import styles from '../../components/NormalInput/index.module.scss';
import TransportTable from '../TransportTable';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  apiV2CarbonFootprintLcaAddOutputPost,
  apiV2CarbonFootprintLcaDetailOutputPost,
  apiV2CarbonFootprintLcaUpdateOutputPost,
  apiV2MaterialListPost,
} from '@maxtropy/device-customer-apis-v2';
import { debounce, isNil } from 'lodash-es';
import ShowInput from '@/shared/components/ShowInput';
import { getUnits, UnitTypeWithUnitList, OutputUnitType } from '@/api/outputConfig';
import { RoadType, FinishOuputType } from '../LifeCycleModel/const';
import { cloneDeep } from 'lodash-es';

export interface findDataType {
  id: number;
  type: number;
  code: string;
  name: string;
  spec: string;
  unitTypeCode: number;
  unitCode: string;
  unitWeight: number;
  carbonFootPrint: number;
  footPrintOrigin: number;
  boundary: number;
  state: number;
  remark: string;
  createTime: string;
}
interface materialListType {
  label: string;
  value?: number;
}
const AddOutput: FC = () => {
  const navigator = useNavigate();
  const [urlSearchParams] = useSearchParams();
  const paramsToGet = ['id', 'lcaId', 'procedureId'];
  const queryParams = Object.fromEntries(paramsToGet.map(param => [param, urlSearchParams.get(param)]));

  const [findData, setFindData] = useState<any>({});
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const routes = [{ name: '添加输出' }];

  const [form] = Form.useForm();
  const { TextArea } = Input;
  const formLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const type = Form.useWatch('type', form); // 填报类型

  const [materialList, setMaterialList] = useState<materialListType[]>([]);
  const [units, setUnits] = useState<UnitTypeWithUnitList[]>([]);
  const [originData, setOriginData] = useState<any>([]);
  const needTransport = Form.useWatch('needTransport', form);

  useEffect(() => {
    if (isNil(queryParams.id)) return;
    apiV2CarbonFootprintLcaDetailOutputPost({ id: Number(queryParams.id) }).then(res => {
      form.setFieldsValue({ ...res });
      apiV2MaterialListPost({ codeOrNameOrSpec: '', type: res.type }).then(result => {
        setOriginData(result?.list ?? []);
        getUnits().then(units => {
          setUnits(units.list);
          const unitCode =
            (res.unitTypeCode === OutputUnitType.PIECE
              ? units.list
                  .find(u => u.unitTypeCode === OutputUnitType.PIECE)
                  ?.outputProductUnitList?.find(e => e?.unitCode === res.unitCode)?.unitName
              : res.unitCode) ?? '--';
          form.setFieldsValue({ unitCode });
        });
        const newOptions = (result.list ?? []).map(k => ({
          label: `${k.code}/${k.name}${!isNil(k.spec) ? '/' : ''}${!isNil(k.spec) ? k.spec : ''}`,
          value: k.id,
        }));
        setMaterialList(newOptions);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.id]);

  useEffect(() => {
    if (isNil(queryParams.id)) {
      getUnits().then(res => {
        setUnits(res.list);
      });
      serachMaterialList('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    apiV2MaterialListPost({ codeOrNameOrSpec: '', type }).then(res => {
      setOriginData(res?.list ?? []);
      const newOptions = (res.list ?? []).map(k => ({
        label: `${k.code}/${k.name}${!isNil(k.spec) ? '/' : ''}${!isNil(k.spec) ? k.spec : ''}`,
        value: k.id,
      }));
      setMaterialList(newOptions);
    });
  }, [type]);

  const onSearchMaterial = debounce((value: string) => {
    serachMaterialList(value);
  }, 500);

  const serachMaterialList = (value: string) => {
    if (isNil(type)) {
      return;
    }
    apiV2MaterialListPost({ codeOrNameOrSpec: value, type }).then(res => {
      setOriginData(res?.list ?? []);
      const newOptions = (res.list ?? []).map(k => ({
        label: `${k.code}/${k.name}${!isNil(k.spec) ? '/' : ''}${!isNil(k.spec) ? k.spec : ''}`,
        value: k.id,
      }));
      setMaterialList(newOptions);
    });
  };

  const unitFind = (value: Key) => {
    const findData = originData?.find((i: any) => {
      return i.id === value;
    });
    setFindData(findData);
    if (isNil(findData)) return;
    const unitCode =
      (findData.unitTypeCode === OutputUnitType.PIECE
        ? units
            .find(u => u.unitTypeCode === OutputUnitType.PIECE)
            ?.outputProductUnitList?.find(e => e?.unitCode === findData.unitCode)?.unitName
        : findData.unitCode) ?? '--';
    form.setFieldsValue({ unitCode });
  };

  const onFinish = (values: FinishOuputType) => {
    //新创建的路段不能将id传后端,只能作为前端的key暂时使用
    const newRoads = (values?.roads ?? []).map((road: RoadType) => {
      if (typeof road.id === 'string') {
        delete road.id;
      }
      return road;
    });

    const params = {
      ...values,
      roads: newRoads,
      isMaterial: 1,
      name: findData.name,
      unitTypeCode: findData.unitTypeCode,
      unitCode: findData.unitCode,
      procedureId: Number(queryParams.procedureId),
    };
    if (!queryParams.id) {
      apiV2CarbonFootprintLcaAddOutputPost({ ...params, id: Number(queryParams.lcaId) }).then(() => {
        navigator(
          `/productCarbonFootPrint/basic/modelManage/newModel?id=${queryParams.lcaId}&&procedureId=${queryParams.procedureId}`
        );
      });
    } else {
      apiV2CarbonFootprintLcaUpdateOutputPost({ ...params, id: Number(queryParams.id) }).then(() => {
        navigator(
          `/productCarbonFootPrint/basic/modelManage/newModel?id=${queryParams.lcaId}&&procedureId=${queryParams.procedureId}`
        );
      });
    }
  };
  return (
    <>
      <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
        <FormTitle title="添加输出"></FormTitle>

        <Form
          layout="vertical"
          form={form}
          {...formLayout}
          onFinish={onFinish}
          initialValues={{
            needTransport: TransportType.NO_NEED,
            carbonFootPrintSetting: CarbonFootPrintSettingType.Non_Negligible,
          }}
        >
          <SubContent title="基础信息" className="mb-8">
            <Row gutter={[48, 0]}>
              <Col span={8}>
                <Form.Item name="type" label="输出类型 " rules={[{ message: '请选择', required: true }]}>
                  <Select
                    placeholder={'请选择'}
                    onChange={() => {
                      form.setFieldsValue({ materialOrEnergyId: undefined, unitCode: undefined });
                    }}
                    options={Object.entries(OutputTypeMap).map(([k, v]) => ({
                      label: v,
                      value: +k,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="materialOrEnergyId"
                  label="物料编码/名称/规格型号 "
                  rules={[{ message: '请选择', required: true }]}
                >
                  <Select
                    showSearch
                    onSearch={onSearchMaterial}
                    options={materialList}
                    onChange={unitFind}
                    optionFilterProp="label"
                    placeholder={'请选择'}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="quantity"
                  label="排放量"
                  style={{ display: 'inline-block' }}
                  rules={[
                    { message: '请输入', required: true },
                    {
                      pattern: /^(?!0+(\.0*)?$)0\.[1-9]\d*|0\.\d*[1-9]\d*$|[1-9]\d*(\.\d+)?$/,
                      message: '数值必须大于0',
                    },
                  ]}
                >
                  <InputNumber min={0} style={{ minWidth: 216 }}></InputNumber>
                </Form.Item>
                <Form.Item name="unitCode" label="单位" style={{ display: 'inline-block', marginLeft: 12 }}>
                  <ShowInput></ShowInput>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[48, 0]}>
              <Col span={8}>
                <Form.Item name="dataOrigin" label="主要数据来源" rules={[{ message: '请选择', required: true }]}>
                  <Select
                    placeholder={'请选择'}
                    options={Object.entries(MainDataSourcesTypeMap).map(([k, v]) => ({
                      label: v,
                      value: +k,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="remark" label="数据说明" style={{ maxWidth: 464 }} rules={[validateText(200) as Rule]}>
                  <TextArea placeholder="请输入1-200字符" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="carbonFootPrintSetting"
                  label="碳足迹设置"
                  rules={[{ message: '请选择', required: true }]}
                >
                  <Radio.Group>
                    <Radio value={CarbonFootPrintSettingType.Non_Negligible}>
                      {CarbonFootPrintSettingTypeMap[CarbonFootPrintSettingType.Non_Negligible]}
                    </Radio>
                    <Radio value={CarbonFootPrintSettingType.Negligible}>
                      {CarbonFootPrintSettingTypeMap[CarbonFootPrintSettingType.Negligible]}
                    </Radio>
                    <Radio value={CarbonFootPrintSettingType.DataUnavailable}>
                      {CarbonFootPrintSettingTypeMap[CarbonFootPrintSettingType.DataUnavailable]}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </SubContent>

          <SubContent title="运输信息" className="mb-8">
            <Row>
              <Col span={6}>
                <Form.Item name="needTransport" label="是否需要运输" rules={[{ message: '请选择', required: true }]}>
                  <Radio.Group>
                    <Radio value={TransportType.NO_NEED}>{TransportTypeMap[TransportType.NO_NEED]}</Radio>
                    <Radio
                      value={TransportType.NEED}
                      onChange={() => {
                        const roads = cloneDeep(form.getFieldValue('roads'));
                        if (isNil(roads) || roads?.length === 0) {
                          form.setFieldsValue({
                            roads: [
                              {
                                id: uuidv4(),
                                startPlace: undefined,
                                endPlace: undefined,
                                distance: undefined,
                                transportation: undefined,
                                factorId: undefined,
                              },
                            ],
                          });
                        }
                      }}
                    >
                      {TransportTypeMap[TransportType.NEED]}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {needTransport === TransportType.NEED && (
                <Form.Item
                  name="grossWeight"
                  label=" "
                  required={false}
                  rules={[
                    { message: '请输入数字', required: true },
                    {
                      pattern: /^(?!0+(\.0*)?$)0\.[1-9]\d*|0\.\d*[1-9]\d*$|[1-9]\d*(\.\d+)?$/,
                      message: '数值必须大于0',
                    },
                  ]}
                >
                  <Space align="center">
                    <span style={{ color: 'var(--mx-form-item-label-color)' }}>1pcs对应的产品的运输毛重为：</span>
                    <InputNumber addonAfter={'kg'} min={0} style={{ minWidth: 123 }}></InputNumber>
                  </Space>
                </Form.Item>
              )}
            </Row>
            {needTransport === TransportType.NEED && <TransportTable form={form}></TransportTable>}
          </SubContent>

          <Space className="sticky-footer" size={8}>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
            <Button
              onClick={() => {
                navigator(
                  `/productCarbonFootPrint/basic/modelManage/newModel?id=${queryParams.lcaId}&&procedureId=${queryParams.procedureId}`
                );
              }}
            >
              返回
            </Button>
          </Space>
        </Form>
      </Wrapper>
    </>
  );
};

export default AddOutput;
