import AuditLog from './AuditLog';
import React, { useEffect, useState } from 'react';
import {
  SubContent,
  Table,
  useBreadcrumbRoutes,
  useUpdate,
  Wrapper,
  Button,
  Modal,
  Form,
  DatePicker,
  BaseInfoContent,
  FormTitle,
} from '@maxtropy/components';
import BaseInfo from '../components/BaseInfo';
import { Link, useNavigate, useParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import {
  BaseElectricityBillResponse,
  BillStatusEnum,
  ElectricityBillPhotovoltaicQuantityQuantityMeterGroups,
  ElectricityBillPhotovoltaicQuantityResponse,
  ElectricityBillPhotovoltaicQuantityType,
  getElectricityBillPhotovoltaicQuantity,
  CapitalContributionRateRequest,
  DiscountRequest,
  electricityStatusProcessNext,
  invoicingBill,
  rescindBill,
  getElectricityBillInfo,
  electricityBillReverseProcessRecheck,
  electricityBillReverseProcessInvoicingRescinded,
  BillPaymentStatusEnum,
  getElectricityBillPhotovoltaicCityQuantity,
  ElectricityBillPhotovoltaicCityQuantityRes,
  getElectricityBillBasicFee,
  ElectricityBillBasicFee,
  QuantityTypeEnum,
  getAdjustmentMeterRecordsById,
  AdjustmentMeterRecordsDataTypeAllRes,
  AdjustmentMeterRecordsDataType,
  AdjustmentMeterRecordsRes,
} from '../../../api/electricity';
import { ElectricitySaleContractSettlementType } from '../../../api/electricitySettlementRules';
import ElectricityBillBasicCharge from '../components/ElectricityBillBasicCharge';
import ElectricityBillReactivePower from '../components/ElectricityBillReactivePower';
import ElectricityBillActivePower from '../components/ElectricityBillActivePower';
import ElectricityBillAmount from '../components/ElectricityBillAmount';
import ElectricityOperator from '../components/ElectricityOperator';
import ElectricityCapitalContributionRate from '../components/ElectricityCapitalContributionRate';
import ElectricityBillErrorModal from '../components/ElectricityBillErrorModal';
import ElectricityBillBasicInfo from '../components/ElectricityBillBasicInfo';
import { Col, Row, Space, Spin } from 'antd';
import ShowInput from '@/shared/components/ShowInput';
import { DatabaseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import ElectricityBillPhotovoltaicQuantity from './DistributedNewEnergy';
import { WorkDivisionEnum } from '@/shared/types';
import { useHasPermission } from '../../../utils/utils';
import { PermissionsType } from '../../../common/permissionsConst';
import ElectricityBillLightingPower from '../components/ElectricityBillLightingPower';
import styles from './index.module.scss';
import ElectricityBillModal, {
  BaseElectricityOperatorModalProps,
  BillOperator,
} from '../components/ElectricityBillModal';
import ElectricityBillDNEActivePower from '../components/ElectricityBillDNEActivePower';
import ElectricityBillDNETotalElectric from '../components/ElectricityBillDNETotalElectric';
import PVPower from '../components/PVPower';
import ResidualElectricityPower from '../components/ResidualElectricityPower';
import PVRealityPower from '../components/PVRealityPower';
import DelChildrenMeterActivePower from '../components/DelChildrenMeterActivePower';
import { meterRecordColumns } from '@/pages/SaleMeterReading/ElectricityRecord/List';

const routes = [{ name: '核查联详情' }];

export interface DisplayDeviceNameProps {
  deviceName?: string;
}

const DisplayDeviceName: React.FC<DisplayDeviceNameProps> = ({ deviceName }) => (
  <Row style={{ marginTop: 10, marginBottom: 10 }}>
    <Col>
      <DatabaseOutlined style={{ color: 'var(--primary-color)', fontSize: 18 }} />
      <span style={{ marginLeft: 5 }}>设备名称：{deviceName}</span>
    </Col>
  </Row>
);

export function formatAdjustmentMeterRecords(res: AdjustmentMeterRecordsRes[]) {
  const list: Array<AdjustmentMeterRecordsRes>[] = [];
  for (let i = 0; i < res.length - 1; i = i + 2) {
    const item = res.slice(i, i + 2);
    list.push(item);
  }
  const sortList = list.map(i => {
    if (i.at(0)?.dataType === AdjustmentMeterRecordsDataType.ADJUSTMENT) {
      return [i.at(1), i.at(0)];
    }
    return i;
  });
  console.log(sortList);
  const records: AdjustmentMeterRecordsDataTypeAllRes[] = sortList.map(i => {
    return {
      ...i.at(0),
      adj_energyAdjustSortSnapshot: i.at(1)?.energyAdjustSortSnapshot,
      adj_magnification: i.at(1)?.magnification,
      adj_eppTs: i.at(1)?.eppTs,
      adj_epp: i.at(1)?.epp,
      adj_eppDisplayValue: i.at(1)?.eppDisplayValue,
      adj_epnTs: i.at(1)?.epnTs,
      adj_epn: i.at(1)?.epn,
      adj_epnDisplayValue: i.at(1)?.epnDisplayValue,
      adj_eqpTs: i.at(1)?.eqpTs,
      adj_eqp: i.at(1)?.eqp,
      adj_eqpDisplayValue: i.at(1)?.eqpDisplayValue,
      adj_maxDemandTs: i.at(1)?.maxDemandTs,
      adj_maxDemand: i.at(1)?.maxDemand,
      adj_maxDemandDisplayValue: i.at(1)?.maxDemandDisplayValue,
      adj_epp1Ts: i.at(1)?.epp1Ts,
      adj_epp1: i.at(1)?.epp1,
      adj_epp1DisplayValue: i.at(1)?.epp1DisplayValue,
      adj_epp2Ts: i.at(1)?.epp2Ts,
      adj_epp2: i.at(1)?.epp2,
      adj_epp2DisplayValue: i.at(1)?.epp2DisplayValue,
      adj_epp3Ts: i.at(1)?.epp3Ts,
      adj_epp3: i.at(1)?.epp3,
      adj_epp3DisplayValue: i.at(1)?.epp3DisplayValue,
      adj_epp4Ts: i.at(1)?.epp4Ts,
      adj_epp4: i.at(1)?.epp4,
      adj_epp4DisplayValue: i.at(1)?.epp4DisplayValue,
      adj_epn1Ts: i.at(1)?.epn1Ts,
      adj_epn1: i.at(1)?.epn1,
      adj_epn1DisplayValue: i.at(1)?.epn1DisplayValue,
      adj_epn2Ts: i.at(1)?.epn2Ts,
      adj_epn2: i.at(1)?.epn2,
      adj_epn2DisplayValue: i.at(1)?.epn2DisplayValue,
      adj_epn3Ts: i.at(1)?.epn3Ts,
      adj_epn3: i.at(1)?.epn3,
      adj_epn3DisplayValue: i.at(1)?.epn3DisplayValue,
      adj_epn4Ts: i.at(1)?.epn4Ts,
      adj_epn4: i.at(1)?.epn4,
      adj_epn4DisplayValue: i.at(1)?.epn4DisplayValue,
    };
  });
  return records;
}

const ElectricityBillDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [updateState, update] = useUpdate();
  const navigate = useNavigate();
  const [process, setProcess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const hasAdjustmentMeterRecord = useHasPermission(PermissionsType.B_ADJUSTMENT_METER_RECORD); // 查看抄表调整记录

  const hasElectricBillInvoiceConfirm = useHasPermission(PermissionsType.B_ELECTRICBILLINVOICECONFIRM); // 确认开票
  const hasBillPay = useHasPermission(PermissionsType.B_ELECTRIC_BILL_PAY); // 缴费按钮权限
  const hasElectricBillInvoiceRescind = useHasPermission(PermissionsType.B_ELECTRIC_BILL_INVOICE_RESCIND); // 作废发票
  const hasElectricBillRecheck = useHasPermission(PermissionsType.B_ELECTRIC_BILL_RECHECK); // 重新复核
  const hasElectricBillAntiBookkeeping = useHasPermission(PermissionsType.B_ELECTRIC_BILL_ANTI_BOOKKEEPING); // 反记账
  const hasB_DISCARD_ELECTRIC_BILL = useHasPermission(PermissionsType.B_DISCARD_ELECTRIC_BILL); // 作废

  const [electricityBillInfo, setElectricityBillInfo] = useState<BaseElectricityBillResponse>();
  const [electricityBillQuantity, setElectricityBillQuantity] = useState<ElectricityBillPhotovoltaicQuantityResponse>();

  const [capitalContributionRateRequest, setCapitalContributionRateRequest] =
    useState<CapitalContributionRateRequest>();
  const [discountRequest, setDiscountRequest] = useState<DiscountRequest>();
  const [invoiceTime, setInvoiceTime] = useState<Dayjs>();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [currentRecord, setCurrentRecord] = useState<BaseElectricityOperatorModalProps>();
  const [phCiQuRes, setPhCiQuRes] = useState<ElectricityBillPhotovoltaicCityQuantityRes>();
  const [basicData, setBasicData] = useState<ElectricityBillBasicFee>();
  const [basicChildData, setBasicChildData] = useState<ElectricityBillBasicFee>();
  const [adjustmentMeterRecords, setAdjustmentMeterRecords] = useState<AdjustmentMeterRecordsDataTypeAllRes[]>([]);
  const [meterRecordModalOpen, setMeterRecordModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getElectricityBillInfo(id).then(res => {
        if (res.invoice?.invoiceTime) {
          setInvoiceTime(dayjs(res.invoice.invoiceTime));
        }
        if (res.settlementType === ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY) {
          getElectricityBillPhotovoltaicCityQuantity(id).then(res => {
            setPhCiQuRes(res);
          });
        }
        setElectricityBillInfo(res);
      });

      getElectricityBillBasicFee(id)
        .then(res => {
          const childData = res.list.filter(i => i.isSubMeter === true);
          const data = res.list.filter(i => i.isSubMeter === false);
          setBasicData(data[0]);
          setBasicChildData(childData[0]);
        })
        .finally(() => setLoading(false));
    }
  }, [id, updateState]);

  useEffect(() => {
    if (electricityBillInfo) {
      setDiscountRequest(undefined);
      setCapitalContributionRateRequest(undefined);
      if (electricityBillInfo.status === BillStatusEnum.INVOICE_PENDING) {
        setInvoiceTime(undefined);
      }
    }
  }, [electricityBillInfo]);

  useEffect(() => {
    if (id && electricityBillInfo?.settlementType === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST) {
      getElectricityBillPhotovoltaicQuantity(id).then(setElectricityBillQuantity);
    }
    if (id && electricityBillInfo && electricityBillInfo.hasAdjustment && hasAdjustmentMeterRecord) {
      getAdjustmentMeterRecordsById(id).then(res => {
        if (res.list) {
          const records = formatAdjustmentMeterRecords(res.list);
          setAdjustmentMeterRecords(records);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, electricityBillInfo]);

  const onSubmit = () => {
    if (electricityBillInfo) {
      const submit = () => {
        setProcess(true);
        electricityStatusProcessNext({
          billId: electricityBillInfo.id,
          status: electricityBillInfo.status,
          ...capitalContributionRateRequest,
          ...discountRequest,
        })
          .then(() => {
            update();
          })
          .finally(() => {
            setProcess(false);
          });
      };
      if (electricityBillInfo.status === BillStatusEnum.DRAFT) {
        if (electricityBillInfo.settlementType === ElectricitySaleContractSettlementType.UNITARYSYSTEM) {
          if (electricityBillInfo.config?.hasCapitalContributionRate) {
            if (!capitalContributionRateRequest) {
              Modal.warning({
                title: '请先设置公摊电费',
              });
              return;
            }
          }
        }
        Modal.confirm({
          title: '确定提交审核？',
          content: <p style={{ color: 'red' }}>请仔细检查完信息后再提交审核！</p>,
          bodyStyle: { color: 'red' },
          onOk() {
            submit();
          },
        });
        return;
      }
      // if (electricityBillInfo.status === BillStatusEnum.INVOICE_PENDING) {
      //   if (!invoiceTime) {
      //     Modal.warning({
      //       title: '请先选择开票日期',
      //     })
      //     return
      //   }
      // }
      if (electricityBillInfo.status === BillStatusEnum.CHECK_PENDING) {
        Modal.confirm({
          title: '确定审核通过？',
          content: '',
          onOk() {
            submit();
          },
        });
        return;
      }
      if (electricityBillInfo.status === BillStatusEnum.REVIEW_PENDING) {
        Modal.confirm({
          title: '确定复核通过？',
          content: '',
          onOk() {
            submit();
          },
        });
        return;
      }
      if (electricityBillInfo.status === BillStatusEnum.PAY_DONE) {
        Modal.confirm({
          title: '确定结账完成？',
          content: <p style={{ color: 'red' }}>结账完成后，核查联不可再修改和回退，请谨慎操作！</p>,
          onOk() {
            submit();
          },
        });
      }
    }
  };

  const onRescind = () => {
    Modal.confirm({
      title: '确认作废？',
      content: '',
      onOk() {
        if (electricityBillInfo) {
          setProcess(true);
          rescindBill({
            billId: electricityBillInfo.id,
            status: electricityBillInfo.status,
          })
            .then(() => {
              update();
            })
            .finally(() => {
              setProcess(false);
            });
        }
      },
    });
  };

  const onInvoicing = () => {
    if (electricityBillInfo) {
      if (electricityBillInfo.status === BillStatusEnum.INVOICE_PENDING) {
        if (!invoiceTime) {
          Modal.warning({
            title: '请先选择开票日期',
          });
          return;
        } else {
          Modal.confirm({
            title: '确定开票？',
            onOk() {
              setProcess(true);
              invoicingBill({
                billId: electricityBillInfo.id,
                status: electricityBillInfo.status,
                invoiceTime: invoiceTime.format('YYYY-MM-DD'),
              })
                .then(() => {
                  update();
                })
                .finally(() => {
                  setProcess(false);
                });
            },
          });
        }
      }
    }
  };

  const goList = () => {
    navigate(`/operation/settlementRules/electricityBill`);
  };

  const onDownload = () => {
    window.open(`/api/v2/electricity/bill/download/${id}`);
  };

  const onBecomeInvalid = (billId: number, status: BillStatusEnum) => {
    Modal.confirm({
      title: '确定作废开票？',
      content: '',
      onOk() {
        setProcess(true);
        electricityBillReverseProcessInvoicingRescinded({
          billId,
          status,
        })
          .then(() => {
            update();
          })
          .finally(() => {
            setProcess(false);
          });
      },
    });
  };

  // 重新复核
  const onReverseProcessRecheck = (billId: number, status: BillStatusEnum) => {
    setProcess(true);
    electricityBillReverseProcessRecheck({
      billId,
      status,
    })
      .then(() => {
        update();
      })
      .finally(() => {
        setProcess(false);
      });
  };

  const onMeterRecordModalOpenCancel = () => {
    setMeterRecordModalOpen(false);
  };
  const onMeterRecordModalOpenOk = () => {
    setMeterRecordModalOpen(false);
  };

  return (
    <Spin spinning={loading}>
      <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}>
        <FormTitle title={routes[0].name} />
        {electricityBillInfo?.status === BillStatusEnum.EXCEPTION ? (
          // 异常状态
          <ElectricityBillErrorModal electricityBillInfo={electricityBillInfo} />
        ) : (
          <>
            <div className={styles.downloadBill}>
              {electricityBillInfo !== undefined &&
                [BillStatusEnum.PAY_PENDING, BillStatusEnum.PAY_DONE, BillStatusEnum.SETTLE_DONE].includes(
                  electricityBillInfo?.status
                ) && (
                  <Space style={{ marginLeft: 10 }}>
                    <Button type="primary" onClick={onDownload}>
                      下载电费核查联详情
                    </Button>
                  </Space>
                )}
              {electricityBillInfo && electricityBillInfo.hasAdjustment && hasAdjustmentMeterRecord && (
                <>
                  <Space style={{ marginLeft: 10 }}>
                    <Button
                      type="primary"
                      onClick={() => {
                        setMeterRecordModalOpen(true);
                      }}
                    >
                      查看电费核查联抄表调整记录
                    </Button>
                  </Space>
                  <span
                    style={{
                      display: 'inline-block',
                      paddingLeft: '10px',
                      textAlign: 'center',
                      position: 'absolute',
                    }}
                  >
                    <p style={{ margin: 0 }}>
                      <InfoCircleOutlined style={{ color: 'var(--primary-color)', paddingRight: '10px' }} />
                      核查联存在尖峰平谷按总有功配平的抄表记录，您可查看调整记录
                    </p>
                    （仅列出已配平表计，按抄见值或无需配平表计不展示）
                  </span>
                </>
              )}
            </div>
            <BaseInfo data={electricityBillInfo} />
            <SubContent title="核查联基础信息">
              <BaseInfoContent>
                <Form.Item label="结算周期">
                  <ShowInput
                    value={
                      electricityBillInfo
                        ? `${dayjs(electricityBillInfo!.settlementStartTime).format('YYYY-MM-DD')} ~ ${dayjs(
                            electricityBillInfo!.settlementEndTime
                          )
                            .subtract(1, 'days')
                            .format('YYYY-MM-DD')}`
                        : undefined
                    }
                  />
                </Form.Item>
                <Form.Item label="账期月份">
                  <ShowInput value={electricityBillInfo?.settlementPeriod} />
                </Form.Item>
                <Form.Item label="开票日期">
                  {electricityBillInfo &&
                    ([BillStatusEnum.DRAFT, BillStatusEnum.CHECK_PENDING, BillStatusEnum.REVIEW_PENDING].includes(
                      electricityBillInfo.status
                    ) ? (
                      <ShowInput />
                    ) : [
                        BillStatusEnum.INVOICE_PENDING /*, BillStatusEnum.PAY_PENDING, BillStatusEnum.PAY_DONE*/,
                      ].includes(electricityBillInfo.status) ? (
                      <DatePicker
                        value={invoiceTime}
                        onChange={value => {
                          setInvoiceTime(value ?? undefined);
                        }}
                      />
                    ) : (
                      <ShowInput value={invoiceTime?.format('YYYY-MM-DD')} />
                    ))}
                </Form.Item>
              </BaseInfoContent>
              {electricityBillInfo && <ElectricityBillBasicInfo data={electricityBillInfo} />}
            </SubContent>
            {/*单一制*/}
            {electricityBillInfo?.settlementType === ElectricitySaleContractSettlementType.UNITARYSYSTEM && (
              <>
                <SubContent title="核查联有功电量">
                  <ElectricityBillActivePower isUnitarySystem={true} billInfo={electricityBillInfo} />
                </SubContent>
                <ElectricityCapitalContributionRate
                  data={electricityBillInfo}
                  value={capitalContributionRateRequest}
                  onChange={setCapitalContributionRateRequest}
                />
              </>
            )}
            {/*两部制*/}
            {electricityBillInfo?.settlementType === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
              <>
                <SubContent title="核查联基本电费">
                  <ElectricityBillBasicCharge isSubMeter={false} data={basicData} />
                </SubContent>
                {electricityBillInfo.config.hasSubMeterRateDeduct ? (
                  <SubContent title="核查联扣除子表基本电费">
                    <ElectricityBillBasicCharge isSubMeter={true} childData={basicChildData} />
                  </SubContent>
                ) : null}
                <SubContent title="核查联无功电量">
                  <ElectricityBillReactivePower />
                </SubContent>
                <SubContent title="核查联有功电量">
                  <ElectricityBillActivePower isUnitarySystem={false} billInfo={electricityBillInfo} />
                </SubContent>
                {electricityBillInfo.config.hasSubMeterRateDeduct ? (
                  <SubContent title="核查联扣除子表有功电量">
                    <DelChildrenMeterActivePower />
                  </SubContent>
                ) : null}
                {electricityBillInfo.config.hasLightingTariff ? (
                  <SubContent title="照明电量">
                    <ElectricityBillLightingPower quantityType={QuantityTypeEnum.QUANTITY} />
                  </SubContent>
                ) : null}
              </>
            )}
            {/*光伏对外 分布式新能源*/}
            {electricityBillInfo?.settlementType ===
              ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST && (
              <>
                <SubContent title="光伏发电量">
                  {(electricityBillQuantity?.quantityMeterGroups ?? []).map(
                    (value: ElectricityBillPhotovoltaicQuantityQuantityMeterGroups, index: number) =>
                      value?.quantities &&
                      value.quantities.length > 0 && (
                        <Space direction="vertical" key={ElectricityBillPhotovoltaicQuantityType.PHOTOVOLTAIC + index}>
                          <DisplayDeviceName deviceName={value?.deviceName} />
                          <ElectricityBillPhotovoltaicQuantity
                            type={ElectricityBillPhotovoltaicQuantityType.PHOTOVOLTAIC}
                            data={value?.quantities}
                          />
                        </Space>
                      )
                  )}
                </SubContent>
                <SubContent title="余电上网电量">
                  {(electricityBillQuantity?.quantityMeterGroups ?? []).map(
                    (value: ElectricityBillPhotovoltaicQuantityQuantityMeterGroups, index: number) =>
                      value?.surplusQuantities &&
                      value.surplusQuantities.length > 0 && (
                        <Space direction="vertical" key={ElectricityBillPhotovoltaicQuantityType.SURPLUS + index}>
                          <DisplayDeviceName deviceName={value?.deviceName} />
                          <ElectricityBillPhotovoltaicQuantity
                            type={ElectricityBillPhotovoltaicQuantityType.SURPLUS}
                            data={value?.surplusQuantities}
                          />
                        </Space>
                      )
                  )}
                </SubContent>
                <SubContent title="实际用电量">
                  <ElectricityBillPhotovoltaicQuantity
                    type={ElectricityBillPhotovoltaicQuantityType.ACTUAL}
                    data={electricityBillQuantity?.actualQuantities}
                  />
                </SubContent>
              </>
            )}
            {/*全额上网，分布式新能源*/}
            {electricityBillInfo?.settlementType ===
              ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL && (
              <>
                <SubContent title="核查联有功电量">
                  <ElectricityBillDNEActivePower />
                </SubContent>
                <SubContent title="总计电度">
                  <ElectricityBillDNETotalElectric />
                </SubContent>
              </>
            )}
            {/* 市电两部制+光伏 */}
            {electricityBillInfo?.settlementType ===
              ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY && (
              <>
                <SubContent title="核查联基本电费">
                  <ElectricityBillBasicCharge isSubMeter={false} data={basicData} />
                </SubContent>
                <SubContent title="核查联无功电量">
                  <ElectricityBillReactivePower />
                </SubContent>
                <SubContent title="核查联有功电量">
                  <ElectricityBillActivePower isUnitarySystem={false} billInfo={electricityBillInfo} />
                </SubContent>
                {electricityBillInfo.config.hasLightingTariff ? (
                  <SubContent title="电量表计照明电量">
                    <ElectricityBillLightingPower quantityType={QuantityTypeEnum.QUANTITY} />
                  </SubContent>
                ) : null}
                {!!phCiQuRes && (
                  <>
                    <SubContent title="光伏发电量">
                      <PVPower photovoltaicMeterGroups={phCiQuRes.photovoltaicMeterGroups} />
                    </SubContent>
                    <SubContent title="余电上网电量">
                      <ResidualElectricityPower demandMeterGroups={phCiQuRes.demandMeterGroups} />
                    </SubContent>
                    <SubContent title="光伏实际用电量">
                      <PVRealityPower actualQuantities={phCiQuRes.actualQuantities} />
                    </SubContent>
                  </>
                )}
                {electricityBillInfo.config.hasLightingTariff ? (
                  <SubContent title="光伏表计照明电量">
                    <ElectricityBillLightingPower quantityType={QuantityTypeEnum.PHOTOVOLTAIC} />
                  </SubContent>
                ) : null}
              </>
            )}
            <SubContent title="优惠金额/总金额">
              <ElectricityBillAmount
                data={electricityBillInfo}
                capitalContributionRateRequest={capitalContributionRateRequest}
                onChange={setDiscountRequest}
              />
            </SubContent>
            <SubContent title="操作人员">
              <ElectricityOperator data={electricityBillInfo} />
            </SubContent>
            <SubContent title="审核日志">
              <AuditLog billInfo={electricityBillInfo} />
            </SubContent>
            <Space style={{ zIndex: 200 }} size={8} className="sticky-footer">
              {electricityBillInfo !== undefined && electricityBillInfo.status === BillStatusEnum.DRAFT && (
                <>
                  {hasB_DISCARD_ELECTRIC_BILL && (
                    <Button type="primary" loading={process} onClick={onRescind}>
                      作废
                    </Button>
                  )}
                  {(electricityBillInfo.staffWorkDivision ?? []).includes(WorkDivisionEnum.ACCOUNTANT) && (
                    <>
                      <Button type="primary" loading={process} onClick={onSubmit}>
                        提交审核
                      </Button>
                    </>
                  )}
                </>
              )}
              {electricityBillInfo !== undefined && electricityBillInfo.status === BillStatusEnum.CHECK_PENDING && (
                <>
                  {hasB_DISCARD_ELECTRIC_BILL && (
                    <Button type="primary" loading={process} onClick={onRescind}>
                      作废
                    </Button>
                  )}
                  {(electricityBillInfo.staffWorkDivision ?? []).includes(WorkDivisionEnum.AUDITOR) && (
                    <>
                      <Button
                        type="primary"
                        loading={process}
                        onClick={() => {
                          setCurrentRecord({
                            record: electricityBillInfo,
                            op: BillOperator.Reverse,
                          });
                        }}
                      >
                        打回
                      </Button>
                      <Button type="primary" loading={process} onClick={onSubmit}>
                        审核通过
                      </Button>
                    </>
                  )}
                </>
              )}
              {electricityBillInfo !== undefined && electricityBillInfo.status === BillStatusEnum.REVIEW_PENDING && (
                <>
                  {hasB_DISCARD_ELECTRIC_BILL && (
                    <Button type="primary" loading={process} onClick={onRescind}>
                      作废
                    </Button>
                  )}
                  {(electricityBillInfo.staffWorkDivision ?? []).includes(WorkDivisionEnum.REVIEWER) && (
                    <>
                      <Button
                        type="primary"
                        loading={process}
                        onClick={() => {
                          setCurrentRecord({
                            record: electricityBillInfo,
                            op: BillOperator.Reverse,
                          });
                        }}
                      >
                        打回
                      </Button>
                      <Button type="primary" loading={process} onClick={onSubmit}>
                        复核通过
                      </Button>
                    </>
                  )}
                </>
              )}
              {electricityBillInfo !== undefined && electricityBillInfo.status === BillStatusEnum.INVOICE_PENDING && (
                <>
                  {hasElectricBillRecheck && (
                    <Button
                      type="primary"
                      loading={process}
                      onClick={() => onReverseProcessRecheck(electricityBillInfo.id, electricityBillInfo.status)}
                    >
                      重新复核
                    </Button>
                  )}
                  {hasElectricBillInvoiceConfirm && (
                    <Button type="primary" loading={process} onClick={onInvoicing}>
                      确认开票
                    </Button>
                  )}
                </>
              )}
              {electricityBillInfo !== undefined && electricityBillInfo.status === BillStatusEnum.PAY_PENDING && (
                <>
                  {electricityBillInfo.paymentStatus === BillPaymentStatusEnum.PARTIALLY_PAID &&
                    hasElectricBillAntiBookkeeping && (
                      <Button
                        type="primary"
                        onClick={() => {
                          setCurrentRecord({
                            record: electricityBillInfo,
                            op: BillOperator.antiBookkeeping,
                          });
                        }}
                      >
                        反记账
                      </Button>
                    )}
                  {hasElectricBillInvoiceRescind &&
                    electricityBillInfo.paymentStatus === BillPaymentStatusEnum.UNPAID && (
                      <Button
                        type="primary"
                        loading={process}
                        onClick={() => onBecomeInvalid(electricityBillInfo.id, electricityBillInfo.status)}
                      >
                        作废发票
                      </Button>
                    )}
                  {hasBillPay && (
                    <Button type="primary">
                      <Link to={`/operation/settlementRules/electricityBill/payment/${electricityBillInfo.id}`}>
                        缴费
                      </Link>
                    </Button>
                  )}
                </>
              )}
              {electricityBillInfo !== undefined &&
                electricityBillInfo.status === BillStatusEnum.PAY_DONE &&
                (electricityBillInfo.staffWorkDivision ?? []).includes(WorkDivisionEnum.REVIEWER) && (
                  <>
                    {hasElectricBillAntiBookkeeping && (
                      <Button
                        type="primary"
                        loading={process}
                        onClick={() => {
                          setCurrentRecord({
                            record: electricityBillInfo,
                            op: BillOperator.antiBookkeeping,
                          });
                        }}
                      >
                        反记账
                      </Button>
                    )}
                    <Button type="primary" loading={process} onClick={onSubmit}>
                      结账完成
                    </Button>
                  </>
                )}
              {electricityBillInfo !== undefined && (
                <>
                  <ElectricityBillModal
                    refresh={update}
                    electricityOperatorRecord={currentRecord}
                    changeVisible={setCurrentRecord}
                  />
                </>
              )}
              <Button onClick={goList}>返回</Button>
            </Space>
          </>
        )}
        <Modal
          width={1198}
          title="抄表记录调整单"
          onCancel={onMeterRecordModalOpenCancel}
          onOk={onMeterRecordModalOpenOk}
          open={meterRecordModalOpen}
          bodyStyle={{
            maxHeight: 620,
            overflowY: 'auto',
            marginBottom: 15,
          }}
        >
          <p>抄表逻辑：尖峰平谷按总有功配平</p>
          <p>判断需要调整的条件：【调整值w】=【（反向）总有功】-【（反向）尖峰平谷】，|w|≥0.01。</p>
          <Table
            rowClassName={styles.meterRecordModalTable}
            scroll={{ x: 1198 }}
            rowKey={'id'}
            columns={meterRecordColumns}
            dataSource={adjustmentMeterRecords}
            bordered
            size="middle"
          />
        </Modal>
      </Wrapper>
    </Spin>
  );
};

export default ElectricityBillDetail;
