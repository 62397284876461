import React, { useEffect, useMemo, useState } from 'react';

import ShowInput from '@/shared/components/ShowInput';
import {
  ElectricityType,
  ElectricityTypeDisplay,
  ElectricityAccountVo,
  AreaItem,
  getElectricityAccount,
} from '../../../api/electricity';
import { getOrganizationWithCodeWithCurrent, OrganizationResponse } from '../../../api/device';
import { getParents } from '../../../utils/utils';
import { ActionType, ActionTypeDisplay, formatTreeData } from '../utils';
import { Form, Input, Modal, Radio, TreeSelect } from '@maxtropy/components';
import { Cascader } from 'antd';

export interface CreateFormProps {
  current?: ElectricityAccountVo;
  districtData?: AreaItem[];
  actionType?: ActionType;
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: any) => void;
}

const CreateForm: React.FC<CreateFormProps> = ({ current, districtData, actionType, visible, onCancel, onSubmit }) => {
  const [form] = Form.useForm();
  const isDetail = actionType === ActionType.DETAIL;

  const [organization, setOrganition] = useState<OrganizationResponse>();
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (current && districtData) {
      getElectricityAccount(current.id).then(res => {
        const codes = getParents(districtData, res.regionCode).map(item => item.adcode);
        setDisabled(!!res.contract);
        form.setFieldsValue({
          number: current.number,
          type: current.type,
          mcids: (current.mcids ?? []).map(i => ({ value: i })),
          regionCode: codes,
        });
      });
    } else if (current === undefined) {
      setDisabled(false);
      form.resetFields();
    }
  }, [current, districtData, form]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible, form]);

  useEffect(() => {
    getOrganizationWithCodeWithCurrent().then(res => {
      setOrganition(res);
    });
  }, []);

  const treeData = useMemo(() => {
    if (organization) {
      return formatTreeData([organization]);
    } else {
      return undefined;
    }
  }, [organization]);

  const onOK = async () => {
    const values = await form.validateFields();
    onSubmit(values);
  };

  return (
    <Modal
      destroyOnClose
      open={visible}
      onCancel={onCancel}
      contentClassName="modal-form-content"
      onOk={onOK}
      title={actionType && `${ActionTypeDisplay[actionType]}用电账户`}
    >
      <Form form={form}>
        <Form.Item
          validateFirst
          name="number"
          label="用电账户户号"
          rules={[
            { required: true, message: '请输入' },
            { pattern: /^([0-9a-zA-Z])*$/g, message: '请输入英文、数字' },
          ]}
        >
          <Input placeholder="请输入" disabled={isDetail} />
        </Form.Item>
        <Form.Item name="mcids" label="关联组织" rules={[{ required: true, message: '请选择' }]}>
          <TreeSelect
            treeDefaultExpandAll
            treeCheckable
            treeCheckStrictly
            showCheckedStrategy={TreeSelect.SHOW_ALL}
            showArrow
            showSearch={false}
            treeData={treeData}
            placeholder="请选择"
            disabled={isDetail}
          />
        </Form.Item>
        <Form.Item name="regionCode" label="所在区域" rules={[{ required: true, message: '请输入' }]}>
          <Cascader
            options={districtData}
            fieldNames={{ label: 'name', value: 'adcode', children: 'districts' }}
            placeholder="请选择"
            disabled={isDetail || disabled}
          />
        </Form.Item>
        <Form.Item
          name="type"
          label="用电种类"
          initialValue={ElectricityType.BUSINESS}
          rules={[{ required: true, message: '请选择' }]}
        >
          <Radio.Group disabled={isDetail || disabled}>
            <Radio value={ElectricityType.BUSINESS}>{ElectricityTypeDisplay[ElectricityType.BUSINESS]}</Radio>
            <Radio value={ElectricityType.INDUSTRY}>{ElectricityTypeDisplay[ElectricityType.INDUSTRY]}</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="identifier3" label="关联变压器">
          <ShowInput />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateForm;
