import { SubContent, useBreadcrumbRoutes, Wrapper, Button, Form, Tabs, FormTitle } from '@maxtropy/components';
import { Col, Divider, Row, Space } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ShowInput from '@/shared/components/ShowInput';
import {
  ElectricMeterTypeEnum,
  getSaleClient,
  ChargeTypeEnum,
  ChargeTypeEnumDisplay,
  SaleClientInfo,
} from '../../../api/sale';

import styles from './index.module.scss';
import SaleDevice from './SaleElectricity/Device';

export interface MeterProps {
  disabled?: boolean;
}

const Meter: FC<MeterProps> = ({ disabled = false }) => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [clientData, setClientData] = useState<SaleClientInfo>();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  // const [defaultActiveKey, setDefaultActiveKey] = useState<MeterType>();

  useEffect(() => {
    if (id) {
      getSaleClient(id).then(setClientData);
    }
  }, [id]);

  useEffect(() => {
    if (clientData) {
      form.setFieldsValue({
        name: clientData.name,
        electricityAccountIds: clientData.electricityAccounts.map(i => i.number).join('、'),
      });
      // setDefaultActiveKey(clientData.meterTypes[0])
    }
  }, [clientData, form]);

  const routes = useMemo(() => {
    return [{ name: `${disabled ? '查看' : '编辑'}表计` }];
  }, [disabled]);

  const goList = () => {
    navigate('/operation/client-related/client');
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className="page_wrapper">
      <FormTitle title={routes[0].name} />
      <SubContent style={{ paddingTop: 0 }}>
        <Form labelCol={{ style: { width: 70 } }} form={form}>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item style={{ marginBottom: 0 }} name="name" label="客户名称">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item style={{ marginBottom: 0 }} name="electricityAccountIds" label="用电账户">
                <ShowInput />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </SubContent>
      <Divider style={{ margin: '10px 0' }} />
      <SubContent style={{ paddingTop: 0 }}>
        {clientData && (
          <Tabs className={styles.tabs}>
            {(clientData?.meterTypes ?? []).includes(ChargeTypeEnum.POWER) && (
              <Tabs.TabPane tab={`${ChargeTypeEnumDisplay[ChargeTypeEnum.POWER]}表计`} key={ChargeTypeEnum.POWER}>
                {/*<Row style={{fontWeight: 500}}>{ElectricMeterTypeEnumDisplay[ElectricMeterTypeEnum.ILLUMINATION]}表计</Row>*/}
                <SaleDevice disabled={disabled} saleClientId={Number(id)} type={ElectricMeterTypeEnum.ILLUMINATION} />

                {/*<SaleElectricityMeter disabled={disabled} saleClientId={Number(id)} />*/}
              </Tabs.TabPane>
            )}
          </Tabs>
        )}
      </SubContent>
      <Space className="sticky-footer">
        <Button onClick={goList}>返回</Button>
      </Space>
    </Wrapper>
  );
};

export default Meter;
