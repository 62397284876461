import * as React from 'react';
import { ColumnType } from 'antd/es/table';
import { getRange } from '../../../../../utils/utils';
import { getActualParameter } from '../modbusPoint/columns';
import { EllipsisSpan } from '@maxtropy/components';
import { FilterModeDisplay } from '@/shared/const';

export const getColumns = (parameter: string[], alterMode?: boolean): ColumnType<any>[] => {
  const _parameter = getActualParameter(parameter);
  const columns: ColumnType<any>[] = [
    {
      title: '单位',
      dataIndex: 'physicalUnitGeneralName',
      ellipsis: { showTitle: true },
      render: (value: string) => (
        <EllipsisSpan value={value} defaultValue={alterMode ? '点击编辑操作修改' : undefined} />
      ),
    },
    {
      title: '采集点类型',
      dataIndex: 'hasProperty',
      ellipsis: { showTitle: true },
      render: (value: boolean) => <EllipsisSpan value={value ? '建模采集点' : '非建模采集点'} />,
    },
    {
      title: '是否可写',
      dataIndex: 'writable',
      ellipsis: { showTitle: true },
      render: (v: string) => (
        <EllipsisSpan value={typeof v === 'boolean' ? (v ? '是' : '否') : alterMode ? '点击编辑操作修改' : undefined} />
      ),
    },
    {
      title: '公共地址',
      dataIndex: 'commonAddress',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => <EllipsisSpan value="excel导入" />,
    },
    {
      title: '信息体地址',
      dataIndex: 'informationAddress',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => <EllipsisSpan value="excel导入" />,
    },
    {
      title: '乘积系数k',
      dataIndex: 'k',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => <EllipsisSpan value="excel导入" />,
    },
    {
      title: '偏移系数b',
      dataIndex: 'b',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => <EllipsisSpan value="excel导入" />,
    },
    {
      title: '数据过滤模式',
      dataIndex: 'valueFilterMode',
      ellipsis: { showTitle: true },
      width: 120,
      render: (_: undefined, record) => {
        return (
          <EllipsisSpan
            value={
              record.parameters?.valueFilterMode
                ? FilterModeDisplay[record.parameters?.valueFilterMode as keyof typeof FilterModeDisplay]
                : alterMode
                ? '点击编辑操作修改'
                : undefined
            }
          />
        );
      },
    },
    {
      title: '数值合理区间',
      dataIndex: 'dataRange',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => {
        const value = getRange(record.parameters?.valueFloor, record.parameters?.valueCeiling);
        return <EllipsisSpan value={value !== '--' ? value : alterMode ? '点击编辑操作修改' : undefined} />;
      },
    },
    {
      title: '备注',
      dataIndex: 'remark',
      ellipsis: { showTitle: true },
      render: (value: string) => <EllipsisSpan value={value} />,
    },
  ];
  return [
    {
      title: '数据属性/标识符',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => (
        <EllipsisSpan value={!!record.hasProperty ? record.dataPropertyName : record.identifier} />
      ),
    },
    ...(_parameter ? columns.filter(column => _parameter.includes(column.dataIndex as string)) : columns),
  ];
};
