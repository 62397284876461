import React, { useContext } from 'react';
import { EllipsisSpan, FieldTable, Modal, MxColumnType, MxColumnsType, Table } from '@maxtropy/components';
import { DataPointType, DataProperty } from '../../../../../types';
import { PointContext, PointContextProps } from '../../../contextTypes';
import { getColumns } from './columns';
import { EdgeDeviceTemplatePoint } from './index';
import { IEnum } from '../utils';

interface DLT645Props {
  loading?: boolean;
  editColumns?: MxColumnsType<EdgeDeviceTemplatePoint>;
  dataPropertiesAll?: DataProperty[];
}

const indexColumn: MxColumnType<EdgeDeviceTemplatePoint> = {
  title: '序号',
  width: 70,
  render: (_, record, index) => index + 1,
};

const columns = [
  {
    title: '枚举',
    dataIndex: 'enum',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ?? '--'} />,
  },
  {
    title: '枚举值名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ?? '--'} />,
  },
  {
    title: '采集（原始）值',
    dataIndex: 'value',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ?? '--'} />,
  },
];

const DLT645PointBase: React.FC<DLT645Props> = props => {
  const { loading, editColumns, dataPropertiesAll } = props;

  const { dataSource, canAdjustField } = useContext(PointContext) as PointContextProps<EdgeDeviceTemplatePoint>;

  const showMappingInfo = (values: IEnum[]) => {
    Modal.info({
      title: '枚举映射',
      width: 640,
      content: (
        <Table
          style={{ marginBottom: '10px' }}
          rowKey="enum"
          dataSource={values}
          columns={columns}
          pagination={false}
        />
      ),
    });
  };

  return (
    <FieldTable
      sticky
      adjustField={canAdjustField}
      adjustWidth={canAdjustField}
      cacheKey="DLT645PointBase"
      scroll={{ y: 500 }}
      loading={loading}
      rowKey="id"
      dataSource={dataSource?.filter(item => item.pointType === DataPointType.BASE_POINT)}
      columns={[
        indexColumn,
        ...getColumns(dataPropertiesAll, showMappingInfo, !canAdjustField ? [] : editColumns || []),
        ...(canAdjustField ? [] : editColumns || []),
      ]}
    />
  );
};

export default DLT645PointBase;
