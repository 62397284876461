import * as React from 'react';
import { ColumnType } from 'antd/es/table';
import { getRange } from '../../../../../utils/utils';
import { OBJECT_TYPE, VALUE_TYPE, LENGTH } from '../../../../../types';
import {
  lengthFormatter,
  objectFormatter,
  valueTypeFormatter,
} from '../../../../EdgeDevicePointInfo/mockingbird/Point/entity';
import {
  getHandleModeMapping,
  getMode,
} from '../../../../EdgeDevicePointInfo/mockingbird/Point/modbusPoint/CreateForm';
import { EllipsisSpan } from '@maxtropy/components';
import { FilterModeDisplay } from '@/shared/const';
import { isNil } from 'lodash-es';

export function getActualParameter(parameter?: string[]) {
  if (!Array.isArray(parameter)) return parameter;
  let _parameter = parameter;
  if (parameter.includes('valueFloor')) {
    _parameter = [..._parameter, 'dataRange', 'valueFilterMode'];
  }
  if (parameter.includes('physicalUnitId')) {
    _parameter = ['physicalUnitGeneralName', ..._parameter];
  }
  return _parameter;
}

export const getColumns = (parameter?: string[], alterMode?: boolean): ColumnType<any>[] => {
  const _parameter = getActualParameter(parameter);
  const columns: ColumnType<any>[] = [
    {
      title: '单位',
      dataIndex: 'physicalUnitGeneralName',
      ellipsis: { showTitle: true },
      width: 100,
      render: (value: string) => (
        <EllipsisSpan value={value} defaultValue={alterMode ? '点击编辑操作修改' : undefined} />
      ),
    },
    {
      title: '采集点类型',
      dataIndex: 'hasProperty',
      ellipsis: { showTitle: true },
      width: 120,
      render: (value: boolean) => <EllipsisSpan value={value ? '建模采集点' : '非建模采集点'} />,
    },
    {
      title: '是否可写',
      dataIndex: 'writable',
      ellipsis: { showTitle: true },
      width: 120,
      render: (v: string) => (
        <EllipsisSpan value={typeof v === 'boolean' ? (v ? '是' : '否') : alterMode ? '点击编辑操作修改' : undefined} />
      ),
    },
    {
      title: '功能码',
      width: 120,
      dataIndex: 'objectType',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => (
        <EllipsisSpan
          value={objectFormatter[record.parameters?.objectType as OBJECT_TYPE]}
          defaultValue={alterMode ? '点击编辑操作修改' : undefined}
        />
      ),
    },
    {
      title: '开始地址',
      width: 120,
      dataIndex: 'starting',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => <EllipsisSpan value="excel导入" />,
    },
    {
      title: '长度',
      width: 100,
      dataIndex: 'length',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => (
        <EllipsisSpan
          value={lengthFormatter[record.parameters?.length as LENGTH]}
          defaultValue={alterMode ? '点击编辑操作修改' : undefined}
        />
      ),
    },
    {
      title: '解码类型',
      width: 140,
      dataIndex: 'valueType',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => (
        <EllipsisSpan
          value={valueTypeFormatter[record.parameters?.valueType as VALUE_TYPE]}
          defaultValue={alterMode ? '点击编辑操作修改' : undefined}
        />
      ),
    },
    {
      title: '处理方式',
      dataIndex: 'handleMode',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => {
        const { objectType, length, handleMode } = record.parameters;
        let value;
        const formatter = getHandleModeMapping(objectType, length);
        if (formatter) {
          const target = Object.entries(formatter).find(item => item[0] === `${handleMode}`);
          value = target ? target[1] : alterMode ? '点击编辑操作修改' : undefined;
        }
        return <EllipsisSpan value={value} />;
      },
    },
    {
      title: '乘积系数k',
      dataIndex: 'k',
      ellipsis: { showTitle: true },
      width: 120,
      render: (_: undefined, record) => <EllipsisSpan value="excel导入" />,
    },
    {
      title: '偏移系数b',
      dataIndex: 'b',
      ellipsis: { showTitle: true },
      width: 120,
      render: (_: undefined, record) => <EllipsisSpan value="excel导入" />,
    },
    {
      title: '位操作模式',
      width: 120,
      dataIndex: 'mode',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => {
        const { bitMode, bitNum, byteNum } = record.parameters;
        const value = getMode(bitMode, bitNum, byteNum);
        return <EllipsisSpan value={value} defaultValue={alterMode ? '点击编辑操作修改' : undefined} />;
      },
    },
    {
      title: '数据过滤模式',
      dataIndex: 'valueFilterMode',
      ellipsis: { showTitle: true },
      width: 120,
      render: (_: undefined, record) => {
        return (
          <EllipsisSpan
            value={
              !isNil(record.parameters?.valueFilterMode)
                ? FilterModeDisplay[record.parameters?.valueFilterMode as keyof typeof FilterModeDisplay]
                : alterMode
                ? '点击编辑过滤模式'
                : undefined
            }
          />
        );
      },
    },
    {
      title: '数值合理区间',
      dataIndex: 'dataRange',
      ellipsis: { showTitle: true },
      width: 120,
      render: (_: undefined, record) => {
        const value = getRange(record.parameters?.valueFloor, record.parameters?.valueCeiling);
        return (
          <EllipsisSpan
            value={
              value !== '--'
                ? value
                : alterMode && record.parameters?.valueFilterMode !== 0
                ? '点击编辑操作修改'
                : undefined
            }
          />
        );
      },
    },
    {
      title: '备注',
      dataIndex: 'remark',
      ellipsis: { showTitle: true },
      render: (value: string) => <EllipsisSpan value={value} />,
    },
  ];
  return [
    {
      title: '数据属性/标识符',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      width: 140,
      render: (_: undefined, record) => (
        <EllipsisSpan value={!!record.hasProperty ? record.dataPropertyName : record.identifier} />
      ),
    },
    ...(_parameter ? columns.filter(column => _parameter.includes(column.dataIndex as string)) : columns),
  ];
};
