import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Modal,
  Select,
  Table,
  EllipsisSpan,
  Paging,
  useBreadcrumbRoutes,
  usePaging,
  useUpdate,
  Wrapper,
  Button,
  CustomFilter,
} from '@maxtropy/components';
import { Space } from 'antd';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  deleteServerAlarmPushRecord,
  getServerAlarmPushList,
  ServerAlarmPushListPageSearchRequest,
  ServerAlarmPushListResponse,
  ServerAlarmPushStatusType,
  ServerAlarmPushStatusTypeValue,
  updateServerAlarmPushStatus,
} from '../../../api/serverAlarmPush';
import { PermissionsType } from '../../../common/permissionsConst';
import { useHasPermission } from '../../../utils/utils';
import styles from './index.module.scss';

const columns = [
  {
    title: '推送编号',
    dataIndex: 'code',
    width: 114,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '推送名称',
    dataIndex: 'name',
    width: 141,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    width: 110,
    ellipsis: { showTitle: true },
    render: (v: ServerAlarmPushStatusType, record: ServerAlarmPushListResponse) => {
      let statusText = '';
      switch (v) {
        case ServerAlarmPushStatusType.Disable:
          statusText = '禁用';
          break;
        case ServerAlarmPushStatusType.Enable:
          statusText = '启用';
          break;
        default:
          statusText = '-';
          break;
      }
      return <EllipsisSpan value={statusText} />;
    },
  },
  {
    title: '所属运营单元',
    dataIndex: 'ouName',
    width: 142,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '推送人员数',
    dataIndex: 'contactsCount',
    width: 142,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    width: 135,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={`${dayjs(v).format('YYYY-MM-DD HH:mm:ss')} `} />,
  },
  {
    title: '最后操作人',
    dataIndex: 'updateByUsername',
    width: 155,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

// const routes = [
//   { name: 'Iot配置' },
//   { name: '报警推送' },
//   { name: '服务端推送管理' }
// ];

export interface SearchParams extends Omit<ServerAlarmPushListPageSearchRequest, 'page' | 'size'> {}

const ServerAlarmPushList: FC = () => {
  const hasServerAlarmPushAdd = useHasPermission(PermissionsType.B_SERVERALARMPUSHADD); // 新增服务端推送
  const hasServerAlarmPushDelete = useHasPermission(PermissionsType.B_SERVERALARMPUSHDELETE); // 删除服务端推送
  const hasServerAlarmPushUpdate = useHasPermission(PermissionsType.B_SERVERALARMPUSHUPDATE); // 编辑服务端推送
  const hasServerAlarmPushEnableDisable = useHasPermission(PermissionsType.B_SERVERALARMPUSHENABLEDISABLE); // 禁用/启用服务端推送

  const [searchForm] = Form.useForm(); // 搜索的表单
  const pagingInfo = usePaging(50);
  const [searchParams, setSearchParams] = useState<SearchParams>(); // 搜索参数
  const [loading, setLoading] = useState<boolean>(false); // 加载状态
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo; // 分页的一些配置
  const [data, setData] = useState<ServerAlarmPushListResponse[]>([]); // 后端返回数据
  const [updateState, updateFn] = useUpdate(); // 更新
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    setLoading(true);
    getServerAlarmPushList({
      ...searchParams,
      page: pageOffset,
      size: pageSize,
    }).then(res => {
      setLoading(false);
      setData(res.list);
      setTotalCount(res.total);
    });
  }, [pageOffset, pageSize, searchParams, setTotalCount, updateState]);

  const onFinish = (val: SearchParams) => {
    const params = {
      codeOrName: val.codeOrName,
      status: val.status,
    };
    setSearchParams(params);
    setPageOffset(1);
  };

  const onReset = () => {
    const params = {
      // code: undefined, // 编号
      codeOrName: undefined, // 名称
      status: undefined, // 状态
    };
    setSearchParams(params);
    setPageOffset(1);
  };

  const onDeleteRecord = (id: number) => {
    Modal.confirm({
      title: <span>确认删除？</span>,
      icon: <ExclamationCircleOutlined />,
      content: (
        <span>
          <span style={{ color: 'red' }}>删除后不可恢复，</span>
          <span style={{ color: 'gray' }}>你还要继续吗？</span>
        </span>
      ),
      okText: '继续',
      onOk() {
        deleteServerAlarmPushRecord(id)
          .then(res => {
            // message.success('删除成功');
            updateFn();
          })
          .catch(err => {
            // message.error('删除失败', err)
          });
      },
      onCancel() {},
    });
  };

  const onChangeStatus = (record: any) => {
    Modal.confirm({
      title: (
        <span>{`确认${
          record.status === ServerAlarmPushStatusType.Enable
            ? ServerAlarmPushStatusTypeValue[ServerAlarmPushStatusType.Disable]
            : ServerAlarmPushStatusTypeValue[ServerAlarmPushStatusType.Enable]
        }？`}</span>
      ),
      icon: <ExclamationCircleOutlined />,
      content:
        record.status === ServerAlarmPushStatusType.Enable ? (
          <span>
            <span style={{ color: 'red' }}>冻结后推送不再生效，</span>
            <span style={{ color: 'gray' }}>你还要继续吗？</span>
          </span>
        ) : (
          <></>
        ),
      okText: '继续',
      onOk() {
        updateServerAlarmPushStatus(record.id, {
          status:
            record.status === ServerAlarmPushStatusType.Enable
              ? ServerAlarmPushStatusType.Disable
              : ServerAlarmPushStatusType.Enable,
        })
          .then(res => {
            // message.success('修改状态成功');
            updateFn();
          })
          .catch(err => {
            // message.error('修改状态失败', err)
          });
      },
      onCancel() {
        console.error('Cancel');
      },
    });
  };

  // 表格操作栏
  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 253,
      render: (record: ServerAlarmPushListResponse) => (
        <Space size={16}>
          {
            // 权限控制与状态判断（根据前后端约定）
            hasServerAlarmPushDelete && (
              <Button
                disabled={record.status === ServerAlarmPushStatusType.Enable}
                type="link"
                onClick={() => onDeleteRecord(record.id)}
              >
                删除
              </Button>
            )
          }
          {hasServerAlarmPushEnableDisable && (
            <Button
              // disabled={(record.status === ElectricitySettlementRulesStatusType.ABORTED)}
              type="link"
              onClick={() => onChangeStatus(record)}
            >
              {record.status === ServerAlarmPushStatusType.Disable ? '启用' : '禁用'}
            </Button>
          )}
          {hasServerAlarmPushUpdate && (
            <Button
              // disabled={!(record.status === ElectricitySaleContractStatusType.INEFFECTIVE || record.status === ElectricitySaleContractStatusType.EFFECTIVE)}
              type="link"
            >
              <Link to={`/device/alarm/serverPush/edit/${record.id}`}>编辑</Link>
            </Button>
          )}
          {
            // hasPriceRateEdit &&
            <Button
              // disabled={!record.editable || record.status === ElectricityContractStatus.INVALID || !(dayjs().isSameOrBefore(dayjs(record.endDate).endOf('date')))}
              type="link"
            >
              <Link to={`/device/alarm/serverPush/detail/${record.id}`}>查看</Link>
            </Button>
          }
        </Space>
      ),
    },
  ];

  const filters = (
    <CustomFilter form={searchForm} onFinish={(val: SearchParams) => onFinish(val)} onReset={onReset}>
      <Form.Item label="编号/名称" name="codeOrName">
        <Input placeholder=" 请输入编号或名称查询" />
      </Form.Item>
      <Form.Item label="状态" name="status">
        <Select placeholder="请选择">
          <Select.Option value={ServerAlarmPushStatusType.Disable}>
            {ServerAlarmPushStatusTypeValue[ServerAlarmPushStatusType.Disable]}
          </Select.Option>
          <Select.Option value={ServerAlarmPushStatusType.Enable}>
            {ServerAlarmPushStatusTypeValue[ServerAlarmPushStatusType.Enable]}
          </Select.Option>
        </Select>
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} filters={filters} className={styles.wrap}>
      <div className={styles.operationArea}>
        {hasServerAlarmPushAdd && (
          <Space>
            <Button type="primary" icon={<PlusOutlined />}>
              <Link to={`/device/alarm/serverPush/create`}>新建推送</Link>
            </Button>
          </Space>
        )}
      </div>
      <Table
        rowKey="id"
        sticky
        scroll={{ x: 1300 }}
        loading={loading}
        columns={buildColumns}
        dataSource={data}
        pagination={false}
      />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default ServerAlarmPushList;
