import { DatePicker, Radio, Tabs, Tooltip } from '@maxtropy/components';
import { DatePickerProps, RadioChangeEvent, Spin } from 'antd';
import React, { FC, Key, useEffect, useMemo, useState } from 'react';
import { apiV2HvacDashboardDetailChartValuePost, apiV2HvacNodeDetailPost } from '@maxtropy/device-customer-apis-v2';
import { DatePickerType, datePickerTypeDisplay } from '@/pages/HVACDashBoard/type';
import dayjs, { Dayjs } from 'dayjs';
import Charts from '@/pages/HVACDashBoard/components/Charts';
import styles from './index.module.scss';
import { isNil } from 'lodash-es';
import { SingleCurrentItemType } from './type';
import { getDefaultTime } from '@/pages/HVACDashBoard/utils';
import { SingleIndicatorBtns } from './config';
import { TabItem } from '../..';

const { RangePicker } = DatePicker;

export interface ChartsDataReq {
  activeId?: string;
  indicatorIds?: number[]; //指标id
  granularityType?: DatePickerType; //时间颗粒度
  startDate?: string; //开始时间
  endDate?: string; //结束时间
}

interface ISingleDeviceContent {
  nodeId?: string;
  tabData?: TabItem[];
}

const SingleDeviceContent: FC<ISingleDeviceContent> = props => {
  const { nodeId, tabData } = props;
  const [activeKey, setActiveKey] = useState<string>(); // Tab选择
  const [query, setQuery] = useState<ChartsDataReq>();
  const [currentItem, setCurrentItem] = useState<SingleCurrentItemType>(); // 保存每一个选择的信息
  const [groupList, setGroupList] = useState<any[]>([]);
  const [chartList, setChartList] = useState<any>();
  const [stateList, setStateList] = useState<any>();

  // 默认选择第一个指标
  useEffect(() => {
    if (tabData && tabData.length) {
      setActiveKey(tabData[0].key);
    }
  }, [tabData]);

  useEffect(() => {
    if (!query?.granularityType || !query?.indicatorIds) return;
    apiV2HvacDashboardDetailChartValuePost({
      id: Number(nodeId),
      startDate: query?.startDate,
      endDate: query?.endDate,
      granularityType: query?.granularityType,
      indicatorIds: query?.indicatorIds,
      type: 2,
    }).then(res => {
      const newList = (res?.indicatorList ?? []).map(k => {
        const names = k?.subName?.split('-');
        const title = names?.[0];
        const subTitle = names?.[1];
        return {
          ...k,
          title,
          subTitle,
        };
      });
      let groupList: { title?: string; children: any[]; physicalUnitName?: string }[] = [];
      newList.forEach(item => {
        const group = groupList.find(k => k.title === item.title);
        if (group) {
          group.children.push({ ...item });
        } else {
          groupList.push({
            title: item.title,
            physicalUnitName: item.physicalUnitName,
            children: [{ ...item }],
          });
        }
      });
      setChartList(res.chartList);
      setStateList(res.statusList);
      setGroupList(groupList);
    });
  }, [nodeId, query]);

  useEffect(() => {
    if (activeKey) {
      const target = SingleIndicatorBtns()?.find(item => item.id.toString() === activeKey);
      const defaultAggrby = target?.defaultSelectBtn?.aggrby;
      const indicatorIds = tabData?.find(item => item.key === activeKey)?.indicatorList;

      setCurrentItem(target);
      setQuery({
        ...query,
        activeId: activeKey!,
        indicatorIds: indicatorIds ?? [],
        granularityType: defaultAggrby,
        startDate: getDefaultTime(defaultAggrby, dayjs()).from.format('YYYY-MM-DD'),
        endDate: getDefaultTime(defaultAggrby, dayjs()).to.format('YYYY-MM-DD'),
      });
    }
  }, [activeKey]);

  const onChangeRangePicker = (range: [Dayjs | null, Dayjs | null]) => {
    if (range) {
      const [beginDate, endDate] = range;
      if (!isNil(beginDate) && !isNil(endDate)) {
        // 查询的state
        setQuery({
          ...query,
          startDate: beginDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD'),
        });
      }
    }
  };

  // 禁用时间范围
  const disabledDate: DatePickerProps['disabledDate'] = (current, { from }) => {
    if (current > dayjs().endOf('day')) return true;
    const mostDays =
      query?.granularityType === DatePickerType.DAY ? 31 : query?.granularityType === DatePickerType.HOUR ? 7 : 7;
    let timeType: 'days' | 'M' = 'days';
    if (query?.granularityType === DatePickerType.DAY || query?.granularityType === DatePickerType.HOUR) {
      timeType = 'days';
    }
    if (query?.granularityType === DatePickerType.MONTH) {
      timeType = 'M' as const;
    }
    let rangeGap = timeType === 'days' ? mostDays : 12;

    if (from) {
      return Math.abs(current.diff(from, timeType)) >= rangeGap;
    }
    return false;
  };

  // 查询图表
  const chartOption = useMemo(
    () =>
      Object.assign(
        {},
        currentItem?.getChartOption?.({
          chartData: chartList,
          query,
          stateData: stateList,
        })
      ),
    [chartList]
  );

  // 日期切换
  const handleGranularityType = (e: RadioChangeEvent) => {
    const selectGranularityType = e.target.value;
    const defaultStartDate = getDefaultTime(selectGranularityType, dayjs()).from;
    const defaultEndDate = getDefaultTime(selectGranularityType, dayjs()).to;
    setQuery({
      ...query,
      granularityType: selectGranularityType,
      startDate: defaultStartDate.format('YYYY-MM-DD'),
      endDate: defaultEndDate.format('YYYY-MM-DD'),
    });
  };

  return (
    <>
      <Tabs onChange={v => setActiveKey(v)} activeKey={query?.activeId?.toString()} items={tabData} />
      {query?.indicatorIds && (
        <>
          <Radio.Group
            onChange={handleGranularityType}
            value={query?.granularityType}
            buttonStyle="solid"
            style={{ marginRight: 8 }}
          >
            {(currentItem?.dateBtn ?? []).map(btn => (
              <Radio.Button key={btn.aggrby} value={btn.aggrby}>
                {datePickerTypeDisplay[btn.aggrby as DatePickerType]}
              </Radio.Button>
            ))}
          </Radio.Group>
          <RangePicker
            value={[dayjs(query.startDate), dayjs(query.endDate)]}
            onChange={onChangeRangePicker}
            picker={query?.granularityType === DatePickerType.MONTH ? 'month' : 'date'}
            disabledDate={disabledDate}
            allowClear={false}
          />
          <Spin spinning={false}>
            <div className={styles.indicatorValue_card_wrapper}>
              {groupList?.map(item => {
                if (item.children.length === 1) {
                  const oneItem = item.children[0];
                  const showUnit = oneItem?.subName?.indexOf('COP') === -1;
                  return (
                    <div className={styles.itemcard_wrapper} key={Math.random()}>
                      <Tooltip
                        title={`${oneItem?.subName ?? '--'}${
                          !showUnit ? '' : `(${oneItem?.physicalUnitName ?? '--'})`
                        }`}
                      >
                        <span className={styles.itemcard_wrapper_top_label}>{`${oneItem?.subName ?? '--'}${
                          !showUnit ? '' : `(${oneItem?.physicalUnitName ?? '--'})`
                        }`}</span>
                      </Tooltip>
                      <Tooltip title={!isNil(oneItem?.value) ? oneItem?.value.toFixed(2) : '--'}>
                        <span className={styles.itemcard_wrapper_top_value}>
                          {!isNil(oneItem?.value) ? oneItem?.value.toFixed(2) : '--'}
                        </span>
                      </Tooltip>
                    </div>
                  );
                }
                return (
                  <div className={styles.long_itemcard_wrapper} key={Math.random()}>
                    <Tooltip title={`${item?.title ?? '--'}(${item?.physicalUnitName ?? '--'})`}>
                      <span className={styles.itemcard_wrapper_top_label}>{`${item?.title ?? '--'}(${
                        item?.physicalUnitName ?? '--'
                      })`}</span>
                    </Tooltip>
                    <div className={styles.itemDesc}>
                      {item.children.map((m: any, index: number) => (
                        <div className={styles.itemRow} key={index}>
                          <span className={styles.subTitle}>{m.subTitle}:</span>
                          <Tooltip title={!isNil(m?.value) ? m?.value.toFixed(2) : '--'}>
                            <div className={styles.row_value}>{!isNil(m?.value) ? m?.value.toFixed(2) : '--'}</div>
                          </Tooltip>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={styles.chart_box_inner}>
              <Charts chartData={chartList} option={chartOption} />
            </div>
          </Spin>
        </>
      )}
    </>
  );
};

export default SingleDeviceContent;
