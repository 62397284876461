import { Space } from 'antd';
import classnames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from '../index.module.scss';
import { CheckCircleOutlined } from '@ant-design/icons';
import { UetInfoProps } from '../../../../../api/uet';
import { useQuery } from '@/shared/utils/utils';
import { getDeviceTransformerAttribute, getDeviceTransformerList } from '../../../../../api/device';
import { DeviceListByOuProps, getDeviceListByOu } from '../../../../../api/ou';
import { loadTransformers } from '../columns';
import { defaultErrorHandling, Button, Form, message, Modal, Select, InputNumber } from '@maxtropy/components';
import { isNil } from 'lodash-es';

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

interface CreateTransformerModalProps {
  level?: string;
  onCancel: () => void;
  submitAction: (v: loadTransformers) => void;
  info?: UetInfoProps;
  parentInfo?: loadTransformers;
  list: loadTransformers[];
  editInfo?: loadTransformers;
}

const CreateTransformerModal: React.FC<CreateTransformerModalProps> = ({
  level,
  onCancel,
  submitAction,
  info,
  parentInfo,
  list,
  editInfo,
}) => {
  const [form] = Form.useForm();
  const [total, setTotal] = useState(false);
  const [production, setProduction] = useState(false);
  const [visible, setVisible] = useState<boolean>(!!level);
  const [capacity, setCapacity] = useState<number>();
  const [totalTransformer, setTotalTransformer] = useState<DeviceListByOuProps[]>([]);
  const [productionTransformer, setProductionTransformer] = useState<DeviceListByOuProps[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    setVisible(!!level);
  }, [level]);

  useEffect(() => {
    if (editInfo) {
      console.log('editInfo', editInfo);
      if (editInfo.ouIdOfTotalLoadDevice)
        totalLoadDeviceChange(editInfo.ouIdOfTotalLoadDevice, 'total', editInfo.totalLoadDeviceId);
      if (editInfo.ouIdOfProductionLoadDevice)
        totalLoadDeviceChange(editInfo.ouIdOfProductionLoadDevice, 'production', editInfo.productionLoadDeviceId);
      form.setFieldsValue({
        ...editInfo,
      });
      setTotal(!!editInfo.totalLoadDeviceId);
      setProduction(!!editInfo.productionLoadDeviceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editInfo]);

  const { data: deviceList } = useQuery(
    useCallback(
      () => (info ? getDeviceTransformerList(info.mcid).then(res => res.list ?? []) : Promise.resolve([])),
      [info]
    )
  );

  const onFinish = (v: any) => {
    if (errorMessage) {
      message.error(errorMessage);
    } else {
      setVisible(false);
      const device = deviceList?.find(i => i.id === v.deviceId);
      const totalDevice = totalTransformer.find(i => i.id === v.totalLoadDeviceId);
      const productionDevice = productionTransformer.find(i => i.id === v.productionLoadDeviceId);
      const params = {
        ...v,
        level: level,
        code: device?.code,
        typeName: device?.typeName,
        name: device?.name,
        totalLoadDeviceCode: totalDevice?.code,
        totalLoadDeviceName: totalDevice?.name,
        productionLoadDeviceCode: productionDevice?.code,
        productionLoadDeviceName: productionDevice?.name,
        parentDeviceId: parentInfo?.deviceId,
        parentInfo: {
          level: parentInfo?.level,
          sequence: parentInfo?.sequence,
        },
      };
      submitAction(params);
    }
  };

  const onSelect = (v: number) => {
    getDeviceTransformerAttribute(v)
      .onError(e => {
        defaultErrorHandling(e);
        const { errorMessage } = e?.cause;
        setErrorMessage(errorMessage);
        throw e;
      })
      .then(res => {
        setErrorMessage(undefined);
        setCapacity(isNil(res.value) ? undefined : Number(res.value));
        form.setFieldsValue({
          capacity: isNil(res.value) ? undefined : Number(res.value),
        });
      })
      .catch(err => {
        console.error('err', err?.errorMessage);
      });
  };

  const targetLoadChange = (v: number | null) => {
    if (!isNil(v)) {
      const capacity = form.getFieldValue('capacity');
      if (!capacity) return;
      const res = (v / capacity) * 100;
      form.setFieldsValue({
        targetLoadRate: Number(res.toFixed(2)),
      });
    } else {
      form.setFieldsValue({
        targetLoadRate: undefined,
      });
    }
  };

  const targetLoadRateChange = (v: number | null) => {
    if (!isNil(v)) {
      const capacity = form.getFieldValue('capacity');
      if (!capacity) return;
      const res = (form.getFieldValue('capacity') / 100) * v;
      form.setFieldsValue({
        targetLoad: Number(res.toFixed(2)),
      });
    } else {
      form.setFieldsValue({
        targetLoad: undefined,
      });
    }
  };

  const totalLoadDeviceChange = (v: number, key: string, value?: number) => {
    getDeviceListByOu(v).then(res => {
      const selectedIds: number[] = [];
      console.log('list', list);
      list.forEach(i => {
        if (i.productionLoadDeviceId) selectedIds.push(i.productionLoadDeviceId);
        if (i.totalLoadDeviceId) selectedIds.push(i.totalLoadDeviceId);
      });
      // 排除总负载电表
      if (editInfo?.totalLoadDeviceId) {
        if (selectedIds.includes(editInfo?.totalLoadDeviceId)) {
          const findIndex = selectedIds.findIndex(i => i === editInfo?.totalLoadDeviceId);
          if (findIndex !== -1) {
            selectedIds.splice(findIndex, 1);
          }
        }
      }
      // 排除生产负载电表
      if (editInfo?.productionLoadDeviceId) {
        if (selectedIds.includes(editInfo?.productionLoadDeviceId)) {
          const findIndex = selectedIds.findIndex(i => i === editInfo?.productionLoadDeviceId);
          if (findIndex !== -1) {
            selectedIds.splice(findIndex, 1);
          }
        }
      }

      const devices = res.filter(i => !selectedIds.includes(i.id));
      if (key === 'total') {
        form.setFieldsValue({
          totalLoadDeviceId: value ?? undefined,
        });
        setTotalTransformer(devices);
      } else {
        form.setFieldsValue({
          productionLoadDeviceId: value ?? undefined,
        });
        setProductionTransformer(devices);
      }
    });
  };

  const selectedDeviceIds = list.filter(i => i.deviceId !== editInfo?.deviceId).map(i => i.deviceId);

  const totalLoadDeviceOptions = useMemo(() => {
    return totalTransformer.map(i => ({ label: i.name, value: i.id }));
  }, [totalTransformer]);

  const productionLoadDeviceOptions = useMemo(() => {
    return productionTransformer.map(i => ({ label: i.name, value: i.id }));
  }, [productionTransformer]);

  // const onTotalLoadDeviceSearch = debounce((value: string) => {
  //   setTotalLoadDeviceSearchValue(value)
  // }, 10)

  // const onProductionLoadDeviceSearch = debounce((value: string) => {
  //   setProductionLoadDeviceSearchValue(value)
  // }, 10)

  // const onTotalLoadDeviceBlur = () => {
  //   if (totalLoadDeviceSearchValue) {
  //     form.setFieldsValue({
  //       totalLoadDeviceId: totalLoadDeviceSearchValue
  //     })
  //   }
  // }
  // const onProductionLoadDeviceBlur = () => {
  //   if (productionLoadDeviceSearchValue) {
  //     form.setFieldsValue({
  //       productionLoadDeviceId: productionLoadDeviceSearchValue
  //     })
  //   }
  // }

  return (
    <>
      <Modal
        open={visible}
        width={600}
        title={`选择${level === '0' ? 'UET总负载' : level === '1' ? '1级子网络' : '2级子网络'}变压器`}
        onCancel={() => onCancel()}
        onOk={() => form.submit()}
      >
        <Form form={form} {...formLayout} onFinish={onFinish}>
          {level !== '0' && (
            <Form.Item label="父级变压器" name="parentDeviceId">
              <span>
                {level === '1' ? '总负载1#' : `1级-${parentInfo?.sequence}#`}
                {parentInfo?.name}，{parentInfo?.capacity}kVA
              </span>
            </Form.Item>
          )}
          <Form.Item
            label="变压器"
            name="deviceId"
            validateTrigger="onBlur"
            rules={[{ required: true, message: '请选择变压器' }]}
          >
            <Select placeholder="请选择" onSelect={onSelect}>
              {deviceList
                ?.filter(f => !selectedDeviceIds.includes(f.id))
                .map(i => (
                  <Select.Option value={i.id} key={i.id}>
                    {i.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="容量" name="capacity">
            <InputNumber addonAfter="kVA" placeholder="请输入" />
          </Form.Item>
          <Form.Item label="目标负载" name="targetLoad">
            <InputNumber
              addonAfter="kW"
              min={0}
              max={capacity}
              precision={2}
              placeholder="请输入"
              onChange={targetLoadChange}
            />
          </Form.Item>
          <Form.Item label="目标负载率" name="targetLoadRate">
            <InputNumber
              addonAfter="%"
              min={0}
              max={100}
              precision={2}
              placeholder="请输入"
              onChange={targetLoadRateChange}
            />
          </Form.Item>
          <Space style={{ margin: '0 0 20px 30px' }}>
            <Button
              className={classnames(styles.btn, total && styles.checked)}
              icon={total && <CheckCircleOutlined />}
              onClick={() => setTotal(!total)}
            >
              总负载电表
            </Button>
            <Button
              className={classnames(styles.btn, production && styles.checked)}
              icon={production && <CheckCircleOutlined />}
              onClick={() => setProduction(!production)}
            >
              生产负载电表
            </Button>
          </Space>
          {total && (
            <>
              <Form.Item
                label="运营单元"
                name="ouIdOfTotalLoadDevice"
                rules={[{ required: true, message: '请选择运营单元' }]}
              >
                <Select placeholder="请选择" onSelect={(v: number) => totalLoadDeviceChange(v, 'total')}>
                  {info?.ous.map(i => (
                    <Select.Option value={i.id} key={i.id}>
                      {i.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="总负载电表"
                name="totalLoadDeviceId"
                rules={[{ required: true, message: '请选择总负载电表' }]}
              >
                <Select
                  placeholder="请选择"
                  showSearch
                  // onSearch={onTotalLoadDeviceSearch}
                  // onBlur={onTotalLoadDeviceBlur}
                  optionFilterProp="label"
                  options={totalLoadDeviceOptions}
                />
              </Form.Item>
            </>
          )}
          {production && (
            <>
              <Form.Item
                label="运营单元"
                name="ouIdOfProductionLoadDevice"
                rules={[{ required: true, message: '请选择运营单元' }]}
              >
                <Select placeholder="请选择" onSelect={(v: number) => totalLoadDeviceChange(v, 'production')}>
                  {info?.ous.map(i => (
                    <Select.Option value={i.id} key={i.id}>
                      {i.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="生产负载电表"
                name="productionLoadDeviceId"
                rules={[{ required: true, message: '请选择生产负载电表' }]}
              >
                <Select
                  placeholder="请选择"
                  showSearch
                  // onSearch={onProductionLoadDeviceSearch}
                  // onBlur={onProductionLoadDeviceBlur}
                  optionFilterProp="label"
                  options={productionLoadDeviceOptions}
                />
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default CreateTransformerModal;
