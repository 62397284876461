import { FC, useEffect, useState } from 'react';
import {
  Wrapper,
  SubContent,
  Table,
  Paging,
  usePaging,
  useBreadcrumbRoutes,
  Button,
  EllipsisSpan,
  Form,
  FormTitle,
} from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import styles from './index.module.scss';
import ShowInput from '@/shared/components/ShowInput';
import {
  CreditLimitType,
  getSaleAccountVoucherPage,
  getSaleClientAccount,
  SaleClientAccountInfo,
  SaleVoucherVo,
  TransactionType,
  TransactionTypeDisplay,
  TransactionTypeOperator,
} from '../../../api/sale';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ColumnType } from 'antd/es/table';
import DetailModal from './DetailModal';

const routes = [{ name: '凭证' }];

const columns: ColumnType<SaleVoucherVo>[] = [
  {
    title: '凭证名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '凭证类型',
    dataIndex: 'type',
    ellipsis: { showTitle: true },
    render: (v: TransactionType) => <EllipsisSpan value={TransactionTypeDisplay[v]} />,
  },
  {
    title: '交易金额',
    dataIndex: 'amount',
    ellipsis: { showTitle: true },
    render: (v: number, record) => (
      <EllipsisSpan
        value={(v ?? undefined) !== undefined ? `${TransactionTypeOperator[record.type]}${v}元` : undefined}
      />
    ),
  },
  {
    title: '凭证说明',
    dataIndex: 'description',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const Voucher: FC = () => {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);

  const [saleClientAccount, setSaleClientAccount] = useState<SaleClientAccountInfo>();

  const [data, setData] = useState<SaleVoucherVo[]>([]);
  const pagingInfo = usePaging(10);

  const [currentRecord, setCurrentRecord] = useState<SaleVoucherVo>();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const goList = () => {
    navigate('/operation/client-related/client-account');
  };

  // 分页数据
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;
  useEffect(() => {
    if (id) {
      setLoading(true);
      getSaleAccountVoucherPage({
        saleClientAccountId: id,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        setLoading(false);
        setData(res.list);
        setTotalCount(res.total);
      });
    }
  }, [pageOffset, pageSize, id, setTotalCount]);

  // 客户信息接口
  useEffect(() => {
    if (id) {
      getSaleClientAccount(id).then(setSaleClientAccount);
    }
  }, [id]);

  useEffect(() => {
    if (saleClientAccount) {
      form.setFieldsValue({
        name: saleClientAccount.name,
        saleClientName: saleClientAccount.saleClientName,
        creditLimitType: saleClientAccount.creditLimitType,
        creditLimitAmount:
          (saleClientAccount.creditLimitAmount ?? undefined) !== undefined
            ? `${saleClientAccount.creditLimitAmount}元`
            : saleClientAccount.creditLimitAmount,
        balance: (saleClientAccount.balance ?? undefined) !== undefined ? `${saleClientAccount.balance}元` : undefined,
      });
    }
  }, [saleClientAccount, form]);

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 200,
      fixed: 'right' as const,
      render: (_: undefined, record: SaleVoucherVo) => {
        return (
          <Space size={16}>
            {record.type === TransactionType.RECHARGE ? (
              <Button type="link">
                <Link to={`/operation/client-related/client-account/voucher/voucherDetail/${record.id}`}>使用明细</Link>
              </Button>
            ) : (
              <Button type="link">使用明细</Button>
            )}
            <Button
              type="link"
              onClick={() => {
                setCurrentRecord(record);
              }}
            >
              查看
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Wrapper className={styles.wrapper} routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}>
        <FormTitle title={routes[0].name} />
        <SubContent title="账户信息">
          <Form layout="vertical" className={styles.formWrapper} form={form} {...formLayout}>
            <Row>
              <Col span={6}>
                <Form.Item name="saleClientName" label="客户名称">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="name" label="账户名称">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="balance" label="账户余额">
                  <ShowInput style={{ color: '#FF0000' }} />
                </Form.Item>
              </Col>
              <Form.Item noStyle dependencies={['creditLimitType']}>
                {({ getFieldValue }) => {
                  return (
                    getFieldValue('creditLimitType') === CreditLimitType.LIMITED && (
                      <Col span={6}>
                        <Form.Item name="creditLimitAmount" label="信用额度">
                          <ShowInput style={{ color: '#FF0000' }} />
                        </Form.Item>
                      </Col>
                    )
                  );
                }}
              </Form.Item>
            </Row>
          </Form>
        </SubContent>
        <SubContent title="交易凭证">
          <Table rowKey="id" sticky loading={loading} dataSource={data} columns={buildColumns} />
          <Paging pagingInfo={pagingInfo} />
        </SubContent>
        <Space className="sticky-footer-c">
          <Button onClick={goList}> 返回 </Button>
        </Space>
      </Wrapper>
      <DetailModal record={currentRecord} onCancel={setCurrentRecord} />
    </>
  );
};

export default Voucher;
