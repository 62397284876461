import {
  V2StationSignboardStationListPostResponse,
  V2HvacDashboardDetailGetNodeIndicatorValuePostResponse,
} from '@maxtropy/device-customer-apis-v2';

// 指标类型
export enum IndexType {
  TOTAL = 1,
  NODE = 2,
}

// 介质颜色
export const colorCategory: Record<string, string> = {
  '2030': '#59DBFF',
  '2021': '#2CA02C',
  '2020': '#FFCB47',
  '2001': '#FF477B',
  '2022': '#59DBFF',
  '2023': '#2CA02C',
  '2024': '#FFCB47',
  '2011': '#FF477B',
};

export enum IndicatorKey {
  Status = 'Status',
  PowerConsumption = 'PowerConsumption',
  PowerToElectricityRatio = 'PowerToElectricityRatio',
  AirCompressorExhaustPressure = 'AirCompressorExhaustPressure',
  NitrogenGeneratorNitrogenPurity = 'NitrogenGeneratorNitrogenPurity',
  OutputFlow = 'OutputFlow',
  AirTankPressure = 'AirTankPressure',
  StationExitPressure = 'StationExitPressure',
  StationExitFlow = 'StationExitFlow',
  ThermometerTemperature = 'ThermometerTemperature',
}

export enum NodeStatus {
  SHUT = 0,
  RUNNING = 1,
  OFFLINE,
}

export const IndicatorKeyName = {
  [IndicatorKey.Status]: '状态',
  [IndicatorKey.NitrogenGeneratorNitrogenPurity]: '氮气纯度',
  [IndicatorKey.PowerConsumption]: '当日用电量',
  [IndicatorKey.PowerToElectricityRatio]: '当日气电比',
  [IndicatorKey.AirCompressorExhaustPressure]: '排气压力',
  [IndicatorKey.OutputFlow]: '输出流量',
  [IndicatorKey.AirTankPressure]: '压力',
  [IndicatorKey.StationExitPressure]: '出口压力',
  [IndicatorKey.StationExitFlow]: '出口流量',
  [IndicatorKey.ThermometerTemperature]: '温度',
};

export type StationItem = Exclude<V2StationSignboardStationListPostResponse['list'], undefined>[number];
export type TopoNodeItem = Exclude<V2HvacDashboardDetailGetNodeIndicatorValuePostResponse['list'], undefined>[number];

export type LinkItem = {
  nodeId?: string;
  targetId?: number;
  processId?: number;
  sum?: number;
  indicatorId?: number;
  energyMediumId?: number;
  energyName?: string;
  name?: string;
  unit?: string;
};

export interface AlarmNode {
  nodeId: number;
  nodeType: number;
}

export const NodeStatusDisplay = {
  [NodeStatus.RUNNING]: '运行',
  [NodeStatus.OFFLINE]: '停机',
  [NodeStatus.SHUT]: '关机',
};

export const NodeStatusTag = {
  [NodeStatus.RUNNING]: 'success',
  [NodeStatus.OFFLINE]: 'warning',
  [NodeStatus.SHUT]: 'invalid',
};

export enum NodeType {
  COMPRESSOR = 1,
  NITROGEN_GENERATOR,
  GAS_TANK,
  STATION_OUTLET,
  GAS_MANIFOLD,
  THERMOMETER,
}

export enum Position {
  TOP = 1,
  BOTTOM,
}

export const PositionDisplay = {
  [Position.TOP]: '上',
  [Position.BOTTOM]: '下',
};
