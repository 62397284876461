import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { Key } from 'react';
import { OuStaffType, OuStatus } from '@/shared/const';
import { Operator } from '@/shared/types';
import { PageRequest, PageResponse } from './page';

export interface QueryProductionUnitListRequest extends PageRequest {
  nameOrSerialNum?: string;
  ifCancel?: boolean;
}

export interface ProductionUnitListProps {
  homePage?: string;
  id: number;
  name: string;
  ouTypeId: string;
  mcid: string;
  tenantId: string;
  tenantName: string;
  customerName: string;
  directorName: string;
  directorPhone: string;
  remark: string;
  updateTime: string;
  updateByUsername: string;
  updateByUserId: string;
  updateSource: Operator;
  serialNumber: string;
  ouTypeName: string;
  deviceNum: string;
  status: OuStatus;
  userType: OuStaffType;
}

export const queryProductionUnitList = (params: QueryProductionUnitListRequest) =>
  fetch<PageResponse<ProductionUnitListProps>>(`/api/ou/page?${qs.stringify(params, { indices: false })}`);

interface QueryUserListRequest extends PageRequest {
  name?: string;
  staffCode?: string;
  phone?: string;
  username?: string;
  humanCode?: string;
  mcid: string;
}

export interface UserListProps {
  staffId: number;
  id: number;
  humanCode: string;
  staffType: any;
  mcid: string;
  muid: string;
  status: number;
  staffCode: string;
  username: string;
  name: string;
  phone: string;
  orgName: string;
  orgFullName: string;
}

export const queryUserList = (params: QueryUserListRequest) =>
  fetch<PageResponse<UserListProps>>(`/api/ou/user/page?${qs.stringify(params, { indices: false })}`);

export interface OuStaffListProps {
  staffId: number;
  id: number;
  staffType: OuStaffType;
  muid: string;
  username: string;
  staffCode: string;
  name: string;
  phone: string;
  orgName: string;
  humanCode: string;
}

export const queryOuStaffList = (id: string) => fetch<OuStaffListProps[]>(`/api/ou/loadStaffInfoList/${id}`);

export const queryOuStaffPage = (id: string, params: PageRequest) =>
  fetch<PageResponse<OuStaffListProps>>(
    `/api/ou/loadStaffInfoPage/${id}?${qs.stringify(params, {
      indices: false,
    })}`
  );

export interface OuTypeListProps {
  id: number;
  name: string;
  key: 'PRODUCTION_LINE' | 'MICRONET';
}

export const queryOuTypeList = () => fetch<OuTypeListProps[]>(`/api/ou/ouType/list`);

interface AddOuBody {
  name: string;
  ouTypeId: string;
  mcid: string;
  directorName: string;
  directorPhone: string;
  remark: string;
  memberIds: number[];
  adminIds: number[];
}

export const addOu = (body: AddOuBody) => fetch<never>(`/api/ou`, { method: 'POST', body: JSON.stringify(body) });

export interface OuInfoProps {
  id: number;
  name: string;
  ouTypeId: number;
  ouTypeName: string;
  status: OuStatus;
  tenantId: string;
  tenantName: string;
  tenantCode?: any;
  mcid: string;
  deviceNum?: any;
  customerName: string;
  directorName: string;
  directorPhone: string;
  remark: string;
  createTime: string;
  updateTime: string;
  updateByUsername: string;
  updateByUserId: string;
  updateSource: number;
  serialNumber: string;
  userType: OuStaffType;
  tenantMcid?: string;
}

export const getOuInfo = (id: string) => fetch<OuInfoProps>(`/api/ou/${id}`);

interface UpdateOuBody {
  id: string;
  name: string;
  directorName: string;
  directorPhone: string;
  remark: string;
  memberIds: number[];
  adminIds: number[];
}

export const updateOu = (id: string, body: UpdateOuBody) =>
  fetch<never>(`/api/ou/${id}`, { method: 'PUT', body: JSON.stringify(body) });

interface UpdateOuState {
  state: OuStatus;
  password: string;
}

export const updateOuState = (id: number, body: UpdateOuState) =>
  fetch<never>(`/api/ou/${id}/state`, {
    method: 'PUT',
    body: JSON.stringify(body),
  });

export const getStaffInfo = () => fetch<OuStaffListProps>(`/api/ou/loadStaffInfo`);

export interface OuListResponse {
  id: number;
  name: string;
  customerName: string;
  status: OuStatus;
}

export const getOuListOwned = () => fetch<OuListResponse[]>(`/api/ou/list/owned`);

export const getOuListByMcid = (mcid: string) => fetch<OuInfoProps[]>(`/api/ou/list-by-mcid?mcid=${mcid}`);

export const getOuByFcs = () => fetch<OuInfoProps[]>(`/api/ou/list/fcs`);

export interface DeviceListByOuProps {
  code: string;
  createTime: string;
  edgeDeviceId: number;
  id: number;
  modelId: number;
  modelName: string;
  name: string;
  sn: string;
  status: number;
  typeId: number;
  typeName: string;
}

export const getDeviceListByOu = (id: number) =>
  fetch<DeviceListByOuProps[]>(`/api/ou/${id}/device/list/electricityMeter`);

// 根据物业id查询ouList
export const getouListByPmcid = (id: string) =>
  // fetch<OuInfoProps[]>(`/api/ou/list-by-pmcid?${qs.stringify(id, {
  fetch<OuInfoProps[]>(
    `/api/ou/list-by-pmcid?${qs.stringify(
      { propertyManagementConfigId: id },
      {
        indices: false,
      }
    )}`
  );

export interface OuListByUetRes extends ProductionUnitListProps {
  tenantMcid: Key; // 租户mcid
  createTime: string; // 创建时间
  updateTime: string; // 更新时间
  manageUpdateByUsername: string; // 操作人(实施)
  manageUpdateByUserId: Key; // 操作人id(实施)
  manageUpdateTime: string; // 更新时间(实施)
}

// 获取拓扑运营单元列表
export const getUetOuList = (id: Key) => {
  return fetch<OuListByUetRes[]>(`/api/uet/ou-list/${id}`, {
    method: 'GET',
  });
};
