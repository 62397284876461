import { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Key, EllipsisSpan, Radio, Table } from '@maxtropy/components';

export interface rankData {
  carbonEmissionConsumption: number;
  energyConsumptionStandardCoal: number;
  workCenterId: number;
  workProcedureId: number;
  workProcedureName: string;
}

interface Props {
  rankData?: rankData[];
}

const StatisticalRanking: React.FC<Props> = ({ rankData }) => {
  const [tabActiveKey, setTabActiveKey] = useState<Key>('1');
  const [sortCarbonTableData, setCarbonSortTableData] = useState<rankData[]>([]);
  const [sortEnergyTableData, setEnergySortTableData] = useState<rankData[]>([]);

  const colors = ['#FF4D4F', '#FAAE07', '#27BAF1', '#979797'];

  useEffect(() => {
    const sortedTableData = [...(rankData as rankData[])];
    sortedTableData.sort(
      (a: { energyConsumptionStandardCoal: number }, b: { energyConsumptionStandardCoal: number }) =>
        b.energyConsumptionStandardCoal - a.energyConsumptionStandardCoal
    );
    setEnergySortTableData(sortedTableData);
  }, [rankData]);

  useEffect(() => {
    const sortedTableData = [...(rankData as rankData[])];
    sortedTableData.sort(
      (a: { carbonEmissionConsumption: number }, b: { carbonEmissionConsumption: number }) =>
        b.carbonEmissionConsumption - a.carbonEmissionConsumption
    );
    setCarbonSortTableData(sortedTableData);
  }, [rankData]);

  const carbonColumns = [
    {
      title: '排名',
      render: (v: string, row: any, index: number) => {
        const colorIndex = index >= 3 ? 3 : index;
        return (
          <div style={{ backgroundColor: colors[colorIndex], width: 20, height: 20, textAlign: 'center' }}>
            {index + 1}
          </div>
        );
      },
    },
    {
      title: '工序',
      dataIndex: 'workProcedureName',
      ellipsis: { showTitle: true },
      render: (v: string, row: any, index: number) => {
        return <EllipsisSpan value={v} />;
      },
    },
    {
      title: '碳排放量(tCO2e)',
      dataIndex: 'carbonEmissionConsumption',
      ellipsis: { showTitle: true },
      render: (v: string, row: any, index: number) => {
        return <EllipsisSpan value={(Number(v) / 1000).toFixed(2)} />;
      },
    },
  ];

  const energyColumns = [
    {
      title: '排名',
      render: (v: string, row: any, index: number) => {
        const colorIndex = index >= 3 ? 3 : index;
        return (
          <div style={{ backgroundColor: colors[colorIndex], width: 20, height: 20, textAlign: 'center' }}>
            {index + 1}
          </div>
        );
      },
    },
    {
      title: '工序',
      dataIndex: 'workProcedureName',
      ellipsis: { showTitle: true },
      render: (v: string, row: any, index: number) => {
        return <EllipsisSpan value={v} />;
      },
    },
    {
      title: '用能量(tce)',
      dataIndex: 'energyConsumptionStandardCoal',
      ellipsis: { showTitle: true },
      render: (v: string, row: any, index: number) => {
        return <EllipsisSpan value={(Number(v) / 1000).toFixed(2)} />;
      },
    },
  ];

  return (
    <div className={styles.bottomStyles}>
      <span style={{ fontSize: 16, marginRight: 20, position: 'absolute', left: 4, top: 3 }}>排名</span>

      <Radio.Group
        className={styles.carbonRadio}
        onChange={(v: any) => {
          setTabActiveKey(v.target.value);
        }}
        defaultValue="1"
        buttonStyle="solid"
      >
        <Radio.Button value="1">碳排放量</Radio.Button>
        <Radio.Button value="2">用能量</Radio.Button>
      </Radio.Group>
      {tabActiveKey === '1' && (
        <div style={{ flex: 1 }}>
          <Table
            rowKey="id"
            dataSource={sortCarbonTableData}
            columns={carbonColumns}
            pagination={false}
            scroll={{ y: 195 }}
          ></Table>
        </div>
      )}
      {tabActiveKey === '2' && (
        <div style={{ flex: 1 }}>
          <Table
            rowKey="id"
            dataSource={sortEnergyTableData}
            columns={energyColumns}
            pagination={false}
            scroll={{ y: 195 }}
          ></Table>
        </div>
      )}
    </div>
  );
};

export default StatisticalRanking;
