import React, { useEffect, useMemo, useState } from 'react';

import { getOrganizationWithCodeWithCurrent, OrganizationResponse } from '../../../api/device';
import { ActionType, ActionTypeDisplay, formatTreeData } from '../utils';
import { getUetEnergyMediumSceneList, UetEnergyMediumSceneListResponse } from '../../../api/uet';
import ShowInput from '@/shared/components/ShowInput';
import {
  WorkingSubstanceAccountCreateParams,
  WorkingSubstanceAccountPageRes,
  addWorkingSubstanceAccount,
  updateWorkingSubstanceAccount,
} from '@/api/workingSubstance';
import type { DefaultOptionType } from 'rc-tree-select/es/TreeSelect';
import { Form, Input, Modal, Select, TreeSelect } from '@maxtropy/components';

interface CreateFormProps {
  showModal?: ActionType;
  setShowModal: (v: ActionType | undefined) => void;
  updateFn: () => void;
  row?: WorkingSubstanceAccountPageRes;
}

const CreateForm: React.FC<CreateFormProps> = ({ row, showModal, setShowModal, updateFn }) => {
  const [form] = Form.useForm();
  const [organization, setOrganization] = useState<OrganizationResponse>();
  const [sceneList, setSceneList] = useState<UetEnergyMediumSceneListResponse[]>([]); // 获取能源介质场景列表
  useEffect(() => {
    getOrganizationWithCodeWithCurrent().then(res => {
      setOrganization(res);
    });
    // 获取能源介质场景列表
    getUetEnergyMediumSceneList().then(setSceneList);
  }, []);

  const treeData = useMemo(() => {
    if (organization) {
      return formatTreeData([organization]);
    } else {
      return undefined;
    }
  }, [organization]);

  useEffect(() => {
    if (row) {
      form.setFieldsValue({
        accountNumber: row.accountNumber,
        mcidList: showModal === ActionType.DETAIL ? row.mcidListStr : row.mcidList,
        energyMediumSceneId: showModal === ActionType.DETAIL ? row.energyMediumSceneName : row.energyMediumSceneId,
      });
    }
  }, [row, showModal, form]);

  const sceneOptions = useMemo(() => {
    if (sceneList && sceneList.length > 0) {
      return sceneList.map(i => ({ label: i.sceneName, value: i.id }));
    }
  }, [sceneList]);

  useEffect(() => {
    if (!showModal) {
      form.resetFields();
    }
  }, [showModal, form]);

  const onFinish = (
    val: Omit<WorkingSubstanceAccountCreateParams, 'mcidList'> & {
      mcidList: DefaultOptionType[];
    }
  ) => {
    if (showModal === ActionType.CREATE) {
      if (val.mcidList && val.mcidList.length > 0) {
        addWorkingSubstanceAccount({
          ...val,
          mcidList: val.mcidList.map(i => i.value as string),
        }).then(() => {
          setShowModal(undefined);
          updateFn();
        });
      }
    }
    if (showModal === ActionType.UPDATE && row) {
      updateWorkingSubstanceAccount({
        ...val,
        id: row.id,
      }).then(() => {
        setShowModal(undefined);
        updateFn();
      });
    }
  };

  return (
    <Modal
      width={600}
      destroyOnClose
      open={!!showModal}
      onCancel={() => {
        setShowModal(undefined);
      }}
      onOk={() => {
        if (showModal === ActionType.DETAIL) {
          setShowModal(undefined);
        } else {
          form.submit();
        }
      }}
      title={`${ActionTypeDisplay[showModal ?? ActionType.CREATE]}工质账户`}
    >
      <Form
        form={form}
        onFinish={(
          val: Omit<WorkingSubstanceAccountCreateParams, 'mcidList'> & {
            mcidList: DefaultOptionType[];
          }
        ) => onFinish(val)}
      >
        <Form.Item
          validateFirst
          name="accountNumber"
          label="工质账户户号"
          rules={[
            { required: showModal !== ActionType.DETAIL, message: '请输入' },
            { max: 30, message: '最多输入30个字符' },
          ]}
        >
          {showModal === ActionType.DETAIL ? <ShowInput /> : <Input placeholder="请输入" />}
        </Form.Item>
        <Form.Item
          name="mcidList"
          label="关联组织"
          rules={[{ required: showModal !== ActionType.DETAIL, message: '请选择' }]}
        >
          {showModal === ActionType.DETAIL ? (
            <ShowInput />
          ) : (
            <TreeSelect
              treeDefaultExpandAll
              treeCheckable
              treeCheckStrictly
              showCheckedStrategy={TreeSelect.SHOW_ALL}
              showArrow
              showSearch={false}
              treeData={treeData}
              placeholder="请选择"
              disabled={showModal === ActionType.UPDATE}
              dropdownStyle={{ maxHeight: 200, overflowY: 'auto' }}
            />
          )}
        </Form.Item>
        <Form.Item
          name="energyMediumSceneId"
          label="能源介质场景"
          rules={[{ required: showModal !== ActionType.DETAIL, message: '请选择' }]}
        >
          {showModal === ActionType.DETAIL ? (
            <ShowInput />
          ) : (
            <Select placeholder="请选择" options={sceneOptions} disabled={showModal === ActionType.UPDATE} />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateForm;
