import { EllipsisSpan, MxColumnType } from '@maxtropy/components';
import * as React from 'react';
import { ComputationalLogic, computationalLogicDisplay } from '../../../../types';

export const columnsBefore: MxColumnType<any>[] = [
  {
    title: '数据属性/标识符',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    showForce: true,
    showComplete: true,
    keyList: ['dataPropertyName'],
    width: 180,
    render: (_: any, record) => <EllipsisSpan value={record.dataPropertyName} />,
  },
  {
    title: '单位',
    dataIndex: 'physicalUnitGeneralName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? v : '--'} />,
  },
];

export const columnsAfter: MxColumnType<any>[] = [
  {
    title: '计算逻辑',
    dataIndex: 'computationalLogic',
    ellipsis: { showTitle: true },
    render: (v: ComputationalLogic) => <EllipsisSpan value={computationalLogicDisplay[v]} />,
  },
  {
    title: '备注',
    dataIndex: 'remark',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];
