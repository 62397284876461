import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Key,
  Wrapper,
  useBreadcrumbRoutes,
  FormTitle,
  SubContent,
  DatePicker,
  Form,
  InputNumber,
  Modal,
  Radio,
  Select,
} from '@maxtropy/components';
import { Row, Col, Space } from 'antd';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import ShowInput from '@/shared/components/ShowInput';
import { Button as AntdButton } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CarbonEmissionMonthPlanDtosItem,
  CarbonYearDisChargePlanBody,
  FillingType,
  getCarbonYearDisChargePlanDetail,
  saveCarbonYearDisChargePlan,
} from '@/api/carbonYearDisChargePlan';
import { AccountUnitListItem, getAccountUnitDetailById, getAccountUnitList } from '@/api/CarbonAccountUnits';
import styles from './index.module.scss';

interface AddYearPlanProps {
  isEdit?: boolean;
  isDetail?: boolean;
}

interface FormProps {
  accountingUnitId: Key;
  fillingYear: number;
  fillingType: FillingType;
  emissionRights: number;
  emissionReductionRatio?: number;
  preYearDischarge: number;
  carbonEmissionMonthPlanVos?: CarbonEmissionMonthPlanDtosItem[];
  totalWeight: number;
  totalMinusDischarge: number;
  totalPreDischarge: number;
}

const disabledMonthMap = new Map<number, string>();

const CarbonAddYearPlan: React.FC<AddYearPlanProps> = ({ isEdit = false, isDetail = false }) => {
  const routes = [{ name: isEdit ? '编辑' : isDetail ? '查看' : '新建' }];
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const fillingType = Form.useWatch('fillingType', form); // 填报类型
  const fillingYear = Form.useWatch('fillingYear', form); // 财年
  const emissionRights = Form.useWatch('emissionRights', form); // 年碳排放权
  const emissionReductionRatio = Form.useWatch('emissionReductionRatio', form); // 年减排比例
  const carbonEmissionMonthPlanVos = Form.useWatch('carbonEmissionMonthPlanVos', form); // 月计划列表
  const accountingUnitId = Form.useWatch('accountingUnitId', form);
  const [startMonth, setStartMonth] = useState<number>(); // 起始月份
  const [disabledMonth, setDisabledMonth] = useState<string[]>([]);
  const [currentMonth, setCurrentMonth] = useState<string>(); // 当前focus的月份
  const navigate = useNavigate();
  const [accountUnitList, setAccountUnitList] = useState<AccountUnitListItem[]>([]);

  // 获取核算单元列表
  useEffect(() => {
    disabledMonthMap.clear();
    getAccountUnitList().then(setAccountUnitList);
  }, []);

  // 获取详情
  useEffect(() => {
    if (id) {
      getCarbonYearDisChargePlanDetail(id).then(res => {
        form.setFieldsValue({
          ...res,
          preYearDischarge: res.totalEmission, // 年预计碳排放
          totalMinusDischarge: res.totalReductedEmission, // 总减排碳排放
          totalPreDischarge: res.totalEmission, // 总预计碳排放
          totalWeight: res.emissionRights, // 总碳排放权
          fillingYear: dayjs(String(res.fillingYear)),
        });
        if (res.fillingType === FillingType.MONTH) {
          res.carbonEmissionMonthPlanVos?.forEach((item, index) => {
            disabledMonthMap.set(index, item.emissionMonth);
          });
          setDisabledMonth(Array.from(disabledMonthMap.values()));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // 获取起始月份
  useEffect(() => {
    if (accountingUnitId) {
      getAccountUnitDetailById(accountingUnitId).then(res => {
        setStartMonth(res.startMonth);
      });
    }
  }, [accountingUnitId]);

  // 合计逻辑
  useEffect(() => {
    // 按财年
    if (fillingType === FillingType.YEAR) {
      const discharge =
        !isNil(emissionRights) && !isNil(emissionReductionRatio)
          ? Number(((emissionRights * (100 - emissionReductionRatio)) / 100).toFixed(2))
          : undefined;
      const minusDis =
        !isNil(emissionRights) && !isNil(emissionReductionRatio)
          ? Number(((emissionRights * emissionReductionRatio) / 100).toFixed(2))
          : undefined;
      form.setFieldsValue({
        preYearDischarge: discharge, // 年预计碳排放
        totalMinusDischarge: minusDis, // 总减排碳排放
        totalPreDischarge: discharge, // 总预计碳排放
        totalWeight: emissionRights, // 总碳排放权
      });
    }
    // 按月
    if (fillingType === FillingType.MONTH) {
      const planList = carbonEmissionMonthPlanVos ?? [];
      const totalWeight = planList
        .reduce((pre: any, cur: any) => {
          return pre + (cur?.emissionRights ?? 0);
        }, 0)
        ?.toFixed(2);
      const totalMinusDischarge = planList
        .reduce((pre: any, cur: any) => {
          return pre + (cur?.emissionRights ?? 0) * ((cur?.emissionReductionRatio ?? 0) / 100);
        }, 0)
        ?.toFixed(2);
      const totalPreDischarge = planList
        .reduce((pre: any, cur: any) => {
          return pre + (cur?.estimatedEmission ?? 0);
        }, 0)
        ?.toFixed(2);
      form.setFieldsValue({
        totalMinusDischarge, // 总减排碳排放
        totalPreDischarge, // 总预计碳排放
        totalWeight, // 总碳排放权
      });
    }
  }, [fillingType, emissionRights, emissionReductionRatio, form, carbonEmissionMonthPlanVos]);

  // 月份选择
  const monthOptions = useMemo(() => {
    if (!startMonth || !fillingYear) return [];
    let month = startMonth;
    let selectedYear = dayjs(fillingYear).get('year');
    const res = [];
    let disabled = disabledMonth;
    // 释放focus的选项
    if (!isNil(currentMonth)) {
      disabled = disabled.filter(i => i !== currentMonth);
    }
    // 获取从起始月开始的12个月
    for (let i = 1; i <= 12; i++) {
      if (month <= 12) {
        res.push({
          label: `${selectedYear}年-${month}月`,
          value: `${selectedYear}-${month < 10 ? `0${month}` : month}-01`,
          disabled: disabled.includes(`${selectedYear}-${month < 10 ? `0${month}` : month}-01`),
        });
      } else {
        res.push({
          label: `${selectedYear + 1}年-${month - 12}月`,
          value: `${selectedYear + 1}-${month - 12 < 10 ? `0${month - 12}` : month - 12}-01`,
          disabled: disabled.includes(`${selectedYear + 1}-${month - 12 < 10 ? `0${month - 12}` : month - 12}-01`),
        });
      }
      month++;
    }
    return res;
  }, [startMonth, disabledMonth, currentMonth, fillingYear]);

  const accountUnitOptions = useMemo(() => {
    return (accountUnitList ?? []).map(i => ({ label: i.unitName, value: i.id }));
  }, [accountUnitList]);

  const onFinish = (v: FormProps) => {
    let body: CarbonYearDisChargePlanBody = {
      accountingUnitId: v.accountingUnitId,
      fillingYear: dayjs(v.fillingYear).get('year'),
      fillingType: v.fillingType,
      emissionRights: v.fillingType === FillingType.MONTH ? Number(v.totalWeight) : v.emissionRights,
      emissionReductionRatio: v.emissionReductionRatio,
      totalEmission: Number(v.totalPreDischarge),
      totalReductedEmission: Number(v.totalMinusDischarge),
      carbonEmissionMonthPlanVos: isNil(v.carbonEmissionMonthPlanVos) ? [] : v.carbonEmissionMonthPlanVos,
    };

    if (id && isEdit) {
      body = { ...body, id };
      Modal.warning({
        title: '编辑内容后，为保证数据准确性，对应的碳排放报告建议重新生成。',
        okText: '好的',
        onOk() {
          saveCarbonYearDisChargePlan(body).then(() => {
            navigate('/carbonManage/mainAccount/yearDisChargePlan');
          });
        },
      });
      return;
    }
    saveCarbonYearDisChargePlan(body).then(() => {
      navigate('/carbonManage/mainAccount/yearDisChargePlan');
    });
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className="page_wrapper">
      <FormTitle title="年度碳排放计划" />
      <Form
        // isOrigin
        form={form}
        initialValues={{
          fillingYear: dayjs(),
        }}
        labelAlign="left"
        onFinish={v => {
          onFinish(v);
        }}
      >
        <SubContent style={{ marginBottom: 0 }}>
          <Row>
            <Col span={24}>
              <Form.Item
                label="核算单元"
                name="accountingUnitId"
                rules={[{ required: true, message: '请选择核算单元' }]}
              >
                <Select
                  disabled={isDetail || isEdit}
                  options={accountUnitOptions}
                  placeholder="请选择"
                  onChange={() => {
                    disabledMonthMap.clear();
                    setDisabledMonth([]);
                    form.setFieldsValue({
                      carbonEmissionMonthPlanVos: undefined,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="财年" name="fillingYear" rules={[{ required: true, message: '请选择财年' }]}>
                <DatePicker
                  disabled={isDetail || isEdit}
                  style={{ width: '100%' }}
                  picker="year"
                  allowClear={false}
                  onChange={() => {
                    disabledMonthMap.clear();
                    setDisabledMonth([]);
                    form.setFieldsValue({
                      carbonEmissionMonthPlanVos: undefined,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="填报类型" name="fillingType" rules={[{ required: true, message: '请选择填报类型' }]}>
                <Radio.Group
                  onChange={() => {
                    form.setFieldsValue({
                      totalMinusDischarge: undefined,
                      totalPreDischarge: undefined,
                      totalWeight: undefined,
                    });
                  }}
                  disabled={isDetail || isEdit}
                >
                  <Radio value={FillingType.YEAR}>按财年</Radio>
                  <Radio value={FillingType.MONTH}>按月</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          {/* 按财年 */}
          {fillingType === FillingType.YEAR && (
            <div className={styles.ml128}>
              <Row gutter={20}>
                <Col span={3}>
                  <Form.Item className={styles.originItem} labelCol={{ span: 24 }} label="财年">
                    <ShowInput value={`${dayjs(fillingYear).format('YYYY')}`} />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    className={styles.originItem}
                    labelCol={{ span: 24 }}
                    label="碳排放权"
                    name="emissionRights"
                    rules={[{ required: true, message: '请输入碳排放权' }]}
                  >
                    <InputNumber
                      min={0}
                      max={999999999.99}
                      step="0.01"
                      precision={2}
                      addonAfter="t"
                      style={{ width: '100%' }}
                      disabled={isDetail}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    className={styles.originItem}
                    labelCol={{ span: 24 }}
                    label="减排比例"
                    name="emissionReductionRatio"
                    rules={[{ required: true, message: '请输入碳排放比例' }]}
                  >
                    <InputNumber
                      disabled={isDetail}
                      min={0}
                      max={100}
                      step="0.01"
                      precision={2}
                      addonAfter="%"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    className={styles.originItem}
                    labelCol={{ span: 24 }}
                    label="预计碳排放"
                    name="preYearDischarge"
                  >
                    <ShowInput addonAfter="t" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}
          {/* 按月 */}
          {fillingType === FillingType.MONTH && (
            <div className={styles.ml128}>
              <Form.List name="carbonEmissionMonthPlanVos" layout="vertical">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Row key={key} gutter={20}>
                          <Col span={3}>
                            <Form.Item
                              label={name === 0 ? '月份' : ''}
                              labelCol={name === 0 ? { span: 24 } : { span: 0 }}
                              {...restField}
                              name={[name, 'emissionMonth']}
                              rules={[{ required: true, message: '请选择月份' }]}
                            >
                              <Select
                                disabled={isDetail}
                                options={monthOptions}
                                onChange={v => {
                                  disabledMonthMap.set(key, v);
                                  setDisabledMonth(Array.from(disabledMonthMap.values()));
                                }}
                                onDropdownVisibleChange={open => {
                                  if (form.getFieldValue(['carbonEmissionMonthPlanVos', name, 'emissionMonth'])) {
                                    if (open) {
                                      setCurrentMonth(
                                        form.getFieldValue(['carbonEmissionMonthPlanVos', name, 'emissionMonth'])
                                      );
                                    } else {
                                      setCurrentMonth(undefined);
                                    }
                                  }
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item
                              label={name === 0 ? '碳排放权' : ''}
                              labelCol={name === 0 ? { span: 24 } : { span: 0 }}
                              {...restField}
                              name={[name, 'emissionRights']}
                              rules={[{ required: true, message: '请输入碳排放权' }]}
                            >
                              <InputNumber
                                disabled={isDetail}
                                style={{ width: '100%' }}
                                min={0}
                                max={999999999.99}
                                step="0.01"
                                precision={2}
                                addonAfter="t"
                                onChange={v => {
                                  const emissionReductionRatio = form.getFieldValue([
                                    'carbonEmissionMonthPlanVos',
                                    name,
                                    'emissionReductionRatio',
                                  ]);
                                  // 每月预计碳排放
                                  form.setFieldValue(
                                    ['carbonEmissionMonthPlanVos', name, 'estimatedEmission'],
                                    !isNil(v) && !isNil(emissionReductionRatio)
                                      ? Number(((v * (100 - emissionReductionRatio)) / 100).toFixed(2))
                                      : undefined
                                  );
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item
                              label={name === 0 ? '减排比例' : ''}
                              labelCol={name === 0 ? { span: 24 } : { span: 0 }}
                              {...restField}
                              name={[name, 'emissionReductionRatio']}
                              rules={[{ required: true, message: '请输入减排比例' }]}
                            >
                              <InputNumber
                                disabled={isDetail}
                                style={{ width: '100%' }}
                                min={0}
                                max={100}
                                step="0.01"
                                precision={2}
                                addonAfter="%"
                                onChange={v => {
                                  const emissionRights = form.getFieldValue([
                                    'carbonEmissionMonthPlanVos',
                                    name,
                                    'emissionRights',
                                  ]);
                                  // 每月预计碳排放
                                  form.setFieldValue(
                                    ['carbonEmissionMonthPlanVos', name, 'estimatedEmission'],
                                    !isNil(v) && !isNil(emissionRights)
                                      ? Number(((emissionRights * (100 - v)) / 100).toFixed(2))
                                      : undefined
                                  );
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item
                              label={name === 0 ? '预计碳排放' : ''}
                              labelCol={name === 0 ? { span: 24 } : { span: 0 }}
                              name={[name, 'estimatedEmission']}
                            >
                              <ShowInput addonAfter="t" />
                            </Form.Item>
                          </Col>
                          <Col span={1}>
                            {isDetail ? null : fields.length > 1 ? (
                              <Form.Item label={name === 0 ? ' ' : ''} labelCol={{ span: 24 }}>
                                <Button
                                  type="link"
                                  onClick={() => {
                                    remove(name);
                                    disabledMonthMap.delete(key);
                                    setDisabledMonth(Array.from(disabledMonthMap.values()));
                                  }}
                                >
                                  删除
                                </Button>
                              </Form.Item>
                            ) : null}
                          </Col>
                        </Row>
                      ))}
                      {isDetail ? null : fields.length < 12 ? (
                        <Row>
                          <Col span={14}>
                            <Form.Item>
                              <AntdButton
                                type="dashed"
                                style={{ width: '100' }}
                                onClick={() => add({})}
                                block
                                icon={<PlusOutlined />}
                              >
                                添加
                              </AntdButton>
                            </Form.Item>
                          </Col>
                        </Row>
                      ) : null}
                    </>
                  );
                }}
              </Form.List>
            </div>
          )}
          <div className={styles.ml128}>
            <Row gutter={20}>
              <Col span={3}>
                <Form.Item className={styles.originItem} labelCol={{ span: 24 }} label=" ">
                  <ShowInput value="合计" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item className={styles.originItem} labelCol={{ span: 24 }} label="碳排放权" name="totalWeight">
                  <ShowInput addonAfter="t" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  className={styles.originItem}
                  labelCol={{ span: 24 }}
                  label="减排碳排放"
                  name="totalMinusDischarge"
                >
                  <ShowInput addonAfter="t" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  className={styles.originItem}
                  labelCol={{ span: 24 }}
                  label="预计碳排放"
                  name="totalPreDischarge"
                >
                  <ShowInput addonAfter="t" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </SubContent>
        <Space className="sticky-footer">
          {isDetail ? (
            <Button
              onClick={() => {
                navigate('/carbonManage/mainAccount/yearDisChargePlan');
              }}
            >
              返回
            </Button>
          ) : (
            <Space size={8}>
              <Button htmlType="submit" type="primary">
                保存
              </Button>
              <Button
                onClick={() => {
                  navigate('/carbonManage/mainAccount/yearDisChargePlan');
                }}
              >
                取消
              </Button>
            </Space>
          )}
        </Space>
      </Form>
    </Wrapper>
  );
};

export default CarbonAddYearPlan;
