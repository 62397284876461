/**
 * 余额报警管理
 */

import { PageRequest, PageResponse } from './page';
import { fetch } from '@maxtropy/components';
import qs from 'qs';

// 分页查询余额报警
export interface BalanceAlarmListResponse {
  id: number; // 主键id
  code: string; // 规则编号
  saleClientName: string; // 所属客户
  saleClientAccountName: string; // 客户账户
  name: string; // 报警规则名称
  ouName: string; // 所属运营单元
  status: BalanceAlarmStatusType; // 状态
  updateTime: string; // 最后操作时间
  updateByUserId: string; // 最后操作人
}

// 分页模糊查询请求参数
export interface BalanceAlarmListPageSearchRequest extends PageRequest {
  code?: string; // 报警规则编号
  name?: string; // 报警规则名称
  saleClientAccountName?: string; // 客户账户名称
  status?: BalanceAlarmStatusType; // 状态
  ouId?: string; // 所属运营单元
  saleClientId?: string; // 所属客户id
}

// 查看详情
export interface BalanceAlarmDetails extends BalanceAlarmListResponse {
  balanceSceneInfo: any;
  saleClientAccountCode: string;
  ouId: number;
  alarmType: BalanceAlarmalarmTypeType; // 报警类型
  saleClientAccountId: number; // 销售客户账户id
  saleClientId: number; // 销售客户id
  pushChannelIntervalInfoList: PushChannelIntervalInfoObject[];
  ruleBalanceInsufFixed: {
    threshold: number; // 报警阈值
  };
}

// 批量创建余额报警规则
export interface CreateBalanceAlarmRequest {
  name: string; // 报警规则名称
  ouId: number; // 运营单元
  alarmType: BalanceAlarmalarmTypeType; // 报警类型
  pushChannelIntervalInfoList: PushChannelIntervalInfoObject[]; // 触发间隔
  sceneType: number; // 场景类型
  triggerType: number; // 触发类型
  balanceSceneInfo: {
    saleClientAccountIds?: number[]; // 客户账户
    saleClientAccountId?: number;
    ruleBalanceInsufFixed: {
      threshold: number; // 报警阈值
    };
  };
}

export interface PushChannelIntervalInfoObject {
  intervalType: IntervalType; //触发间隔类型
  pushChannel: PushChannelType; // 触发类型枚举
  intervalHours: number; // 触发间隔（小时）
}

export enum PushChannelType {
  WEB_SOCKET = 1, // websocket平台推送
  SMS = 2, // sms阿里短信
  VOICE = 3, // voice阿里电话
}

// 余额报警类型
export enum BalanceAlarmalarmTypeType {
  BalanceLow = 1, // 余额不足某个定值
  DemandBalanceLowLow = 2, // 最大需量预估余额不足
  DynamicEstimationHistoricalElectricityLow = 3, // 动态估算历史电费余额不足
  CapacityBalanceLow = 4, // 容量余额不足
  EstimateBalanceLow = 6, // 暂估余额不足
}

// 余额报警管理状态
export enum BalanceAlarmStatusType {
  Disable = 0, // 禁用
  Enable = 1, // 启用
}

// 余额报警类型的值
export const BalanceAlarmalarmTypeTypeValue = {
  [BalanceAlarmalarmTypeType.BalanceLow]: '余额不足某个定值',
  [BalanceAlarmalarmTypeType.DemandBalanceLowLow]: '最大需量预估余额不足',
  [BalanceAlarmalarmTypeType.DynamicEstimationHistoricalElectricityLow]: '动态估算历史电费余额不足',
  [BalanceAlarmalarmTypeType.CapacityBalanceLow]: '容量余额不足',
  [BalanceAlarmalarmTypeType.EstimateBalanceLow]: '暂估余额不足某个定值',
};

// 余额报警管理状态
export const BalanceAlarmStatusTypeValue = {
  [BalanceAlarmStatusType.Disable]: '禁用',
  [BalanceAlarmStatusType.Enable]: '启用',
};

// 触发间隔类型
export enum IntervalType {
  ONCE = 1, // 通知一次
  HOUR = 2, // 按小时
  DAY = 3, // 按天
}

// 触发间隔类型的值
export const IntervalTypeValue = {
  [IntervalType.ONCE]: '通知一次',
  [IntervalType.HOUR]: '按小时',
  [IntervalType.DAY]: '按天',
};

// 余额报警管理分页查询
export function getBalanceAlarmList(params: BalanceAlarmListPageSearchRequest) {
  return fetch<PageResponse<BalanceAlarmListResponse>>(
    `/api/server-alarm/rule/page?${qs.stringify({ sceneType: 1, ...params }, { indices: false })}`
  );
  // return fetch<PageResponse<BalanceAlarmListResponse>>(`http://10.50.16.219:40001/mock/182/api/server-alarm/rule/balance/page?${qs.stringify(params, { indices: false })}`)
}

// 删除余额报警规则
export function deleteBalanceAlarmRecord(id: number): Promise<boolean> {
  return fetch(`/api/server-alarm/rule/${id}`, {
    // return fetch(`http://10.50.16.219:40001/mock/182/api/server-alarm/rule/${id}`, {
    method: 'DELETE',
  });
}

// 根据余额报警规则id进行状态变更
export function updateBalanceAlarmStatus(
  serverAlarmRuleId: number,
  params: { status: BalanceAlarmStatusType }
): Promise<void> {
  return fetch(`/api/server-alarm/rule/${serverAlarmRuleId}/status?${qs.stringify(params, { indices: false })}`, {
    // return fetch(`http://10.50.16.219:40001/mock/182/api/server-alarm/rule/balance/${serverAlarmRuleId}?${qs.stringify(params, { indices: false })}`, {
    method: 'PUT',
  });
}

// 批量新增余额报警规则
export function batchBalanceAlarm(params: CreateBalanceAlarmRequest): Promise<void> {
  return fetch(`/api/server-alarm/rule/batch`, {
    // return fetch(`http://10.50.16.219:40001/mock/182/api/server-alarm/rule/batch`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

// 新增余额报警规则
export function createBalanceAlarm(params: CreateBalanceAlarmRequest): Promise<void> {
  // return fetch(`/api/server-alarm/rule/balance`, {
  return fetch(`http://10.50.16.219:40001/mock/182/api/server-alarm/rule/balance`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

// 查看余额报警详情
export function getBalanceAlarmById(serverAlarmRuleId: string): Promise<BalanceAlarmDetails> {
  return fetch(`/api/server-alarm/rule/${serverAlarmRuleId}`);
  // return fetch(`http://10.50.16.219:40001/mock/182/api/server-alarm/rule/${serverAlarmRuleId}`)
}

// 编辑余额报警规则
export function updateBalanceAlarmRecord(serverAlarmRuleId: number, params: CreateBalanceAlarmRequest): Promise<void> {
  return fetch(`/api/server-alarm/rule/${serverAlarmRuleId}`, {
    // return fetch(`http://10.50.16.219:40001/mock/182/api/server-alarm/rule/${serverAlarmRuleId}`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });
}
