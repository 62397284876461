import { Card, Divider } from 'antd';
import { Form, Tag } from '@maxtropy/components';
import React, { FC } from 'react';
import ShowInput from '@/shared/components/ShowInput';
import styles from './index.module.scss';
import { V2UetEmtProcessDetailPostResponse } from '@maxtropy/device-customer-apis-v2';

export interface IViewEachProcessTabContent {
  uetEMTProcessDetail?: V2UetEmtProcessDetailPostResponse;
  currentKey?: string;
}

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const ViewEachProcessTabContent: FC<IViewEachProcessTabContent> = props => {
  const { uetEMTProcessDetail, currentKey } = props;

  return (
    <>
      {currentKey === '2' &&
        uetEMTProcessDetail?.processEntryVos?.map(i => (
          <Card
            key={i.id}
            bodyStyle={{ borderRadius: 4, padding: 0 }}
            style={{ marginBottom: 16, border: '1px solid rgba(255,255,255,0.12)', background: 'transparent' }}
          >
            <div style={{ width: '100%', height: 40, backgroundColor: 'var(--mx-base-bg-color)' }}>
              <div style={{ fontSize: 14, fontWeight: 700, padding: '8px 16px' }}>{i.name}</div>
            </div>
            <Divider style={{ margin: 0 }} className={styles.DividerStyle} />
            <Form className={styles.FormCardItemStyle} layout="horizontal" {...formLayout}>
              <Form.Item label="能源介质" style={{ paddingLeft: 16 }}>
                <ShowInput value={i.energyMediumName} />
              </Form.Item>
              <Divider style={{ margin: 0 }} className={styles.DividerStyle} />
              <Form.Item label="引自" style={{ paddingLeft: 16 }}>
                <ShowInput value={i.refName} />
              </Form.Item>
              <Divider style={{ margin: 0 }} className={styles.DividerStyle} />
              <Form.Item label="计量设备" style={{ paddingLeft: 16 }}>
                <ShowInput
                  value={i.deviceOuVos?.map(i => (
                    <Tag key={i.id} style={{ margin: 2 }} border="solid" color="default">
                      {i.name}
                    </Tag>
                  ))}
                />
              </Form.Item>
            </Form>
          </Card>
        ))}
      {currentKey === '3' &&
        uetEMTProcessDetail?.processExitVos?.map(i => (
          <Card
            key={i.id}
            bodyStyle={{ borderRadius: 4, padding: 0 }}
            style={{ marginBottom: 16, border: '1px solid rgba(255,255,255,0.12)', background: 'transparent' }}
          >
            <div style={{ width: '100%', height: 40, backgroundColor: 'var(--mx-base-bg-color)' }}>
              <div style={{ fontSize: 14, fontWeight: 700, padding: '8px 16px' }}>{i.name}</div>
            </div>
            <Divider style={{ margin: 0 }} className={styles.DividerStyle} />
            <Form className={styles.FormCardItemStyle} layout="horizontal" {...formLayout}>
              <Form.Item label="能源介质" style={{ paddingLeft: 16 }}>
                <ShowInput value={i.energyMediumName} />
              </Form.Item>
              <Divider style={{ margin: 0 }} className={styles.DividerStyle} />
              <Form.Item label="计量设备" style={{ paddingLeft: 16 }}>
                <ShowInput
                  value={i.deviceOuVos?.map(i => (
                    <Tag style={{ margin: 2 }} border="solid" color="default">
                      {i.name}
                    </Tag>
                  ))}
                />
              </Form.Item>
            </Form>
          </Card>
        ))}
      {currentKey === '4' && (
        <Card
          bodyStyle={{ borderRadius: 4, padding: 0 }}
          style={{ marginBottom: 16, border: '1px solid rgba(255,255,255,0.12)', background: 'transparent' }}
        >
          <Form className={styles.FormCardItemStyle} layout="horizontal" {...formLayout}>
            <Form.Item label="能源介质" style={{ paddingLeft: 16 }}>
              <ShowInput value={uetEMTProcessDetail?.processNodeVo?.energyMediumName} />
            </Form.Item>
            <Divider style={{ margin: 0 }} className={styles.DividerStyle} />
            <Form.Item label="计量设备" style={{ paddingLeft: 16 }}>
              <ShowInput
                value={uetEMTProcessDetail?.processNodeVo?.deviceOuVos?.map(i => (
                  <Tag style={{ margin: 2 }} border="solid" color="default">
                    {i.name}
                  </Tag>
                ))}
              />
            </Form.Item>
          </Form>
        </Card>
      )}
    </>
  );
};

export default ViewEachProcessTabContent;
