import {
  V2StationSignboardStationListPostResponse,
  V2StationSignboardTopologyDataPostResponse,
} from '@maxtropy/device-customer-apis-v2';

// 机器类型
export enum MachineType {
  AIRCOMPRESSOR = 1,
  NGENERATOR = 2,
}

export const MachineTypeDisplay = {
  [MachineType.AIRCOMPRESSOR]: '空压站看板',
  [MachineType.NGENERATOR]: '制氮站看板',
};

// 指标类型
export enum IndexType {
  TOTAL = 1,
  NODE = 2,
}

// 介质颜色
export const colorCategory: Record<string, string> = {
  '2030': '#59DBFF',
  '2021': '#2CA02C',
  '2020': '#FFCB47',
  '2001': '#FF477B',
  '2022': '#59DBFF',
  '2023': '#2CA02C',
  '2024': '#FFCB47',
  '2011': '#FF477B',
};

export enum IndicatorKey {
  Status = 'Status',
  PowerConsumption = 'PowerConsumption',
  PowerToElectricityRatio = 'PowerToElectricityRatio',
  AirCompressorExhaustPressure = 'AirCompressorExhaustPressure',
  NitrogenGeneratorNitrogenPurity = 'NitrogenGeneratorNitrogenPurity',
  OutputFlow = 'OutputFlow',
  AirTankPressure = 'AirTankPressure',
  StationExitPressure = 'StationExitPressure',
  StationExitFlow = 'StationExitFlow',
  ThermometerTemperature = 'ThermometerTemperature',

  CD_STATUS = 'CD_STATUS',
  CD_POWER_CONSUMPTION = 'CD_POWER_CONSUMPTION',
  CD_RATIO = 'CD_RATIO',
  CD_GAS_CAPACITY = 'CD_GAS_CAPACITY',
  CD_DEW_TEMPERATURE = 'CD_DEW_TEMPERATURE',
  CD_WORKING_PRESSURE = 'CD_WORKING_PRESSURE',
  CD_INTAKE_AIR_TEMPERATURE = 'CD_INTAKE_AIR_TEMPERATURE',
  CD_POWER = 'CD_POWER',
  FM_INST_FLOW = 'FM_INST_FLOW',
  FM_CUR_FLOW = 'FM_CUR_FLOW',
  PG_PRESSURE = 'PG_PRESSURE',
}

export const HoverKey = [
  IndicatorKey.PowerConsumption,
  IndicatorKey.PowerToElectricityRatio,
  IndicatorKey.CD_POWER_CONSUMPTION,
  IndicatorKey.CD_RATIO,
  IndicatorKey.CD_GAS_CAPACITY,
  IndicatorKey.CD_DEW_TEMPERATURE,
  IndicatorKey.CD_WORKING_PRESSURE,
];

export enum NodeStatus {
  RUNNING = 0,
  OFFLINE = 1,
  SHUT = 2,
}

export const IndicatorKeyName = {
  [IndicatorKey.Status]: '状态',
  [IndicatorKey.NitrogenGeneratorNitrogenPurity]: '氮气纯度',
  [IndicatorKey.PowerConsumption]: '当日用电量',
  [IndicatorKey.PowerToElectricityRatio]: '当日气电比',
  [IndicatorKey.AirCompressorExhaustPressure]: '排气压力',
  [IndicatorKey.OutputFlow]: '输出流量',
  [IndicatorKey.AirTankPressure]: '压力',
  [IndicatorKey.StationExitPressure]: '出口压力',
  [IndicatorKey.StationExitFlow]: '出口流量',
  [IndicatorKey.ThermometerTemperature]: '温度',
  [IndicatorKey.CD_STATUS]: '状态',
  [IndicatorKey.CD_POWER_CONSUMPTION]: '用电量',
  [IndicatorKey.CD_RATIO]: '能效比',
  [IndicatorKey.CD_GAS_CAPACITY]: '气体处理量',
  [IndicatorKey.CD_DEW_TEMPERATURE]: '露点温度',
  [IndicatorKey.CD_WORKING_PRESSURE]: '工作压力',
  [IndicatorKey.CD_INTAKE_AIR_TEMPERATURE]: '进气温度',
  [IndicatorKey.CD_POWER]: '功率',
  [IndicatorKey.FM_INST_FLOW]: '瞬时流量',
  [IndicatorKey.FM_CUR_FLOW]: '累积流量',
  [IndicatorKey.PG_PRESSURE]: '压力',
};

export type StationItem = Exclude<V2StationSignboardStationListPostResponse['list'], undefined>[number];
export type TopoNodeItem = Exclude<V2StationSignboardTopologyDataPostResponse['list'], undefined>[number];
export type PipeNodeItem = {
  id?: number;
  name?: string;
  entryCostVos?: {
    id?: number;
    type?: number;
    sum?: number;
    indicatorId?: number;
    energyMediumId?: number;
    hasDevice?: boolean;
    energyName?: string;
    name?: string;
    unit?: string;
  }[];
  exitCostVos?: {
    id?: number;
    type?: number;
    sum?: number;
    hasDevice?: boolean;
    indicatorId?: number;
    energyMediumId?: number;
    energyName?: string;
    name?: string;
    unit?: string;
  }[];
  info?: {
    type?: number;
    permissions?: number;
    pictureKey?: string;
  };
};

export type PipeEdgeItem = {
  sourceId?: number;
  targetId?: number;
  entryId?: number;
  exitId?: number;
};

export type LinkItem = {
  nodeId?: string;
  targetId?: number;
  processId?: number;
  sum?: number;
  indicatorId?: number;
  energyMediumId?: number;
  energyName?: string;
  name?: string;
  unit?: string;
};

export interface AlarmNode {
  nodeId: number;
  nodeType: number;
}

export const NodeStatusDisplay = {
  [NodeStatus.RUNNING]: '运行',
  [NodeStatus.OFFLINE]: '停机',
  [NodeStatus.SHUT]: '关机',
};

export const NodeStatusTag = {
  [NodeStatus.RUNNING]: 'success',
  [NodeStatus.OFFLINE]: 'warning',
  [NodeStatus.SHUT]: 'invalid',
};

export enum NodeType {
  COMPRESSOR = 1,
  NITROGEN_GENERATOR,
  GAS_TANK,
  STATION_OUTLET,
  GAS_MANIFOLD,
  THERMOMETER,
}
