import React, { MutableRefObject, useCallback, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import OUModal, { OUModalRef } from './OUModal';
import styles from './index.module.scss';
import { useQuery } from '../../utils/utils';
import { getOUList } from '../../api/options';
import { OuStaffType } from '../../const';
import { Button, Modal, Tag } from '@maxtropy/components';

interface MultiModalSelectProps {
  onChange?: (val: Array<number> | undefined) => void;
  value?: Array<number> | undefined;
  mcid: string | undefined;
}

const MultiModalSelect: React.FC<MultiModalSelectProps> = ({ mcid, onChange, value }) => {
  const ouRef: MutableRefObject<OUModalRef | null> = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { data } = useQuery(
    useCallback(() => {
      if (mcid && value?.length) {
        return getOUList({ ids: value }).then(res => {
          return res;
        });
      }
      return Promise.resolve(undefined);
    }, [mcid, value])
  );

  const onShowModal = () => {
    setIsModalVisible(true);
  };

  const onHideModal = () => {
    setIsModalVisible(false);
  };

  const onClose = (e: React.MouseEvent<HTMLElement>, ouId: number) => {
    e.preventDefault();
    Modal.confirm({
      title: '确定移除运营单元',
      okText: '确定',
      onOk: async () => {
        const _ouIds = value?.filter(id => id !== ouId);
        onChange!(_ouIds);
      },
    });
  };

  const onOk = () => {
    onHideModal();
    const _ouIds: Array<number> | undefined = ouRef.current?.selectedRowKeys;
    onChange!(_ouIds);
  };

  return (
    <>
      <Button type="dashed" ghost icon={<PlusOutlined />} size="small" onClick={onShowModal}>
        {value?.length ? '继续选择' : '请选择'}
      </Button>
      <div className={styles.value}>
        {value?.map(ouId => {
          const userType = data?.find(item => item.id === ouId)?.userType;
          return (
            <Tag
              key={ouId}
              closable={
                userType !== null ? (userType === undefined ? true : userType === OuStaffType.ADMINISTRATOR) : false
              }
              className={styles.tag}
              onClose={e => onClose(e, ouId)}
            >
              {data?.find(item => item.id === ouId)?.name}
            </Tag>
          );
        })}
      </div>
      <Modal size="big" title="运营单元选择" open={isModalVisible} destroyOnClose onOk={onOk} onCancel={onHideModal}>
        <OUModal mcid={mcid} ouIds={value} ref={ouRef} />
      </Modal>
    </>
  );
};

export default MultiModalSelect;
