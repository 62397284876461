import { Form, Wrapper, useBreadcrumbRoutes, Button, FormTitle, SubContent } from '@maxtropy/components';
import { FC, useEffect, useState } from 'react';
import { Row, Col, Space } from 'antd';

import styles from './index.module.scss';
import ShowInput from '@/shared/components/ShowInput';

import { AlarmLevelDisplay } from '@/shared/types';
import { useNavigate, useParams } from 'react-router-dom';
import { getAlarmPush, AlarmPush } from '../../../api/deviceAlarmPushStrategy';

import SelectDevices from './SelectDevices';
import SelectPushStaff from '../../../components/SelectPushStaff';
import SelectPushOutSider from '../../../components/SelectPushOutsider';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const routes = [{ name: `查看设备推送` }];

const AlarmPushDetail: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [data, setData] = useState<AlarmPush>();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    if (id) {
      getAlarmPush(id).then(res => {
        setData(res);
      });
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data?.name,
        ouId: data.ouName,
        levels: data.levels.map(i => AlarmLevelDisplay[i]).join('、'),
        pushCenterStaffLinkVoList: data.pushCenterStaffLinkVoList ?? [],
        deviceAlarmPushDeviceVoList: data.deviceAlarmPushDeviceVoList ?? [],
        outsiders: data.outsiders ?? [],
      });
    }
  }, [data, form]);

  const goList = () => {
    navigate(`/device/alarm/push`);
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title={routes[0].name} />
      <Form form={form} layout="vertical" {...formLayout}>
        <SubContent className="mb-8">
          <Row>
            <Col span={8} className={styles.col}>
              <Form.Item name="name" label="推送名称">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item name="ouId" label="运营单元">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item name="levels" label="推送报警等级">
                <ShowInput />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <SubContent title="监管设备" className="mb-8">
          <Row>
            <Col span={24}>
              <Form.Item name="deviceAlarmPushDeviceVoList" wrapperCol={{ span: 24 }}>
                <SelectDevices disabled />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <SubContent title="内部推送人员" className="mb-8">
          <Row>
            <Col span={24}>
              <Form.Item name="pushCenterStaffLinkVoList" wrapperCol={{ span: 24 }}>
                <SelectPushStaff disabled />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <SubContent title="外部推送人员" className="mb-8">
          <Row>
            <Col span={24}>
              <Form.Item className={styles.form_content} name="outsiders" wrapperCol={{ span: 24 }}>
                <SelectPushOutSider disabled />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <Space className="sticky-footer-c">
          <Button className={styles.button} onClick={goList}>
            返回
          </Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default AlarmPushDetail;
