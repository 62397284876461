import {
  SubContent,
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  Form,
  Input,
  Select,
  Radio,
  InputNumber,
  FormTitle,
} from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ShowInput from '@/shared/components/ShowInput';
import {
  createSaleClientAccount,
  CreditLimitType,
  CreditLimitTypeDisplay,
  getSaleClientAccount,
  getSaleClientList,
  SaleClientAccountAddRequest,
  SaleClientAccountInfo,
  SaleClientInfo,
  updateSaleClientAccount,
} from '../../../api/sale';
import { formLayout } from '../util';

import styles from './index.module.scss';

interface CreateClientAccountProps {
  isEdit?: boolean;
}

const CreateClientAccount: FC<CreateClientAccountProps> = ({ isEdit = false }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { id } = useParams<{ id?: string }>();

  const [clientData, setClientData] = useState<SaleClientInfo[]>([]);

  const [data, setData] = useState<SaleClientAccountInfo>();
  const [loading, setLoading] = useState<boolean>(false);
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    if (!id) {
      getSaleClientList().then(setClientData);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      getSaleClientAccount(id).then(setData);
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      const value: Partial<SaleClientAccountInfo> = {
        name: data.name,
        creditLimitType: data.creditLimitType,
        creditLimitAmount: data.creditLimitAmount,
        description: data.description,
      };

      if (id) {
        value['saleClientName'] = data.saleClientName;
        value['companyName'] = data.companyName;
        value['uscc'] = data.uscc;
        value['ouName'] = data.ouName;
      } else {
        value['saleClientId'] = data.saleClientId;
      }

      form.setFieldsValue(value);
    }
  }, [id, data, form]);

  const clientDataOptions = useMemo(() => {
    if (clientData.length !== 0) {
      return clientData.map(i => ({ label: `${i.name} ${i.code}`, value: i.id }));
    }
  }, [clientData]);

  const routes = useMemo(() => {
    return [{ name: `${isEdit ? '编辑' : '新建'}账户` }];
  }, [isEdit]);

  const onFinish = (val: SaleClientAccountAddRequest) => {
    setLoading(true);
    if (id) {
      updateSaleClientAccount(id, val).then(() => {
        setLoading(false);
        goList();
      });
    } else {
      createSaleClientAccount(val).then(() => {
        setLoading(false);
        goList();
      });
    }
  };

  const goList = () => {
    navigate('/operation/client-related/client-account');
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <Form layout="vertical" form={form} {...formLayout} onFinish={onFinish}>
        <FormTitle title={routes[0].name} />
        <SubContent className="mb-8">
          <Row>
            <Col span={8} className={styles.col}>
              <Form.Item
                name="name"
                label="账户名称"
                rules={[
                  { required: true, message: '请输入账户名称' },
                  { max: 20, message: '最多输入二十个字' },
                ]}
              >
                <Input placeholder="请输入" maxLength={20} />
              </Form.Item>
            </Col>
            {id === undefined ? (
              <Col span={8} className={styles.col}>
                <Form.Item name="saleClientId" rules={[{ required: true, message: '请选择' }]} label="所属客户">
                  <Select placeholder="请选择" options={clientDataOptions} showSearch optionFilterProp="label" />
                </Form.Item>
              </Col>
            ) : (
              <>
                <Col span={8} className={styles.col}>
                  <Form.Item name="saleClientName" label="所属客户">
                    <ShowInput />
                  </Form.Item>
                </Col>
                <Col span={8} className={styles.col}>
                  <Form.Item name="companyName" label="企业名称">
                    <ShowInput />
                  </Form.Item>
                </Col>
                <Col span={8} className={styles.col}>
                  <Form.Item name="uscc" label="统一信用代码">
                    <ShowInput />
                  </Form.Item>
                </Col>
                <Col span={8} className={styles.col}>
                  <Form.Item name="ouName" label="所属运营单元">
                    <ShowInput />
                  </Form.Item>
                </Col>
              </>
            )}

            <Col span={8} className={styles.col}>
              <Form.Item
                name="creditLimitType"
                label="信用额度类型"
                rules={[{ required: true, message: '请选择信用额度类型' }]}
              >
                <Radio.Group>
                  <Radio value={CreditLimitType.NONE}>{CreditLimitTypeDisplay[CreditLimitType.NONE]}</Radio>
                  <Radio value={CreditLimitType.LIMITED}>{CreditLimitTypeDisplay[CreditLimitType.LIMITED]}</Radio>
                  <Radio value={CreditLimitType.UNLIMITED}>{CreditLimitTypeDisplay[CreditLimitType.UNLIMITED]}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Form.Item noStyle dependencies={['creditLimitType']}>
              {({ getFieldValue }) => {
                return (
                  getFieldValue('creditLimitType') === CreditLimitType.LIMITED && (
                    <Col span={8} className={styles.col}>
                      <Form.Item
                        name="creditLimitAmount"
                        label="信用额度"
                        rules={[{ required: true, message: '请输入信用额度' }]}
                      >
                        <InputNumber min={0} precision={2} addonAfter="￥" placeholder="请输入" />
                      </Form.Item>
                    </Col>
                  )
                );
              }}
            </Form.Item>
            <Col span={8} className={styles.col}>
              <Form.Item name="description" label="备注">
                <Input.TextArea maxLength={50} placeholder="请输入" />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <Space size={8} className="sticky-footer-c">
          <Button loading={loading} htmlType="submit" type="primary">
            保存
          </Button>
          <Button onClick={goList}>取消</Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default CreateClientAccount;
