import {
  Form,
  Input,
  Select,
  Modal,
  Wrapper,
  useBreadcrumbRoutes,
  Button,
  useAsync,
  FormTitle,
  SubContent,
} from '@maxtropy/components';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col, Space } from 'antd';
import styles from './index.module.scss';
import ShowInput from '@/shared/components/ShowInput';
import { getOuListMember } from '../../../api/alarm';

import { AlarmLevel, AlarmLevelDisplay } from '@/shared/types';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getAlarmPush,
  AlarmPush,
  createAlarmPush,
  updateAlarmPush,
  PushCenterStaffLinkVoList,
  CreateAlarmPushRequest,
  UpdateAlarmPushRequest,
  OutsiderResponse,
} from '../../../api/deviceAlarmPushStrategy';

import SelectDevices from './SelectDevices';
import SelectPushStaff from '../../../components/SelectPushStaff';
import SelectPushOutSider from '../../../components/SelectPushOutsider';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

function alarmLevelOptions() {
  const level: Array<{ label: string; value: AlarmLevel }> = [
    {
      label: AlarmLevelDisplay[AlarmLevel.HIGHEST],
      value: AlarmLevel.HIGHEST,
    },
    {
      label: AlarmLevelDisplay[AlarmLevel.HIGH],
      value: AlarmLevel.HIGH,
    },
    {
      label: AlarmLevelDisplay[AlarmLevel.MIDDLE],
      value: AlarmLevel.MIDDLE,
    },
    {
      label: AlarmLevelDisplay[AlarmLevel.LOW],
      value: AlarmLevel.LOW,
    },
    {
      label: AlarmLevelDisplay[AlarmLevel.LOWEST],
      value: AlarmLevel.LOWEST,
    },
  ];
  return level.map(i => ({ label: i.label, value: i.value }));
}

const CreateAlarmPush: FC<{ isEdit?: boolean }> = ({ isEdit = false }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [data, setData] = useState<AlarmPush>();

  const [pushCenterStaffLinkVoList, setPushCenterStaffLinkVoList] = useState<PushCenterStaffLinkVoList[]>([]);
  const [outsiders, setOutsiders] = useState<OutsiderResponse[]>([]);
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (id) {
      getAlarmPush(id).then(res => {
        setData(res);
        setPushCenterStaffLinkVoList(res.pushCenterStaffLinkVoList ?? []);
        setOutsiders(res.outsiders ?? []);
      });
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data?.name,
        rootMcid: data.tenantName,
        ouId: data.ouName,
        levels: data.levels,
        pushCenterStaffLinkVoList: data.pushCenterStaffLinkVoList ?? [],
        deviceAlarmPushDeviceVoList: data.deviceAlarmPushDeviceVoList ?? [],
        outsiders: data.outsiders ?? [],
      });
    }
  }, [data, form]);

  const [ouId, setOuId] = useState<number>();

  const ou = useAsync(getOuListMember, []);

  useEffect(() => {
    // 重新获取ou
    if (ouId) {
      // 清空部分Form
      clearFormData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ouId, form]);

  const clearFormData = () => {
    form.setFieldsValue({
      pushCenterStaffLinkVoList: [],
      deviceAlarmPushDeviceVoList: [],
    });
    setPushCenterStaffLinkVoList([]);
  };

  const routes = useMemo(() => {
    return [{ name: `${isEdit ? '编辑' : '新建'}设备推送` }];
  }, [isEdit]);

  const goList = () => {
    navigate(`/device/alarm/push`);
  };

  const onCacel = () => {
    Modal.confirm({
      title: <div>是否放弃所有未保存信息并返回列表？</div>,
      onOk: goList,
    });
  };

  const onFinish = (value: AlarmPush) => {
    setSubmitting(true);
    if (isEdit && id) {
      const currentDevicesId = (value.deviceAlarmPushDeviceVoList ?? []).map(i => i.deviceId);
      const devicesIds = (data?.deviceAlarmPushDeviceVoList ?? []).map(i => i.deviceId);
      const addDeviceIdList = currentDevicesId.filter(i => !devicesIds.includes(i));
      const deleteDeviceIdList = devicesIds.filter(i => !currentDevicesId.includes(i));
      const newValue: UpdateAlarmPushRequest = {
        pushId: data!.id,
        name: value.name,
        levels: value.levels,
        addDeviceIdList,
        deleteDeviceIdList,
        oldPushCenterStaffLinkRequestList: (data?.pushCenterStaffLinkVoList ?? []).map(i => ({
          staffId: i.staffId,
          thirdPartyIdList: i.thirdPartyAppId ?? [],
        })),
        newPushCenterStaffLinkRequestList: (value.pushCenterStaffLinkVoList ?? []).map(i => ({
          staffId: i.staffId,
          thirdPartyIdList: i.thirdPartyAppId ?? [],
        })),
        oldOutsidersIds: (data?.outsiders ?? []).map(i => i.id),
        newOutsiders: (value.outsiders ?? []).map(i => ({
          contactName: i.contactName,
          contactPhone: i.contactPhone,
          thirdPartyIdList: i.thirdPartyIdList ?? [],
        })),
      };
      updateAlarmPush(newValue)
        .then(res => {
          if (res) {
            goList();
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      const newValue: CreateAlarmPushRequest = {
        name: value.name,
        ouId: value.ouId,
        levels: value.levels,
        pushCenterStaffLinkRequestList: (value.pushCenterStaffLinkVoList ?? []).map(i => ({
          staffId: i.staffId,
          thirdPartyIdList: i.thirdPartyAppId ?? [],
        })),
        deviceIdList: (value.deviceAlarmPushDeviceVoList ?? []).map(i => i.deviceId),
        outsidersList: (value.outsiders ?? []).map(i => ({
          contactName: i.contactName,
          contactPhone: i.contactPhone,
          thirdPartyIdList: i.thirdPartyIdList ?? [],
        })),
      };
      createAlarmPush(newValue)
        .then(res => {
          if (res) {
            goList();
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const pushStaffValidator = useCallback(
    (value: string[]) => {
      return outsiders.map(i => i.contactPhone).some(i => value.includes(i));
    },
    [outsiders]
  );

  const outSiderValidator = useCallback(
    (value: string[]) => {
      return pushCenterStaffLinkVoList.map(i => i.phone).some(i => value.includes(i));
    },
    [pushCenterStaffLinkVoList]
  );

  const ouOptions = useMemo(() => {
    if (ou.length !== 0) {
      return ou.map(i => ({ label: i.name, value: i.id }));
    }
  }, [ou]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title={'新建设备推送'}></FormTitle>
      <Form form={form} layout="vertical" {...formLayout} onFinish={onFinish}>
        <SubContent className="mb-8">
          <Row>
            <Col span={8}>
              <Form.Item
                name="name"
                label="推送名称"
                rules={[
                  { required: true, message: '请输入推送名称' },
                  { max: 20, message: '最多输入二十个字' },
                ]}
              >
                <Input placeholder="请输入推送名称" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="ouId" label="运营单元" rules={[{ required: true, message: '请选择运营单元' }]}>
                {isEdit ? (
                  <ShowInput />
                ) : (
                  <Select
                    style={{ width: '100%' }}
                    placeholder="请选择"
                    onChange={(v: number) => {
                      setOuId(v);
                    }}
                    options={ouOptions}
                    showSearch
                    optionFilterProp="label"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="levels" label="推送报警等级" rules={[{ required: true, message: '请选择推送报警等级' }]}>
                <Select style={{ width: '100%' }} mode="multiple" placeholder="请选择" options={alarmLevelOptions()} />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>

        <SubContent title="监管设备" className="mb-8">
          <Row>
            <Col span={24}>
              <Form.Item name="deviceAlarmPushDeviceVoList" wrapperCol={{ span: 24 }}>
                <SelectDevices ouId={ouId ?? data?.ouId} />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>

        <SubContent title="内部推送人员" className="mb-8">
          <Row>
            <Col span={24}>
              <Form.Item name="pushCenterStaffLinkVoList" wrapperCol={{ span: 24 }}>
                <SelectPushStaff
                  onChange={setPushCenterStaffLinkVoList}
                  ouId={ouId ?? data?.ouId}
                  validator={pushStaffValidator}
                />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>

        <SubContent title="外部推送人员" className="mb-8">
          <Row>
            <Col span={24}>
              <Form.Item name="outsiders" wrapperCol={{ span: 24 }}>
                <SelectPushOutSider onChange={setOutsiders} validator={outSiderValidator} />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>

        <Space size={8} className="sticky-footer" style={{ zIndex: 99, paddingLeft: 32 }}>
          <Button type="primary" htmlType="submit" loading={submitting}>
            保存
          </Button>
          <Button className={styles.button} onClick={onCacel}>
            取消
          </Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default CreateAlarmPush;
