import { EnergyUnitTreeItem, getEnergyUnitTree } from '@/api/energyUnitMapping';
import {
  Button,
  EllipsisSpan,
  Input,
  Select,
  Table,
  useBreadcrumbRoutes,
  Form,
  Wrapper,
  FormTitle,
  SubContent,
  Radio,
  TreeSelect,
  TimePicker,
  Modal,
} from '@maxtropy/components';
import { AlarmLevel, AlarmLevelDisplay } from '@/shared/types';
import {
  V2EnergyAnalysisListByOu2PostResponse,
  apiV2DispatchPlanAddPost,
  apiV2DispatchPlanCircuitTreePost,
  apiV2EnergyAnalysisListByOu2Post,
  apiV2DispatchPlanTopologyProcessPost,
  apiV2DispatchPlanDetailPost,
  V2DispatchPlanDetailPostResponse,
  apiV2DispatchPlanEditPost,
  apiV2DispatchPlanGetUnitIdsPost,
  V2DispatchPlanGetUnitIdsPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { queryOuStaffList } from '../../../../api/ou';
import { Col, Row, TreeSelectProps } from 'antd';
import React, { useEffect, useMemo, useState, Ref } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import { PlusOutlined } from '@ant-design/icons';
import type { RadioChangeEvent } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { v4 as uuidv4 } from 'uuid';
import ModalEquipment from '../components/ModalDevice/index';
import { getOuListOwned, OuListResponse } from '../../../../api/ou';
import { isNil } from 'lodash-es';
import { getUetEnergyMediumSceneList, UetEnergyMediumSceneListResponse } from '../../../../api/uet';
const RangePicker = TimePicker.RangePicker;

const format = 'HH:mm';
//计划子表
interface PlanDataType {
  dispatchCycles: number[];
  dispatchTime: string;
  processors: string;
  acceptor: string;
  key: string;
}

//人员
interface UserListResponse {
  id: number;
  name: string;
  staffCode: string;
}

//设备子表
export interface EquipmentDataType {
  key?: React.Key;
  name?: string;
  code?: string;
  sn?: string;
  modelName?: string;
  id?: string;
}

//组件状态
interface WorkOrderManagementCreate {
  isEdit?: boolean;
}

export interface AddOrRemoveModalRef {
  selectDatas: Array<EquipmentDataType> | undefined;
}

//回路-电能
export interface LoopTreeItem {
  name: string; // 名称
  id: number;
  otherUser: boolean | undefined; // 是否可选（true:不可选）
  children: LoopTreeItem[];
}

//用能分析组
export type GroupDetails = Exclude<V2EnergyAnalysisListByOu2PostResponse['list'], undefined>[number];

//电能枚举
//派工周期枚举
export const energy = {
  ENERGYID: 507,
};

//场景枚举
export enum typeEnum {
  LOOP = 1, //回路
  ENEYGY = 2, //用能
}

//派工周期枚举
export enum DispatchCycleEmun {
  MONDAY = 1, // 周一
  TUESDAY = 2, // 周二
  WEDNESDAY = 3, // 周三
  THURSDAY = 4, // 周四
  FRIDAY = 5, // 周五
  SATURDAY = 6, // 周六
  SUNDAY = 7, //周日
}

export const DispatchCycleDisplay = {
  [DispatchCycleEmun.MONDAY]: '周一',
  [DispatchCycleEmun.TUESDAY]: '周二',
  [DispatchCycleEmun.WEDNESDAY]: '周三',
  [DispatchCycleEmun.THURSDAY]: '周四',
  [DispatchCycleEmun.FRIDAY]: '周五',
  [DispatchCycleEmun.SATURDAY]: '周六',
  [DispatchCycleEmun.SUNDAY]: '周日',
};

//回路数据处理-电能
const formatToNodeLoopData = (data: any) => {
  return data.map((i: any) => {
    return {
      title: i.name,
      value: i.id,
      disabled: i.otherUser,
      children: i.children && i.children.length > 0 ? formatToNodeLoopData(i.children) : [],
    };
  });
};

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const CreatePlan: React.FC<WorkOrderManagementCreate> = (props, ref: Ref<AddOrRemoveModalRef>) => {
  const { isEdit } = props;
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [selectDatas, setSelectDatas] = useState<any>([]);
  const [tableDatas, setTableDatas] = useState<any>([]);
  //运营单元
  const [ou, setOu] = useState<OuListResponse[]>([]);
  //派工人员
  const [user, setUser] = useState<UserListResponse[]>([]);
  const [sceneList, setSceneList] = useState<UetEnergyMediumSceneListResponse[]>([]); // 获取能源介质场景列表
  //回路=其他
  const [loopList, setLoopList] = useState<any>([]);
  //用能分析组
  const [unitGroup, setUnitGroup] = useState<GroupDetails[]>([]);
  //用能单元
  const [treeNodeData, setTreeNodeData] = useState<TreeSelectProps['treeData']>([]);
  //回路
  const [treeNodeLoopData, setTreeNodeLoopData] = useState<TreeSelectProps['treeData']>([]);
  //回路禁选
  const [loopDisabled, setLoopDisabled] = useState<boolean>(true);
  //回路管网
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  //设备列表
  const [keys, setKeys] = useState<any>([]);
  const [currentTime, setCurrentTime] = useState<any>();
  //类型
  const [type, setType] = useState<Number>(0);
  const energyUnitGroupId: number = Form.useWatch('energyUnitGroupId', form);
  //运营单元
  const ouId: string = Form.useWatch('ouId', form);
  //能源介质场景
  const energyMediumSceneId: number = Form.useWatch('energyMediumSceneId', form);
  //回路网管
  const energyCircuitId: { value: number }[] = Form.useWatch('circuitId', form);

  //派工人员选项
  const userOptions = useMemo(() => {
    if (user.length !== 0) {
      return user.map(i => ({ label: `${i.name}${i.staffCode}`, value: `${i.id}+${i.name}` }));
    }
  }, [user]);

  const ouOptions = useMemo(() => {
    if (ou.length !== 0) {
      return ou.map(i => ({ label: i.name, value: i.id }));
    }
  }, [ou]);

  //场景为回路
  const sceneOptions = useMemo(() => {
    return (sceneList ?? []).map(i => ({ label: i.sceneName, value: i.id }));
  }, [sceneList]);

  //回路-其他介质
  const loopOptions = useMemo(() => {
    if (loopList && loopList.length > 0) {
      let arr = loopList.map((e: any) => {
        return {
          title: e.name,
          value: e.id,
          checkable: false,
          children: (e.processTreeDtos ?? []).map((item: any) => {
            return {
              title: item.name,
              value: item.id,
              disabled: item.otherUser,
              children: [],
            };
          }),
        };
      });
      return arr;
    }
  }, [loopList]);

  const routes = useMemo(() => {
    return [{ name: `${isEdit ? '编辑' : '新建'}派工计划` }];
  }, [isEdit]);

  // 所属运营单元下拉选择
  useEffect(() => {
    getOuListOwned().then(setOu);
    apiV2EnergyAnalysisListByOu2Post({}).then(res => {
      setUnitGroup(res.list ?? []);
    });
  }, []);

  // 获取用能单元树
  useEffect(() => {
    if (isNil(energyUnitGroupId)) return;
    getEnergyUnitTree(energyUnitGroupId).then(res => {
      let params = {
        id: isEdit ? id : undefined,
      };
      apiV2DispatchPlanGetUnitIdsPost({ ...params }).then(v => {
        const tree = formatToNodeData(res, v);
        setTreeNodeData(tree);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyUnitGroupId]);

  //获取派工
  useEffect(() => {
    if (isNil(ouId)) return;
    queryOuStaffList(ouId).then(setUser);
  }, [ouId]);

  //获取回路
  useEffect(() => {
    if (isNil(energyMediumSceneId)) return;
    //如果选择电能
    if (energyMediumSceneId === energy.ENERGYID) {
      let params = {
        id: isEdit ? id : undefined,
      };
      apiV2DispatchPlanCircuitTreePost({ ...params }).then(res => {
        if (res) {
          const tree = formatToNodeLoopData(res.list);
          setTreeNodeLoopData(tree);
          setLoopDisabled(false);
        }
      });
    } else {
      let params = {
        dispatchPlanId: isEdit ? id : undefined,
        sceneId: energyMediumSceneId,
      };
      apiV2DispatchPlanTopologyProcessPost({ ...params }).then(res => {
        setLoopList(res.list);
        setLoopDisabled(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyMediumSceneId]);

  useEffect(() => {
    getUetEnergyMediumSceneList().then(setSceneList);
    setType(1);
    if (!isEdit) {
      form.setFieldsValue({ tableList: [] });
      form.setFieldsValue({ dispatchScene: 1 });
      // 获取能源介质场景列表
    } else {
      apiV2DispatchPlanDetailPost({ id }).then(res => {
        //mock
        if (res) {
          setType(res.dispatchScene ?? 1);
          form.setFieldsValue({
            planName: res.planName,
            ouId: res.ouId,
            dispatchScene: res.dispatchScene,
            alarmType: res.alarmLevels,
            tableList: getNameList(res.dispatchPlanTriggerTimeProcessorsLinks),
          });
          //回路
          if (res.dispatchScene === 1) {
            form.setFieldsValue({
              energyMediumSceneId:
                res.dispatchPlanCircuitLinks && res.dispatchPlanCircuitLinks.length > 0
                  ? res.dispatchPlanCircuitLinks[0].energyMediumSceneId
                  : res.dispatchPlanProcessLinks && res.dispatchPlanProcessLinks.length > 0
                  ? res.dispatchPlanProcessLinks[0].energyMediumSceneId
                  : undefined,
              circuitId: getCircuitId(res),
            });
            if (res.deviceList && res.deviceList.length > 0) {
              setTableDatas(res.deviceList);
              setKeys(res.deviceList);
            }
            setCurrentTime(dayjs());
          } else {
            //用能
            form.setFieldsValue({
              energyUnitGroupId: (res.dispatchPlanEnergyUnitLinks ?? [])[0]?.energyUnitGroupId,
              energyUnitId: (res.dispatchPlanEnergyUnitLinks ?? []).map(e => e.energyUnitId),
            });
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //处理派工人员子表梳理
  const getNameList = (data: V2DispatchPlanDetailPostResponse['dispatchPlanTriggerTimeProcessorsLinks']) => {
    return (data ?? []).map(e => ({
      dispatchCycles: e.dispatchCycles,
      acceptor: `${e.acceptorId}+${e.acceptorName}`,
      acceptorId: e.acceptorId,
      processorsId: e.processorsId,
      processors: `${e.processorsId}+${e.processorsName}`,
      dispatchTime: [dayjs(`${e.dispatchStartTime} `, format), dayjs(`${e.dispatchEndTime}`, format)],
      key: uuidv4(),
    }));
  };

  const getCircuitId = (data: V2DispatchPlanDetailPostResponse) => {
    if ((data.dispatchPlanCircuitLinks ?? []).length > 0) {
      return (data.dispatchPlanCircuitLinks ?? []).map(e => e.circuitId);
    } else {
      return (data.dispatchPlanProcessLinks ?? []).map(e => e.processId);
    }
  };

  //切换运营单元-清空相关
  const onChangeOuId = (value: string) => {
    //回路
    if (type === typeEnum.LOOP) {
    } else {
      form.setFieldsValue({
        energyUnitGroupId: null, //清空用能组
        energyUnitId: [], //清空用能单元
      });
    }
    let data = form.getFieldValue('tableList');
    let newData = (data ?? []).map((e: any) => {
      return {
        ...e,
        acceptor: undefined,
        acceptorId: undefined,
        processors: undefined,
        processorsId: undefined,
      };
    });
    form.setFieldsValue({ tableList: newData });
  };

  //用能分析组切换
  const onchangeEnergy = () => {
    form.setFieldsValue({
      energyUnitId: [],
    });
  };

  const addColon = (str: string) => {
    // 提取小时和分钟部分
    const hours = str.substring(0, 2);
    const minutes = str.substring(2);

    // 拼接小时和分钟，并加上冒号
    const formattedTime = hours + ':' + minutes;

    return formattedTime;
  };

  //保存校验时间重叠
  const checkScheduleConflict = (arr: PlanTime[]) => {
    const days = arr.map(e => e.day);
    const allDays = days.flat();
    const flatArr: number[] = allDays.filter((item, index) => {
      // 返回当前元素在数组中的索引不等于第一次出现的索引，即为重复元素
      return allDays.indexOf(item) !== index;
    }); // 将二维数组展开成一维数组
    const newArr = Array.from(new Set(flatArr));
    if (newArr.length === 0) return { flag: true };
    let flag: Boolean = true;
    let time = {
      start: '',
      end: '',
    };
    let day = 0;
    newArr.forEach(e => {
      day = e;
      const filteredItems = arr.filter(item => item.day.includes(e));
      const filterTime = filteredItems.map(item => {
        return {
          start: item.time.startTime.replace(':', ''),
          end: item.time.endTime.replace(':', ''),
        };
      });
      filterTime.sort((a: any, b: any) => {
        return a.start - b.start;
      });
      for (let i = 1; i < filterTime.length; i++) {
        if (!flag) return;
        if (!(filterTime[i - 1].end < filterTime[i].start)) {
          flag = false;
          time.start = addColon(filterTime[i].start);
          time.end = addColon(filterTime[i - 1].end);
          return;
        }
      }
    });
    return { flag, time, day };
  };

  //保存校验周六周日是否有空余
  const checkTimeAvailability = (arr: PlanTime[]) => {
    const days = arr.map(e => e.day);
    const allDays = days.flat();
    if (!allDays.includes(6) || !allDays.includes(7)) {
      return false;
    }
    //周六
    const filterTimeSix = arr.filter(item => item.day.includes(6));
    //周日
    const filterTimeSenven = arr.filter(item => item.day.includes(7));
    let sumSix: number = 0;
    let sumSenven: number = 0;
    //累计每个时间段的差异分钟
    filterTimeSix.forEach(e => {
      const time1Obj = dayjs(e.time.startTime, 'HH:mm');
      const time2Obj = dayjs(e.time.endTime, 'HH:mm');
      sumSix += time2Obj.diff(time1Obj, 'minute') + 1;
    });
    filterTimeSenven.forEach(e => {
      const time1Obj = dayjs(e.time.startTime, 'HH:mm');
      const time2Obj = dayjs(e.time.endTime, 'HH:mm');
      sumSenven += time2Obj.diff(time1Obj, 'minute') + 1;
    });
    //一天1440分钟
    if (sumSenven !== 1440 || sumSix !== 1440) {
      return false;
    }
    return true;
  };

  //编辑
  const editPlan = () => {
    const values = form.getFieldsValue();
    let params = {
      planName: values.planName,
      ouId: values.ouId,
      alarmLevels: values.alarmType,
      dispatchScene: values.dispatchScene,
      dispatchPlanEnergyUnitLinks: mapEnergyUnitId(mapId(values.energyUnitId), values.energyUnitGroupId),
      dispatchPlanCircuitLinks: mapCircuitId(mapId(values.circuitId), values.energyMediumSceneId),
      devices: (selectDatas ?? []).map((e: any) => e.id),
      dispatchPlanProcessLinks: mapCircuitLoopId(mapId(values.circuitId), values.energyMediumSceneId),
      dispatchPlanTriggerTimeProcessorsLinks: userTableList(values.tableList),
      id: id,
    };
    apiV2DispatchPlanEditPost({ ...params }).then(res => {
      let arr = values.tableList.map((e: any) => {
        return {
          day: e.dispatchCycles,
          time: {
            startTime: dayjs(e.dispatchTime[0]).format('HH:mm'),
            endTime: dayjs(e.dispatchTime[1]).format('HH:mm'),
          },
        };
      });
      if (checkTimeAvailability(arr)) {
        navigate(`/TicketCenter/WorkOrderManagement/list`);
      } else {
        Modal.confirm({
          title: <span>周六、周日还有空余时段没有安排派工人员，是否离开？</span>,
          onOk: () => {
            navigate(`/TicketCenter/WorkOrderManagement/list`);
          },
        });
      }
    });
  };
  interface PlanTime {
    day: number[];
    time: {
      startTime: string;
      endTime: string;
    };
  }
  const onFinish = () => {
    let data = form.getFieldValue('tableList');
    if (data.length >= 1) {
      let arr = data.map((e: any) => {
        return {
          day: e.dispatchCycles,
          time: {
            startTime: dayjs(e.dispatchTime[0]).format('HH:mm'),
            endTime: dayjs(e.dispatchTime[1]).format('HH:mm'),
          },
        };
      });
      const obj = checkScheduleConflict(arr);
      if (!obj.flag) {
        Modal.confirm({
          title: (
            <span>
              【{DispatchCycleDisplay[obj.day as keyof typeof DispatchCycleDisplay]}】【{obj.time?.start}~
              {obj.time?.end}】请勿重复
            </span>
          ),
          onOk: () => {},
        });
        return;
      }
    }
    if (isEdit) {
      editPlan();
    } else {
      creatPlan();
    }
  };
  const onChange = (e: RadioChangeEvent) => {
    console.log(e.target.value);

    setType(e.target.value);
  };

  const addRow = () => {
    const tableData = form.getFieldValue('tableList');
    const newRow = {
      key: uuidv4(),
      dispatchCycle: [],
      processors: null,
      acceptor: null,
    };
    form.setFieldsValue({ tableList: [...tableData, newRow] });
  };

  //用能单元数据处理
  const formatToNodeData = (
    data: EnergyUnitTreeItem[],
    res: V2DispatchPlanGetUnitIdsPostResponse
  ): TreeSelectProps['treeData'] => {
    return data.map(i => {
      return {
        title: i.name,
        value: i.energyUnitId,
        disabled: (res.list ?? []).includes(Number(i.energyUnitId)),
        children: i.children && i.children.length > 0 ? formatToNodeData(i.children, res) : [],
      };
    });
  };

  //删除派工子表
  const deletePlan = (row: PlanDataType) => {
    const tableData = form.getFieldValue('tableList');
    console.log(tableData);

    const newData = tableData.filter((e: PlanDataType) => e.key !== row.key);

    form.setFieldsValue({ tableList: [...newData] });
  };

  //处理回路官网-用能单元
  const mapEnergyUnitId = (data: number[], groupId: number) => {
    if (isNil(groupId)) return [];
    return (data ?? []).map(e => {
      return {
        energyUnitGroupId: groupId,
        energyUnitId: e,
      };
    });
  };

  //处理回路官网-回路管网-电能
  const mapCircuitId = (data: Array<number>, groupId: number) => {
    if (data && data.length > 0 && groupId === energy.ENERGYID) {
      return data.map(e => {
        return {
          energyMediumSceneId: groupId,
          circuitId: e,
        };
      });
    } else {
      return [];
    }
  };

  const findFatherId = (id: any, data: any) => {
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let parent = data[i];
        for (let j = 0; j < parent.children.length; j++) {
          if (parent.children[j].value === id) {
            return parent.value;
          }
        }
      }
    } else {
      return null;
    }
  };

  //处理回路官网-回路管网-非电能
  const mapCircuitLoopId = (data: Array<number>, groupId: number) => {
    if (data && data.length > 0 && groupId !== energy.ENERGYID) {
      return data.map(e => {
        return {
          energyMediumSceneId: groupId,
          energyMediumTopologyId: findFatherId(e, loopOptions),
          processId: e,
        };
      });
    } else {
      return [];
    }
  };

  const userTableList = (data: any) => {
    if (data && data.length > 0) {
      return data.map((e: any) => {
        return {
          dispatchCycles: e.dispatchCycles,
          dispatchStartTime: dayjs(e.dispatchTime[0]).format('HH:mm'),
          dispatchEndTime: dayjs(e.dispatchTime[1]).format('HH:mm'),
          //拼接姓名跟id
          processorsId: Number(e.processors.split('+')[0]),
          processorsName: e.processors.split('+')[1],
          acceptorId: Number(e.acceptor.split('+')[0]),
          acceptorName: e.acceptor.split('+')[1],
        };
      });
    } else {
      return [];
    }
  };

  const mapId = (data: any) => {
    return (data ?? []).map((e: any) => (e.value ? e.value : e));
  };
  //保存事件
  const creatPlan = () => {
    const values = form.getFieldsValue();
    let params = {
      planName: values.planName,
      ouId: values.ouId,
      alarmLevels: values.alarmType,
      dispatchScene: values.dispatchScene,
      dispatchPlanEnergyUnitLinks: mapEnergyUnitId(mapId(values.energyUnitId), values.energyUnitGroupId),
      dispatchPlanCircuitLinks: mapCircuitId(mapId(values.circuitId), values.energyMediumSceneId),
      devices: (selectDatas ?? []).map((e: any) => e.id),
      dispatchPlanProcessLinks: mapCircuitLoopId(mapId(values.circuitId), values.energyMediumSceneId),
      dispatchPlanTriggerTimeProcessorsLinks: userTableList(values.tableList),
    };
    apiV2DispatchPlanAddPost({ ...params }).then(res => {
      let arr = values.tableList.map((e: any) => {
        return {
          day: e.dispatchCycles,
          time: {
            startTime: dayjs(e.dispatchTime[0]).format('HH:mm'),
            endTime: dayjs(e.dispatchTime[1]).format('HH:mm'),
          },
        };
      });
      if (checkTimeAvailability(arr)) {
        navigate(`/TicketCenter/WorkOrderManagement/list`);
      } else {
        Modal.confirm({
          title: <span>周六、周日还有空余时段没有安排派工人员，是否离开？</span>,
          onOk: () => {
            navigate(`/TicketCenter/WorkOrderManagement/list`);
          },
        });
      }
    });
  };

  //打开设备选择弹窗
  const openModal = () => {
    setModalVisible(true);
  };
  //
  const setDefaultData = (data: EquipmentDataType[]) => {
    if (id && keys.length !== 0) {
      data = keys;
      setKeys([]);
    } else {
      setKeys([]);
    }
    const newKeys: any = (data ?? []).map((e: EquipmentDataType) => {
      return e.id;
    });
    setSelectedRowKeys(newKeys);
    setSelectDatas(data);
  };
  //移除设备
  const onDetele = (record: EquipmentDataType) => {
    const newData = selectDatas.filter((e: EquipmentDataType) => {
      return e.id !== record.id;
    });
    const newKeys = newData.map((e: EquipmentDataType) => {
      return e.id;
    });
    setSelectDatas(newData);
    setTableDatas(newData);
    setSelectedRowKeys(newKeys);
  };

  //添加设备
  const addData = () => {
    setTableDatas(selectDatas);
    setModalVisible(false);
  };

  //关闭弹窗并校对表格数据
  const close = () => {
    setModalVisible(false);
    if (tableDatas.length !== 0) {
      setSelectDatas(tableDatas);
      const newKeys = tableDatas.map((e: EquipmentDataType) => {
        return e.id;
      });
      setSelectedRowKeys(newKeys);
    }
  };

  //派工计划子表
  const columnsPlan: ColumnsType<PlanDataType> = [
    {
      title: (
        <div>
          <span style={{ color: '#c73b3b', marginInlineEnd: '4px' }}>*</span>
          <span>报警派工周期</span>
        </div>
      ),
      dataIndex: 'dispatchCycles',
      width: 280,
      render(_, record, index) {
        return (
          <Form.Item
            name={['tableList', index, 'dispatchCycles']}
            style={{ marginBottom: 0 }}
            rules={[{ message: '请选择', required: true }]}
          >
            <Select placeholder="请选择" maxTagCount={2} mode="multiple">
              <Select.Option value={DispatchCycleEmun.MONDAY}>
                {DispatchCycleDisplay[DispatchCycleEmun.MONDAY]}
              </Select.Option>
              <Select.Option value={DispatchCycleEmun.TUESDAY}>
                {DispatchCycleDisplay[DispatchCycleEmun.TUESDAY]}
              </Select.Option>
              <Select.Option value={DispatchCycleEmun.WEDNESDAY}>
                {DispatchCycleDisplay[DispatchCycleEmun.WEDNESDAY]}
              </Select.Option>
              <Select.Option value={DispatchCycleEmun.THURSDAY}>
                {DispatchCycleDisplay[DispatchCycleEmun.THURSDAY]}
              </Select.Option>
              <Select.Option value={DispatchCycleEmun.FRIDAY}>
                {DispatchCycleDisplay[DispatchCycleEmun.FRIDAY]}
              </Select.Option>
              <Select.Option value={DispatchCycleEmun.SATURDAY}>
                {DispatchCycleDisplay[DispatchCycleEmun.SATURDAY]}
              </Select.Option>
              <Select.Option value={DispatchCycleEmun.SUNDAY}>
                {DispatchCycleDisplay[DispatchCycleEmun.SUNDAY]}
              </Select.Option>
            </Select>
          </Form.Item>
        );
      },
    },
    {
      title: (
        <div>
          <span style={{ color: '#c73b3b', marginInlineEnd: '4px' }}>*</span>
          <span>报警派工时段</span>
        </div>
      ),
      dataIndex: 'dispatchTime',
      width: 300,
      render: (_, record: PlanDataType, index) => {
        return (
          <Form.Item
            name={['tableList', index, 'dispatchTime']}
            style={{ marginBottom: 0 }}
            rules={[{ message: '请选择', required: true }]}
          >
            <RangePicker style={{ width: '100%' }} format={format} />
          </Form.Item>
        );
      },
    },
    {
      title: (
        <div>
          <span style={{ color: '#c73b3b', marginInlineEnd: '4px' }}>*</span>
          <span>问题处理负责人</span>
        </div>
      ),
      dataIndex: 'processors',
      width: 300,
      render(_, record, index) {
        return (
          <Form.Item
            name={['tableList', index, 'processors']}
            style={{ marginBottom: 0 }}
            rules={[{ message: '请选择', required: true }]}
          >
            <Select placeholder="请选择" options={userOptions} />
          </Form.Item>
        );
      },
    },
    {
      title: (
        <div>
          <span style={{ color: '#c73b3b', marginInlineEnd: '4px' }}>*</span>
          <span>工单验收人</span>
        </div>
      ),
      dataIndex: 'acceptor',
      width: 280,
      render(_, record, index) {
        return (
          <Form.Item
            name={['tableList', index, 'acceptor']}
            style={{ marginBottom: 0 }}
            rules={[{ message: '请选择', required: true }]}
          >
            <Select placeholder="请选择" options={userOptions} />
          </Form.Item>
        );
      },
    },
    {
      title: '操作',
      dataIndex: 'option',
      width: 280,
      render: (_, record: PlanDataType) => {
        return (
          <Button
            type="link"
            onClick={() => {
              deletePlan(record);
            }}
          >
            删除计划
          </Button>
        );
      },
    },
  ];

  //设备列表
  const columnsEquipment: ColumnsType<EquipmentDataType> = [
    {
      title: '编号',
      dataIndex: 'code',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '设备名称',
      dataIndex: 'name',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '设备SN',
      dataIndex: 'sn',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '型号',
      dataIndex: 'modelName',
      render: (v: string) => <EllipsisSpan value={v} />,
    },

    {
      title: '操作',
      dataIndex: 'option',
      width: 280,
      render: (_, record: EquipmentDataType) => {
        return (
          <Button
            type="link"
            onClick={() => {
              onDetele(record);
            }}
          >
            移除
          </Button>
        );
      },
    },
  ];

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapperStyles}>
      <FormTitle title={isEdit ? '编辑派工计划' : '创建派工计划'}></FormTitle>
      <SubContent title="基本信息">
        <Form colon={true} form={form} {...formLayout} onFinish={onFinish}>
          <Row>
            <Col span={12}>
              <Form.Item label="计划名称" name="planName" rules={[{ required: true }, { max: 64 }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="所属运营单元" name="ouId" rules={[{ required: true }]}>
                <Select
                  placeholder="请选择"
                  showSearch
                  options={ouOptions}
                  optionFilterProp="label"
                  onChange={onChangeOuId}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item label="派工场景" name="dispatchScene" rules={[{ required: true }]}>
                <Radio.Group onChange={onChange} value={type}>
                  <Radio value={1}>回路管网</Radio>
                  <Radio value={2}>用能单元</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="alarmType" label="报警等级" rules={[{ required: true }]}>
                <Select placeholder="请选择" maxTagCount={3} mode="multiple">
                  <Select.Option value={AlarmLevel.HIGHEST}>{AlarmLevelDisplay[AlarmLevel.HIGHEST]}</Select.Option>
                  <Select.Option value={AlarmLevel.HIGH}>{AlarmLevelDisplay[AlarmLevel.HIGH]}</Select.Option>
                  <Select.Option value={AlarmLevel.MIDDLE}>{AlarmLevelDisplay[AlarmLevel.MIDDLE]}</Select.Option>
                  <Select.Option value={AlarmLevel.LOW}>{AlarmLevelDisplay[AlarmLevel.LOW]}</Select.Option>
                  <Select.Option value={AlarmLevel.LOWEST}>{AlarmLevelDisplay[AlarmLevel.LOWEST]}</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {type === typeEnum.ENEYGY && (
            <Row>
              <Col span={12}>
                <Form.Item
                  name="energyUnitGroupId"
                  label="用能分析组"
                  rules={[{ required: true, message: '请选择用能分析组' }]}
                >
                  <Select
                    placeholder="请选择"
                    fieldNames={{ label: 'energyGroupName', value: 'energyGroupId' }}
                    options={unitGroup}
                    onChange={onchangeEnergy}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="energyUnitId"
                  label="用能单元"
                  rules={[{ required: true, message: '请选择至少一项用能单元' }]}
                  tooltip={{
                    placement: 'topRight',
                    title: '该用能单元能耗报警会生成工单被派工。不同派工计划中，用能单元不能被重复选择',
                    icon: <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />,
                  }}
                >
                  <TreeSelect
                    treeData={treeNodeData}
                    maxTagCount={3}
                    treeCheckStrictly
                    treeCheckable
                    showCheckedStrategy="SHOW_ALL"
                    treeDefaultExpandAll
                    placeholder="请选择"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {type === typeEnum.LOOP && (
            <Row>
              <Col span={12}>
                <Form.Item label="能源介质场景" name="energyMediumSceneId" rules={[{ required: true }]}>
                  <Select
                    placeholder="请选择"
                    options={sceneOptions}
                    onChange={() => {
                      form.setFieldsValue({ circuitId: [] });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="回路管网" name="circuitId" rules={[{ required: true }]}>
                  {energyMediumSceneId === energy.ENERGYID && (
                    <TreeSelect
                      showSearch
                      disabled={loopDisabled}
                      treeData={treeNodeLoopData}
                      maxTagCount={3}
                      treeCheckStrictly
                      treeCheckable
                      showCheckedStrategy="SHOW_ALL"
                      treeDefaultExpandAll
                      placeholder="请选择"
                      onChange={() => {
                        setCurrentTime(dayjs());
                        setTableDatas([]);
                      }}
                      filterTreeNode={true}
                      treeNodeFilterProp="title"
                    />
                  )}
                  {energyMediumSceneId !== energy.ENERGYID && (
                    <TreeSelect
                      showSearch
                      treeData={loopOptions}
                      maxTagCount={3}
                      treeCheckStrictly
                      treeCheckable
                      showCheckedStrategy="SHOW_ALL"
                      treeDefaultExpandAll
                      placeholder="请选择"
                      onChange={() => {
                        setCurrentTime(dayjs());
                        setTableDatas([]);
                      }}
                      filterTreeNode={true}
                      treeNodeFilterProp="title"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          )}
          {type === typeEnum.LOOP && (
            <SubContent
              className={styles.infoBox}
              title={
                <div>
                  <Button type="dashed" ghost onClick={openModal}>
                    选择设备
                  </Button>
                  <InfoCircleOutlined style={{ color: 'var(--warning-color)', margin: '0 9px' }} />
                  <span className={styles.title}>不同派工计划中，设备不能被重复选择</span>
                </div>
              }
            >
              <Table columns={columnsEquipment} dataSource={tableDatas} scroll={{ y: 500 }} />
            </SubContent>
          )}
          <SubContent
            className={styles.infoBox}
            title={
              <div>
                派工计划
                <InfoCircleOutlined style={{ color: 'var(--warning-color)', margin: '0 9px' }} />
                <span className={styles.title}>
                  基本信息中配置的内容，会在发生报警时，根据派工计划自动创建工单并且指派给相应的人员，请确保相应的人员角色包含工单相应权限
                </span>
              </div>
            }
          >
            <Form.Item noStyle labelAlign="left" name="tableList" valuePropName="dataSource">
              <Table columns={columnsPlan} rowKey="key" />
            </Form.Item>
          </SubContent>

          <Button
            wrapClassName={styles['add-row-btn']}
            className={styles['row-btn-inner']}
            type="dashed"
            onClick={addRow}
            ghost
            icon={<PlusOutlined />}
          >
            新建
          </Button>
          <Button wrapClassName={styles['save-row-btn']} type="primary" htmlType="submit">
            保存
          </Button>
          <Button
            onClick={() => {
              Modal.confirm({
                title: <span>存在编辑信息，确定丢弃未保存信息并返回？</span>,
                onOk: () => {
                  navigate(`/TicketCenter/WorkOrderManagement/list`);
                },
              });
            }}
          >
            返回
          </Button>
        </Form>
      </SubContent>
      <ModalEquipment
        visible={modalVisible}
        close={close}
        ids={mapId(energyCircuitId)}
        selectKeys={selectedRowKeys}
        selectDatas={selectDatas}
        onChangeSelectKeys={setSelectedRowKeys}
        onChangeSelectData={setSelectDatas}
        setDefaultData={setDefaultData}
        addRow={addData}
        id={isEdit ? id : undefined}
        type={energyMediumSceneId === energy.ENERGYID ? 1 : 2}
        changeCurrent={currentTime}
      />
    </Wrapper>
  );
};
export default CreatePlan;
