import { Col, Divider, Form, Row } from 'antd';
import React, { FC, useEffect } from 'react';
import ShowInput from '@/shared/components/ShowInput';
import { BaseElectricityBillResponse } from '../../../../api/electricity';
import styles from './index.module.scss';
import { ElectricitySaleContractSettlementType } from '../../../../api/electricitySettlementRules';

export interface ElectricityBillBaseInfo {
  data: BaseElectricityBillResponse;
}

const ElectricityBillBasicInfo: FC<ElectricityBillBaseInfo> = ({ data }) => {
  const [form] = Form.useForm();
  const invoice = data?.invoice;

  useEffect(() => {
    if (invoice) {
      form.setFieldsValue({
        salesTaxId: invoice.salesTaxId,
        customerAddress: invoice.customerAddress,
        salesBankAccount: invoice.salesBankAccount,
        salesBeneficialBank: invoice.salesBeneficialBank,
        billTitle: invoice.billTitle,
        salesGeneralAccountNumber: invoice.salesGeneralAccountNumber,
        salesContactNumber: invoice.salesContactNumber,
        customerCompanyTitle: invoice.customerCompanyTitle,
        customerSectionAccountNo: invoice.customerSectionAccountNo,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice]);

  return (
    <Form form={form}>
      <Row className={styles.showBaseInfo}>
        <Col span={12} style={{ top: 10, left: 10 }}>
          <Form.Item label="电费核查联表头" name="billTitle">
            <ShowInput />
          </Form.Item>
        </Col>
        <Col span={12} style={{ top: 10, left: 10 }}>
          <Form.Item label="供电服务热线" name="salesContactNumber">
            <ShowInput />
          </Form.Item>
        </Col>
      </Row>
      {[
        ElectricitySaleContractSettlementType.UNITARYSYSTEM,
        ElectricitySaleContractSettlementType.TWOPARTSYSTEM,
        ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY,
      ].includes(data.settlementType) && (
        <>
          <div className={styles.boxBody}>
            <div className={styles.boxLeftPart}>
              <Col>
                <Row>
                  <Form.Item label="纳税号" name="salesTaxId">
                    <ShowInput />
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item label="总户号" name="salesGeneralAccountNumber">
                    <ShowInput />
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item label="收款开户行" name="salesBeneficialBank">
                    <ShowInput />
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item label="收款帐号" name="salesBankAccount">
                    <ShowInput />
                  </Form.Item>
                </Row>
              </Col>
            </div>

            <Divider type="vertical" style={{ height: '100%' }} />

            <div className={styles.boxRightPart}>
              <Col>
                <Row>
                  <Form.Item label="段户号" name="customerSectionAccountNo">
                    <ShowInput />
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item label="客户地址" name="customerAddress">
                    <ShowInput />
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item label="客户企业抬头" name="customerCompanyTitle">
                    <ShowInput />
                  </Form.Item>
                </Row>
              </Col>
            </div>
          </div>
        </>
      )}
    </Form>
  );
};

export default ElectricityBillBasicInfo;
