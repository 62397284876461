import { Form, Modal, Tag } from '@maxtropy/components';

import ShowInput from '@/shared/components/ShowInput';
import { UnitConfGroupDetailEffectMirrorResponse } from '@/pages/EnergyConsumption/Config';

interface Props {
  open: boolean;
  setOpen: (v: boolean) => void;
  detail?: UnitConfGroupDetailEffectMirrorResponse;
  setUnitDetail: (v?: UnitConfGroupDetailEffectMirrorResponse) => void;
}

const UnitDetailModal: React.FC<Props> = ({ open, setOpen, detail, setUnitDetail }) => {
  const [form] = Form.useForm();

  return (
    <>
      <Modal
        title="用能单元详情"
        open={open}
        onCancel={() => {
          setUnitDetail(undefined);
          setOpen(false);
        }}
        onOk={() => {
          setUnitDetail(undefined);
          setOpen(false);
        }}
        size="normal"
      >
        <Form form={form}>
          <Form.Item label="用能单元">
            <ShowInput value={detail?.name} />
          </Form.Item>
          <Form.Item label="剔除设备">
            {detail && detail.rejectDeviceList && detail.rejectDeviceList.length > 0
              ? detail.rejectDeviceList.map(i => {
                  return <Tag>{i.name}</Tag>;
                })
              : '--'}
          </Form.Item>
          <Form.Item label="光伏站并网点">
            {detail && detail.gridDeviceList && detail.gridDeviceList.length > 0
              ? detail.gridDeviceList.map(i => {
                  return <Tag>{i.name}</Tag>;
                })
              : '--'}
          </Form.Item>
          <Form.Item label="储能站">
            {detail && detail.bsaDeviceList && detail.bsaDeviceList.length > 0
              ? detail.bsaDeviceList.map(i => {
                  return <Tag>{i.name}</Tag>;
                })
              : '--'}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UnitDetailModal;
