import { getOuListOwned } from '@/api/ou';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission, useQuery } from '@/utils/utils';
import {
  Button,
  EllipsisSpan,
  Paging,
  useBreadcrumbRoutes,
  usePaging,
  useUpdate,
  Wrapper,
  useAsync,
  Form,
  Input,
  PopConfirm,
  Select,
  Table,
  TreeSelect,
  CustomFilter,
} from '@maxtropy/components';
import { Space, TreeSelectProps } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from './index.module.scss';
import { EnergyUnitTreeItem, getEnergyUnitTree } from '@/api/energyUnitMapping';
import {
  EnergyUnitServerAlarmPageRulePostRequest,
  EnergyUnitServerAlarmPageRulePostResponse,
} from '@maxtropy/device-customer-apis';
import { deleteBalanceAlarmRecord } from '@/api/balanceAlarmManagement';
import {
  EnergyConsumptionAlarmStatusType,
  EnergyConsumptionAlarmStatusTypeValue,
  updateEnergyUnitAlarmStatus,
} from '@/api/energyUnitAlarmRules';
import dayjs from 'dayjs';
import qs from 'qs';
import { stringToNumber } from '../CraftProcess/utils';
import {
  apiV2EnergyAnalysisListByOuPost,
  apiV2EnergyUnitServerAlarmPageRulePost,
} from '@maxtropy/device-customer-apis-v2';
import { V2EnergyAnalysisListPostResponse } from '@maxtropy/device-customer-apis-v2';
import { PlusOutlined } from '@ant-design/icons';

export type AnalysisUnit = Exclude<V2EnergyAnalysisListPostResponse['list'], undefined>[number];

function recordStatusOptions() {
  const status: Array<{ label: string; value: EnergyConsumptionAlarmStatusType }> = [
    {
      label: EnergyConsumptionAlarmStatusTypeValue[EnergyConsumptionAlarmStatusType.DISABLE],
      value: EnergyConsumptionAlarmStatusType.DISABLE,
    },
    {
      label: EnergyConsumptionAlarmStatusTypeValue[EnergyConsumptionAlarmStatusType.ENABLE],
      value: EnergyConsumptionAlarmStatusType.ENABLE,
    },
  ];
  return status.map(i => ({ label: i.label, value: i.value }));
}

const columns = [
  {
    title: '规则编号',
    dataIndex: 'ruleCode',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '规则名称',
    dataIndex: 'ruleName',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '用能单元',
    dataIndex: 'energyUnitNames',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string[]) => <EllipsisSpan value={v && v.length > 0 ? v.join() : '--'} />,
  },
  {
    title: '所属运营单元',
    dataIndex: 'ouName',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: EnergyConsumptionAlarmStatusType) => (
      <EllipsisSpan value={EnergyConsumptionAlarmStatusTypeValue[v as EnergyConsumptionAlarmStatusType]} />
    ),
  },
  {
    title: '最后操作时间',
    dataIndex: 'lastOperationTime',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'} />,
  },
];

function formatToNodeData(data: EnergyUnitTreeItem[]): TreeSelectProps['treeData'] {
  return data.map(i => {
    return {
      title: i.name,
      value: i.energyUnitId,
      children: i.children && i.children.length > 0 ? formatToNodeData(i.children) : [],
      // disabled: i.disabled,
    };
  });
}

export interface SearchParams extends Omit<EnergyUnitServerAlarmPageRulePostRequest, 'page' | 'size'> {}
export type TableRow = Exclude<EnergyUnitServerAlarmPageRulePostResponse['list'], undefined>[number];

interface FilterParams {
  ruleName?: string;
  energyUnitGroupId?: number;
  energyUnitId?: number;
  ouId?: number;
  status?: number;
}

const EnergyUnitsAlarmRules = () => {
  const [searchForm] = Form.useForm();
  // const energyAnalysisUnitGroupId = Form.useWatch('energyUnitGroupId', searchForm);
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  let [urlSearchParams] = useSearchParams();

  const [searchParams, setSearchParams] = useState<FilterParams>({
    ruleName: urlSearchParams.get('ruleName')?.length ? urlSearchParams.get('ruleName')! : undefined,
    energyUnitGroupId: urlSearchParams.get('energyUnitGroupId')?.length
      ? (urlSearchParams.get('energyUnitGroupId')! as unknown as number)
      : undefined,
    energyUnitId: urlSearchParams.get('energyUnitId')?.length
      ? (urlSearchParams.get('energyUnitId')! as unknown as number)
      : undefined,
    ouId: urlSearchParams.get('ouId')?.length ? (urlSearchParams.get('ouId')! as unknown as number) : undefined,
    status: urlSearchParams.get('status')?.length ? (urlSearchParams.get('status')! as unknown as number) : undefined,
  });

  const hasCreatePermission = useHasPermission(PermissionsType.B_ENERGYCONSUMPTIONCREATERULE);
  const hasDelPermission = useHasPermission(PermissionsType.B_ENERGYCONSUMPTIONDELETE);
  const hasStatusPermission = useHasPermission(PermissionsType.B_DISABLEENABLE);
  const hasEditPermission = useHasPermission(PermissionsType.B_ENERGYCONSUMPTIONEDIT);

  const [treeNodeData, setTreeNodeData] = useState<TreeSelectProps['treeData']>([]);

  const [updateState, updateFn] = useUpdate();

  const ouList = useAsync(getOuListOwned, []);
  const [unitGroup, setUnitGroup] = useState<AnalysisUnit[]>([]);

  const [energyUnitGroupId, setEnergyUnitGroupId] = useState<number | undefined>(
    urlSearchParams.get('energyUnitId')?.length
      ? (urlSearchParams.get('energyUnitId')! as unknown as number)
      : undefined
  );

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        apiV2EnergyUnitServerAlarmPageRulePost({
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) setTotalCount(res.total!);
          return res.list;
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [pageOffset, pageSize, searchParams, setTotalCount, updateState]
    )
  );

  useEffect(() => {
    // 获取用能分析组
    apiV2EnergyAnalysisListByOuPost({}).then(res => {
      setUnitGroup((res.list ?? []) as AnalysisUnit[]);
    });
  }, []);

  useEffect(() => {
    if (energyUnitGroupId) {
      searchForm.setFieldValue('energyUnitId', undefined);
      getEnergyUnitTree(energyUnitGroupId).then(res => {
        const tree = formatToNodeData(res);
        setTreeNodeData(tree);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyUnitGroupId]);

  // 用能分析组
  const unitGroupOption = useMemo(() => {
    return (unitGroup ?? []).map(i => ({
      label: i.name,
      value: i.id,
    }));
  }, [unitGroup]);

  // ou下拉列表
  const ouOptions = useMemo(() => {
    if (ouList && ouList.length !== 0) {
      return ouList.map((i: any) => ({ label: i.name, value: i.id }));
    }
  }, [ouList]);

  useEffect(() => {
    if (urlSearchParams.get('energyUnitGroupId')?.length && unitGroup && unitGroup.length) {
      getEnergyUnitTree(stringToNumber(urlSearchParams.get('energyUnitGroupId')!)!).then(res => {
        const tree = formatToNodeData(res);
        setTreeNodeData(tree);
      });
    }

    searchForm.setFieldsValue({
      ouId: stringToNumber(urlSearchParams.get('ouId')!),
      energyUnitGroupId: stringToNumber(urlSearchParams.get('energyUnitGroupId')!),
      energyUnitId: stringToNumber(urlSearchParams.get('energyUnitId')!),
      ruleName: urlSearchParams.get('ruleName')!,
      status: stringToNumber(urlSearchParams.get('status')!),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitGroup, urlSearchParams]);

  const onFinish = (val: FilterParams) => {
    const params: SearchParams = {
      ruleName: val.ruleName,
      energyUnitGroupId: val.energyUnitGroupId,
      energyUnitId: val.energyUnitId,
      ouId: val.ouId,
      status: val.status,
    };
    setSearchParams(params);
    setPageOffset(1);
    navigate(`?${qs.stringify(params)}`);
  };

  const onReset = () => {
    const params: SearchParams = {
      ruleName: undefined,
      energyUnitGroupId: undefined,
      energyUnitId: undefined,
      ouId: undefined,
      status: undefined,
    };
    setSearchParams(params);
    setPageOffset(1);
    navigate(`?`);
  };

  const filters = (
    <CustomFilter
      form={searchForm}
      onFinish={(val: SearchParams) => onFinish(val as FilterParams)}
      onReset={() => onReset()}
    >
      <Form.Item name="ruleName" label="规则名称">
        <Input placeholder=" 请输入" />
      </Form.Item>
      <Form.Item name="energyUnitGroupId" label="用能分析组">
        <Select
          style={{ width: '100%' }}
          placeholder="请选择"
          options={unitGroupOption}
          showSearch
          optionFilterProp="label"
          onChange={val => setEnergyUnitGroupId(val)}
        />
      </Form.Item>
      <Form.Item name="energyUnitId" label="用能单元">
        <TreeSelect
          treeData={treeNodeData}
          disabled={!energyUnitGroupId}
          // treeCheckStrictly
          // treeCheckable
          // showCheckedStrategy="SHOW_ALL"
          treeDefaultExpandAll
          placeholder="请选择"
          style={{
            width: '100%',
          }}
        />
      </Form.Item>
      <Form.Item name="ouId" label="所属运营单元">
        <Select
          style={{ width: '100%' }}
          placeholder="请选择"
          options={ouOptions}
          showSearch
          optionFilterProp="label"
        />
      </Form.Item>
      <Form.Item name="status" label="状态">
        <Select style={{ width: '100%' }} allowClear placeholder="请选择" options={recordStatusOptions()} />
      </Form.Item>
    </CustomFilter>
  );

  const onDeleteRecord = (id?: number) => {
    deleteBalanceAlarmRecord(id!).then(_ => {
      updateFn();
    });
  };

  const disOrEnableChargingStation = (status?: number, id?: number) => {
    updateEnergyUnitAlarmStatus(id!, {
      status:
        status === EnergyConsumptionAlarmStatusType.ENABLE
          ? EnergyConsumptionAlarmStatusType.DISABLE
          : EnergyConsumptionAlarmStatusType.ENABLE,
    }).then(_ => {
      updateFn();
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 130,
      render: (record: TableRow) => (
        <Space size={16}>
          {hasDelPermission && (
            <PopConfirm
              title={'确认删除？'}
              onConfirm={() => onDeleteRecord(record.ruleId)}
              okText="确定"
              cancelText="取消"
            >
              <Button type="link">删除</Button>
            </PopConfirm>
          )}
          {hasStatusPermission && (
            <PopConfirm
              title={
                record.status === EnergyConsumptionAlarmStatusType.DISABLE
                  ? '确认启用？'
                  : '禁用后该报警规则不再生效，你还要继续吗？'
              }
              onConfirm={() => disOrEnableChargingStation(record.status, record.ruleId)}
              okText="确定"
              cancelText="取消"
            >
              <Button type="link">
                <span style={{ color: record.status === EnergyConsumptionAlarmStatusType.DISABLE ? 'unset' : 'red' }}>
                  {record.status === EnergyConsumptionAlarmStatusType.DISABLE
                    ? EnergyConsumptionAlarmStatusTypeValue[EnergyConsumptionAlarmStatusType.ENABLE]
                    : EnergyConsumptionAlarmStatusTypeValue[EnergyConsumptionAlarmStatusType.DISABLE]}
                </span>
              </Button>
            </PopConfirm>
          )}
          {hasEditPermission && (
            <Button
              type="link"
              onClick={() => {
                window.open(`/energyUnits/Alarm/rules/create/${record.ruleId}?${urlSearchParams.toString()}`, '_self');
              }}
            >
              编辑
            </Button>
          )}

          <Button
            type="link"
            onClick={() => {
              window.open(`/energyUnits/Alarm/rules/detail/${record.ruleId}?${urlSearchParams.toString()}`, '_self');
            }}
          >
            查看
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Wrapper className={styles.wrapperPadding} routes={breadcrumbRoutes?.routes} filters={filters}>
        <Space size={8} direction="vertical" className={styles.btnBlank}>
          {hasCreatePermission && (
            <Button
              type="primary"
              onClick={() => {
                window.open(`/energyUnits/Alarm/rules/create?${urlSearchParams.toString()}`, '_self');
              }}
            >
              <PlusOutlined /> 新建规则
            </Button>
          )}
        </Space>
        <Table
          rowKey="ruleId"
          sticky
          scroll={{ x: 1300 }}
          loading={isLoading}
          columns={buildColumns}
          dataSource={data}
          pagination={false}
        />
        <Paging pagingInfo={pagingInfo} />
      </Wrapper>
    </>
  );
};

export default EnergyUnitsAlarmRules;
