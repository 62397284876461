import {
  Button,
  SubContent,
  Wrapper,
  useBreadcrumbRoutes,
  Key,
  Radio,
  TreeSelect,
  Form,
  Select,
  DatePicker,
  FormTitle,
  Tag,
  Input,
  Tree,
  Switch,
  Checkbox,
  message,
} from '@maxtropy/components';
import type { DataNode } from 'antd/es/tree';
import { Col, Spin, Row, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { SetStateAction, useEffect, useMemo, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import BillingConfiguration from '../components/BillingConfiguration';
import { getmeterTypeList } from '@/api/CarbonAccountUnits';
import styles from '../CreateMeterReading/index.module.scss';
import { isNil } from 'lodash';
import { EnergyUnitTreeItem, getEnergyUnitTree, EnergyUnitTreeItemDataNode } from '@/api/energyUnitMapping';
import * as React from 'react';

import {
  ReadingMeterPeriod,
  ReadingMeterPeriodFormat,
  meterReadingObjectsType,
  accountUnitListType,
  treeToListType,
  meterReadingObjsType,
} from '../CreateMeterReading/CreateMeter';
import {
  apiV2UniverseMeterReadingProjectDetailPost,
  V2UniverseMeterReadingProjectEditPostRequest,
  apiV2UniverseMeterReadingProjectEditPost,
  apiV2EnergyConsumptionEvaluationAnalysisUnitFormToTreePost,
  V2UniverseMeterReadingProjectDetailPostResponse,
  apiV2EnergyAnalysisListByOuPost,
  V2EnergyAnalysisListPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { useWatch } from 'antd/es/form/Form';

export type AnalysisUnit = Exclude<V2EnergyAnalysisListPostResponse['list'], undefined>[number];

const CreateMeterReading: React.FC = () => {
  const routes = [{ name: '详情' }];
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [urlSearchParams] = useSearchParams();

  const energyGroupId = Form.useWatch('energyGroupId', form);

  const [accountUnitList, setAccountUnitList] = useState<accountUnitListType[]>([]);
  const [checkTime, setCheckTime] = useState<Dayjs>(); //首次抄表时间
  const [scheduleType, setScheduleType] = useState<number>(); //抄表的周期类型
  const [Message, setMessage] = useState<string>(''); //提示信息
  const [TreeFlag, setTreeFlag] = useState<boolean>(false); //树显示隐藏

  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>();
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [prevTreeData, setPrevTreeData] = useState<EnergyUnitTreeItemDataNode[]>([]); //初始化treeData
  const [treeNodeData, setTreeNodeData] = useState<DataNode[]>([]); //更改后treeData

  const [unitGroup, setUnitGroup] = useState<AnalysisUnit[]>([]); //抄表对象options
  const [unitCheckedKeys, setUnitCheckedKeys] = useState<number[]>([]); //选中的keys
  const [Flag, setFlag] = useState<boolean>(false); //编辑保存
  const [detail, setdetail] = useState<number[]>([]);
  const [treeSwitch, setTreeSwitch] = useState<boolean>(false); //多层级抄表
  const [allSelectFlag, setAllSelectFlag] = useState<boolean>(false); //是否全选

  const [treeToList, setTreeToList] = useState<treeToListType[]>([]);
  const [meterReadingObjects, setMeterReadingObjects] = useState<meterReadingObjectsType[]>([]); //成本中心配置

  const [data, setData] = useState<any>({ costCenterConfig: [] });
  const [meterReadingObjs, setMeterReadingObjs] = useState<meterReadingObjsType[]>([]); //成本中心配置抄表对象

  const [currentCheckedKey, setCurrentCheckedKey] = useState<number>(); //当前选中的key

  const [tableData, setTableData] = useState<any>();
  const [totalNodeLength, setTotalNodeLength] = useState<number>();

  const [unitTree, setUnitTree] = useState<any>([]);
  const [searchValue, setSearchValue] = useState(''); // 搜索的值
  const [specialFlag, setSpecialFlag] = useState<boolean>(false);
  const [peakTipFlag, setpeakTipFlag] = useState<boolean>(false);
  const defaultGetValueWay = useWatch('defaultGetValueWay', form);
  const energyMediumIds = useWatch('energyMediumIds', form);
  useEffect(() => {
    setData({
      costCenterConfig: [],
    });

    // 获取用能分析组
    apiV2EnergyAnalysisListByOuPost({}).then(res => {
      setUnitGroup((res.list ?? []) as AnalysisUnit[]);
    });
  }, []);

  useEffect(() => {
    const flag = (energyMediumIds ?? [])?.some((item: number) => item === 2030);
    setSpecialFlag(flag);
    if (isNil(form.getFieldValue('defaultGetValueWay'))) {
      form.setFieldsValue({ defaultGetValueWay: 1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyMediumIds]);
  // message
  useEffect(() => {
    if (checkTime && scheduleType) {
      let message = '';
      const days = ['日', '一', '二', '三', '四', '五', '六'];
      let dayOfWeekString = days[dayjs(checkTime).day()];

      switch (scheduleType) {
        case ReadingMeterPeriod.DAY:
          message = `每日${dayjs(checkTime).hour()}时${dayjs(checkTime).minute()}分抄表`;
          break;
        case ReadingMeterPeriod.WEEK:
          message = `每周${dayOfWeekString}${dayjs(checkTime).hour()}时${dayjs(checkTime).minute()}分抄表`;
          break;
        case ReadingMeterPeriod.MONTH:
          message = `每月${dayjs(checkTime).date()}日${dayjs(checkTime).hour()}时${dayjs(checkTime).minute()}分抄表`;
          break;
        case ReadingMeterPeriod.YEAR:
          message = `每年${dayjs(checkTime).month() + 1}月${dayjs(checkTime).date()}日抄表`;
          break;
        default:
      }
      setMessage(message);
    }
  }, [checkTime, scheduleType]);

  useEffect(() => {
    apiV2UniverseMeterReadingProjectDetailPost({ id: Number(id) }).then(
      (res: V2UniverseMeterReadingProjectDetailPostResponse) => {
        const changeCost = res?.costCenterConfig?.map(item => {
          return {
            ...item,
            readingObjectIds: item?.readingObjects?.map(obj => obj.id),
          };
        });
        if (res.specialMeterReadingObjectIds && res.specialMeterReadingObjectIds?.length > 0) {
          apiV2EnergyConsumptionEvaluationAnalysisUnitFormToTreePost({
            energyUnitIds: res.specialMeterReadingObjectIds,
          }).then(res => {
            setUnitTree(res.list);
          });
        }
        setpeakTipFlag(res.defaultGetValueWay === 2 ? true : false);
        form.setFieldsValue({
          ...res,
          specialMeterReadingObjectIds: isNil(res.specialMeterReadingObjectIds)
            ? undefined
            : res.specialMeterReadingObjectIds,
          projectName: res.projectName,
          meterReadingObjetIds: res?.meterReadingObjects?.map(item => item.id),
          energyMediumIds: res.energyMediumList?.map(item => {
            return item.id;
          }),
          energyGroupId: res.energyGroupId,
          readingPeriod: res.readingPeriod,
          firstReadingTime: dayjs(res?.firstReadingTime), //首次抄
          adjustmentBillingMethodType: res.adjustmentBillingMethodType,
          amortizedBillingMethodType: res.amortizedBillingMethodType,
          costCenterConfig: changeCost,
          rateValueType: res.rateValueType,
          hierarchicalMeterReading: res.hierarchicalMeterReading === 1 ? 1 : 0,
        });
        setTreeSwitch(res.hierarchicalMeterReading === 1);
        setTableData(res);
        setCheckTime(dayjs(res?.firstReadingTime));
        setUnitCheckedKeys(
          (res?.meterReadingObjects?.map(item => item.id) || []).filter(num => num !== undefined) as number[]
        );
        setScheduleType(isNil(res.readingPeriod) ? undefined : res?.readingPeriod);
        setAccountUnitList(isNil(res.energyMediumList) ? [] : res.energyMediumList);
        setdetail(
          isNil(res.energyMediumList)
            ? []
            : (res.energyMediumList.map(item => {
                return item.id;
              }) as number[])
        );
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  // 监听抄表对象formItem值的变化

  useEffect(() => {
    if (energyGroupId) {
      setLoading(true);
      setTreeFlag(true);
      getEnergyUnitTree(energyGroupId)
        .then(res => {
          setTreeToList(treeToListFC(res));
          setPrevTreeData(formatToNodeData(res));
          setTreeNodeData(formatToNodeData(res));
          generateList(formatToNodeData(res));
          setExpandedKeys(dataList.map(i => i.key));
          setTotalNodeLength(getAllNodeKeys(formatToNodeData(res)).length);
          setSearchValue('');
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyGroupId]);

  useEffect(() => {
    form.setFieldsValue({ allSelected: unitCheckedKeys.length === totalNodeLength });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalNodeLength, unitCheckedKeys]);

  const convertToArrayOfOptions = (data: treeToListType[], ids: number[]) => {
    return data
      ?.filter((item: treeToListType) => ids.includes(item.energyUnitId))
      ?.map((item: treeToListType) => {
        return { label: item.name, value: item.energyUnitId };
      });
  };

  const treeToListFC = (datas: { [x: string]: any }) => {
    let arr: {
      energyUnitId: number;
      name: string;
      level: number;
      parentId: number;
    }[] = [];
    formateData(datas, 0);

    function formateData(datas: { [x: string]: any }, levels: number) {
      let level = levels || 0;
      level++;
      for (let i in datas) {
        if (
          datas[i].hasOwnProperty('energyUnitId') &&
          datas[i].hasOwnProperty('name') &&
          datas[i].hasOwnProperty('parentId')
        ) {
          arr.push({
            energyUnitId: datas[i]['energyUnitId'],
            name: datas[i]['name'],
            level: level,
            parentId: datas[i]['parentId'],
          });
        }
        if (datas[i].hasOwnProperty('children')) {
          formateData(datas[i].children, level);
        }
      }
    }

    return arr;
  };

  // 所有转换后的节点数据的数组。
  function formatToNodeData(data: EnergyUnitTreeItem[]): EnergyUnitTreeItemDataNode[] {
    return data.map(i => {
      return {
        title: i.name, // title: 节点的名称，
        key: i.energyUnitId, // key: 节点的唯一标识符，
        children: i.children && i.children.length > 0 ? formatToNodeData(i.children) : [],
        disabled: i.disabled,
        parentId: i.parentId,
      };
    });
  }

  const dataList: { key: React.Key; title: string; disabled?: boolean }[] = [];
  // 接受一个类型为DataNode[]的参数data。遍历输入的数据数组，并将每个节点的key和title属性以对象的形式添加到dataList数组中。如果当前节点有子节点，则递归调用
  const generateList = (data: DataNode[]) => {
    for (let i = 0; i < data.length; i++) {
      const node = data[i];
      const { key, title } = node;
      dataList.push({ key, title: title as string });
      if (node.children) {
        generateList(node.children);
      }
    }
  };

  // 抄表对象options
  const unitGroupOption = useMemo(() => {
    if (unitGroup.length > 0) {
      return unitGroup.map(i => ({
        label: i.name,
        value: i.id,
      }));
    }
  }, [unitGroup]);

  useEffect(() => {
    if (unitCheckedKeys.length > 0) {
      getmeterTypeList({ ids: unitCheckedKeys }).then((res: any) => {
        setAccountUnitList(res.list ?? []);
      }); //能源覆盖介质
    }
  }, [unitCheckedKeys]);

  useEffect(() => {
    const options = convertToArrayOfOptions(
      treeToList,
      !isNil(form.getFieldValue('meterReadingObjetIds')) ? form.getFieldValue('meterReadingObjetIds') : []
    );
    setMeterReadingObjs(options);
    console.log(options);
    // updateCostCenterConfig(updatedCostCenterConfig);
  }, [form, treeToList]);
  // 能源覆盖介质options
  const accountUnitOptions = useMemo(() => {
    return (accountUnitList ?? []).map(i => ({ label: i.energyName, value: i.id }));
  }, [accountUnitList]);

  const getLevels = (keys: number[], dataList: { [x: string]: any }) => {
    const levels: { [key: number]: number } = {};
    for (const key of keys) {
      const foundItem = dataList.find((item: { energyUnitId: number; level: number }) => item.energyUnitId === key);
      if (foundItem) {
        levels[key] = foundItem.level;
      }
    }

    return levels;
  };

  const treeData = useMemo(() => {
    const loop = (data: DataNode[]): DataNode[] =>
      data.map(item => {
        const strTitle = item.title as string;
        const title = <span>{strTitle}</span>;
        if (item.children) {
          return {
            title,
            key: item.key,
            children: loop(item.children),
            disabled: item.disabled,
            selectable: true,
          };
        }
        return {
          title,
          key: item.key,
          disabled: item.disabled,
          selectable: true,
        };
      });
    return loop(treeNodeData);
  }, [treeNodeData]);

  const onExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  // 获取所有父节点
  const getParentKey = (key: React.Key, data: DataNode[], keyList: React.Key[]): number[] => {
    for (const node of data) {
      keyList.push(node.key);
      if (node.key === key) return keyList as number[];
      if (node.children) {
        const findChildren = getParentKey(key, node.children, keyList as unknown as number[]);
        if (findChildren?.length) return findChildren;
      }
      keyList.pop();
    }
    return [];
  };

  const findClosestSelectedParent = (nodeId: number): number => {
    const node = treeToList.find((item: any) => item.energyUnitId === nodeId);
    if (node) {
      if (node.parentId === 0) {
        return 0;
      } else if (unitCheckedKeys.includes(node.parentId)) {
        return node.parentId;
      } else {
        return findClosestSelectedParent(node.parentId);
      }
    }
    return 0; // 如果找不到节点，默认返回0
  };

  useEffect(() => {
    if (!treeSwitch) {
      const disKeys = unitCheckedKeys
        .map((key: number) => {
          let keyList: number[] = [];
          getParentKey(key, treeData, keyList);
          const childKeys = getChildKeys(treeData, key);
          console.log('111', childKeys);
          const tmpList = [...keyList, ...childKeys];
          return tmpList;
        })
        .flat();
      // treeData中disabled为true的key
      const prevDisabledKeys = getDisabledKeys(prevTreeData);
      const tmpKeyList: number[] = Array.from(new Set([...disKeys, ...prevDisabledKeys]));

      unitCheckedKeys.forEach(m => {
        tmpKeyList.splice(tmpKeyList.indexOf(m), 1);
      });

      updateNode(tmpKeyList, treeData);
      setTreeNodeData(treeData);
    } else {
      setTreeNodeData(prevTreeData);
    }

    if (unitCheckedKeys.length === 0) return;
    const parentIds = unitCheckedKeys.map(nodeId => findClosestSelectedParent(nodeId));
    const levelsOfCheckedKeys = getLevels(unitCheckedKeys, treeToList);
    const meterReadingObjects = unitCheckedKeys.map((id, index) => ({
      id,
      level: levelsOfCheckedKeys[id] || 0, // 如果找不到对应的 level，设置为 0 或者其他默认值
      parentReadingObjectId: parentIds[index],
    }));
    setMeterReadingObjects(meterReadingObjects);
    // 获取当前点击的节点的上级KEY

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCheckedKey, prevTreeData, treeSwitch, unitCheckedKeys]);

  const generateListTree = (data: any, dataList: Key[]) => {
    for (let i = 0; i < data.length; i++) {
      const node = data[i];
      const { value } = node;
      dataList.push(value);
      if (node.children) {
        generateListTree(node.children, dataList);
      }
    }
  };
  function formatToTreeData(data: any, searchValue: any) {
    return data
      .map((i: any) => {
        const strTitle = i.energyUnitName;
        const index = strTitle.indexOf(searchValue);
        const beforeStr = strTitle.substring(0, index);
        const afterStr = strTitle.slice(index + searchValue.length);
        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span className={styles.siteTreeSearchValue}>{searchValue}</span>
              {afterStr}
            </span>
          ) : (
            strTitle
          );

        if (i.list && i.list.length > 0) {
          return {
            value: i.energyUnitId,
            title: title,
            key: i.energyUnitId,
            disabled: !i.canChecked,
            children: i.list && i.list.length > 0 ? formatToTreeData(i.list, searchValue) : [],
          };
        }

        return index > -1
          ? {
              value: i.energyUnitId,
              title: title,
              key: i.energyUnitId,
              disabled: !i.canChecked,
            }
          : {
              value: i.energyUnitId,
              title: null,
              key: i.energyUnitId,
              disabled: !i.canChecked,
            };
      })
      .filter((item: any) => !!item.title);
  }

  const unitTreeOptions = useMemo(() => {
    if (unitTree && unitTree.length !== 0) {
      return formatToTreeData(unitTree, searchValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitTree, searchValue]);

  // 选中的keyID
  const onCheck = (keys: any, e: any) => {
    const checkedKeys = keys.checked.map(Number);

    if (unitTreeOptions) {
      generateListTree(unitTreeOptions, checkedKeys);
    }
    //存给计费配置的超标对象下拉框的值
    setMeterReadingObjs(convertToArrayOfOptions(treeToList, keys.checked));

    setUnitCheckedKeys(keys.checked);
    const checked = keys.checked.some((k: number) => k === e.node.key);
    if (!treeSwitch) {
      setCurrentCheckedKey(checked ? e.node.key : undefined);
    }
  };

  // 获取node中所有的key
  const getNodeKeys = (data: DataNode[]): number[] => {
    let keys: number[] = [];
    function getKey(data: DataNode[]) {
      data.forEach((element: any) => {
        if (element.children.length) {
          getKey(element.children);
        }
        keys.push(element.key);
      });
    }
    getKey(data);
    return keys;
  };

  // 获取treeData中disabled为true的key
  const getDisabledKeys = (data: DataNode[]): number[] => {
    let keys: number[] = [];
    function getKey(data: DataNode[]) {
      data.forEach((element: any) => {
        if (element.children.length) {
          getKey(element.children);
        }
        if (element.disabled) {
          keys.push(element.key);
        }
      });
    }
    getKey(data);
    return keys;
  };

  const getBeforeSelectDisabledKeys = (data: DataNode[], selectedKeys: number[]): number[] => {
    let keys: number[] = [];
    function getKey(data: DataNode[]) {
      data.forEach((element: any) => {
        if (element.children.length) {
          getKey(element.children);
        }
        if (element.disabled && selectedKeys.includes(element.key as number)) {
          keys.push(element.key as number);
        }
      });
    }
    getKey(data);
    return keys;
  };

  //更新给定keys的禁用状态
  const updateNode = (keys: number[], tree: any[]) => {
    for (const item of tree) {
      // 包含上级或者下级就操作一下
      item.disabled = keys.includes(item.key);

      if (item.children && item.children.length) {
        updateNode(keys, item.children);
      }
    }
  };

  // 获取当前节点的所有孩子节点的key
  const getChildKeys = (tree: DataNode[], key: number) => {
    let keys: number[] = [];
    function getKey(key: number, data: DataNode[]) {
      data.forEach((element: any) => {
        if (element.children.length) {
          getKey(key, element.children);
        }
        if (element.key === key) {
          const allKeys = getNodeKeys(element.children);
          keys = [...keys, ...allKeys, key];
        }
      });
    }
    getKey(key, tree);
    return keys;
  };

  const getAllNodeKeys = (treeData: EnergyUnitTreeItemDataNode[], keys: Array<string> = []) => {
    for (const node of treeData) {
      if (!node.disabled) {
        keys.push(node.key); // 只将未禁用的节点的 key 值添加到 keys 数组中
      }
      if (node.children) {
        getAllNodeKeys(node.children, keys); // 递归处理子节点
      }
    }
    return keys;
  };

  const allCheck = (allSelectFlag: boolean) => {
    const keys = getAllNodeKeys(prevTreeData);
    const numericKeys = keys.map(key => (typeof key === 'string' ? parseInt(key, 10) : key));
    if (allSelectFlag) {
      setMeterReadingObjs(convertToArrayOfOptions(treeToList, []));
      setUnitCheckedKeys([]);
    } else {
      setMeterReadingObjs(convertToArrayOfOptions(treeToList, numericKeys));
      setUnitCheckedKeys(numericKeys);
    }
  };
  //将 node.children 中的结果合并到一个数组中
  const renderSelectedData = (data: DataNode[], selectedKeys: number[]) => {
    const result = data.reduce((acc: any, node: any) => {
      if (selectedKeys.includes(node.key)) {
        acc.push(
          <Tag className={styles.selectTag} key={node.key} border="solid">
            <span>{node.title}</span>
          </Tag>
        );
      }
      if (node.children) {
        acc = acc.concat(renderSelectedData(node.children, selectedKeys));
      }
      return acc;
    }, []);

    return result;
  };

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      const {
        projectName,
        energyGroupId,
        energyMediumIds,
        firstReadingTime,
        adjustmentBillingMethodType,
        amortizedBillingMethodType,
        costCenterConfig,
        specialMeterReadingObjectIds,
        defaultGetValueWay,
        rateValueType,
      } = values;
      if (!isNil(costCenterConfig)) {
        const uniqueNames = new Set(costCenterConfig?.map((item: any) => item.name));
        if (uniqueNames.size !== costCenterConfig.length) {
          message.error('成本中心名称不能重复!');
          return;
        }
        const allIdsExist = costCenterConfig.every((config: { readingObjectIds: any[] }) => {
          return config.readingObjectIds.every((id: number | undefined) =>
            meterReadingObjs.some(obj => obj.value === id)
          );
        });

        // 如果没有匹配的值，显示提示消息
        if (!allIdsExist) {
          message.error('成本中心配置项中抄表对象有误，请检查！');
          return;
        }
      }
      const params: V2UniverseMeterReadingProjectEditPostRequest = {
        id: Number(id),
        projectName,
        energyGroupId, //用能分析组id
        meterReadingObjects: meterReadingObjects, //抄表对象
        energyMediumIds, //能源工质id
        readingPeriod: Number(scheduleType), //抄表周期
        firstReadingTime: dayjs(firstReadingTime).unix().valueOf(), //首次抄表时时间戳
        adjustmentBillingMethodType,
        amortizedBillingMethodType,
        costCenterConfig,
        rateValueType,
        specialMeterReadingObjectIds,
        defaultGetValueWay,
      };
      apiV2UniverseMeterReadingProjectEditPost(params).then(res => {
        navigate('/energy/consumption/meterReadingProject');
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.content}>
      <FormTitle title="抄表项目详情"></FormTitle>

      <Form
        form={form}
        labelCol={{ flex: '128px' }}
        labelAlign="left"
        onFinish={v => {
          onFinish();
        }}
      >
        <SubContent className="mb-8">
          <Row>
            <Col span={10}>
              <Form.Item
                label="抄表项目"
                name="projectName"
                rules={[
                  { required: true, message: '请输入抄表项目' },
                  { max: 15, message: '最多输入15字' },
                  { pattern: /^((?![\\/:*?"<>|]).)+$/, message: '请确保产出物名称中不含下列字符： / : * ? " < > |' },
                ]}
              >
                <Input disabled={!Flag} placeholder="请输入抄表项目名称" />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>

        <SubContent title="抄表配置" className="mb-8">
          <Row>
            <Col span={10}>
              <Form.Item label="多层级对象抄表" name="hierarchicalMeterReading" valuePropName="checked">
                <Switch
                  onChange={() => {
                    setTreeSwitch(!treeSwitch);
                    if (!treeSwitch) {
                      // 如果开关关闭，禁用上下级节点
                      const disabledKeys = getBeforeSelectDisabledKeys(treeData, unitCheckedKeys);
                      setUnitCheckedKeys(unitCheckedKeys.filter(key => !disabledKeys.includes(key)));
                    } else {
                      setUnitCheckedKeys([]);
                      // 重置全选开关
                      setAllSelectFlag(false);
                    }
                  }}
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Form.Item
                label="抄表对象"
                name="energyGroupId"
                style={{ marginBottom: 10 }}
                rules={[{ required: true, message: '请选择抄表对象' }]}
              >
                <Select placeholder="请选择抄表对象" disabled={true} options={unitGroupOption} />
              </Form.Item>
              {treeSwitch && (
                <Row>
                  <Col span={20}>
                    <Form.Item name="allSelected" valuePropName="checked" style={{ marginBottom: 10 }}>
                      <Checkbox
                        className={styles.allCheck}
                        disabled={!Flag}
                        onChange={e => {
                          setAllSelectFlag(!allSelectFlag);
                          allCheck(allSelectFlag);
                        }}
                      >
                        全选
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Form.Item style={{ display: TreeFlag ? 'block' : 'none', marginBottom: 10 }} name="meterReadingObjetIds">
                <div>
                  <Spin spinning={loading}>
                    <div
                      className={styles.treeBackground}
                      style={{ overflow: 'auto', maxHeight: 360, maxWidth: 474, minWidth: 340 }}
                    >
                      <Tree
                        disabled={!Flag}
                        checkable
                        checkStrictly
                        checkedKeys={unitCheckedKeys}
                        onExpand={onExpand}
                        expandedKeys={expandedKeys}
                        autoExpandParent={autoExpandParent}
                        treeData={treeData}
                        onCheck={onCheck}
                      />
                    </div>
                  </Spin>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <div className={styles.selectName}>
            <div>{renderSelectedData(prevTreeData, unitCheckedKeys)}</div>
          </div>
          <Row>
            <Col span={10}>
              <Form.Item
                label="覆盖能源介质"
                name="energyMediumIds"
                rules={[{ required: true, message: '请选择覆盖能源介质' }]}
              >
                <Select
                  disabled={!Flag}
                  value={detail}
                  options={accountUnitOptions}
                  placeholder="请选择覆盖能源介质"
                  onChange={v => {
                    setdetail(v);
                  }}
                  mode="multiple"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Form.Item name="readingPeriod" label="抄表周期" rules={[{ required: true, message: '请选择抄表周期' }]}>
                <Select
                  placeholder="请选择抄表周期"
                  disabled={true}
                  options={[
                    { value: ReadingMeterPeriod.YEAR, label: ReadingMeterPeriodFormat[ReadingMeterPeriod.YEAR] },
                    { value: ReadingMeterPeriod.MONTH, label: ReadingMeterPeriodFormat[ReadingMeterPeriod.MONTH] },
                    { value: ReadingMeterPeriod.WEEK, label: ReadingMeterPeriodFormat[ReadingMeterPeriod.WEEK] },
                    { value: ReadingMeterPeriod.DAY, label: ReadingMeterPeriodFormat[ReadingMeterPeriod.DAY] },
                  ]}
                  value={scheduleType}
                  onChange={val => {
                    setScheduleType(val);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Form.Item
                style={{ marginBottom: 5 }}
                label="首次抄表时间"
                name="firstReadingTime"
                rules={[{ required: true, message: '请选择首次抄表时间' }]}
              >
                <DatePicker
                  disabled={true}
                  className={styles.DatePicker}
                  showNow={false}
                  format={'YYYY-MM-DD HH:mm'}
                  showTime={{
                    format: 'YYYY-MM-DD HH:mm',
                  }}
                  disabledDate={(currentDate: Dayjs) => {
                    return currentDate.isBefore(dayjs(), 'minute');
                  }}
                  onChange={(val: SetStateAction<dayjs.Dayjs | undefined> | null) => {
                    if (val !== null) {
                      setCheckTime(val);
                    }
                  }}
                />
              </Form.Item>
              <Space className={styles.message}>{Message}</Space>
            </Col>
          </Row>
          {specialFlag && (
            <>
              <Row>
                <Col span={18}>
                  <Form.Item
                    label="默认尖峰平谷取值"
                    name="defaultGetValueWay"
                    style={{ marginBottom: 5 }}
                    rules={[{ required: true }]}
                  >
                    <Radio.Group
                      disabled={!Flag}
                      onChange={(e: any) => {
                        setpeakTipFlag(e.target.value === 2 ? true : false);
                      }}
                    >
                      <Radio value={1} style={{ width: '180px' }}>
                        按设备数据属性取值
                      </Radio>
                      <Radio value={2} style={{ width: '275px' }}>
                        按时段划分计算(回路尖峰平谷设置)
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  {peakTipFlag && (
                    <div className={styles.peakTip}>
                      <InfoCircleOutlined
                        style={{ color: 'var(--warning-color)', fontSize: '16px', paddingRight: '2px' }}
                      />
                      会因为采集数据不完整，产生计算误差。
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={10}>
                  <Form.Item style={{ marginBottom: 5 }} label="特殊抄表对象设置" name="specialMeterReadingObjectIds">
                    <TreeSelect
                      showSearch
                      disabled={!Flag}
                      style={{ width: '100%' }}
                      treeNodeFilterProp="title"
                      fieldNames={{ label: 'title', value: 'value' }}
                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                      placeholder="请选择"
                      allowClear
                      treeDefaultExpandAll
                      treeData={unitTreeOptions}
                      showCheckedStrategy={'SHOW_ALL'}
                      onFocus={() => {
                        apiV2EnergyConsumptionEvaluationAnalysisUnitFormToTreePost({
                          energyUnitIds: unitCheckedKeys,
                        }).then(res => {
                          setUnitTree(res.list);
                        });
                      }}
                      multiple
                      onSearch={searchValue => {
                        setSearchValue(searchValue);
                      }}
                      onChange={v => {
                        setSearchValue(v);
                      }}
                      filterTreeNode={(inputValue, treeNode) => {
                        const title = treeNode.title;
                        if (React.isValidElement(title) && title.props && title.props.children) {
                          const textContent = title.props.children;
                          if (textContent.length === 3) {
                            return true;
                          } else {
                            return false;
                          }
                        }
                        if (typeof title === 'string') {
                          return title.includes(inputValue);
                        }
                        return false;
                      }}
                    />
                  </Form.Item>
                  <div className={styles.specialTip}>
                    <InfoCircleOutlined style={{ color: 'var(--warning-color)', fontSize: '16px' }} />
                    可选择部分抄表对象，为【{defaultGetValueWay === 1 ? '按时段划分计算' : '按设备数据属性取值'}】
                  </div>
                </Col>
              </Row>
            </>
          )}
        </SubContent>

        <SubContent title="计费配置">
          <BillingConfiguration
            setSearchValue={setSearchValue}
            meterReadingObjs={meterReadingObjs}
            data={data}
            form={form}
            Flag={Flag}
            unitCheckedKeys={unitCheckedKeys}
            unitTreeOptions={unitTreeOptions}
            setUnitTree={setUnitTree}
            setTableData={setTableData}
            tableData={tableData}
          ></BillingConfiguration>
        </SubContent>
        {Flag && (
          <Space className="sticky-footer">
            <Button htmlType="submit" type="primary">
              保存
            </Button>
            <Button
              onClick={() => {
                form.setFieldsValue({
                  projectName: undefined,
                  meterReadingObjetIds: undefined,
                  energyMediumIds: undefined,
                  readingPeriod: undefined,
                  firstReadingTime: undefined,
                });
                setMessage('');
                setTreeFlag(false);
                navigate('/energy/consumption/meterReadingProject');
                setUnitCheckedKeys([]);
              }}
            >
              取消
            </Button>
          </Space>
        )}
        {!Flag && (
          <Space className="sticky-footer" size={8}>
            <Button
              type="primary"
              onClick={() => {
                setFlag(true);
              }}
            >
              编辑
            </Button>
            <Button
              onClick={() => {
                navigate(`/energy/consumption/meterReadingProject?${urlSearchParams.toString()}`);
              }}
            >
              返回
            </Button>
          </Space>
        )}
      </Form>
    </Wrapper>
  );
};

export default CreateMeterReading;
