import {
  DistributeStatus,
  DistributeStatusDisplay,
  findNetworkDetailById,
  Gateway,
  networkConfigsDistribute,
  NetworkDetail,
  NetworkingTypeDisPlay,
} from '@/api/netWorking';
import {
  EllipsisSpan,
  SubContent,
  Table,
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  FormTitle,
  Form,
  BaseInfoContent,
} from '@maxtropy/components';
import { Space, Spin } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ShowInput from '@/shared/components/ShowInput';
import { GatewayNetStatus, GatewayNetStatusDisplay } from '@/shared/types';
import styles from './index.module.scss';
import qs from 'qs';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';

const columns: ColumnsType<Gateway> = [
  {
    title: '网关编号',
    dataIndex: 'gatewayNo',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '网关名称',
    dataIndex: 'gatewayName',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '所在组织',
    dataIndex: 'orgName',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'gatewayStatus',
    ellipsis: { showTitle: true },
    render: (v: GatewayNetStatus) => <EllipsisSpan value={GatewayNetStatusDisplay[v]} />,
  },
  {
    title: 'IP地址',
    dataIndex: 'gatewayIp',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '最新下发时间',
    dataIndex: 'gatewayDistributeTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : null} />,
  },
  {
    title: '失败原因',
    dataIndex: 'gatewayDistributeFailureReason',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
];
const DetailNetworking: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const [dataSource, setDataSource] = useState<Gateway[]>([]);
  const [detail, setDetail] = useState<NetworkDetail>();
  const [loading, setLoading] = useState(false);
  const hasExportBtn = useHasPermission(PermissionsType.B_POINTEXPORT); // 导出点表

  useEffect(() => {
    if (id) {
      setLoading(true);
      findNetworkDetailById(id)
        .then(res => {
          if (res) {
            setDetail(res);
            setDataSource(res.gatewayList);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      ellipsis: { showTitle: true },
      render: (_v: any, record: Gateway) => {
        return (
          <Space size={16}>
            <Button
              disabled={
                detail?.distributeStatus === DistributeStatus.NOTSTART ||
                record?.gatewayDistributeStatus === DistributeStatus.STARTING
              }
              type="link"
              onClick={() => {
                if (id) {
                  networkConfigsDistribute(id, [record.gatewayId]).then(res => {
                    if (res.flag) {
                      setLoading(true);
                      findNetworkDetailById(id)
                        .then(res => {
                          if (res) {
                            setDetail(res);
                            setDataSource(res.gatewayList);
                          }
                        })
                        .finally(() => {
                          setLoading(false);
                        });
                    }
                  });
                }
              }}
            >
              重新下发
            </Button>
            {hasExportBtn && (
              <Button
                type="link"
                onClick={() => {
                  window.open(
                    `/api/v2/network/gateway/point/export` +
                      qs.stringify(
                        {
                          id: id,
                          gatewayId: record.gatewayId,
                        },
                        { addQueryPrefix: true }
                      )
                  );
                }}
              >
                导出点表
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  buildColumns.splice(5, 0, {
    title: '最新下发状态',
    ellipsis: { showTitle: true },
    render: () => <EllipsisSpan value={DistributeStatusDisplay[detail?.distributeStatus as DistributeStatus]} />,
  });

  const routes = [{ name: '查看组网' }];

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title="查看组网" />
      <SubContent>
        <Spin spinning={loading}>
          <Form isOrigin={true} style={{ marginBottom: 24 }} form={form}>
            <BaseInfoContent colNum={3} layout="horizontal">
              <Form.Item label="组网名称">
                <ShowInput value={detail?.netName} />
              </Form.Item>

              <Form.Item label="组网类型">
                <ShowInput value={detail?.netType ? NetworkingTypeDisPlay[detail.netType] : '--'} />
              </Form.Item>
            </BaseInfoContent>
          </Form>
          <span style={{ color: 'rgba(255, 255, 255, 0.65)' }}>组网网关:</span>
          <Table style={{ marginBottom: 30 }} columns={buildColumns} rowKey="gatewayId" dataSource={dataSource} />
          <Space>
            <Button>
              <Link to={'/device/networking/networkingList'}>返回</Link>
            </Button>
          </Space>
        </Spin>
      </SubContent>
    </Wrapper>
  );
};

export default DetailNetworking;
