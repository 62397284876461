import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Form, Input, Modal, Upload } from '@maxtropy/components';
import { Row, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useMemo, useState } from 'react';
import ShowInput from '@/shared/components/ShowInput';
import { TableRow } from '..';
import {
  apiV2WorkProcedureAddPost,
  apiV2WorkProcedureCheckCodeUniquePost,
  apiV2WorkProcedureCheckNameUniquePost,
  apiV2WorkProcedureDetailPost,
  apiV2WorkProcedureEditPost,
} from '@maxtropy/device-customer-apis-v2';
import { debounce, isNil } from 'lodash-es';
import ImageProcesss from '../../../assets/productionProcesse.png';

interface Iprops {
  type?: string;
  data?: TableRow;
  cancel?: () => void;
  confirm?: () => void;
}
const ProcessModalOp = ({ type, data, cancel, confirm }: Iprops) => {
  const [form] = useForm();
  const [Flag, setFlag] = useState<boolean>(false);

  useEffect(() => {
    if (type !== 'add' && data && data.id) {
      apiV2WorkProcedureDetailPost({ id: data.id }).then(res => {
        form.setFieldsValue(res);
        setFlag(isNil(res.iconKey) ? true : false);
      });
    }
  }, [type, data, form]);

  const title = useMemo(() => {
    return type === 'add' ? '新建' : type === 'edit' ? '编辑' : '查看';
  }, [type]);

  // 是否被映射
  const codeCanChange = useMemo(() => {
    return data?.isUse || type === 'view';
  }, [data, type]);

  const confirmBtn = () => {
    if (type === 'view') {
      cancel?.();
      return;
    }
    form
      .validateFields()
      .then(res => {
        if (data && data.id) {
          apiV2WorkProcedureEditPost({
            ...res,
            id: data.id,
          }).then(() => {
            confirm?.();
          });
          return;
        }
        apiV2WorkProcedureAddPost({
          ...res,
        }).then(res => {
          confirm?.();
        });
      })
      .catch(e => {});
  };

  return (
    <>
      <Modal
        title={`${title}工序信息`}
        open
        onCancel={() => cancel?.()}
        onOk={() => {
          const cannotSubmit =
            form.getFieldsError(['code'])[0].errors.length > 0 || form.getFieldsError(['name'])[0].errors.length > 0;
          if (cannotSubmit) return;
          form.submit();
        }}
      >
        <Form form={form} onFinish={confirmBtn} style={{ padding: '8px 48px' }} labelCol={{ flex: '128px' }}>
          <Form.Item
            label="工序编码"
            name="code"
            rules={[
              { required: !codeCanChange },
              {
                validator: (_rule: any, value: any, _callback: any) => {
                  if (!value) return Promise.resolve();
                  let reg = /[\\\\/*?:"<>|\u4E00-\u9FFF]+/;
                  if (reg.test(value)) {
                    return Promise.reject(new Error('编码为字母，数字，符号的组合,  不包括\\ / * ? : "<> | 字符'));
                  }
                  return Promise.resolve();
                },
              },
            ]}
            extra={
              type !== 'view' && (
                <Row justify="start">
                  <Space>
                    <ExclamationCircleOutlined style={{ color: 'var(--warning-color)' }} />
                    编码在能耗单元映射后不可被修改
                  </Space>
                </Row>
              )
            }
          >
            {codeCanChange ? (
              <ShowInput />
            ) : (
              <Input
                maxLength={32}
                onChange={debounce((e: any) => {
                  if (isNil(e.target.value) || e.target.value === '') return;
                  apiV2WorkProcedureCheckCodeUniquePost({
                    id: data?.id,
                    code: e.target.value,
                  }).then(res => {
                    if (isNil(res)) return;
                    if (!res.unique) {
                      form.setFields([
                        {
                          name: 'code',
                          errors: ['同租户下的工序编码重复'],
                        },
                      ]);
                    } else {
                      form.setFields([
                        {
                          name: 'code',
                          errors: [],
                        },
                      ]);
                    }
                  });
                }, 500)}
              ></Input>
            )}
          </Form.Item>
          <Form.Item
            label="工序名称"
            name="name"
            rules={[
              { required: type !== 'view' },
              {
                validator: (_rule: any, value: any, _callback: any) => {
                  if (!value) return Promise.resolve();
                  let reg = /[\\\\/*?:"<>|]+/;
                  if (reg.test(value)) {
                    return Promise.reject(
                      new Error('名称为汉字，字母，数字，符号的组合,  不包括\\ / * ? : "<> | 字符')
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            {type === 'view' ? (
              <ShowInput />
            ) : (
              <Input
                maxLength={32}
                onChange={debounce((e: any) => {
                  if (isNil(e.target.value) || e.target.value === '') return;
                  apiV2WorkProcedureCheckNameUniquePost({
                    id: data?.id,
                    name: e.target.value,
                  }).then(res => {
                    if (isNil(res)) return;
                    if (!res.unique) {
                      form.setFields([
                        {
                          name: 'name',
                          errors: ['同租户下的工序名称重复'],
                        },
                      ]);
                    } else {
                      form.setFields([
                        {
                          name: 'name',
                          errors: [],
                        },
                      ]);
                    }
                  });
                }, 500)}
              ></Input>
            )}
          </Form.Item>
          {type === 'view' && Flag ? (
            <Form.Item name="iconKey" label="工序图标">
              <img src={ImageProcesss} alt="" style={{ width: '64px', height: '64px' }}></img>
            </Form.Item>
          ) : (
            <Form.Item name="iconKey" label="工序图标" extra="支持jpg、jpeg、png格式，文件小于1MB">
              <Upload disabled={type === 'view'} fileSize={1} />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default ProcessModalOp;
