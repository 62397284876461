import {
  Table,
  Button,
  useBreadcrumbRoutes,
  Wrapper,
  Modal,
  Select,
  InputNumber,
  useUpdate,
  Form,
  FormTitle,
} from '@maxtropy/components';
import { FC, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  V2WorkCenterTreePostResponse,
  apiV2EnergyConsumptionWorkProcedureCompositeValuePost,
  apiV2ProductionBaseTopologyConfigGetPost,
  apiV2ProductionBaseTopologyConfigUpdatePost,
  apiV2ProductionBaseTopoTreePost,
  apiV2EnergyConsumptionProductionBaseAggrMomValuePost,
  apiV2EnergyConsumptionProductionBaseAggrValuePost,
} from '@maxtropy/device-customer-apis-v2';
import { Graph } from '@antv/x6';
import {
  FullscreenExitOutlined,
  FullscreenOutlined,
  LeftOutlined,
  RightOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import styles from './index.module.scss';
import { drawGraph, initGraph } from './utils/draw';
import { pollingInterval } from './components/Swiper/utils';
import registerX6 from './utils/registerX6';
import { goFullscreen } from '@/pages/EnergyOverview/utils';
import { Space } from 'antd';
import PieEcharts from './components/PieEcharts';
import StatisticalRanking from './components/StatisticalRanking';
import WorkCenterTop from './components/WorkCenterTop';
import WorkerCenterSwiper from './components/Swiper';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import ShowInput from '@/shared/components/ShowInput';
import { PieCostItem } from './components/PieEcharts';
import { EnergyData } from './components/Swiper';
import { rankData } from './components/StatisticalRanking';
import { useRequest } from 'ahooks';
import { useSize } from 'ahooks';
import { setFitScale } from '../WaterUseOverview/utils';
import { setFitScaleFull } from './utils/index';
import { baseCenterTypes, workCenterHierarchyType } from './components/WorkCenterTop';
export type NodeData = Exclude<V2WorkCenterTreePostResponse['list'], undefined>[number];

export enum TopologyDisplay {
  EnergyUnitNode = 1,
  WorkStation = 2,
  ProcessNode = 3,
}

export const TopologyDisplayFormat = {
  [TopologyDisplay.EnergyUnitNode]: '生产基地-工作中心-工序-工站-用能单元',
  [TopologyDisplay.WorkStation]: '生产基地-工作中心-工序-工站',
  [TopologyDisplay.ProcessNode]: '生产基地-工作中心-工序',
};

export const formateCommon: Record<string, string> = {
  D: 'YYYY-MM-DD',
  M: 'YYYY-MM',
  Y: 'YYYY',
};

const WorkCenterTopo: FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const containerRef = useRef<HTMLDivElement>(null);
  const wrapRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>();
  const wrapperFullRef = useRef<HTMLDivElement>(null);
  const size = useSize(wrapperFullRef);

  const [scaleRate, setScaleRate] = useState<number>();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const prevCurrentDateType = urlSearchParams.get('currentDateType') || undefined;
  const prevCurrentDate = urlSearchParams.get('currentDate') || undefined;
  const prevProductionBaseId = urlSearchParams.get('productionBaseId') || undefined;
  const prevWorkCenterId = urlSearchParams.get('prevWorkCenterId') || undefined;

  const graphRef = useRef<Graph>();

  const [form] = Form.useForm();
  const [settingForm] = Form.useForm();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isFull, setIsFull] = useState<boolean>(false);
  const [openSider, setOpenSider] = useState(true); // 左侧树形toggle

  const [updateState, updateFn] = useUpdate();

  const [choseWorkerCenterIds, setChoseWorkCenterIds] = useState<number[]>([]);
  const [currentDateType, setCurrentDateType] = useState<string>(prevCurrentDateType || 'M'); //当前日期选择器选择类型
  const [currentDate, setCurrentDate] = useState<string>(
    dayjs(prevCurrentDate).format(formateCommon[prevCurrentDateType || 'M'])
  );
  const [rankData, setRankData] = useState<rankData[]>([]);
  const [currentSettingType, setCurrentSettingType] = useState<number>(1);
  const [pieData, setPieData] = useState<PieCostItem[]>([]);
  const [aggrValue, setAggrValue] = useState<EnergyData[]>([]);

  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [areaHeight, setAreaHeight] = useState<number>();
  const [choseproductionBaseId, setChoseProductionBaseId] = useState<number>();
  const [BaseCenterHierarchyOptions, setBaseCenterHierarchyOptions] = useState<baseCenterTypes[]>([]);

  useEffect(() => {
    console.log((size?.width ?? 0) / 1920);
    if (!isFull) {
      setFitScale(wrapperRef.current, size?.width, size?.height);
      setScaleRate((size?.width ?? 0) / 1920);
    } else {
      setFitScaleFull(wrapperRef.current, size?.width, size?.height);
      setScaleRate((size?.width ?? 0) / 1920);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size?.width, isFull, size?.height]);

  useEffect(() => {
    if (isNil(scaleRate)) return;
    if (scaleRate >= 1.5 && scaleRate < 2 && !isFull) {
      setAreaHeight(1005);
    } else if (scaleRate < 1.5 && !isFull) {
      setAreaHeight(1023);
    } else if (scaleRate >= 2 && !isFull) {
      setAreaHeight(970);
    } else if (scaleRate <= 1 && !isFull) {
      setAreaHeight(920);
    } else if (scaleRate <= 1 && isFull) {
      setAreaHeight(970);
    } else if (scaleRate >= 2 && isFull) {
      setAreaHeight(990);
    } else {
      setAreaHeight(980);
    }
  }, [scaleRate, isFull]);

  const updateURLParameter = (key: string, value: any) => {
    let url = window.location.href;
    let urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.set(key, value);
    window.history.replaceState({}, '', `${url.split('?')[0]}?${urlSearchParams.toString()}`);
  };

  useEffect(() => {
    updateURLParameter('currentDateType', currentDateType);
    if (!isNil(choseproductionBaseId)) {
      updateURLParameter('productionBaseId', choseproductionBaseId);
    }
    const timeStamps = getTimestamps(currentDate);
    setStartDate(timeStamps[0].toString());
    setEndDate(timeStamps[1].toString());
    updateURLParameter('fromTime', timeStamps[0].toString());
    updateURLParameter('toTime', timeStamps[1].toString());
    updateURLParameter('currentDate', currentDate);
    updateURLParameter('currentSettingType', currentSettingType);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDateType, currentDate, currentSettingType, choseproductionBaseId]);

  useEffect(() => {
    if (BaseCenterHierarchyOptions && prevProductionBaseId && BaseCenterHierarchyOptions.length > 0) {
      setChoseProductionBaseId(Number(prevProductionBaseId));
      if (!isNil(prevWorkCenterId)) {
        setChoseWorkCenterIds([Number(prevWorkCenterId)]);
        form.setFieldsValue({
          workCenterIds: [Number(prevProductionBaseId), Number(prevWorkCenterId)],
        });
      } else {
        const findData = BaseCenterHierarchyOptions.find((i: baseCenterTypes) => i.id === Number(prevProductionBaseId));
        if (isNil(findData) || isNil(findData?.workCenterHierarchy)) return;
        const resultMap = findData?.workCenterHierarchy?.map((item: workCenterHierarchyType) => [
          Number(prevProductionBaseId),
          item.id,
        ]);
        form.setFieldsValue({
          workCenterIds: resultMap,
        });
        const setFilterSelected = Array.from(new Set(resultMap?.map((item: any) => item[1] as number)));
        setChoseWorkCenterIds(setFilterSelected as number[]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevProductionBaseId, BaseCenterHierarchyOptions]);

  useEffect(() => {
    if (startDate === '' || !choseWorkerCenterIds || !choseproductionBaseId || choseWorkerCenterIds.length === 0)
      return;
    let params = {
      productionBaseId: choseproductionBaseId,
      workCenterIds: choseWorkerCenterIds,
      fromTime: startDate,
      toTime: endDate,
    };
    //饼图
    apiV2EnergyConsumptionProductionBaseAggrMomValuePost(params).then(res => {
      setPieData(res.list as PieCostItem[]);
    });
    //排行榜
    apiV2EnergyConsumptionWorkProcedureCompositeValuePost(params).then(res => {
      setRankData(res.list as rankData[]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choseWorkerCenterIds, startDate, updateState, choseproductionBaseId]);

  //左上角swiper数据
  const { loading } = useRequest(
    () => {
      return apiV2EnergyConsumptionProductionBaseAggrValuePost({
        productionBaseId: choseproductionBaseId,
        workCenterIds: choseWorkerCenterIds,
        fromTime: startDate,
        toTime: endDate,
      }).then(res => {
        setAggrValue(res?.list as EnergyData[]);
      });
    },
    {
      pollingInterval,
      refreshDeps: [choseWorkerCenterIds, startDate, choseproductionBaseId],
      ready:
        !isNil(choseWorkerCenterIds) &&
        startDate !== '' &&
        !isNil(choseproductionBaseId) &&
        choseWorkerCenterIds.length !== 0,
    }
  );

  function addParentId(obj: any, targetLevel: number, parentId: number | null) {
    if (obj.level === targetLevel) {
      parentId = obj.id;
    }

    if (obj.children && obj.children.length > 0) {
      for (let i = 0; i < obj.children.length; i++) {
        obj.children[i].parentId = parentId;
        addParentId(obj.children[i], targetLevel, parentId);
      }
    }
  }
  function addRootId(obj: any, targetLevel: number, rootId: number | null) {
    if (obj.level === targetLevel) {
      rootId = obj.id;
    }

    if (obj.children && obj.children.length > 0) {
      for (let i = 0; i < obj.children.length; i++) {
        obj.children[i].rootId = rootId;
        addRootId(obj.children[i], targetLevel, rootId);
      }
    }
  }

  //拓扑图
  useEffect(() => {
    console.log(choseWorkerCenterIds);
    if (!choseWorkerCenterIds || isNil(choseWorkerCenterIds) || !choseproductionBaseId) return;

    const resizeObserver = new ResizeObserver(entries => {
      const entry = entries[0];
      if (!entry) return;
      const { width, height } = entry.contentRect;
      graphRef.current?.resize(width, height);
    });
    registerX6();
    if (choseWorkerCenterIds.length === 0) return;
    //树结构
    apiV2ProductionBaseTopoTreePost({ id: choseproductionBaseId, workCenterIds: choseWorkerCenterIds }).then(res => {
      if (!res?.list?.[0]) return;
      const filteredList = res?.list[0];
      addParentId(filteredList, 2, null);
      addRootId(filteredList, 1, null);
      if (currentSettingType !== TopologyDisplay.EnergyUnitNode) {
        processFilterList(filteredList);
      }
      const graph = initGraph(containerRef.current!);
      graphRef.current = graph;
      drawGraph(graph, filteredList, currentDateType);
      resizeObserver.observe(wrapRef.current!);
    });
    return () => {
      if (graphRef.current) {
        //不能用dispose销毁画布，clearCells清除画布上的节点
        graphRef.current.clearCells();
        resizeObserver.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSettingType, choseWorkerCenterIds, isFull, choseproductionBaseId]);

  //右侧配置
  useEffect(() => {
    if (isNil(choseproductionBaseId)) return;
    apiV2ProductionBaseTopologyConfigGetPost({
      productionBaseId: Number(choseproductionBaseId) as number,
    }).then(res => {
      settingForm.setFieldsValue({
        topologyType: res.topologyType,
        energyMediumConfig: res.energyMediumConfig,
      });
      setCurrentSettingType(isNil(res.topologyType) ? 1 : (res.topologyType as number));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choseproductionBaseId, updateState]);

  const processChildren = (parent: any, children: any) => {
    for (let i = children.length - 1; i >= 0; i--) {
      const child = children[i];
      if (
        (currentSettingType === 2 && child.level === 5) ||
        (currentSettingType === 3 && (child.level === 5 || child.level === 4))
      ) {
        children.splice(i, 1);
      } else {
        if (child.children && child.children.length > 0) {
          processChildren(child, child.children);
        }
      }
    }

    if (children.length === 0 && parent) {
      parent.children = [];
    }
  };

  const processFilterList = (filterList: any) => {
    if (!filterList.children && !filterList.unitList) {
      return;
    }

    if (filterList.children && filterList.children.length > 0) {
      processChildren(null, filterList.children);
    }
  };

  const getTimestamps = (inputDate: string): string[] => {
    if (currentDateType === 'M') {
      const startDate = dayjs(inputDate).startOf('month').format('YYYY-MM-DD');
      const endDate = dayjs(inputDate).endOf('month').format('YYYY-MM-DD');
      return [startDate, endDate];
    } else if (currentDateType === 'D') {
      const startDate = dayjs(inputDate).startOf('day').format('YYYY-MM-DD');
      const endDate = dayjs(inputDate).endOf('day').format('YYYY-MM-DD');
      return [startDate, endDate];
    } else if (currentDateType === 'Y') {
      const startDate = dayjs(inputDate).startOf('year').format('YYYY-MM-DD');
      const endDate = dayjs(inputDate).endOf('year').format('YYYY-MM-DD');
      return [startDate, endDate];
    } else {
      return ['0', '0'];
    }
  };

  const handleFullScreen = async () => {
    if (isFull) {
      await document.exitFullscreen?.();
    } else {
      await goFullscreen(wrapperFullRef.current!);
    }
    setIsFull(!isFull);
  };
  const handleCancel = () => {
    setModalOpen(false);
  };

  const columns = [
    {
      title: '能源介质（介质单位）',
      dataIndex: 'energyMediumName',
      ellipsis: { showTitle: true },
      render: (_: string, record: any, index: number) => (
        <>
          <Form.Item
            style={{ marginBottom: 0, display: 'inline-block', width: '140', marginRight: 5 }}
            name={['energyMediumConfig', index, 'energyMediumName']}
            initialValue={record.energyMediumName}
          >
            <ShowInput />
          </Form.Item>

          <span>
            <span style={{ marginTop: 5, display: 'inline-block' }}>{'('}</span>
            <Form.Item
              style={{ marginBottom: 0, display: 'inline-block', width: '40' }}
              name={['energyMediumConfig', index, 'physicalUnitGeneralName']}
              initialValue={record.physicalUnitGeneralName}
            >
              <ShowInput />
            </Form.Item>
            <span style={{ marginTop: 5, display: 'inline-block' }}>{')'}</span>
          </span>
        </>
      ),
    },
    {
      title: '碳排放因子/参数(kgCO2e/介质单位)',
      dataIndex: 'carbonEmissionRatio',
      ellipsis: { showTitle: true },
      render: (_: number, record: any, index: number) => (
        <>
          <Form.Item
            style={{ marginBottom: 0 }}
            name={['energyMediumConfig', index, 'carbonEmissionRatio']}
            initialValue={record.carbonEmissionRatio}
          >
            <InputNumber min={0} precision={2} style={{ width: '100%' }} />
          </Form.Item>
        </>
      ),
    },
  ];

  return (
    <>
      <div ref={wrapperFullRef} className={styles.wrapperRef}>
        {/* {!isFull ? ( */}
        <div
          ref={ref => {
            if (ref) {
              wrapperRef.current = ref;
            }
          }}
          className={styles.wrapperRef}
          style={{ overflowY: (scaleRate as number) <= 1 ? 'auto' : 'hidden' }}
        >
          <Wrapper routes={breadcrumbRoutes?.routes ?? []}>
            <div
              className={styles.area_outer}
              style={{
                height: areaHeight,
              }}
            >
              <div className={styles.area_top}>
                <FormTitle
                  title="用能流向拓扑图"
                  style={{ position: 'relative' }}
                  extraContent={
                    <Space size={8}>
                      {!isFull ? (
                        <Button
                          type="primary"
                          icon={<ToolOutlined />}
                          onClick={() => {
                            setModalOpen(true);
                            settingForm.setFieldsValue({ topologyType: currentSettingType });
                          }}
                        >
                          配置
                        </Button>
                      ) : null}
                      <Button
                        type="primary"
                        icon={isFull ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                        onClick={handleFullScreen}
                      >
                        {isFull ? '退出全屏' : '展开全屏'}
                      </Button>
                    </Space>
                  }
                />
                <Form form={form} isOrigin>
                  <WorkCenterTop
                    isFull={isFull}
                    setCurrentDate={setCurrentDate}
                    form={form}
                    setCurrentDateType={setCurrentDateType}
                    currentDateType={currentDateType}
                    setChoseWorkCenterIds={setChoseWorkCenterIds}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    BaseCenterHierarchyOptions={BaseCenterHierarchyOptions}
                    setBaseCenterHierarchyOptions={setBaseCenterHierarchyOptions}
                    choseproductionBaseId={choseproductionBaseId}
                    choseWorkerCenterIds={choseWorkerCenterIds}
                    setChoseProductionBaseId={setChoseProductionBaseId}
                    currentDate={currentDate}
                  ></WorkCenterTop>
                </Form>
              </div>

              <div className={styles.area_bottom}>
                <div className={styles.layOut}>
                  <div
                    className={`${styles.leftSider} ${openSider ? styles.bordered : ''}`}
                    style={{
                      width: openSider ? 430 : 0,
                      opacity: openSider ? 1 : 0,
                    }}
                  >
                    <div className={styles.leftContent}>
                      <WorkerCenterSwiper aggrValue={aggrValue} currentDate={currentDate} />

                      <div style={{ position: 'relative', minHeight: 260 }}>
                        <PieEcharts pieData={pieData} isFull={isFull} openSider={openSider}></PieEcharts>
                      </div>
                      <StatisticalRanking rankData={rankData} />
                    </div>
                  </div>
                  <div className={styles.layout_right} style={{ position: 'relative' }}>
                    <>
                      <div
                        className={styles.toggleBtn}
                        style={{ left: openSider ? -30 : 0 }}
                        onClick={() => setOpenSider(!openSider)}
                      >
                        {openSider ? <LeftOutlined /> : <RightOutlined />}
                      </div>
                      <div className={styles.wrapperStyles} ref={wrapRef}>
                        <div className={styles.container} ref={containerRef}></div>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </Wrapper>
        </div>
      </div>
      <Modal
        title={'配置'}
        size={'normal'}
        bodyScroll
        open={modalOpen}
        onCancel={handleCancel}
        footer={
          <Space size={8}>
            <Button
              onClick={() => {
                setModalOpen(false);
              }}
            >
              取消
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => {
                settingForm.validateFields().then(res => {
                  apiV2ProductionBaseTopologyConfigUpdatePost({
                    productionBaseId: choseproductionBaseId,
                    topologyType: res.topologyType,
                    energyMediumConfig: res.energyMediumConfig,
                  }).then(res => {
                    setModalOpen(false);
                    updateFn();
                  });
                });
              }}
            >
              保存
            </Button>
          </Space>
        }
      >
        <Form form={settingForm} labelAlign="left">
          <Form.Item name="topologyType" label="拓扑展示结构">
            <Select
              options={Object.entries(TopologyDisplayFormat).map(([k, v]) => ({ label: v, value: Number(k) }))}
            ></Select>
          </Form.Item>

          <Form.Item name="energyMediumConfig" label="碳排设置" style={{ marginBottom: 0 }} valuePropName="dataSource">
            <Table columns={columns} rowKey={'energyMediumId'} pagination={false}></Table>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default WorkCenterTopo;
