import {
  SubContent,
  Key,
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  Form,
  DatePicker,
  Modal,
  Select,
  Input,
  TreeSelect,
  Radio,
  InputNumber,
  FormTitle,
} from '@maxtropy/components';
import { Col, Row, Space, Spin } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  CreateElectricitySettlementRulesRequest,
  createSettlementRules,
  ElectricitySaleContractSettlement,
  ElectricitySaleContractSettlementMeterType,
  ElectricitySaleContractSettlementType,
  ElectricitySettlementRulesDetails,
  getElectricitySaleContractSettlementRulesMeterList,
  getElectricitySettlementRulesById,
  HasPreferentialAmount,
  HasPreferentialAmountType,
  HasTimeOfUseElectricityPricePropsType,
  HasTimeOfUseElectricityPriceType,
  SettlementRulesStatusTypeValue,
  updateSettlementRules,
} from '../../../api/electricitySettlementRules';
import styles from './index.module.scss';
import dayjs, { Dayjs } from 'dayjs';
import {
  ElectricitySaleContractStatus,
  ElectricitySaleContractVo,
  getElectricitySaleContractById,
  getElectricitySaleContractMeterList,
  getPropertyList,
  getSaleElectricityContractListByPropertyManagementConfigIdOrOuIdOrSaleClientId,
  PropertyItem,
} from '../../../api/electricitySalesContract';
import qs from 'qs';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import { getOrganizationWithCodeWithCurrent } from '../../../api/device';
import EffectiveMeterForm from './components/EffectiveMeterForm';
import UnitarySystem from './components/UnitarySystem';
import { getSaleClientList, SaleClientInfo } from '../../../api/sale';
import TwoPartSystem from './components/TwoPartSystem';
import DistributedNewEnergy from './components/DistributedNewEnergy';
import ShowInput from '@/shared/components/ShowInput';
import DistributedNewEnergyFull from './components/DistributedNewEnergyFull';
import TwoPartSystemAndNewEnergy from './components/TwoPartSystemAndNewEnergy';
import { InfoCircleOutlined } from '@ant-design/icons';

interface ICreateSettlementRules {
  isEdit?: boolean;
}

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

interface FormProps {
  deductPhotovoltaicMeters?: any[];
  lightingTariffRatio: number | undefined;
  hasLightingTariff?: boolean;
  unitLightingTariffRate?: number;
  customerMcid?: string; // 关联组织
  propertyManagementConfigId?: number; // 所属物业
  name: string; // 结算规则名称
  description: string; // 描述
  effectiveTime?: [Dayjs, Dayjs]; // 生效区间
  saleElectricityContractId: number; // 所属合同
  settlementType: ElectricitySaleContractSettlementType; // 结算类型
  rateType?: number; // 电度电价类型
  standingRate?: number; // 电度单价
  timeOfDayRateChecked?: HasTimeOfUseElectricityPricePropsType[]; // 时段选择
  summitRate?: number; // 尖
  peakRate?: number; // 峰
  plainRate?: number; // 平
  valleyRate?: number; // 谷
  declarationType?: number; // 用电类型
  transformerCapacity?: number; // 变压器容量
  unitCapacityRate?: number; // 容量单价
  declaredDemand?: number; // 申报需量
  unitDeclaredDemandRate?: number; // 申报需量单价
  unitDemandRate?: number; // 需量单价
  hasCapitalContributionRate?: boolean; // 是否有公摊系数
  // capitalContributionRate?: number;// 公摊系数
  // hasServiceCharge: boolean;// 是否有服务费
  // serviceCharge: number;// 服务费
  hasDiscountedRate?: number; // 是否有优惠金额
  discountedType?: number; // 优惠方式
  totalRateDiscountedRatio?: number; // 电度电费总折扣比例
  totalRateDiscountedRate?: number; // 电度电费总优惠金额
  unitRateDiscountedRate?: number; // 电度电费单价优惠金额
  electricityMeters?: any[]; // 电量计量表计
  demandMeters?: any[]; // 需量计量表计
  photovoltaicMeters?: any[]; // 光伏计量表计
  subMeters?: any[]; // 子表计量表计
  totalRateDiscountedQuantity?: number; // 电度电费总优惠量
  hasSubMeterRateDeduct?: boolean; // 是否有子表扣除
  subMeterUnitDemandRate?: number; // 子表需量单价
  subMeterSummitRate?: number; // 子表尖时电价
  subMeterPeakRate?: number; //	子表峰时电价
  subMeterPlainRate?: number; // 子表平时电价
  subMeterValleyRate?: number; //子表谷时电价
  powerFactorRatioCalculationType?: number; //功率因数比值计算规则
}

const DATE_FORMAT = 'YYYY-MM-DD';

const formatPostRequest = (formValues: FormProps): CreateElectricitySettlementRulesRequest | undefined => {
  let params = {
    name: formValues.name,
    description: formValues.description,
    saleElectricityContractId: formValues.saleElectricityContractId,
    settlementType: formValues.settlementType,
    startTime:
      Object.prototype.toString.call(formValues.effectiveTime) === '[object Array]'
        ? formValues.effectiveTime![0].format(DATE_FORMAT)
        : undefined,
    endTime:
      Object.prototype.toString.call(formValues.effectiveTime) === '[object Array]'
        ? formValues.effectiveTime![1].format(DATE_FORMAT)
        : undefined,
  };
  if (formValues.settlementType === ElectricitySaleContractSettlementType.UNITARYSYSTEM) {
    return {
      ...params,
      onePartInfo: {
        timeOfDayRateChecked: formValues.timeOfDayRateChecked,
        rateType: formValues.rateType,
        standingRate: formValues.standingRate,
        summitRate: formValues.summitRate,
        peakRate: formValues.peakRate,
        plainRate: formValues.plainRate,
        valleyRate: formValues.valleyRate,
        hasCapitalContributionRate: formValues.hasCapitalContributionRate,
        hasDiscountedRate: formValues.hasDiscountedRate,
        discountedType: formValues.discountedType,
        totalRateDiscountedRate: formValues.totalRateDiscountedRate,
        unitRateDiscountedRate: formValues.unitRateDiscountedRate,
        totalRateDiscountedRatio: formValues.totalRateDiscountedRatio,
        totalRateDiscountedQuantity: formValues.totalRateDiscountedQuantity,
      },
    };
  } else if (formValues.settlementType === ElectricitySaleContractSettlementType.TWOPARTSYSTEM) {
    return {
      ...params,
      twoPartInfo: {
        timeOfDayRateChecked: formValues.timeOfDayRateChecked,
        declarationType: formValues.declarationType,
        rateType: formValues.rateType,
        transformerCapacity: formValues.transformerCapacity,
        unitCapacityRate: formValues.unitCapacityRate,
        declaredDemand: formValues.declaredDemand,
        unitDeclaredDemandRate: formValues.unitDeclaredDemandRate,
        unitDemandRate: formValues.unitDemandRate,
        standingRate: formValues.standingRate,
        summitRate: formValues.summitRate,
        peakRate: formValues.peakRate,
        plainRate: formValues.plainRate,
        valleyRate: formValues.valleyRate,
        hasLightingTariff: formValues.hasLightingTariff,
        lightingTariffRatio: formValues.lightingTariffRatio,
        unitLightingTariffRate: formValues.unitLightingTariffRate,
        hasDiscountedRate: formValues.hasDiscountedRate,
        discountedType: formValues.discountedType,
        totalRateDiscountedRate: formValues.totalRateDiscountedRate,
        unitRateDiscountedRate: formValues.unitRateDiscountedRate,
        totalRateDiscountedRatio: formValues.totalRateDiscountedRatio,
        hasSubMeterRateDeduct: formValues.hasSubMeterRateDeduct,
        subMeterUnitDemandRate: formValues.subMeterUnitDemandRate,
        subMeterSummitRate: formValues.subMeterSummitRate,
        subMeterPeakRate: formValues.subMeterPeakRate,
        subMeterPlainRate: formValues.subMeterPlainRate,
        subMeterValleyRate: formValues.subMeterValleyRate,
        totalRateDiscountedQuantity: formValues.totalRateDiscountedQuantity,
      },
    };
  } else if (formValues.settlementType === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST) {
    return {
      ...params,
      distributedNewEnergyInfo: {
        timeOfDayRateChecked: formValues.timeOfDayRateChecked,
        summitRate: formValues.summitRate,
        peakRate: formValues.peakRate,
        plainRate: formValues.plainRate,
        valleyRate: formValues.valleyRate,
        hasDiscountedRate: formValues.hasDiscountedRate,
        discountedType: formValues.discountedType,
        totalRateDiscountedRate: formValues.totalRateDiscountedRate,
        unitRateDiscountedRate: formValues.unitRateDiscountedRate,
        totalRateDiscountedRatio: formValues.totalRateDiscountedRatio,
        totalRateDiscountedQuantity: formValues.totalRateDiscountedQuantity,
      },
    };
  } else if (formValues.settlementType === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL) {
    return {
      ...params,
      distEnergyAllFeedInInfo: {
        timeOfDayRateChecked: formValues.timeOfDayRateChecked,
        summitRate: formValues.summitRate,
        peakRate: formValues.peakRate,
        plainRate: formValues.plainRate,
        valleyRate: formValues.valleyRate,
        hasDiscountedRate: formValues.hasDiscountedRate,
        discountedType: formValues.discountedType,
        totalRateDiscountedRate: formValues.totalRateDiscountedRate,
        unitRateDiscountedRate: formValues.unitRateDiscountedRate,
        totalRateDiscountedRatio: formValues.totalRateDiscountedRatio,
        totalRateDiscountedQuantity: formValues.totalRateDiscountedQuantity,
      },
    };
  } else if (formValues.settlementType === ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY) {
    return {
      ...params,
      twoPartAndEnergyInfo: {
        timeOfDayRateChecked: formValues.timeOfDayRateChecked,
        declarationType: formValues.declarationType,
        rateType: formValues.rateType,
        transformerCapacity: formValues.transformerCapacity,
        unitCapacityRate: formValues.unitCapacityRate,
        declaredDemand: formValues.declaredDemand,
        unitDeclaredDemandRate: formValues.unitDeclaredDemandRate,
        unitDemandRate: formValues.unitDemandRate,
        standingRate: formValues.standingRate,
        summitRate: formValues.summitRate,
        peakRate: formValues.peakRate,
        plainRate: formValues.plainRate,
        valleyRate: formValues.valleyRate,
        hasLightingTariff: formValues.hasLightingTariff,
        lightingTariffRatio: formValues.lightingTariffRatio,
        unitLightingTariffRate: formValues.unitLightingTariffRate,
        hasDiscountedRate: formValues.hasDiscountedRate,
        discountedType: formValues.discountedType,
        totalRateDiscountedRate: formValues.totalRateDiscountedRate,
        unitRateDiscountedRate: formValues.unitRateDiscountedRate,
        totalRateDiscountedRatio: formValues.totalRateDiscountedRatio,
        totalRateDiscountedQuantity: formValues.totalRateDiscountedQuantity,
        powerFactorRatioCalculationType: formValues.powerFactorRatioCalculationType,
      },
    };
  } else {
    return undefined;
  }
};

const { RangePicker } = DatePicker;

const CreateSettlementRules: FC<ICreateSettlementRules> = props => {
  const [saleElectricityContractList, setSaleElectricityContractList] = useState<ElectricitySaleContractVo[]>([]); // 根据ou权限获取合同列表
  const [findSaleElectricityContract, setFindSaleElectricityContract] = useState<ElectricitySaleContractVo>();
  const [loading, setLoading] = useState<boolean>(false);
  const { isEdit } = props;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const [data, setData] = useState<ElectricitySettlementRulesDetails>();
  const [treeData, setTreeData] = useState<DefaultOptionType[]>([]); // 组织树
  const [customerMcid, setCustomerMcid] = useState<string>(); // 选择树的id
  const [saleElectricityContractId, setSaleElectricityContractId] = useState<Key>();
  const [customList, setCustomList] = useState<SaleClientInfo[]>([]);
  const [saleClientId, setSaleClientId] = useState<Key>(); // 客户id
  const [propertyManagementConfigId, setPropertyManagementConfigId] = useState<Key>(); // 选择物业的时候保存物业id，用于合同查找
  const [propertyList, setPropertyList] = useState<PropertyItem[]>([]); // 物业list
  const [propertyManagementConfigRange, setPropertyManagementConfigRange] = useState<string>('1,2'); // 当选择物业的时候带出物业范围，用于结算规则类型
  const [finishLoading, setFinishLoading] = useState<boolean>(false); // 点击保存loading
  const { copyId = '' }: { copyId?: string } = qs.parse(search, { ignoreQueryPrefix: true });
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [deductPhotovoltaicMetersData, setDeductPhotovoltaicMetersData] = useState<any[]>([]);
  const [photovoltaicMetersData, setPhotovoltaicMetersData] = useState<any[]>([]);
  const [electricityMetersDatas, setElectricityMetersDatas] = useState<any[]>([]);
  const [subMetersDatas, setSubMetersDatas] = useState<any[]>([]);
  const [demandMetersDatas, setDemandMetersDatas] = useState<any[]>([]);

  const formSettlementType = Form.useWatch<ElectricitySaleContractSettlementType | undefined>('settlementType', form);
  const hasSubMeterRateDeduct = Form.useWatch<boolean | undefined>('hasSubMeterRateDeduct', form);

  const formatTreeData = (data: any[]) => {
    return data.map(item => {
      const res: DefaultOptionType = {
        key: item.data?.mcid ?? '',
        value: item.data?.mcid ?? '',
        title: item.data?.name,
        children: formatTreeData(item.children),
        disabled: !item.data?.hasPermission,
      };
      // console.log(res)
      return res;
    });
  };

  // 通过组织id查找物业list
  useEffect(() => {
    if (customerMcid) {
      // 切换组织的时候清空物业等信息
      form.setFieldsValue({
        propertyManagementConfigId: null,
        saleElectricityContractId: null,
        settlementType: null,
      });
      setFindSaleElectricityContract(undefined);
      setPropertyManagementConfigId(undefined);
      setSaleElectricityContractId(undefined);
      getPropertyList([customerMcid]).then(res => {
        if (res) {
          setPropertyList(res);
          if (res.length === 1) {
            form.setFieldsValue({ propertyManagementConfigId: res[0].id });
            setPropertyManagementConfigId(String(res[0].id));
            setPropertyManagementConfigRange(res[0]!.businessScope);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerMcid]);

  // 所属客户
  useEffect(() => {
    getSaleClientList().then(setCustomList);
  }, []);

  // 查询所属组织
  useEffect(() => {
    if (!isEdit) {
      getOrganizationWithCodeWithCurrent().then(res => {
        setTreeData(formatTreeData([res]));
        if (res.children?.length === 0 && res.data.hasPermission === true) {
          form.setFieldsValue({ customerMcid: res.data.mcid });
          setCustomerMcid(res.data.mcid);
        }
      });
    }
  }, [isEdit]);

  useEffect(() => {
    if (data) {
      getElectricitySaleContractById(String(data.saleElectricityContractId)).then(res => {
        form.setFieldsValue({
          saleContractEffectTime: `${dayjs(res.startTime).format('YYYY-MM-DD')}~${dayjs(res.endTime).format(
            'YYYY-MM-DD'
          )}`,
        });
      });
    }
  }, [data, form]);

  useEffect(() => {
    if (isEdit && id) {
      // 请求数据进行编辑
      setLoading(true);
      getElectricitySettlementRulesById(id)
        .then(res => {
          setData(res);
          setSaleElectricityContractId(String(res.saleElectricityContractId));
          form.setFieldsValue({
            name: res.name,
            code: res.code,
            customerMcid: res.customerName,
            propertyManagementConfigId: res.propertyManagementConfigName,
            saleClientId: res.saleClientName,
            saleElectricityContractId: res.saleElectricityContractName,
            effectiveTime: `${dayjs(res.startTime).format('YYYY-MM-DD')}~${dayjs(res.endTime).format('YYYY-MM-DD')}`,
            settlementType: res.settlementType,
            description: res.description,
            status: SettlementRulesStatusTypeValue[res.status],
          });
          if (res.settlementType === ElectricitySaleContractSettlementType.UNITARYSYSTEM) {
            form.setFieldsValue({
              hasCapitalContributionRate: res.onePartInfo!.hasCapitalContributionRate,
              hasServiceCharge: res.onePartInfo!.hasServiceCharge,
              hasDiscountedRate: res.onePartInfo!.hasDiscountedRate,
              rateType: res.onePartInfo!.rateType,
              standingRate: res.onePartInfo!.standingRate,
              timeOfDayRateChecked: res.onePartInfo!.timeOfDayRateChecked,
              summitRate: res.onePartInfo!.summitRate,
              peakRate: res.onePartInfo!.peakRate,
              plainRate: res.onePartInfo!.plainRate,
              valleyRate: res.onePartInfo!.valleyRate,
            });
            if (res.onePartInfo!.hasDiscountedRate) {
              form.setFieldsValue({ discountedType: res.onePartInfo!.discountedType });
              form.setFieldsValue({ totalRateDiscountedRate: res.onePartInfo!.totalRateDiscountedRate });
              form.setFieldsValue({ unitRateDiscountedRate: res.onePartInfo!.unitRateDiscountedRate });
              form.setFieldsValue({ totalRateDiscountedRatio: res.onePartInfo!.totalRateDiscountedRatio });
              form.setFieldsValue({ totalRateDiscountedQuantity: res.onePartInfo!.totalRateDiscountedQuantity });
            }
            if (res.onePartInfo!.rateType === HasTimeOfUseElectricityPriceType.NOTIMEOFUSEELECTRICITYPRICETYPE) {
              form.setFieldsValue({ timeOfDayRateChecked: [1, 2, 3, 4] });
            }
          } else if (res.settlementType === ElectricitySaleContractSettlementType.TWOPARTSYSTEM) {
            form.setFieldsValue({
              hasDiscountedRate: res.twoPartInfo!.hasDiscountedRate,
              declarationType: res.twoPartInfo!.declarationType,
              rateType: res.twoPartInfo!.rateType,
              transformerCapacity: res.twoPartInfo!.transformerCapacity,
              unitCapacityRate: res.twoPartInfo!.unitCapacityRate,
              declaredDemand: res.twoPartInfo!.declaredDemand,
              unitDeclaredDemandRate: res.twoPartInfo!.unitDeclaredDemandRate,
              unitDemandRate: res.twoPartInfo!.unitDemandRate,
              standingRate: res.twoPartInfo!.standingRate,
              timeOfDayRateChecked: res.twoPartInfo!.timeOfDayRateChecked,
              summitRate: res.twoPartInfo!.summitRate,
              peakRate: res.twoPartInfo!.peakRate,
              plainRate: res.twoPartInfo!.plainRate,
              valleyRate: res.twoPartInfo!.valleyRate,
              hasLightingTariff: res.twoPartInfo!.hasLightingTariff,
              lightingTariffRatio: res.twoPartInfo!.lightingTariffRatio,
              unitLightingTariffRate: res.twoPartInfo!.unitLightingTariffRate,
              hasSubMeterRateDeduct: res.twoPartInfo!.hasSubMeterRateDeduct,
              subMeterUnitDemandRate: res.twoPartInfo!.subMeterUnitDemandRate,
              subMeterSummitRate: res.twoPartInfo!.subMeterSummitRate,
              subMeterPeakRate: res.twoPartInfo!.subMeterPeakRate,
              subMeterPlainRate: res.twoPartInfo!.subMeterPlainRate,
              subMeterValleyRate: res.twoPartInfo!.subMeterValleyRate,
            });
            // res.twoPartInfo!.hasServiceCharge && form.setFieldsValue({ serviceCharge: res.twoPartInfo!.serviceCharge })
            // res.twoPartInfo!.hasCapitalContributionRate && form.setFieldsValue({ capitalContributionRate: res.twoPartInfo!.capitalContributionRate })
            if (res.twoPartInfo!.hasDiscountedRate) {
              form.setFieldsValue({ discountedType: res.twoPartInfo!.discountedType });
              form.setFieldsValue({ totalRateDiscountedRate: res.twoPartInfo!.totalRateDiscountedRate });
              form.setFieldsValue({ unitRateDiscountedRate: res.twoPartInfo!.unitRateDiscountedRate });
              form.setFieldsValue({ totalRateDiscountedRatio: res.twoPartInfo!.totalRateDiscountedRatio });
              form.setFieldsValue({ totalRateDiscountedQuantity: res.twoPartInfo!.totalRateDiscountedQuantity });
            }
          } else if (res.settlementType === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST) {
            form.setFieldsValue({
              hasServiceCharge: res.distributedNewEnergyInfo!.hasServiceCharge,
              hasDiscountedRate: res.distributedNewEnergyInfo!.hasDiscountedRate,
              timeOfDayRateChecked: res.distributedNewEnergyInfo!.timeOfDayRateChecked,
              summitRate: res.distributedNewEnergyInfo!.summitRate,
              peakRate: res.distributedNewEnergyInfo!.peakRate,
              plainRate: res.distributedNewEnergyInfo!.plainRate,
              valleyRate: res.distributedNewEnergyInfo!.valleyRate,
            });
            // res.distributedNewEnergyInfo!.hasServiceCharge && form.setFieldsValue({ serviceCharge: res.distributedNewEnergyInfo!.serviceCharge })
            // res.distributedNewEnergyInfo!.hasCapitalContributionRate && form.setFieldsValue({ capitalContributionRate: res.distributedNewEnergyInfo!.capitalContributionRate })
            if (res.distributedNewEnergyInfo!.hasDiscountedRate) {
              form.setFieldsValue({ discountedType: res.distributedNewEnergyInfo!.discountedType });
              form.setFieldsValue({ totalRateDiscountedRate: res.distributedNewEnergyInfo!.totalRateDiscountedRate });
              form.setFieldsValue({ unitRateDiscountedRate: res.distributedNewEnergyInfo!.unitRateDiscountedRate });
              form.setFieldsValue({ totalRateDiscountedRatio: res.distributedNewEnergyInfo!.totalRateDiscountedRatio });
              form.setFieldsValue({
                totalRateDiscountedQuantity: res.distributedNewEnergyInfo!.totalRateDiscountedQuantity,
              });
            }
          } else if (res.settlementType === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL) {
            form.setFieldsValue({
              hasServiceCharge: res.distEnergyAllFeedInInfo!.hasServiceCharge,
              hasDiscountedRate: res.distEnergyAllFeedInInfo!.hasDiscountedRate,
              timeOfDayRateChecked: res.distEnergyAllFeedInInfo!.timeOfDayRateChecked,
              summitRate: res.distEnergyAllFeedInInfo!.summitRate,
              peakRate: res.distEnergyAllFeedInInfo!.peakRate,
              plainRate: res.distEnergyAllFeedInInfo!.plainRate,
              valleyRate: res.distEnergyAllFeedInInfo!.valleyRate,
            });
            if (res.distEnergyAllFeedInInfo!.hasDiscountedRate) {
              form.setFieldsValue({ discountedType: res.distEnergyAllFeedInInfo!.discountedType });
              form.setFieldsValue({ totalRateDiscountedRate: res.distEnergyAllFeedInInfo!.totalRateDiscountedRate });
              form.setFieldsValue({ unitRateDiscountedRate: res.distEnergyAllFeedInInfo!.unitRateDiscountedRate });
              form.setFieldsValue({ totalRateDiscountedRatio: res.distEnergyAllFeedInInfo!.totalRateDiscountedRatio });
              form.setFieldsValue({
                totalRateDiscountedQuantity: res.distEnergyAllFeedInInfo!.totalRateDiscountedQuantity,
              });
            }
          } else if (
            res.settlementType === ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY
          ) {
            form.setFieldsValue({
              hasDiscountedRate: res.twoPartAndEnergyInfo!.hasDiscountedRate,
              declarationType: res.twoPartAndEnergyInfo!.declarationType,
              rateType: res.twoPartAndEnergyInfo!.rateType,
              transformerCapacity: res.twoPartAndEnergyInfo!.transformerCapacity,
              unitCapacityRate: res.twoPartAndEnergyInfo!.unitCapacityRate,
              declaredDemand: res.twoPartAndEnergyInfo!.declaredDemand,
              unitDeclaredDemandRate: res.twoPartAndEnergyInfo!.unitDeclaredDemandRate,
              unitDemandRate: res.twoPartAndEnergyInfo!.unitDemandRate,
              standingRate: res.twoPartAndEnergyInfo!.standingRate,
              timeOfDayRateChecked: res.twoPartAndEnergyInfo!.timeOfDayRateChecked,
              summitRate: res.twoPartAndEnergyInfo!.summitRate,
              peakRate: res.twoPartAndEnergyInfo!.peakRate,
              plainRate: res.twoPartAndEnergyInfo!.plainRate,
              valleyRate: res.twoPartAndEnergyInfo!.valleyRate,
              hasLightingTariff: res.twoPartAndEnergyInfo!.hasLightingTariff,
              lightingTariffRatio: res.twoPartAndEnergyInfo!.lightingTariffRatio,
              unitLightingTariffRate: res.twoPartAndEnergyInfo!.unitLightingTariffRate,
              powerFactorRatioCalculationType: res.twoPartAndEnergyInfo!.powerFactorRatioCalculationType,
            });
            if (res.twoPartAndEnergyInfo!.hasDiscountedRate) {
              form.setFieldsValue({ discountedType: res.twoPartAndEnergyInfo!.discountedType });
              form.setFieldsValue({ totalRateDiscountedRate: res.twoPartAndEnergyInfo!.totalRateDiscountedRate });
              form.setFieldsValue({ unitRateDiscountedRate: res.twoPartAndEnergyInfo!.unitRateDiscountedRate });
              form.setFieldsValue({ totalRateDiscountedRatio: res.twoPartAndEnergyInfo!.totalRateDiscountedRatio });
              form.setFieldsValue({
                totalRateDiscountedQuantity: res.twoPartAndEnergyInfo!.totalRateDiscountedQuantity,
              });
            }
          } else {
            form.setFieldsValue(undefined);
          }

          getElectricitySaleContractSettlementRulesMeterList(id).then(totalMeterList => {
            if (res.settlementType === ElectricitySaleContractSettlementType.UNITARYSYSTEM) {
              let electricityMeters = totalMeterList.filter(
                item => item.meterType === ElectricitySaleContractSettlementMeterType.ELECTRICITY
              );
              form.setFieldsValue({ electricityMeters });
              setElectricityMetersDatas([...(electricityMeters ?? [])]);
            } else if (res.settlementType === ElectricitySaleContractSettlementType.TWOPARTSYSTEM) {
              let electricityMeters = totalMeterList.filter(
                item => item.meterType === ElectricitySaleContractSettlementMeterType.ELECTRICITY
              );
              let demandMeters = totalMeterList.filter(
                item => item.meterType === ElectricitySaleContractSettlementMeterType.DEMAND
              );
              let subMeters = totalMeterList.filter(
                item => item.meterType === ElectricitySaleContractSettlementMeterType.SUBTABLE
              );
              form.setFieldsValue({ electricityMeters });
              form.setFieldsValue({ demandMeters });
              form.setFieldsValue({ subMeters });
              setElectricityMetersDatas([...(electricityMeters ?? [])]);
              setDemandMetersDatas([...(demandMeters ?? [])]);
              setSubMetersDatas([...(subMeters ?? [])]);
            } else if (res.settlementType === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST) {
              let electricityMeters = totalMeterList.filter(
                item => item.meterType === ElectricitySaleContractSettlementMeterType.ELECTRICITY
              );
              let photovoltaicMeters = totalMeterList.filter(
                item => item.meterType === ElectricitySaleContractSettlementMeterType.PHOTOVOLTAIC
              );
              form.setFieldsValue({ electricityMeters });
              form.setFieldsValue({ photovoltaicMeters });
              setElectricityMetersDatas([...(electricityMeters ?? [])]);
              setPhotovoltaicMetersData([...(photovoltaicMeters ?? [])]);
            } else if (res.settlementType === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL) {
              let deductPhotovoltaicMeters = totalMeterList.filter(
                item => item.meterType === ElectricitySaleContractSettlementMeterType.DEDUCTPHOTOVOLTAIC
              );
              let photovoltaicMeters = totalMeterList.filter(
                item => item.meterType === ElectricitySaleContractSettlementMeterType.PHOTOVOLTAIC
              );
              form.setFieldsValue({ deductPhotovoltaicMeters });
              form.setFieldsValue({ photovoltaicMeters });
              setDeductPhotovoltaicMetersData([...(deductPhotovoltaicMeters ?? [])]);
              setPhotovoltaicMetersData([...(photovoltaicMeters ?? [])]);
            } else if (
              res.settlementType === ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY
            ) {
              let electricityMeters = totalMeterList.filter(
                item => item.meterType === ElectricitySaleContractSettlementMeterType.ELECTRICITY
              );
              let demandMeters = totalMeterList.filter(
                item => item.meterType === ElectricitySaleContractSettlementMeterType.DEMAND
              );
              let photovoltaicMeters = totalMeterList.filter(
                item => item.meterType === ElectricitySaleContractSettlementMeterType.PHOTOVOLTAIC
              );
              form.setFieldsValue({ electricityMeters });
              form.setFieldsValue({ demandMeters });
              form.setFieldsValue({ photovoltaicMeters });
              setElectricityMetersDatas([...(electricityMeters ?? [])]);
              setDemandMetersDatas([...(demandMeters ?? [])]);
              setPhotovoltaicMetersData([...(photovoltaicMeters ?? [])]);
            } else {
              return undefined;
            }
          });
          setLoading(false);
        })
        .catch(e => {
          console.error(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, id]);

  useEffect(() => {
    if (copyId) {
      setLoading(true);
      getElectricitySettlementRulesById(copyId)
        .then(res => {
          setData(res);
          setCustomerMcid(res.customerMcid);
          setSaleClientId(res.saleClientId);
          setPropertyManagementConfigId(res.propertyManagementConfigId);
          setSaleElectricityContractId(res.saleElectricityContractId);
          form.setFieldsValue({
            customerMcid: res.customerMcid,
            settlementType: res.settlementType,
            description: res.description,
            propertyManagementConfigId: res.propertyManagementConfigId,
            saleClientId: res.saleClientId,
            saleElectricityContractId: res.saleElectricityContractId,
          });
          if (res.settlementType === ElectricitySaleContractSettlementType.UNITARYSYSTEM) {
            form.setFieldsValue({
              hasCapitalContributionRate: res.onePartInfo!.hasCapitalContributionRate,
              hasServiceCharge: res.onePartInfo!.hasServiceCharge,
              hasDiscountedRate: res.onePartInfo!.hasDiscountedRate,
              rateType: res.onePartInfo!.rateType,
              standingRate: res.onePartInfo!.standingRate,
              timeOfDayRateChecked: res.onePartInfo!.timeOfDayRateChecked,
              summitRate: res.onePartInfo!.summitRate,
              peakRate: res.onePartInfo!.peakRate,
              plainRate: res.onePartInfo!.plainRate,
              valleyRate: res.onePartInfo!.valleyRate,
            });
            if (res.onePartInfo!.hasDiscountedRate) {
              form.setFieldsValue({ discountedType: res.onePartInfo!.discountedType });
              form.setFieldsValue({ totalRateDiscountedRate: res.onePartInfo!.totalRateDiscountedRate });
              form.setFieldsValue({ unitRateDiscountedRate: res.onePartInfo!.unitRateDiscountedRate });
              form.setFieldsValue({ totalRateDiscountedRatio: res.onePartInfo!.totalRateDiscountedRatio });
              form.setFieldsValue({ totalRateDiscountedQuantity: res.onePartInfo!.totalRateDiscountedQuantity });
            }
          } else if (res.settlementType === ElectricitySaleContractSettlementType.TWOPARTSYSTEM) {
            form.setFieldsValue({
              hasDiscountedRate: res.twoPartInfo!.hasDiscountedRate,
              declarationType: res.twoPartInfo!.declarationType,
              rateType: res.twoPartInfo!.rateType,
              transformerCapacity: res.twoPartInfo!.transformerCapacity,
              unitCapacityRate: res.twoPartInfo!.unitCapacityRate,
              declaredDemand: res.twoPartInfo!.declaredDemand,
              unitDeclaredDemandRate: res.twoPartInfo!.unitDeclaredDemandRate,
              unitDemandRate: res.twoPartInfo!.unitDemandRate,
              standingRate: res.twoPartInfo!.standingRate,
              timeOfDayRateChecked: res.twoPartInfo!.timeOfDayRateChecked,
              summitRate: res.twoPartInfo!.summitRate,
              peakRate: res.twoPartInfo!.peakRate,
              plainRate: res.twoPartInfo!.plainRate,
              valleyRate: res.twoPartInfo!.valleyRate,
              hasLightingTariff: res.twoPartInfo!.hasLightingTariff,
              lightingTariffRatio: res.twoPartInfo!.lightingTariffRatio,
              unitLightingTariffRate: res.twoPartInfo!.unitLightingTariffRate,
              hasSubMeterRateDeduct: res.twoPartInfo!.hasSubMeterRateDeduct,
              subMeterUnitDemandRate: res.twoPartInfo!.subMeterUnitDemandRate,
              subMeterSummitRate: res.twoPartInfo!.subMeterSummitRate,
              subMeterPeakRate: res.twoPartInfo!.subMeterPeakRate,
              subMeterPlainRate: res.twoPartInfo!.subMeterPlainRate,
              subMeterValleyRate: res.twoPartInfo!.subMeterValleyRate,
            });
            if (res.twoPartInfo!.hasDiscountedRate) {
              form.setFieldsValue({ discountedType: res.twoPartInfo!.discountedType });
              form.setFieldsValue({ totalRateDiscountedRate: res.twoPartInfo!.totalRateDiscountedRate });
              form.setFieldsValue({ unitRateDiscountedRate: res.twoPartInfo!.unitRateDiscountedRate });
              form.setFieldsValue({ totalRateDiscountedRatio: res.twoPartInfo!.totalRateDiscountedRatio });
              form.setFieldsValue({ totalRateDiscountedQuantity: res.twoPartInfo!.totalRateDiscountedQuantity });
            }
          } else if (res.settlementType === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST) {
            form.setFieldsValue({
              hasServiceCharge: res.distributedNewEnergyInfo!.hasServiceCharge,
              hasDiscountedRate: res.distributedNewEnergyInfo!.hasDiscountedRate,
              timeOfDayRateChecked: res.distributedNewEnergyInfo!.timeOfDayRateChecked,
              summitRate: res.distributedNewEnergyInfo!.summitRate,
              peakRate: res.distributedNewEnergyInfo!.peakRate,
              plainRate: res.distributedNewEnergyInfo!.plainRate,
              valleyRate: res.distributedNewEnergyInfo!.valleyRate,
            });
            if (res.distributedNewEnergyInfo!.hasDiscountedRate) {
              form.setFieldsValue({ discountedType: res.distributedNewEnergyInfo!.discountedType });
              form.setFieldsValue({ totalRateDiscountedRate: res.distributedNewEnergyInfo!.totalRateDiscountedRate });
              form.setFieldsValue({ unitRateDiscountedRate: res.distributedNewEnergyInfo!.unitRateDiscountedRate });
              form.setFieldsValue({ totalRateDiscountedRatio: res.distributedNewEnergyInfo!.totalRateDiscountedRatio });
              form.setFieldsValue({
                totalRateDiscountedQuantity: res.distributedNewEnergyInfo!.totalRateDiscountedQuantity,
              });
            }
          } else if (res.settlementType === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL) {
            form.setFieldsValue({
              hasServiceCharge: res.distEnergyAllFeedInInfo!.hasServiceCharge,
              hasDiscountedRate: res.distEnergyAllFeedInInfo!.hasDiscountedRate,
              timeOfDayRateChecked: res.distEnergyAllFeedInInfo!.timeOfDayRateChecked,
              summitRate: res.distEnergyAllFeedInInfo!.summitRate,
              peakRate: res.distEnergyAllFeedInInfo!.peakRate,
              plainRate: res.distEnergyAllFeedInInfo!.plainRate,
              valleyRate: res.distEnergyAllFeedInInfo!.valleyRate,
            });
            if (res.distEnergyAllFeedInInfo!.hasDiscountedRate) {
              form.setFieldsValue({ discountedType: res.distEnergyAllFeedInInfo!.discountedType });
              form.setFieldsValue({ totalRateDiscountedRate: res.distEnergyAllFeedInInfo!.totalRateDiscountedRate });
              form.setFieldsValue({ unitRateDiscountedRate: res.distEnergyAllFeedInInfo!.unitRateDiscountedRate });
              form.setFieldsValue({ totalRateDiscountedRatio: res.distEnergyAllFeedInInfo!.totalRateDiscountedRatio });
              form.setFieldsValue({
                totalRateDiscountedQuantity: res.distEnergyAllFeedInInfo!.totalRateDiscountedQuantity,
              });
            }
          } else if (
            res.settlementType === ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY
          ) {
            form.setFieldsValue({
              hasDiscountedRate: res.twoPartAndEnergyInfo!.hasDiscountedRate,
              declarationType: res.twoPartAndEnergyInfo!.declarationType,
              rateType: res.twoPartAndEnergyInfo!.rateType,
              transformerCapacity: res.twoPartAndEnergyInfo!.transformerCapacity,
              unitCapacityRate: res.twoPartAndEnergyInfo!.unitCapacityRate,
              declaredDemand: res.twoPartAndEnergyInfo!.declaredDemand,
              unitDeclaredDemandRate: res.twoPartAndEnergyInfo!.unitDeclaredDemandRate,
              unitDemandRate: res.twoPartAndEnergyInfo!.unitDemandRate,
              standingRate: res.twoPartAndEnergyInfo!.standingRate,
              timeOfDayRateChecked: res.twoPartAndEnergyInfo!.timeOfDayRateChecked,
              summitRate: res.twoPartAndEnergyInfo!.summitRate,
              peakRate: res.twoPartAndEnergyInfo!.peakRate,
              plainRate: res.twoPartAndEnergyInfo!.plainRate,
              valleyRate: res.twoPartAndEnergyInfo!.valleyRate,
              hasLightingTariff: res.twoPartAndEnergyInfo!.hasLightingTariff,
              lightingTariffRatio: res.twoPartAndEnergyInfo!.lightingTariffRatio,
              unitLightingTariffRate: res.twoPartAndEnergyInfo!.unitLightingTariffRate,
              powerFactorRatioCalculationType: res.twoPartAndEnergyInfo!.powerFactorRatioCalculationType,
            });
            if (res.twoPartAndEnergyInfo!.hasDiscountedRate) {
              form.setFieldsValue({ discountedType: res.twoPartAndEnergyInfo!.discountedType });
              form.setFieldsValue({ totalRateDiscountedRate: res.twoPartAndEnergyInfo!.totalRateDiscountedRate });
              form.setFieldsValue({ unitRateDiscountedRate: res.twoPartAndEnergyInfo!.unitRateDiscountedRate });
              form.setFieldsValue({ totalRateDiscountedRatio: res.twoPartAndEnergyInfo!.totalRateDiscountedRatio });
              form.setFieldsValue({
                totalRateDiscountedQuantity: res.twoPartAndEnergyInfo!.totalRateDiscountedQuantity,
              });
            }
          } else {
            form.setFieldsValue(undefined);
          }
          setLoading(false);
        })
        .catch(e => {
          console.error(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyId]);

  // 通过客户id和物业id查找合同列表
  useEffect(() => {
    if (saleClientId && propertyManagementConfigId) {
      getSaleElectricityContractListByPropertyManagementConfigIdOrOuIdOrSaleClientId({
        saleClientId,
        propertyManagementConfigId,
      }).then(res => {
        setSaleElectricityContractList(res);
        let find = res.find(item => item.id === form.getFieldValue('saleElectricityContractId')); // 找到这一整条信息
        setFindSaleElectricityContract(find); // 为了显示合同生效周期
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleClientId, propertyManagementConfigId]);

  const routes = useMemo(() => {
    return [{ name: `${isEdit ? '编辑' : copyId ? '复制' : '新建'}电费结算规则` }];
  }, [isEdit, copyId]);

  // 在新建时，默认填充表计
  useEffect(() => {
    if (!id && saleElectricityContractId && formSettlementType) {
      if (formSettlementType === ElectricitySaleContractSettlementType.UNITARYSYSTEM) {
        Promise.all([
          getElectricitySaleContractMeterList(
            saleElectricityContractId,
            ElectricitySaleContractSettlementMeterType.ELECTRICITY
          ),
        ]);
        getElectricitySaleContractMeterList(
          saleElectricityContractId,
          ElectricitySaleContractSettlementMeterType.ELECTRICITY
        ).then(electricityMeters => {
          form.setFieldsValue({ electricityMeters });
        });
      } else if (formSettlementType === ElectricitySaleContractSettlementType.TWOPARTSYSTEM) {
        Promise.all([
          getElectricitySaleContractMeterList(
            saleElectricityContractId,
            ElectricitySaleContractSettlementMeterType.ELECTRICITY
          ),
          getElectricitySaleContractMeterList(
            saleElectricityContractId,
            ElectricitySaleContractSettlementMeterType.DEMAND
          ),
        ]).then(([electricityMeters, demandMeters]) => {
          form.setFieldsValue({
            electricityMeters,
            demandMeters: [demandMeters.at(0)].filter(i => i),
          });
        });
      } else if (formSettlementType === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST) {
        Promise.all([
          getElectricitySaleContractMeterList(
            saleElectricityContractId,
            ElectricitySaleContractSettlementMeterType.ELECTRICITY
          ),
          getElectricitySaleContractMeterList(
            saleElectricityContractId,
            ElectricitySaleContractSettlementMeterType.PHOTOVOLTAIC
          ),
        ]).then(([electricityMeters, photovoltaicMeters]) => {
          form.setFieldsValue({
            electricityMeters,
            photovoltaicMeters,
          });
        });
      } else if (formSettlementType === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL) {
        Promise.all([
          getElectricitySaleContractMeterList(
            saleElectricityContractId,
            ElectricitySaleContractSettlementMeterType.DEDUCTPHOTOVOLTAIC
          ),
          getElectricitySaleContractMeterList(
            saleElectricityContractId,
            ElectricitySaleContractSettlementMeterType.PHOTOVOLTAIC
          ),
        ]).then(([deductPhotovoltaicMeters, photovoltaicMeters]) => {
          form.setFieldsValue({
            deductPhotovoltaicMeters,
            photovoltaicMeters,
          });
        });
      } else if (formSettlementType === ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY) {
        Promise.all([
          getElectricitySaleContractMeterList(
            saleElectricityContractId,
            ElectricitySaleContractSettlementMeterType.ELECTRICITY
          ),
          getElectricitySaleContractMeterList(
            saleElectricityContractId,
            ElectricitySaleContractSettlementMeterType.DEMAND
          ),
          getElectricitySaleContractMeterList(
            saleElectricityContractId,
            ElectricitySaleContractSettlementMeterType.PHOTOVOLTAIC
          ),
        ]).then(([electricityMeters, demandMeters, photovoltaicMeters]) => {
          form.setFieldsValue({
            electricityMeters,
            demandMeters: [demandMeters.at(0)].filter(i => i),
            photovoltaicMeters,
          });
        });
      }
    }
  }, [id, saleElectricityContractId, formSettlementType, form]);

  useEffect(() => {
    if (
      !id &&
      saleElectricityContractId &&
      formSettlementType &&
      formSettlementType === ElectricitySaleContractSettlementType.TWOPARTSYSTEM &&
      hasSubMeterRateDeduct
    ) {
      getElectricitySaleContractMeterList(
        saleElectricityContractId,
        ElectricitySaleContractSettlementMeterType.SUBTABLE
      ).then(subMeters => {
        form.setFieldsValue({
          subMeters: [subMeters.at(0)].filter(i => i),
        });
      });
    }
  }, [id, saleElectricityContractId, formSettlementType, hasSubMeterRateDeduct, form]);

  const onFinish = (val: FormProps) => {
    // console.log(val)
    if (val.settlementType === ElectricitySaleContractSettlementType.UNITARYSYSTEM) {
      if (val.electricityMeters?.length === 0 || !val.electricityMeters) {
        Modal.error({
          title: '请添加电量计量表计',
        });
        return false;
      }
    } else if (val.settlementType === ElectricitySaleContractSettlementType.TWOPARTSYSTEM) {
      if (val.electricityMeters?.length === 0 || !val.electricityMeters) {
        Modal.error({
          title: '请添加电量计量表计',
        });
        return false;
      } else if (val.demandMeters?.length === 0 || !val.demandMeters) {
        Modal.error({
          title: '请添加需量计量表计',
        });
        return false;
      } else if (val.hasSubMeterRateDeduct === true) {
        if (val.subMeters?.length === 0 || !val.subMeters) {
          Modal.error({
            title: '请添加子表计量表计',
          });
          return false;
        }
      }
    } else if (val.settlementType === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST) {
      if (val.electricityMeters?.length === 0 || !val.electricityMeters) {
        Modal.error({
          title: '请添加电量计量表计',
        });
        return false;
      } else if (val.photovoltaicMeters?.length === 0 || !val.photovoltaicMeters) {
        Modal.error({
          title: '请添加光伏计量表计',
        });
        return false;
      }
    } else if (val.settlementType === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL) {
      let deductAndNoDeductPhotovoltaicMeters = [
        ...(val.deductPhotovoltaicMeters ?? []),
        ...(val.photovoltaicMeters ?? []),
      ];
      if (deductAndNoDeductPhotovoltaicMeters.length === 0) {
        Modal.error({
          title: '请添加光伏计量表计',
        });
        return false;
      }
    } else if (val.settlementType === ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY) {
      if (val.electricityMeters?.length === 0 || !val.electricityMeters) {
        Modal.error({
          title: '请添加电量计量表计',
        });
        return false;
      } else if (val.demandMeters?.length === 0 || !val.demandMeters) {
        Modal.error({
          title: '请添加需量计量表计',
        });
        return false;
      } else if (val.photovoltaicMeters?.length === 0 || !val.photovoltaicMeters) {
        Modal.error({
          title: '请添加光伏计量表计',
        });
        return false;
      }
    }
    if (isEdit && id) {
      let saveMeterList = [
        ...(val.electricityMeters ?? []),
        ...(val.demandMeters ?? []),
        ...(val.photovoltaicMeters ?? []),
        ...(val.deductPhotovoltaicMeters ?? []),
        ...(val.subMeters ?? []),
      ];
      let meters = saveMeterList.map(item => {
        return {
          saleElectricityContractElectricityMeterId: item.saleElectricityContractElectricityMeterInfoId,
          saleElectricitySettlementRuleElectricityMeterId: item.saleElectricitySettlementRuleElectricityMeterInfoId,
        };
      });

      let params = {
        ...formatPostRequest(val),
        meters,
      };
      setFinishLoading(true);
      updateSettlementRules(Number(id), params as CreateElectricitySettlementRulesRequest)
        .then(() => {
          navigate(`/operation/settlementRules/electricity`);
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          setFinishLoading(false);
        });
    } else if (copyId) {
      let saveMeterList = [
        ...(val.electricityMeters ?? []),
        ...(val.demandMeters ?? []),
        ...(val.photovoltaicMeters ?? []),
        ...(val.deductPhotovoltaicMeters ?? []),
        ...(val.subMeters ?? []),
      ];
      let meters = saveMeterList.map(item => {
        return {
          saleElectricityContractElectricityMeterId: item.saleElectricityContractElectricityMeterInfoId,
          saleElectricitySettlementRuleElectricityMeterId: item.saleElectricitySettlementRuleElectricityMeterInfoId,
        };
      });
      let params = { ...formatPostRequest(val), meters };
      setFinishLoading(true);
      createSettlementRules(params as CreateElectricitySettlementRulesRequest)
        .then(() => {
          navigate(`/operation/settlementRules/electricity`);
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          setFinishLoading(false);
        });
    } else {
      let saveMeterList = [
        ...(val.electricityMeters ?? []),
        ...(val.demandMeters ?? []),
        ...(val.photovoltaicMeters ?? []),
        ...(val.deductPhotovoltaicMeters ?? []),
        ...(val.subMeters ?? []),
      ];
      let meters = saveMeterList.map(item => {
        return {
          saleElectricityContractElectricityMeterId: item.saleElectricityContractElectricityMeterInfoId,
          saleElectricitySettlementRuleElectricityMeterId: item.saleElectricitySettlementRuleElectricityMeterInfoId,
        };
      });
      let params = { ...formatPostRequest(val), meters };
      setFinishLoading(true);
      createSettlementRules(params as CreateElectricitySettlementRulesRequest)
        .then(() => {
          // message.success('创建成功');
          navigate(`/operation/settlementRules/electricity`);
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          setFinishLoading(false);
        });
    }
  };

  // 当合同选择的时候显示在下方显示时间
  const onSaleElectricityContractChange = (id: number) => {
    setSaleElectricityContractId(String(id));
    let find = saleElectricityContractList.find(item => item.id === id);
    setFindSaleElectricityContract(find);
  };

  // 表单值改变的时候（选择合同的时候展示添加按钮）
  const onFormValueChange = ({ saleElectricityContractId }: { saleElectricityContractId: string }) => {
    saleElectricityContractId && setSaleElectricityContractId(saleElectricityContractId);
  };

  // 结算类型切换时，时段选择全选
  const onSettlementTypeChange = () => {
    form.setFieldsValue({ timeOfDayRateChecked: [1, 2, 3, 4] });
  };

  // 物业选择的时候改变下面的业务范围（主要是结算规则）
  const onPropertyManagementConfigIdChange = (id: string) => {
    let find = propertyList.find(item => item.id === id);
    setPropertyManagementConfigId(id);
    form.setFieldsValue({
      settlementType: null,
      saleElectricityContractId: null,
    });
    setPropertyManagementConfigRange(find!.businessScope);
  };

  // 切换所属客户的时候清空合同（因为合同是通过客户和物业进行查找的）
  const onsaleClientIdChange = (val: string) => {
    setSaleClientId(val);
    form.setFieldsValue({
      saleElectricityContractId: null,
    });
  };

  const saleElectricityContractListOptions = useMemo(() => {
    if (saleElectricityContractList.length !== 0) {
      return saleElectricityContractList.map(i => ({
        label: `${i.code}【${ElectricitySaleContractStatus[i.status]}】【${i.name}】`,
        value: i.id,
      }));
    }
  }, [saleElectricityContractList]);

  const propertyOptions = useMemo(() => {
    return propertyList?.map(item => ({ label: `${item.code}【${item.salesOrg}】`, value: item.id }));
  }, [propertyList]);

  const customListOptions = useMemo(() => {
    if (customList.length !== 0) {
      return customList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [customList]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <Form
        layout="vertical"
        form={form}
        {...formLayout}
        onFinish={onFinish}
        scrollToFirstError={{
          block: 'center',
          behavior: 'smooth',
        }}
        initialValues={{
          // settlementType: 1,
          // meteringScenarios: 1,
          timeOfDayRateChecked: [1, 2, 3, 4],
          powerFactorRatioCalculationType: 1,
        }}
        onValuesChange={onFormValueChange}
      >
        <FormTitle title={routes[0].name} />
        <Spin spinning={loading}>
          <SubContent>
            <Row className={styles.row}>
              {isEdit ? (
                <Col span={8}>
                  <Form.Item name="code" label="结算规则编号">
                    <ShowInput />
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
              <Col span={8}>
                <Form.Item
                  name="name"
                  label="结算规则名称"
                  rules={[
                    { required: true, message: '请输入结算规则名称' },
                    { max: 50, message: '最多输入50个字符' },
                    { whitespace: true },
                  ]}
                >
                  <Input placeholder="请输入结算规则名称,不超过五十个字" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="customerMcid" label="关联组织" rules={[{ required: true, message: '请选择关联组织' }]}>
                  {isEdit ? (
                    <ShowInput />
                  ) : (
                    <TreeSelect
                      treeDefaultExpandAll
                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                      onChange={val => setCustomerMcid(val)}
                      treeData={treeData}
                      placeholder="请选择"
                      style={{ width: '100%' }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="propertyManagementConfigId"
                  label="所属物业"
                  rules={[{ required: true, message: '请选择所属物业' }]}
                >
                  {isEdit ? (
                    <ShowInput />
                  ) : (
                    <Select
                      placeholder="请选择"
                      disabled={!customerMcid}
                      onChange={val => onPropertyManagementConfigIdChange(val)}
                      options={propertyOptions}
                    />
                  )}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="所属客户" name="saleClientId">
                  {isEdit ? (
                    <ShowInput />
                  ) : (
                    <Select
                      placeholder="请选择"
                      onChange={val => onsaleClientIdChange(val)}
                      options={customListOptions}
                      showSearch
                      optionFilterProp="label"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="saleElectricityContractId"
                  label="所属合同"
                  extra={
                    findSaleElectricityContract ? (
                      <span>
                        合同有效期：
                        {`${dayjs(findSaleElectricityContract.startTime).format(DATE_FORMAT)}~${dayjs(
                          findSaleElectricityContract.endTime
                        ).format(DATE_FORMAT)}`}
                      </span>
                    ) : (
                      false
                    )
                  }
                  rules={[{ required: true, message: '请选择所属合同' }]}
                >
                  {isEdit ? (
                    <ShowInput />
                  ) : (
                    <Select
                      placeholder={'请选择'}
                      onChange={e => onSaleElectricityContractChange(e)}
                      disabled={!(!!saleClientId && !!propertyManagementConfigId)}
                      options={saleElectricityContractListOptions}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="effectiveTime"
                  label="结算规则生效区间"
                  rules={[{ required: true, message: '请输入生效区间' }]}
                >
                  {isEdit ? <ShowInput /> : <RangePicker style={{ width: '100%' }} />}
                </Form.Item>
              </Col>
              {isEdit ? (
                <>
                  <Col span={8}>
                    <Form.Item name="saleContractEffectTime" label="合同有效期">
                      <ShowInput />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item name="status" label="结算规则状态">
                      <ShowInput />
                    </Form.Item>
                  </Col>
                </>
              ) : (
                <></>
              )}
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="settlementType"
                  label="结算类型"
                  rules={[{ required: true, message: '请选择结算类型' }]}
                >
                  {propertyManagementConfigRange.length > 1 ? (
                    <Radio.Group
                      style={{ width: 850 }}
                      onChange={onSettlementTypeChange}
                      disabled={isEdit || !propertyManagementConfigId}
                    >
                      <Radio value={ElectricitySaleContractSettlementType.UNITARYSYSTEM}>
                        {ElectricitySaleContractSettlement[ElectricitySaleContractSettlementType.UNITARYSYSTEM]}
                      </Radio>
                      <Radio value={ElectricitySaleContractSettlementType.TWOPARTSYSTEM}>
                        {ElectricitySaleContractSettlement[ElectricitySaleContractSettlementType.TWOPARTSYSTEM]}
                      </Radio>
                      <Radio value={ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST}>
                        {
                          ElectricitySaleContractSettlement[
                            ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST
                          ]
                        }
                      </Radio>
                      <Radio value={ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL}>
                        {
                          ElectricitySaleContractSettlement[
                            ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL
                          ]
                        }
                      </Radio>
                      <Radio value={ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY}>
                        {
                          ElectricitySaleContractSettlement[
                            ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY
                          ]
                        }
                      </Radio>
                    </Radio.Group>
                  ) : propertyManagementConfigRange === '1' ? (
                    <Radio.Group
                      style={{ width: 850 }}
                      onChange={onSettlementTypeChange}
                      disabled={isEdit || !propertyManagementConfigId}
                    >
                      <Radio value={ElectricitySaleContractSettlementType.UNITARYSYSTEM}>
                        {ElectricitySaleContractSettlement[ElectricitySaleContractSettlementType.UNITARYSYSTEM]}
                      </Radio>
                      <Radio value={ElectricitySaleContractSettlementType.TWOPARTSYSTEM}>
                        {ElectricitySaleContractSettlement[ElectricitySaleContractSettlementType.TWOPARTSYSTEM]}
                      </Radio>
                      <Radio value={ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY}>
                        {
                          ElectricitySaleContractSettlement[
                            ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY
                          ]
                        }
                      </Radio>
                    </Radio.Group>
                  ) : propertyManagementConfigRange === '2' ? (
                    <Radio.Group
                      style={{ width: 850 }}
                      onChange={onSettlementTypeChange}
                      disabled={isEdit || !propertyManagementConfigId}
                    >
                      <Radio value={ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST}>
                        {
                          ElectricitySaleContractSettlement[
                            ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST
                          ]
                        }
                      </Radio>
                      <Radio value={ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL}>
                        {
                          ElectricitySaleContractSettlement[
                            ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL
                          ]
                        }
                      </Radio>
                    </Radio.Group>
                  ) : (
                    <></>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <UnitarySystem />
            <TwoPartSystem form={form} />
            <DistributedNewEnergy />
            <DistributedNewEnergyFull />
            <TwoPartSystemAndNewEnergy />

            <Row>
              <Form.Item noStyle dependencies={['settlementType']}>
                {({ getFieldValue }) => {
                  return (
                    getFieldValue('settlementType') === ElectricitySaleContractSettlementType.UNITARYSYSTEM && (
                      <Col span={8}>
                        <Form.Item
                          name="hasCapitalContributionRate"
                          label="是否有公摊系数"
                          extra={
                            <Space size={4} style={{ width: 380 }}>
                              <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
                              注：公摊电量需要在核查联生成后，手动在核查联中录入
                            </Space>
                          }
                          rules={[{ required: true, message: '请选择是否有公摊系数' }]}
                        >
                          <Radio.Group>
                            <Radio value={true}>是</Radio>
                            <Radio value={false}>否</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    )
                  );
                }}
              </Form.Item>
            </Row>

            {/* 是否有优惠金额 */}
            <Col span={8}>
              <Form.Item
                name="hasDiscountedRate"
                label="是否有优惠金额"
                rules={[{ required: true, message: '请选择是否有优惠金额' }]}
              >
                <Radio.Group>
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Form.Item noStyle dependencies={['hasDiscountedRate']}>
              {({ getFieldValue }) => {
                return (
                  getFieldValue('hasDiscountedRate') === true && (
                    <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', padding: '20px' }}>
                      <Form.Item noStyle dependencies={['hasDiscountedRate']}>
                        {({ getFieldValue }) => {
                          return (
                            getFieldValue('hasDiscountedRate') === true && (
                              <>
                                <Col span={24}>
                                  <Form.Item
                                    name="discountedType"
                                    label="优惠方式"
                                    rules={[{ required: true, message: '请选择优惠方式' }]}
                                  >
                                    <Radio.Group style={{ width: 850 }}>
                                      <Radio value={HasPreferentialAmountType.TOTALDISCOUNTRATIO}>
                                        {HasPreferentialAmount[HasPreferentialAmountType.TOTALDISCOUNTRATIO]}
                                      </Radio>
                                      <Radio value={HasPreferentialAmountType.TOTALPREFERENTIALAMOUNT}>
                                        {HasPreferentialAmount[HasPreferentialAmountType.TOTALPREFERENTIALAMOUNT]}
                                      </Radio>
                                      <Radio value={HasPreferentialAmountType.UNITPRICEPREFERENTIALAMOUNT}>
                                        {HasPreferentialAmount[HasPreferentialAmountType.UNITPRICEPREFERENTIALAMOUNT]}
                                      </Radio>
                                      {/* <Radio value={HasPreferentialAmountType.TIMESHARINGDISCOUNTRATIO}>{HasPreferentialAmount[HasPreferentialAmountType.TIMESHARINGDISCOUNTRATIO]}</Radio> */}
                                      {/* <Radio value={HasPreferentialAmountType.TIMESHARINGPREFERENTIALAMOUNT}>{HasPreferentialAmount[HasPreferentialAmountType.TIMESHARINGPREFERENTIALAMOUNT]}</Radio> */}
                                    </Radio.Group>
                                  </Form.Item>
                                </Col>
                              </>
                            )
                          );
                        }}
                      </Form.Item>

                      <Form.Item noStyle dependencies={['hasDiscountedRate', 'discountedType']}>
                        {({ getFieldValue }) => {
                          return (
                            getFieldValue('hasDiscountedRate') === true &&
                            getFieldValue('discountedType') === HasPreferentialAmountType.TOTALDISCOUNTRATIO && (
                              <>
                                <Col span={8}>
                                  <Form.Item
                                    name="totalRateDiscountedRatio"
                                    label={HasPreferentialAmount[HasPreferentialAmountType.TOTALDISCOUNTRATIO]}
                                    extra={<>例：输80%，等于打8折</>}
                                    rules={[{ required: true, message: '请输入' }]}
                                  >
                                    <InputNumber
                                      style={{ width: '100%' }}
                                      placeholder="请输入"
                                      max={99.99}
                                      min={0}
                                      addonAfter="%"
                                      step="0.01"
                                      precision={2}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={8}></Col>
                              </>
                            )
                          );
                        }}
                      </Form.Item>

                      <Form.Item noStyle dependencies={['hasDiscountedRate', 'discountedType']}>
                        {({ getFieldValue }) => {
                          return (
                            getFieldValue('hasDiscountedRate') === true &&
                            getFieldValue('discountedType') === HasPreferentialAmountType.TOTALPREFERENTIALAMOUNT && (
                              <>
                                <Col span={8}>
                                  <Form.Item
                                    name="totalRateDiscountedQuantity"
                                    label="优惠电度数"
                                    rules={[{ required: true, message: '请输入' }]}
                                  >
                                    <InputNumber
                                      style={{ width: '100%' }}
                                      placeholder="请输入"
                                      max={99999999.9999}
                                      min={0}
                                      addonAfter="kWh"
                                      step="0.01"
                                      precision={2}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={16}></Col>
                                <Col span={8}>
                                  <Form.Item
                                    name="totalRateDiscountedRate"
                                    label="优惠金额"
                                    rules={[{ required: true, message: '请输入' }]}
                                  >
                                    <InputNumber
                                      style={{ width: '100%' }}
                                      placeholder="请输入"
                                      max={99999999.99999999}
                                      min={0}
                                      addonAfter="元"
                                      step="0.00000001"
                                      precision={8}
                                    />
                                  </Form.Item>
                                </Col>
                              </>
                            )
                          );
                        }}
                      </Form.Item>

                      <Form.Item noStyle dependencies={['hasDiscountedRate', 'discountedType']}>
                        {({ getFieldValue }) => {
                          return (
                            getFieldValue('hasDiscountedRate') === true &&
                            getFieldValue('discountedType') ===
                              HasPreferentialAmountType.UNITPRICEPREFERENTIALAMOUNT && (
                              <>
                                <Col span={8}>
                                  <Form.Item
                                    name="unitRateDiscountedRate"
                                    label={HasPreferentialAmount[HasPreferentialAmountType.UNITPRICEPREFERENTIALAMOUNT]}
                                    rules={[{ required: true, message: '请输入' }]}
                                  >
                                    <InputNumber
                                      style={{ width: '100%' }}
                                      placeholder="请输入"
                                      max={100}
                                      min={0}
                                      addonAfter="分"
                                      addonBefore="每度减"
                                      step="1"
                                      precision={2}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={8}></Col>
                              </>
                            )
                          );
                        }}
                      </Form.Item>
                    </Row>
                  )
                );
              }}
            </Form.Item>
          </SubContent>
          <Form.Item noStyle dependencies={['settlementType']}>
            {({ getFieldValue }) => {
              return (
                getFieldValue('settlementType') && (
                  <SubContent title={'生效表计'} className="mb-8" style={{ paddingRight: 0 }}>
                    <Form.Item noStyle dependencies={['settlementType']}>
                      {({ getFieldValue }) => {
                        return (
                          getFieldValue('settlementType') === ElectricitySaleContractSettlementType.UNITARYSYSTEM && (
                            <Row>
                              <Col span={24}>
                                <Form.Item name="electricityMeters" label="" wrapperCol={{ span: 24 }}>
                                  <EffectiveMeterForm
                                    saleElectricityContractId={saleElectricityContractId}
                                    addType={'电量-'}
                                    meterType={ElectricitySaleContractSettlementMeterType.ELECTRICITY}
                                    isEdit={isEdit}
                                    meterDatas={electricityMetersDatas}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          )
                        );
                      }}
                    </Form.Item>

                    <Form.Item noStyle dependencies={['settlementType', 'hasSubMeterRateDeduct']}>
                      {({ getFieldValue }) => {
                        return (
                          getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
                            <>
                              <Row>
                                <Col span={24}>
                                  <Form.Item name="demandMeters" label="" wrapperCol={{ span: 24 }}>
                                    <EffectiveMeterForm
                                      saleElectricityContractId={saleElectricityContractId}
                                      addType={'需量-'}
                                      meterType={ElectricitySaleContractSettlementMeterType.DEMAND}
                                      isEdit={isEdit}
                                      meterDatas={demandMetersDatas}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row>
                                <Col span={24}>
                                  <Form.Item name="electricityMeters" label="" wrapperCol={{ span: 24 }}>
                                    <EffectiveMeterForm
                                      saleElectricityContractId={saleElectricityContractId}
                                      addType={'电量-'}
                                      meterType={ElectricitySaleContractSettlementMeterType.ELECTRICITY}
                                      isEdit={isEdit}
                                      meterDatas={electricityMetersDatas}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              {getFieldValue('hasSubMeterRateDeduct') === true && (
                                <Row>
                                  <Col span={24}>
                                    <Form.Item name="subMeters" label="" wrapperCol={{ span: 24 }}>
                                      <EffectiveMeterForm
                                        saleElectricityContractId={saleElectricityContractId}
                                        addType={'子表-'}
                                        meterType={ElectricitySaleContractSettlementMeterType.SUBTABLE}
                                        isEdit={isEdit}
                                        meterDatas={subMetersDatas}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              )}
                            </>
                          )
                        );
                      }}
                    </Form.Item>

                    <Form.Item noStyle dependencies={['settlementType']}>
                      {({ getFieldValue }) => {
                        return (
                          getFieldValue('settlementType') ===
                            ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST && (
                            <>
                              <Row>
                                <Col span={24}>
                                  <Form.Item name="electricityMeters" label="" wrapperCol={{ span: 24 }}>
                                    <EffectiveMeterForm
                                      saleElectricityContractId={saleElectricityContractId}
                                      addType={'电量-'}
                                      meterType={ElectricitySaleContractSettlementMeterType.ELECTRICITY}
                                      isEdit={isEdit}
                                      meterDatas={electricityMetersDatas}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row>
                                <Col span={24}>
                                  <Form.Item name="photovoltaicMeters" label="" wrapperCol={{ span: 24 }}>
                                    <EffectiveMeterForm
                                      saleElectricityContractId={saleElectricityContractId}
                                      addType={'不扣除反向电量-光伏'}
                                      meterType={ElectricitySaleContractSettlementMeterType.PHOTOVOLTAIC}
                                      isEdit={isEdit}
                                      meterDatas={photovoltaicMetersData}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </>
                          )
                        );
                      }}
                    </Form.Item>

                    <Form.Item noStyle dependencies={['settlementType']}>
                      {({ getFieldValue }) => {
                        return (
                          getFieldValue('settlementType') ===
                            ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL && (
                            <>
                              <Row>
                                <Col span={24}>
                                  <Form.Item name="deductPhotovoltaicMeters" label="" wrapperCol={{ span: 24 }}>
                                    <EffectiveMeterForm
                                      saleElectricityContractId={saleElectricityContractId}
                                      addType={'扣除反向电量-光伏'}
                                      meterType={ElectricitySaleContractSettlementMeterType.DEDUCTPHOTOVOLTAIC}
                                      isEdit={isEdit}
                                      meterDatas={deductPhotovoltaicMetersData}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row>
                                <Col span={24}>
                                  <Form.Item name="photovoltaicMeters" label="" wrapperCol={{ span: 24 }}>
                                    <EffectiveMeterForm
                                      saleElectricityContractId={saleElectricityContractId}
                                      addType={'不扣除反向电量-光伏'}
                                      meterType={ElectricitySaleContractSettlementMeterType.PHOTOVOLTAIC}
                                      isEdit={isEdit}
                                      meterDatas={photovoltaicMetersData}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </>
                          )
                        );
                      }}
                    </Form.Item>

                    <Form.Item noStyle dependencies={['settlementType']}>
                      {({ getFieldValue }) => {
                        return (
                          getFieldValue('settlementType') ===
                            ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY && (
                            <>
                              <Row>
                                <Col span={24}>
                                  <Form.Item name="demandMeters" label="" wrapperCol={{ span: 24 }}>
                                    <EffectiveMeterForm
                                      saleElectricityContractId={saleElectricityContractId}
                                      addType={'需量-'}
                                      meterType={ElectricitySaleContractSettlementMeterType.DEMAND}
                                      isEdit={isEdit}
                                      meterDatas={demandMetersDatas}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row>
                                <Col span={24}>
                                  <Form.Item name="electricityMeters" label="" wrapperCol={{ span: 24 }}>
                                    <EffectiveMeterForm
                                      saleElectricityContractId={saleElectricityContractId}
                                      addType={'电量-'}
                                      meterType={ElectricitySaleContractSettlementMeterType.ELECTRICITY}
                                      isEdit={isEdit}
                                      meterDatas={electricityMetersDatas}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row>
                                <Col span={24}>
                                  <Form.Item name="photovoltaicMeters" label="" wrapperCol={{ span: 24 }}>
                                    <EffectiveMeterForm
                                      saleElectricityContractId={saleElectricityContractId}
                                      addType={'光伏-'}
                                      meterType={ElectricitySaleContractSettlementMeterType.PHOTOVOLTAIC}
                                      isEdit={isEdit}
                                      meterDatas={photovoltaicMetersData}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </>
                          )
                        );
                      }}
                    </Form.Item>
                  </SubContent>
                )
              );
            }}
          </Form.Item>

          <SubContent className="mb-8">
            <Row>
              <Col span={12}>
                <Form.Item
                  name="description"
                  label="备注"
                  rules={[
                    // { required: true, message: '请输入备注' },
                    { max: 100, message: '最多输入100个字符' },
                  ]}
                >
                  <Input maxLength={100} placeholder="请输入" />
                </Form.Item>
              </Col>
            </Row>
          </SubContent>
        </Spin>
        <Space size={8} className="sticky-footer-c">
          <Button htmlType="submit" type="primary" loading={finishLoading}>
            保存
          </Button>
          <Button onClick={() => navigate('/operation/settlementRules/electricity')}>取消</Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default CreateSettlementRules;

export const limitedIntegerValidator =
  (max: number, min = 0) =>
  (_: any, value: number | string, callback: (message?: string) => void) => {
    const num = Number(value);
    const isOk = num >= min && num <= max;
    if (!isOk && value !== undefined) {
      callback(`输入${min}~${max}内的数`);
    }
    callback();
  };
