import dayjs from 'dayjs';
import { DatePickerType, ParamsOption } from './type';
import * as echarts from 'echarts';
import { isNil } from 'lodash-es';
import { getMax } from '@/pages/MonitorDeviceDashBoard/utils';
import { getCommonMarkPint } from '@/pages/MonitorDeviceDashBoard/components/BottomSingleDevice/charts';

interface LegendProps {
  show: boolean;
  name?: string;
  isRect?: boolean;
}
interface LegendArrProps {
  show: boolean;
  name?: any[];
  isRect?: boolean;
}

export type FormatterItem = {
  name: string;
  value: number;
  marker: string;
  data: [string, number | null];
  seriesName: string;
  dataIndex: number;
  axisValue: string;
  seriesIndex: number;
};

// 通用化配置项
export const getNormalChartOption = (selectBtn?: DatePickerType, unit?: string, isBoxTip = false) => {
  // const colors = ['#1890ff', '#4fc7ec'];
  return {
    backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue('--component-background'),
    grid: {
      left: 90,
      right: 30,
      top: 95,
      bottom: 80,
    },
    dataZoom: [
      {
        type: 'slider',
        bottom: 15,
        left: 120,
        right: 120,
        height: 20,
        textStyle: {
          fontSize: 10,
        },
      },
    ],
    // color: colors,
    tooltip:
      isBoxTip && selectBtn === DatePickerType.DAY
        ? {
            trigger: 'axis',
            // axisPointer: {
            //   type: 'cross',
            // },
            backgroundColor: 'rgba(0,0,0,0.8)',
            borderColor: 'transparent',
            textStyle: {
              color: '#fff',
            },
          }
        : getTooltip({
            unit,
            mode: selectBtn,
          }),
    xAxis: getNormalAxis(selectBtn),
    yAxis: {
      type: 'value',
      name: unit,
      nameTextStyle: {
        padding: -10,
        fontSize: 14,
        align: 'right',
        color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
      },
      nameGap: 30,
      axisLabel: {
        textStyle: {
          color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
        },
      },
      splitLine: {
        lineStyle: {
          color: '#FFFFFF30',
        },
      },
    },
  };
};

// tooltip 通用配置
export const getTooltip = ({ unit = '', mode }: { unit?: string; mode?: DatePickerType; baselineValue?: number }) => ({
  trigger: 'axis',
  backgroundColor: 'rgba(0,0,0,0.8)',
  borderColor: 'transparent',
  textStyle: {
    color: '#fff',
  },
  formatter(items: FormatterItem[]) {
    const { axisValue } = items[0];
    // 根据选择的颗粒度，展示不同的tooltip
    let str: string;
    switch (mode) {
      case DatePickerType.MINUTE_1:
      case DatePickerType.MINUTE_15:
      case DatePickerType.HOUR:
        str = dayjs(axisValue, 'x').format('MM-DD[\n]HH:mm');
        break;
      case DatePickerType.DAY:
        str = dayjs(axisValue, 'x').format('MM-DD');
        break;
      default:
        str = dayjs(axisValue, 'x').format('YYYY-MM');
    }
    items.forEach(item => {
      const { seriesName, data, marker } = item;
      const value = !isNil(data[1]) ? `${data[1].toFixed(2)}${unit}` : '--';
      str += `<br> ${seriesName}: ${value}`;
    });
    return str;
  },
});

// 图例通用配置
export const getLegend = ({ show, name, isRect = true }: LegendProps) => {
  return {
    show,
    right: 30,
    top: 50,
    textStyle: {
      color: '#AFBCC4',
    },
    data: [
      {
        name: name,
        icon: isRect ? 'rect' : 'path://M0 426.666667h1024v170.666666H0z',
      },
    ],
  };
};

// 多图例图例通用配置
export const getLegendArr = ({ show, name, isRect = true }: LegendArrProps) => {
  return {
    show,
    right: 30,
    top: 50,
    textStyle: {
      color: '#AFBCC4',
    },
    data: name?.map(item => ({
      name: item.name,
      icon: isRect ? 'rect' : 'path://M0 426.666667h1024v170.666666H0z',
    })),
  };
};
const xAxis = {
  axisTick: {
    show: false,
  },
  splitLine: {
    show: false,
  },
};
export const rangeCatXAxis = (key: string) => {
  return [
    {
      ...xAxis,
      type: 'time',
      minInterval: key === 'Hour' ? 60 * 1000 : key === 'Day' ? 60 * 1000 * 60 * 24 : 60 * 1000 * 60 * 24 * 28,
      axisLabel: {
        textStyle: {
          color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
        },
        formatter: function (e: number) {
          if (key === 'Hour') {
            return dayjs(e, 'x').format('MM-DD[\n]HH:mm');
          } else if (key === 'Day') {
            return dayjs(e, 'x').format('MM-DD');
          } else {
            return dayjs(e, 'x').format('YYYY-MM');
          }
        },
      },
    },
  ];
};

export const getNormalAxis = (selectBtn?: DatePickerType) => {
  switch (selectBtn) {
    case DatePickerType.MINUTE_1:
    case DatePickerType.MINUTE_15:
    case DatePickerType.HOUR:
      return rangeCatXAxis('Hour');
    case DatePickerType.DAY:
      return rangeCatXAxis('Day');
    case DatePickerType.MONTH:
      return rangeCatXAxis('Month');
    default:
      return {};
  }
};

// 气电比
export const getAirPowerToElectricityRatioChart = ({ chartData, query }: ParamsOption) => {
  const unit = chartData?.list?.[0]?.physicalUnitName;
  const option = getNormalChartOption(query?.granularityType, unit);
  const colors = ['#1890ff', '#4fc7ec'];
  return {
    ...option,
    legend: getLegend({
      show: true,
      name: chartData?.list?.[0]?.name, // 气电比
    }),
    series: [
      ...(chartData?.list ?? []).map(item => ({
        type: 'bar',
        color: colors,
        name: item.name,
        data: (item.chartValues ?? []).map(a => {
          return [a.ts, a.value];
        }),
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: '#81C3F3' },
            { offset: 1, color: '#4A90E2' },
          ]),
        },
      })),
    ],
  };
};

// 用电量
export const getAirTotalPowerConsumptionChart = ({ chartData, query }: ParamsOption) => {
  const unit = chartData?.list?.[0]?.physicalUnitName;
  const option = getNormalChartOption(query?.granularityType, unit);
  const colors = ['#1890ff', '#4fc7ec'];
  return {
    ...option,
    legend: getLegend({
      show: true,
      name: chartData?.list?.[0]?.name, // 用电量
    }),
    series: [
      ...(chartData?.list ?? []).map(item => ({
        type: 'bar',
        color: colors,
        name: item.name,
        data: (item.chartValues ?? []).map(a => {
          return [a.ts, a.value];
        }),
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: '#81C3F3' },
            { offset: 1, color: '#4A90E2' },
          ]),
        },
      })),
    ],
  };
};

// 电流
export const getAirCompressorCurrentChart = ({ chartData, query }: ParamsOption) => {
  const unit = chartData?.list?.[0]?.physicalUnitName;
  const option = getNormalChartOption(query?.granularityType, unit);
  const colors = ['#FFCB47', '#59D744', '#FF4D4F'];
  return {
    ...option,
    legend: getLegendArr({
      show: true,
      name: chartData?.list, // 电流
      isRect: false,
    }),
    series: [
      ...(chartData?.list ?? []).map((item, index) => ({
        type: 'line',
        color: colors[index],
        name: item.name,
        data: (item.chartValues ?? []).map(a => {
          return [a.ts, a.value];
        }),
        smooth: true,
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: '#89CFFF20' },
            { offset: 1, color: '#51A1FF10' },
          ]),
        },
        markPoint: getCommonMarkPint(unit, item.name),
      })),
    ],
  };
};

// 电压
export const getAirCompressorVoltageChart = ({ chartData, query }: ParamsOption) => {
  const unit = chartData?.list?.[0]?.physicalUnitName;
  const option = getNormalChartOption(query?.granularityType, unit);
  const colors = ['#FFCB47', '#59D744', '#FF4D4F'];
  return {
    ...option,
    legend: getLegendArr({
      show: true,
      name: chartData?.list, // 电压
      isRect: false,
    }),
    series: [
      ...(chartData?.list ?? []).map((item, index) => ({
        type: 'line',
        color: colors[index],
        name: item.name,
        data: (item.chartValues ?? []).map(a => {
          return [a.ts, a.value];
        }),
        smooth: true,
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: '#89CFFF20' },
            { offset: 1, color: '#51A1FF10' },
          ]),
        },
        markPoint: getCommonMarkPint(unit, item.name),
      })),
    ],
  };
};

// 排气压力
export const getAirCompressorExhaustPressureChart = ({ chartData, query }: ParamsOption) => {
  const unit = chartData?.list?.[0]?.physicalUnitName;
  const name = chartData?.list?.[0]?.name;
  const option = getNormalChartOption(query?.granularityType, unit, true);

  const colors = ['#00ADFF'];
  return {
    ...option,
    legend: getLegend({
      show: true,
      name: name, // 排气压力
      isRect: false,
    }),
    series: [
      ...(chartData?.list ?? []).map(item => {
        if (query?.granularityType === DatePickerType.DAY) {
          return {
            name: item.name,
            type: 'custom',
            color: colors,
            dimensions: ['-', `最大${item.name}`, `平均${item.name}`, `最小${item.name}`],
            itemStyle: {
              borderWidth: 1.5,
            },
            renderItem: function (params: any, api: any) {
              var xValue = api?.value(0);
              var highPoint = api?.coord([xValue, api.value(1)]);
              var avgPoint = api?.coord([xValue, api.value(2)]);
              var lowPoint = api?.coord([xValue, api.value(3)]);
              // var halfWidth = api?.size([0, 1])[1] / 2;
              // var offsetWidth =
              //   chartDataLength === 1 ? index : chartDataLength === 2 ? (2 * index - 1) * 8 : 16 * index - 16;
              var halfWidth = 8;
              var style = api?.style({
                stroke: api?.visual('color'),
                fill: undefined,
              });
              return {
                type: 'group',
                children: [
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1: highPoint[0] - halfWidth,
                      y1: highPoint[1],
                      x2: highPoint[0] + halfWidth,
                      y2: highPoint[1],
                    },
                    style: style,
                  },
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1: highPoint[0],
                      y1: highPoint[1],
                      x2: lowPoint[0],
                      y2: lowPoint[1],
                    },
                    style: style,
                  },
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1: avgPoint[0] - halfWidth,
                      y1: avgPoint[1],
                      x2: avgPoint[0] + halfWidth,
                      y2: avgPoint[1],
                    },
                    style: style,
                  },
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1: lowPoint[0] - halfWidth,
                      y1: lowPoint[1],
                      x2: lowPoint[0] + halfWidth,
                      y2: lowPoint[1],
                    },
                    style: style,
                  },
                ],
              };
            },
            encode: {
              x: 0,
              y: [1, 2, 3],
              tooltip: [1, 2, 3],
            },
            data: (item?.chartValues ?? []).map((a, index) => {
              return [a.ts, a.valueMax?.toFixed(2), a.value?.toFixed(2), a.valueMin?.toFixed(2)];
            }),
          };
        } else {
          return {
            type: 'line',
            color: colors,
            name: item.name,
            data: (item.chartValues ?? []).map(a => {
              return [a.ts, a.value];
            }),
            smooth: true,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#89CFFF20' },
                { offset: 1, color: '#51A1FF10' },
              ]),
            },
            markPoint: getCommonMarkPint(unit),
          };
        }
      }),
    ],
  };
};
