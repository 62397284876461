import { AccountBookOutlined, RollbackOutlined } from '@ant-design/icons';
import { Form, SubContent, Radio, useBreadcrumbRoutes, Wrapper, FormTitle } from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ShowInput from '@/shared/components/ShowInput';
import {
  getSaleClientAccount,
  SaleClientAccountInfo,
  TransactionType,
  TransactionTypeDisplay,
} from '../../../api/sale';
import { formLayout } from '../util';

import styles from './index.module.scss';
import Recharge from './Recharge';
import Refund from './Refund';

const routes = [{ name: '充值/退款' }];

const RechargeOrRefund: FC = () => {
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const { id } = useParams<{ id?: string }>();

  const [data, setData] = useState<SaleClientAccountInfo>();

  const [transactionType, setTransactionType] = useState<TransactionType>(TransactionType.RECHARGE);
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    if (id) {
      getSaleClientAccount(id).then(setData);
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data.name,
        saleClientName: data.saleClientName,
        balance: (data.balance ?? undefined) !== undefined ? `${data.balance}元` : undefined,
      });
    }
  }, [data, form]);

  const goList = () => {
    navigate('/operation/client-related/client-account');
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title={routes[0].name} />
      <SubContent title="基础信息">
        <Form className={styles.formWrapper} layout="vertical" form={form} {...formLayout}>
          <Row>
            <Col span={6}>
              <Form.Item name="saleClientName" label="所属客户">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="name" label="账户名称">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="balance" label="账户余额">
                <ShowInput style={{ color: '#FF0000' }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </SubContent>
      <SubContent>
        <Radio.Group
          buttonStyle="solid"
          value={transactionType}
          onChange={e => {
            setTransactionType(e.target.value);
          }}
        >
          <Radio.Button value={TransactionType.RECHARGE}>
            <Space>
              <AccountBookOutlined />
              <span>{TransactionTypeDisplay[TransactionType.RECHARGE]}</span>
            </Space>
          </Radio.Button>
          <Radio.Button value={TransactionType.REFUND}>
            <Space>
              <RollbackOutlined />
              <span>{TransactionTypeDisplay[TransactionType.REFUND]}</span>
            </Space>
          </Radio.Button>
        </Radio.Group>
      </SubContent>
      {transactionType === TransactionType.RECHARGE && id !== undefined && (
        <Recharge saleClientAccountId={id} goList={goList} />
      )}
      {transactionType === TransactionType.REFUND && id !== undefined && (
        <Refund saleClientAccountId={id} goList={goList} />
      )}
    </Wrapper>
  );
};

export default RechargeOrRefund;
