import { useEffect } from 'react';
import { addNum, formatTime } from '../../utils';
import { AdjustBody, EnergyMediumTableDataItem, MeterTaskBaseInfo, adjustFunc } from '@/api/universeMeterQuery';
import ShowInput from '@/shared/components/ShowInput';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { Form, Input, InputNumber, Modal } from '@maxtropy/components';

interface Props {
  adjustOpen: boolean;
  setAdjustOpen: (open: boolean) => void;
  isElectricity: boolean;
  currentRow?: EnergyMediumTableDataItem;
  basicInfo?: MeterTaskBaseInfo;
  unitName: string;
  updateFn: () => void;
}

interface FormProps {
  deviceName: string;
  fact: number;
  eleAdjust: {
    summit: number;
    peak: number;
    plain: number;
    valley: number;
  };
  adjust: number;
  afterAdjust: number;
  adjustReason?: string;
}

const inputNumberProps = {
  style: { width: '100%' },
  min: -999999,
  max: 999999,
  step: 0.01,
  precision: 2,
};

const AdjustModal: React.FC<Props> = ({
  unitName,
  basicInfo,
  adjustOpen,
  setAdjustOpen,
  isElectricity,
  currentRow,
  updateFn,
}) => {
  const [adjustForm] = Form.useForm();
  const adjust = Form.useWatch('adjust', adjustForm);
  const eleAdjust = Form.useWatch('eleAdjust', adjustForm);

  useEffect(() => {
    adjustForm.setFieldsValue({
      deviceName: currentRow?.deviceName,
      fact: currentRow?.fact,
      eleAdjust: {
        summit: currentRow?.summitAdjust,
        peak: currentRow?.peakAdjust,
        plain: currentRow?.plainAdjust,
        valley: currentRow?.valleyAdjust,
      },
      afterAdjust: currentRow?.afterAdjust,
      adjustReason: currentRow?.adjustReason,
      adjust: currentRow?.adjust,
    });
  }, [currentRow, adjustForm]);

  useEffect(() => {
    if (isElectricity) {
      const afterAdjust = addNum(
        currentRow?.fact,
        eleAdjust?.summit,
        eleAdjust?.peak,
        eleAdjust?.plain,
        eleAdjust?.valley
      );
      adjustForm.setFieldsValue({ afterAdjust });
      return;
    }
    const afterAdjust = addNum(currentRow?.fact, adjust);
    adjustForm.setFieldsValue({ afterAdjust });
  }, [adjust, currentRow, adjustForm, eleAdjust, isElectricity]);

  const onFinish = (v: FormProps) => {
    if (currentRow?.itemId) {
      let body: AdjustBody;
      if (isElectricity) {
        body = {
          itemId: currentRow?.itemId,
          adjustType: 2,
          adjustAmountSummit: v.eleAdjust.summit,
          adjustAmountPeak: v.eleAdjust.peak,
          adjustAmountPlain: v.eleAdjust.plain,
          adjustAmountValley: v.eleAdjust.valley,
          adjustReason: v.adjustReason,
        };
      } else {
        body = {
          itemId: currentRow?.itemId,
          adjustType: 1,
          adjustAmount: adjust,
          adjustReason: v.adjustReason,
        };
      }
      adjustFunc(body).then(() => {
        setAdjustOpen(false);
        updateFn();
      });
    }
  };

  return (
    <Modal
      open={adjustOpen}
      title="调整值"
      contentClassName="modal-form-content"
      onCancel={() => {
        setAdjustOpen(false);
      }}
      onOk={() => {
        adjustForm.submit();
      }}
      size="normal"
    >
      <Form form={adjustForm} onFinish={onFinish}>
        <Form.Item name="deviceName" label="设备">
          <ShowInput />
        </Form.Item>
        <Form.Item label="时间">
          <ShowInput
            value={`${
              isNil(basicInfo?.lastReadingTime) ? '--' : dayjs(basicInfo?.lastReadingTime).format(formatTime)
            } 至 ${
              isNil(basicInfo?.currentReadingTime) ? '--' : dayjs(basicInfo?.currentReadingTime).format(formatTime)
            }`}
          />
        </Form.Item>
        <Form.Item name="fact" label="实际抄表值">
          <ShowInput addonAfter={unitName} />
        </Form.Item>
        {isElectricity ? (
          <>
            <Form.Item label="调整值" style={{ marginBottom: 0 }}>
              <Form.Item name={['eleAdjust', 'summit']}>
                <InputNumber
                  onChange={v => {
                    if (isNil(v)) {
                      adjustForm.setFieldValue(['eleAdjust', 'summit'], 0);
                    }
                  }}
                  addonAfter={unitName}
                  addonBefore="尖"
                  {...inputNumberProps}
                />
              </Form.Item>
              <Form.Item name={['eleAdjust', 'peak']}>
                <InputNumber
                  onChange={v => {
                    if (isNil(v)) {
                      adjustForm.setFieldValue(['eleAdjust', 'peak'], 0);
                    }
                  }}
                  addonAfter={unitName}
                  addonBefore="峰"
                  {...inputNumberProps}
                />
              </Form.Item>
              <Form.Item name={['eleAdjust', 'plain']}>
                <InputNumber
                  onChange={v => {
                    if (isNil(v)) {
                      adjustForm.setFieldValue(['eleAdjust', 'plain'], 0);
                    }
                  }}
                  addonAfter={unitName}
                  addonBefore="平"
                  {...inputNumberProps}
                />
              </Form.Item>
              <Form.Item name={['eleAdjust', 'valley']}>
                <InputNumber
                  onChange={v => {
                    if (isNil(v)) {
                      adjustForm.setFieldValue(['eleAdjust', 'valley'], 0);
                    }
                  }}
                  addonAfter={unitName}
                  addonBefore="谷"
                  {...inputNumberProps}
                />
              </Form.Item>
            </Form.Item>
          </>
        ) : (
          <Form.Item name="adjust" label="调整值">
            <InputNumber
              onChange={v => {
                if (isNil(v)) {
                  adjustForm.setFieldsValue({ adjust: 0 });
                }
              }}
              addonAfter={unitName}
              {...inputNumberProps}
            />
          </Form.Item>
        )}
        <Form.Item name="afterAdjust" label="调整后值">
          <ShowInput addonAfter={unitName} />
        </Form.Item>
        <Form.Item name="adjustReason" label="调整原因">
          <Input.TextArea
            autoSize={{
              minRows: 3,
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AdjustModal;
