import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';

import {
  EllipsisSpan,
  Paging,
  Table,
  useBreadcrumbRoutes,
  usePaging,
  useTenantPermissions,
  useUpdate,
  Wrapper,
  Button,
  useAsync,
  CustomFilter,
  Modal,
  Form,
  Input,
  Checkbox,
} from '@maxtropy/components';
import { Space } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import qs from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { OuStaffType, OuStatus, OuStatusDisplay } from '@/shared/const';
import { Operator } from '@/shared/types';
import {
  ProductionUnitListProps,
  queryOuTypeList,
  queryProductionUnitList,
  QueryProductionUnitListRequest,
  updateOuState,
} from '../../../api/ou';
import { PermissionsType } from '../../../common/permissionsConst';
import { useHasPermission, useQuery } from '../../../utils/utils';

type SearchParams = Omit<QueryProductionUnitListRequest, 'page' | 'size'>;

const ProductionUitList: React.FC = () => {
  const hasCreateOu = useHasPermission(PermissionsType.B_CREATE_OU);

  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const permission = useTenantPermissions();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const nameOrSerialNum = urlSearchParams.get('name') || undefined;
  const ifCancel = !!urlSearchParams.get('ifCancel');
  const [searchParams, setSearchParams] = useState<SearchParams>({
    nameOrSerialNum,
    ifCancel,
  });
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [invalidForm] = Form.useForm();

  const types = useAsync(queryOuTypeList, []);

  const [update, forceUpdate] = useUpdate();

  const breadcrumbRoutes = useBreadcrumbRoutes();

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        queryProductionUnitList({
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) {
            setTotalCount(res.total);
          }
          return res.list;
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [pageOffset, pageSize, searchParams, setTotalCount, update]
    )
  );

  const columns: ColumnType<ProductionUnitListProps>[] = [
    {
      title: '编号',
      dataIndex: 'serialNumber',
      width: 200,
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v} />,
    },
    {
      title: '名称',
      dataIndex: 'name',
      width: 320,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    ...(Array.isArray(types) && types.length > 1
      ? [
          {
            title: '类型',
            width: 100,
            dataIndex: 'ouTypeName',
            ellipsis: { showTitle: true },
            render: (v: string) => <EllipsisSpan value={v} />,
          },
        ]
      : []),
    {
      title: '设备数量',
      dataIndex: 'deviceNum',
      width: 100,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v ? v : '0'} />,
    },
    {
      title: '状态',
      width: 100,
      dataIndex: 'status',
      ellipsis: { showTitle: true },
      render: (v: OuStatus) => <EllipsisSpan value={OuStatusDisplay[v]} />,
    },
    {
      title: '所属租户',
      width: 160,
      dataIndex: 'tenantName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '所属组织',
      width: 160,
      dataIndex: 'customerName',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={String(v)} />,
    },
    {
      title: '最后操作时间',
      width: 200,
      dataIndex: 'updateTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
    },
    {
      title: '最后操作人',
      width: 200,
      ellipsis: { showTitle: true },
      render: (_: number, record) => (
        <EllipsisSpan value={`${record.updateSource === Operator.OPS ? '运管人员' : record.updateByUsername}`} />
      ),
    },
  ];

  const openInvalid = (id: number) => {
    Modal.confirm({
      icon: null,
      title: '作废',
      content: (
        <>
          <Form form={invalidForm} isOrigin={true} layout="horizontal">
            <Form.Item label="请输入操作密码" name="password" rules={[{ required: true, message: '请输入操作密码' }]}>
              <Input.Password autoComplete="new-password" placeholder="请输入操作密码" />
            </Form.Item>
          </Form>
        </>
      ),
      onOk: () => {
        return invalidForm.validateFields().then(value => {
          updateOuState(id, {
            state: OuStatus.DISABLE,
            password: value.password,
          }).then(() => {
            Modal.warning({
              title: '作废成功！',
            });
            forceUpdate();
          });
        });
      },
      afterClose: () => {
        invalidForm.resetFields();
      },
    });
  };

  const render = (record: ProductionUnitListProps) => (
    <Space size={16}>
      {record.status !== OuStatus.DISABLE && (
        <>
          {(permission ?? []).includes(PermissionsType.B_ABOLISH_OU) && (
            <>
              <Button
                type="link"
                onClick={() => openInvalid(record.id)}
                disabled={record.userType === OuStaffType.MEMBER}
              >
                作废
              </Button>
            </>
          )}
          <Button type="link" disabled={record.userType === OuStaffType.MEMBER}>
            <Link to={`/device/ou/production-unit/edit/${record.id}`}>编辑</Link>
          </Button>

          <Button type="link">
            <Link to={`/device/ou/production-unit/${record.id}/device`}>设备管理</Link>
          </Button>
        </>
      )}
      <Button type="link">
        <Link to={`/device/ou/production-unit/detail/${record.id}`}>查看</Link>
      </Button>
      {record.homePage && (
        <Button onClick={() => window.open(record.homePage, '__blank')} type="link">
          首页
        </Button>
      )}
    </Space>
  );

  useEffect(() => {
    form.setFieldsValue({ nameOrSerialNum, ifCancel });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (val: any) => {
    const params = {
      nameOrSerialNum: val.nameOrSerialNum ? val.nameOrSerialNum : undefined,
      ifCancel: !!val.ifCancel,
    };
    setSearchParams(params);
    setPageOffset(1);
    const paramsObj = {
      nameOrSerialNum: val.nameOrSerialNum ? val.nameOrSerialNum : undefined,
      ifCancel: !!val.ifCancel,
    };
    const paramsMap = new Map<string, string>();
    Object.entries(paramsObj).forEach(([key, value]) => {
      value && paramsMap.set(key, value);
    });
    navigate(`?${qs.stringify(Object.fromEntries(paramsMap))}`);
  };

  const onReset = () => {
    const params: SearchParams = {
      nameOrSerialNum: undefined,
      ifCancel: false,
    };
    setSearchParams(params);
    setPageOffset(1);
    navigate(`?`);
  };

  const filters = (
    <CustomFilter form={form} onFinish={onFinish} onReset={onReset}>
      <Form.Item name="nameOrSerialNum" label="编号/名称">
        <Input placeholder={'请输入编号或名称查询'} />
      </Form.Item>

      <Form.Item name="ifCancel" valuePropName="checked">
        <Checkbox>显示已作废数据</Checkbox>
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} filters={filters} className="page_wrapper">
      {hasCreateOu && (
        <Space size={10} align="center" style={{ marginBottom: 10 }}>
          <Link to={`/device/ou/production-unit/new`}>
            <Button type="primary" icon={<PlusOutlined />}>
              新建运营单元
            </Button>
          </Link>
          <Space size={8}>
            <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />
            <span className="warning_color">
              若要编辑运营单元基础信息，维护运营单元的管理员和成员，维护运营单元的设备，需要将账号设置为运营单元的管理员权限。
            </span>
          </Space>
        </Space>
      )}
      <Table
        sticky
        rowKey="id"
        dataSource={data}
        loading={isLoading}
        scroll={{ x: 1900 }}
        columns={[...columns, { title: '操作', width: 260, fixed: 'right', render }]}
      />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default ProductionUitList;
