import {
  Button,
  useUpdate,
  Input,
  message,
  Select,
  Radio,
  Wrapper,
  Paging,
  usePaging,
  Steps,
  useBreadcrumbRoutes,
  Form,
  Table,
  FormTitle,
} from '@maxtropy/components';
import { Col, Row, Space, Empty } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import type { ColumnsType } from 'antd/es/table';
import ShowInput from '@/shared/components/ShowInput';
import { YearsOptions, MainDataSourcesTypeMap, validateText, BoundaryType, BoundaryTypeMap } from '../../utils';
import { Rule } from 'antd/lib/form';
import { isEmpty, isNil } from 'lodash-es';
import { DataBom, outputDataType, FinishModelType, StepsType } from './const';
import {
  apiV2CarbonFootprintLcaAddPost,
  apiV2OutputProductBomTreePost,
  apiV2OutputProductPagePost,
} from '@maxtropy/device-customer-apis-v2';
import ImportBom from '../../../../OutputConfig/Bom/ImportBom';
import { SearchOutlined } from '@ant-design/icons';
const steps = [
  {
    title: '选择产品',
  },
  {
    title: '设置BOM',
  },
  {
    title: '基本信息',
  },
];

const items = steps.map(item => ({ key: item.title, title: item.title }));

const CreateModel: React.FC = () => {
  const [form] = Form.useForm();
  const [update, updateFn] = useUpdate();

  const navigator = useNavigate();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const { TextArea } = Input;
  const pagingInfo = usePaging(10);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [current, setCurrent] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [selectedRows, setSelectedRows] = useState<outputDataType[]>([]);
  const [importFlag, setImportFlag] = useState<boolean>(false);
  const [bomData, setBomData] = useState<DataBom[]>([]);
  const [outputData, setOutputData] = useState<outputDataType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const routes = [{ name: '新建模型' }];

  useEffect(() => {
    apiV2OutputProductPagePost({
      codeOrNameOrSpec: searchValue,
      state: 1,
      page: pageOffset,
      size: pageSize,
    }).then((res: any) => {
      if (!isNil(res)) {
        setOutputData(res?.list);
        setTotalCount(res?.total ?? 0);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, pageSize, setTotalCount, update]);

  const formatTreeData = (data: any[]) => {
    return data.map((item: any) => {
      const res: DataBom = {
        key: item.id ?? '',
        parentId: item.parentId,
        name: item.name,
        spec: item.spec,
        code: item.code,
        quantity: item.quantity,
        children: isEmpty(item.bomTreeVoV2s) ? undefined : formatTreeData(item.bomTreeVoV2s!),
      };
      return res;
    });
  };

  const next = () => {
    if (current === 0) {
      if (selectedRowKeys.length === 0) {
        message.warning({
          content: '请选择一条产出物',
        });
        return;
      }
      setLoading(true);
      apiV2OutputProductBomTreePost({ id: selectedRowKeys[0] })
        .then(res => {
          setBomData(formatTreeData(res?.list ?? []));
          if (!isNil(res?.list) && res?.list.length > 0) {
            setCurrent(2);
          } else {
            setCurrent(current + 1);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (current === 1) {
      setCurrent(current + 1);
    }
  };

  useEffect(() => {
    if (current === StepsType.Second) {
      apiV2OutputProductBomTreePost({ id: selectedRowKeys[0] }).then(res => {
        setBomData(formatTreeData(res?.list ?? []));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  const prev = () => {
    setCurrent(current - 1);
    setPageOffset(1);
  };

  const columns: ColumnsType<outputDataType> = [
    {
      title: '产出物编码',
      dataIndex: 'code',
      ellipsis: true,
    },
    {
      title: '产出物名称',
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: '规格型号',
      ellipsis: true,
      dataIndex: 'spec',
    },
  ];

  const columnsBOM: ColumnsType<DataBom> = [
    {
      title: '物料编码',
      dataIndex: 'code',
      width: '35%',
    },
    {
      title: '物料名称',
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: '规格型号',
      dataIndex: 'spec',
      ellipsis: true,
    },
    {
      title: '数量/单位',
      dataIndex: 'quantity',
      ellipsis: true,
      width: '15%',
    },
  ];

  const renderEmpty = () => (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      imageStyle={{
        marginTop: 47,
        marginBottom: 20,
      }}
      description={
        <div className={styles.emptyDetail}>
          本产品还未维护 BOM 信息,请
          <Button
            type="link"
            onClick={() => {
              setImportFlag(true);
            }}
          >
            <span style={{ fontSize: 14 }}>导入BOM</span>
          </Button>
        </div>
      }
    ></Empty>
  );

  const onFinish = (values: FinishModelType) => {
    const params = {
      ...values,
      outputProductId: selectedRows[0].id,
      unitTypeCode: selectedRows[0].unitTypeCode,
      unitCode: selectedRows[0].unitCode,
    };

    apiV2CarbonFootprintLcaAddPost({ ...params }).then(res => {
      setSelectedRowKeys([]);
      setPageOffset(1);
      navigator(`/productCarbonFootPrint/basic/modelManage/newModel?id=${res.carbonLcaId}`);
    });
  };

  return (
    <>
      <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.content}>
        <FormTitle title="新建模型"></FormTitle>

        <div className={styles.stepsStyle}>
          <Steps current={current} items={items} />
        </div>
        <Form
          form={form}
          className={styles.createModelForm}
          layout="vertical"
          initialValues={{ boundary: BoundaryType.GateToGate, standardYear: dayjs().year() }}
          onFinish={v => onFinish(v)}
        >
          {current === StepsType.First && (
            <div className={styles.firstStep}>
              <Input
                placeholder="请输入产出物名称/编码/规格"
                className={styles.searchInput}
                suffix={<SearchOutlined />}
                onBlur={e => {
                  setSearchValue(e.target.value);
                  updateFn();
                }}
                onPressEnter={(e: any) => {
                  setSearchValue(e.target.value);
                  updateFn();
                }}
              ></Input>
              <Table
                rowKey={record => record.id}
                loading={loading}
                rowSelection={{
                  selectedRowKeys,
                  type: 'radio',
                  onSelect: (record, selected, selectedRows) => {
                    let keys = [record.id];
                    setSelectedRowKeys(keys);
                    setSelectedRows(selectedRows);
                    form.setFieldsValue({
                      name: selectedRows[0].name,
                      code: selectedRows[0].code,
                      spec: selectedRows[0].spec,
                      quantity: 1,
                    });
                  },
                }}
                columns={columns}
                dataSource={outputData}
                pagination={false}
              />
              <Paging pagingInfo={pagingInfo} />
              <Button type="primary" onClick={() => next()}>
                下一步
              </Button>
            </div>
          )}
          {current === StepsType.Second && (
            <div className={styles.secondStep}>
              <Row gutter={48}>
                <Col span={8}>
                  <Form.Item name="code" label="产出物编码">
                    <ShowInput />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="name" label="产出物名称">
                    <ShowInput />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="spec" label="规格型号">
                    <ShowInput />
                  </Form.Item>
                </Col>
              </Row>
              <div className={styles.bomContent}>
                <div className={styles.bomTitle}>产品 BOM</div>
                <Table
                  rowKey={record => record.key}
                  columns={columnsBOM}
                  dataSource={bomData}
                  pagination={false}
                  indentSize={16}
                  defaultExpandAllRows={true}
                  locale={{
                    emptyText: renderEmpty,
                  }}
                />
              </div>
              <Space size={8}>
                <Button onClick={() => prev()}>上一步</Button>
                <Button type="primary" onClick={() => next()}>
                  下一步
                </Button>
              </Space>
            </div>
          )}
          {current === StepsType.Third && (
            <div className={styles.thirdStep}>
              <Row gutter={48}>
                <Col span={8}>
                  <Form.Item name="code" label="产出物编码">
                    <ShowInput />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="name" label="产出物名称">
                    <ShowInput />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="spec" label="规格型号">
                    <ShowInput />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={48}>
                <Col span={8}>
                  <Form.Item name="quantity" label="数量">
                    <ShowInput />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="dataOrigin" label="主要数据来源" rules={[{ message: '请选择', required: true }]}>
                    <Select
                      placeholder={'请选择'}
                      className={styles.sizeSelect}
                      options={Object.entries(MainDataSourcesTypeMap).map(([k, v]) => ({
                        label: v,
                        value: +k,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="standardYear" label="基准年" rules={[{ message: '请选择', required: true }]}>
                    <Select placeholder={'请选择'} options={YearsOptions}></Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={48}>
                <Col span={8}>
                  <Form.Item name="boundary" label="核算边界">
                    <Radio.Group>
                      <Radio value={BoundaryType.GateToGate}>{BoundaryTypeMap[BoundaryType.GateToGate]}</Radio>
                      <Radio value={BoundaryType.CradleToGate}>{BoundaryTypeMap[BoundaryType.CradleToGate]}</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="company" label="生产公司" rules={[validateText(100) as Rule]}>
                    <TextArea placeholder="请输入生产公司 1~100 字符" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="madeIn" label="产品产地" rules={[validateText(100) as Rule]}>
                    <TextArea placeholder="请输入产品产地 1~100 字符" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="remark" label="补充说明" rules={[validateText(200) as Rule]}>
                    <TextArea placeholder="请输入产品产地 1~200 字符" />
                  </Form.Item>
                </Col>
              </Row>

              <Space size={8}>
                <Button className={styles.preButtonStyle} onClick={() => prev()}>
                  上一步
                </Button>
                <Button htmlType="submit" type="primary">
                  确定创建
                </Button>
              </Space>
            </div>
          )}
        </Form>

        {importFlag && (
          <ImportBom
            data={selectedRows[0]}
            id={Number(selectedRowKeys[0])}
            setVisible={setImportFlag}
            updateFn={updateFn}
          />
        )}
      </Wrapper>
    </>
  );
};
export default CreateModel;
