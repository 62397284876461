import React, { useContext } from 'react';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { Table } from '@maxtropy/components';
import { DataPointType } from '../../../../../types';
import { getColumns } from './columns';
import { EdgeDeviceTemplatePoint } from './index';
import { PointContext, PointContextProps } from '../../../../EdgeDevicePointInfo/contextTypes';
import { BatchEdgeContent } from '../../../contentTypes';
import { getUpdatePointParameters } from '../../../utils';

interface IEC104Props {
  editColumns?: ColumnsType<EdgeDeviceTemplatePoint>;
  alterMode?: boolean;
}

const indexColumn: ColumnType<EdgeDeviceTemplatePoint> = {
  title: '序号',
  width: 70,
  render: (_, record, index) => index + 1,
};

const IEC104PointBase: React.FC<IEC104Props> = props => {
  const { editColumns, alterMode } = props;

  const { dataSource, info } = useContext(PointContext) as PointContextProps<EdgeDeviceTemplatePoint>;
  const { baseForm } = useContext(BatchEdgeContent);
  let { parameter = [] } = baseForm?.getFieldsValue();
  parameter = getUpdatePointParameters(parameter, info?.driveType!);

  return (
    <Table
      sticky
      scroll={{ y: 500 }}
      rowKey="id"
      dataSource={dataSource?.filter(item => item.pointType === DataPointType.BASE_POINT)}
      columns={[indexColumn, ...getColumns(alterMode ? parameter : undefined, alterMode), ...(editColumns || [])]}
    />
  );
};

export default IEC104PointBase;
