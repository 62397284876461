import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { Col, Form, Row } from 'antd';
import { isEmpty, isNil } from 'lodash-es';
import {
  ActionType,
  BIT_MODE,
  DataPointType,
  DataProperty,
  DataPropertyType,
  LENGTH,
  OBJECT_TYPE,
  VALUE_TYPE,
  ModbusPointBase,
} from '../../../../../types';
import { PointContext } from '../../../../EdgeDevicePointInfo/contextTypes';
import { useDataProperties } from '../../../../EdgeDevicePointInfo/mockingbird/Point/hooks';
import FormSubmit from '../../../../EdgeDevicePointInfo/mockingbird/FormSubmit';
import {
  getBitModeAndByteNumAndBitNum,
  getDefaultHandleModeAndValueType,
  getHandleModeMapping,
  getMode,
  getValueTypeMapping,
} from '../../../../EdgeDevicePointInfo/mockingbird/Point/modbusPoint/CreateForm';
import {
  bitModeFormatter,
  checkNum,
  identifierValidatorBuilder,
  lengthFormatter,
  modeOptions,
  objectFormatter,
} from '../../../../EdgeDevicePointInfo/mockingbird/Point/entity';
import ShowInput from '../../../../ShowInput';
import { BatchEdgeContent } from '../../../contentTypes';
import { PointOperateType } from '../../../interface';
import { Input, Modal, Radio, Select, message, InputNumber } from '@maxtropy/components';
import { FilterMode, FilterModeDisplay } from '@/shared/const';
import { validatorValueRange } from '@/shared/utils/utils';

const { Option } = Select;

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

export interface FormModbusPoint extends ModbusPointBase {
  actionType?: ActionType;
  id?: number;
  pointType?: DataPointType;
  hasProperty?: boolean;
  writable?: boolean;
  dataPropertyId?: number;
  physicalUnitId?: number;
  dataPropertyName?: string;
  physicalUnitGeneralName?: string;
  identifier?: string;
}

export interface PointFormDialogProps {
  row?: FormModbusPoint;
  dataPropertiesAll?: DataProperty[];
  usedProperties?: number[];
  usedIdentifier?: string[];
  onCancel: () => void;
  onOk: (values: any) => void;
  alterMode?: boolean;
}

const PointFormDialog: React.FC<PointFormDialogProps> = props => {
  const { row, dataPropertiesAll, usedProperties, usedIdentifier, onCancel, onOk, alterMode } = props;
  const { setRow } = useContext(PointContext);
  const { baseForm } = useContext(BatchEdgeContent);
  const { pointOperateType } = baseForm?.getFieldsValue();

  const { dataProperties, dataPropertyId, setDataPropertyId, units, dataPropertyType } = useDataProperties(
    dataPropertiesAll,
    usedProperties
  );

  const [form] = Form.useForm();
  const { setFieldsValue, getFieldValue } = form;

  const initStatusRef = useRef(true);

  const valueFilterMode = Form.useWatch('valueFilterMode', form);

  const initFormValues = useCallback(
    (
      objectType: OBJECT_TYPE | undefined,
      length: LENGTH | undefined,
      mode: string | undefined,
      handleMode: number | undefined,
      valueType: VALUE_TYPE | undefined,
      rest: object = {}
    ) => {
      Promise.resolve()
        .then(() => setFieldsValue({ objectType }))
        .then(() => setFieldsValue(Object.assign({ length }, !isNil(mode) ? { mode } : {})))
        .then(() =>
          setFieldsValue(
            Object.assign(
              {
                ...rest,
              },
              !isNil(valueType) ? { valueType } : {},
              !isNil(handleMode) ? { handleMode } : {}
            )
          )
        )
        .finally(() => {
          initStatusRef.current = false;
        });
    },
    [setFieldsValue]
  );

  // 初始化
  useEffect(() => {
    if (row && !isNil(row.objectType)) {
      // 编辑 和 保存并继续添加
      const { objectType, length, handleMode, valueType, bitMode, bitNum, byteNum, ...rest } = row;
      const mode = getMode(bitMode, bitNum, byteNum);
      initFormValues(objectType, length, mode, handleMode, valueType, rest);
    } else {
      // 新建
      const { handleMode, valueType } = getDefaultHandleModeAndValueType(LENGTH.ONE, bitModeFormatter[BIT_MODE.NULL]);
      initFormValues(OBJECT_TYPE.SAVEREGISTER, LENGTH.ONE, bitModeFormatter[BIT_MODE.NULL], handleMode, valueType, row);
    }
  }, [initFormValues, row]);

  // 改变objectType
  const onObjectTypeChange = (objectType: OBJECT_TYPE) => {
    if (initStatusRef.current) return;
    if (objectType === OBJECT_TYPE.COILSTATUS || objectType === OBJECT_TYPE.INPUTSTATUS) {
      setFieldsValue({
        valueType: VALUE_TYPE.BOOLEAN,
      });
    }
    if (objectType === OBJECT_TYPE.SAVEREGISTER || objectType === OBJECT_TYPE.INPUTREGISTER) {
      Promise.resolve()
        .then(() =>
          setFieldsValue({
            length: LENGTH.ONE,
            mode: bitModeFormatter[BIT_MODE.NULL],
          })
        )
        .then(() => setFieldsValue(getDefaultHandleModeAndValueType(LENGTH.ONE, bitModeFormatter[BIT_MODE.NULL])));
    }
  };

  // 改变length
  const onLengthChange = (length: LENGTH) => {
    if (initStatusRef.current) return;
    Promise.resolve()
      .then(() =>
        setFieldsValue({
          mode: bitModeFormatter[BIT_MODE.NULL],
        })
      )
      .then(() => {
        setFieldsValue(getDefaultHandleModeAndValueType(length, bitModeFormatter[BIT_MODE.NULL]));
      });
  };

  // 改变mode
  const onModeChange = (mode: string) => {
    if (initStatusRef.current) return;
    setFieldsValue(getDefaultHandleModeAndValueType(getFieldValue('length'), mode));
  };

  const onValuesChange = (changedValues: { [key: string]: any }) => {
    const { objectType, length, mode } = changedValues;

    if (!isNil(objectType)) {
      onObjectTypeChange(objectType);
    }
    if (!isNil(length)) {
      onLengthChange(length);
    }
    if (!isNil(mode)) {
      onModeChange(mode);
    }
  };

  useEffect(() => {
    if (row) {
      setDataPropertyId(row.dataPropertyId);
    }
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);

  useEffect(() => {
    if (dataPropertyType === DataPropertyType.YC && !isEmpty(units)) {
      form.setFieldsValue({
        physicalUnitId: units?.find(unit => unit.default)?.id,
        physicalUnitGeneralName: units?.find(unit => unit.default)?.generalName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPropertyId, dataPropertyType, units]);

  const submit = async (shouldContinue?: boolean) => {
    const values = await form.validateFields();
    const { mode, ...rest } = values;
    const submitValues = {
      ...rest,
      ...getBitModeAndByteNumAndBitNum(mode),
    };
    if (shouldContinue) {
      await onOk(submitValues);
      form.resetFields();
      setRow?.({
        ...values,
        pointType: DataPointType.BASE_POINT,
        dataPropertyId: undefined,
        physicalUnitId: undefined,
        identifier: undefined,
      });
    } else {
      await onOk(submitValues);
      onCancel();
    }
  };

  return (
    <Modal
      open={row?.pointType === DataPointType.BASE_POINT}
      onCancel={onCancel}
      title={`${pointOperateType === PointOperateType.UPDATE ? '编辑' : '添加'}采集点`}
      centered={true}
      maskClosable={false}
      footer={<FormSubmit submit={submit} cancel={onCancel} alterMode={alterMode} />}
    >
      <div style={{ height: 600, overflow: 'auto' }}>
        <Form {...formLayout} form={form} labelWrap={true} onValuesChange={onValuesChange}>
          {alterMode ? <Form.Item noStyle name="dataPropertyId" /> : <Form.Item noStyle name="dataPropertyName" />}
          <Form.Item noStyle name="physicalUnitGeneralName" />

          {alterMode ? (
            <Form.Item noStyle name="hasProperty" />
          ) : (
            <Form.Item
              name="hasProperty"
              label="采集点类型"
              initialValue={true}
              rules={[{ required: true, message: '请选择' }]}
            >
              <Radio.Group disabled={!isNil(row?.id)}>
                <Radio value={true}>建模采集点</Radio>
                <Radio value={false}>非建模采集点</Radio>
              </Radio.Group>
            </Form.Item>
          )}

          {alterMode ? (
            <>
              <Form.Item
                name={row?.hasProperty ? 'dataPropertyName' : 'identifier'}
                label={row?.hasProperty ? '数据属性' : '非建模点标识符'}
              >
                <ShowInput />
              </Form.Item>
              {dataPropertyType === DataPropertyType.YC && (
                <Form.Item name="physicalUnitId" label="单位" rules={[{ required: true, message: '请选择单位' }]}>
                  <Select
                    onChange={value => {
                      form.setFieldsValue({
                        physicalUnitGeneralName: units?.find(item => item.id === value)?.generalName,
                      });
                    }}
                    placeholder="请选择"
                  >
                    {units?.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.generalName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </>
          ) : (
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => prevValues.hasProperty !== curValues.hasProperty}
            >
              {({ getFieldValue }) =>
                getFieldValue('hasProperty') ? (
                  <>
                    <Form.Item
                      name="dataPropertyId"
                      label="数据属性"
                      rules={[{ required: true, message: '请选择数据属性' }]}
                    >
                      <Select
                        onChange={value => {
                          form.setFieldsValue({
                            dataPropertyName: dataProperties?.find(item => item.id === value)?.name,
                          });
                          setDataPropertyId(value as number);
                          form.setFieldsValue({
                            physicalUnitId: undefined,
                            physicalUnitGeneralName: '',
                          });
                        }}
                        placeholder="请输入/选择"
                        showSearch
                        filterOption={(input, option) =>
                          (option!.children as unknown as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
                      >
                        {dataProperties?.map(item => (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {dataPropertyType === DataPropertyType.YC && (
                      <Form.Item name="physicalUnitId" label="单位" rules={[{ required: true, message: '请选择单位' }]}>
                        <Select
                          onChange={value => {
                            form.setFieldsValue({
                              physicalUnitGeneralName: units?.find(item => item.id === value)?.generalName,
                            });
                          }}
                          placeholder="请选择"
                        >
                          {units?.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.generalName}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                  </>
                ) : (
                  <Form.Item
                    required
                    name="identifier"
                    label="非建模采集点标识符"
                    rules={[
                      {
                        validator: identifierValidatorBuilder(usedIdentifier),
                      },
                    ]}
                  >
                    <Input placeholder="请输入" />
                  </Form.Item>
                )
              }
            </Form.Item>
          )}

          <Form.Item
            name="writable"
            label="是否可写"
            initialValue={false}
            rules={[{ required: true, message: '请选择' }]}
          >
            <Radio.Group>
              <Radio value={true}>是</Radio>
              <Radio value={false}>否</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item name="objectType" label="功能码" rules={[{ required: true, message: '请选择' }]}>
            <Select placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}>
              <Option value={OBJECT_TYPE.COILSTATUS}>{objectFormatter[OBJECT_TYPE.COILSTATUS]}</Option>
              <Option value={OBJECT_TYPE.INPUTSTATUS}>{objectFormatter[OBJECT_TYPE.INPUTSTATUS]}</Option>
              <Option value={OBJECT_TYPE.SAVEREGISTER}>{objectFormatter[OBJECT_TYPE.SAVEREGISTER]}</Option>
              <Option value={OBJECT_TYPE.INPUTREGISTER}>{objectFormatter[OBJECT_TYPE.INPUTREGISTER]}</Option>
            </Select>
          </Form.Item>
          <Form.Item required label="开始地址">
            <Input placeholder="excel模版导入" disabled />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const objectType = getFieldValue('objectType');
              return (
                (objectType === OBJECT_TYPE.SAVEREGISTER || objectType === OBJECT_TYPE.INPUTREGISTER) && (
                  <Form.Item name="length" label="长度" rules={[{ required: true, message: '请选择' }]}>
                    <Select
                      placeholder="请选择"
                      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
                    >
                      <Option value={LENGTH.ONE}>{lengthFormatter[LENGTH.ONE]}</Option>
                      <Option value={LENGTH.TWO}>{lengthFormatter[LENGTH.TWO]}</Option>
                      <Option value={LENGTH.FOUR}>{lengthFormatter[LENGTH.FOUR]}</Option>
                    </Select>
                  </Form.Item>
                )
              );
            }}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const objectType = getFieldValue('objectType');
              const length = getFieldValue('length');
              return (
                (objectType === OBJECT_TYPE.SAVEREGISTER || objectType === OBJECT_TYPE.INPUTREGISTER) && (
                  <Form.Item name="mode" label="位操作模式" rules={[{ required: true, message: '请选择' }]}>
                    <Select
                      placeholder="请选择"
                      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
                    >
                      {(length === LENGTH.ONE ? modeOptions : modeOptions.slice(0, 1)).map(key => (
                        <Option key={key} value={key}>
                          {key}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )
              );
            }}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const objectType = getFieldValue('objectType');
              const length = getFieldValue('length');
              const mode = getFieldValue('mode');
              const formatter = getHandleModeMapping(objectType, length);
              const visible =
                (objectType === OBJECT_TYPE.SAVEREGISTER || objectType === OBJECT_TYPE.INPUTREGISTER) &&
                length !== undefined &&
                (length !== LENGTH.ONE || mode === bitModeFormatter[BIT_MODE.NULL]);

              return (
                visible && (
                  <Form.Item name="handleMode" label="处理方式" rules={[{ required: true, message: '请选择' }]}>
                    <Select
                      placeholder="请选择"
                      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
                    >
                      {Object.entries(formatter || {}).map(([value, key]) => {
                        return (
                          <Option key={value} value={Number(value)}>
                            {key}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )
              );
            }}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const objectType = getFieldValue('objectType');
              const length = getFieldValue('length');
              const mode = getFieldValue('mode');
              const formatter = getValueTypeMapping(objectType, length, mode);
              return (
                <Form.Item name="valueType" label="解码类型" rules={[{ required: true, message: '请选择' }]}>
                  <Select placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}>
                    {Object.entries(formatter || {}).map(([value, key]) => {
                      return (
                        <Option key={value} value={Number(value)}>
                          {key}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              return (
                <>
                  <Form.Item
                    required
                    label="乘积系数k"
                    tooltip="y=kx+b，x为直采数据，y为输出数据，k为乘积系数，b为偏移系数"
                  >
                    <Input placeholder="excel模版导入" disabled />
                  </Form.Item>
                  <Form.Item
                    required
                    label="偏移系数b"
                    tooltip="y=kx+b，x为直采数据，y为输出数据，k为乘积系数，b为偏移系数"
                  >
                    <Input placeholder="excel模版导入" disabled />
                  </Form.Item>
                </>
              );
            }}
          </Form.Item>
          <Form.Item
            name="valueFilterMode"
            label="数值过滤模式"
            initialValue={0}
            rules={[{ required: true, message: '请选择' }]}
            shouldUpdate
          >
            <Radio.Group
              onChange={v => {
                if (v.target?.value === FilterMode.GROWTHREASONABLERANGE) {
                  message.warning('新网关版本在3.7.30版本以上');
                }
              }}
            >
              {Object.entries(FilterModeDisplay).map(([k, v]) => (
                <Radio key={k} value={Number(k)}>
                  {v}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          {valueFilterMode === 1 && (
            <Form.Item
              label="数值合理区间"
              name="valueRange"
              dependencies={['valueFloor', 'valueCeiling']}
              rules={[
                form => ({
                  validator: () => validatorValueRange(form),
                }),
              ]}
              tooltip="区间外数值将被直接滤除，视为无效数值"
            >
              <Row gutter={8}>
                <Col span={11}>
                  <Form.Item name="valueFloor" noStyle rules={[{ validator: checkNum }]}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col span={2}>--</Col>
                <Col span={11}>
                  <Form.Item name="valueCeiling" noStyle rules={[{ validator: checkNum }]}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          )}
          {valueFilterMode === 2 && (
            <>
              <Form.Item
                label="增长率合理区间"
                name="valueRange"
                dependencies={['valueFloor', 'valueCeiling']}
                rules={[
                  form => ({
                    validator: () => validatorValueRange(form),
                  }),
                ]}
                tooltip="新网关版本在3.7.30版本以上"
              >
                <Row gutter={8}>
                  <Col span={9}>
                    <Form.Item name="valueFloor" noStyle rules={[{ validator: checkNum }]}>
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col span={2}>--</Col>
                  <Col span={9}>
                    <Form.Item name="valueCeiling" noStyle rules={[{ validator: checkNum }]}>
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col span={3}>/min</Col>
                </Row>
              </Form.Item>
              <Form.Item
                name="valueFilterCnt"
                label="边缘侧异常数据过滤个数"
                initialValue={5}
                rules={[{ required: true, message: '请输入' }]}
              >
                <InputNumber
                  placeholder="请输入"
                  min={1}
                  max={20}
                  precision={0}
                  style={{
                    width: '100%',
                  }}
                />
              </Form.Item>
            </>
          )}
          {!alterMode && (
            <Form.Item label="备注" name="remark" rules={[{ max: 50, message: '长度不能超过50个字符' }]}>
              <Input placeholder="请输入备注" />
            </Form.Item>
          )}
        </Form>
      </div>
    </Modal>
  );
};

export default PointFormDialog;
