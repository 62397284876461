import { isNil } from 'lodash-es';
import { NodeStatus, NodeStatusDisplay, NodeStatusTag, NodeType } from '../../utils/const';
import styles from './index.module.scss';
import { Node } from '@antv/x6';
import { Tag } from '@maxtropy/components';

// 自定义节点
const DescNode = ({ node }: { node: Node }) => {
  const { name, isAlarm, type, status, displayData = [] } = node.getData();
  return (
    <div className={styles.descNode}>
      <div className={styles.monitorTitle}>
        <span style={{ color: isAlarm ? 'var(--mx-error-color)' : 'var(--mx-text-base-color)' }}>{name}</span>
        {(type === NodeType.COMPRESSOR || type === NodeType.NITROGEN_GENERATOR) && !isNil(status) && (
          <Tag border="solid" style={{ borderRadius: 2 }} color={NodeStatusTag[status as NodeStatus]}>
            {NodeStatusDisplay[status as NodeStatus]}
          </Tag>
        )}
      </div>
      {(displayData ?? []).map((k: { label: string; value: string }) => (
        <div className={styles.indicatorRow}>
          {k.label}： <span className={styles.value}>{k.value}</span>
        </div>
      ))}
    </div>
  );
};

export default DescNode;
