import {
  EnergyMediumTreeFormatType,
  getMediumIndicatorProcessPointData,
  MediumIndicatorStatisticsReq,
} from '@/api/energyMedium';
import { Card, Tabs, TabsProps } from 'antd';
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { energyMediumBtn } from '../../config';
import Chart from '../Chart';
import styles from './index.module.scss';
import { useSize } from 'ahooks';
import { Empty, useUpdate } from '@maxtropy/components';
import { MediumUnitDataContext } from '../..';

export interface MonitoringIndicatorsTabProps extends Partial<TabsProps> {
  currentItem?: energyMediumBtn;
  onTabChange?: (tabTile: string) => void;
  query: MediumIndicatorStatisticsReq;
}

const ENTRY = '0'; // 输入
const EXIT = '1'; // 输出

const ProcessChartsTab: FC<MonitoringIndicatorsTabProps> = props => {
  const { activeKey, onTabChange, currentItem, query } = props;

  const configValue = useContext(MediumUnitDataContext);

  const itemRef = useRef<HTMLDivElement>();
  const itemSize = useSize(itemRef);
  const scrollRef = useRef<HTMLDivElement>();

  const [currentEntryChartIndex, setCurrentEntryChartIndex] = useState<number>(0);
  const [currentExitChartIndex, setCurrentExitChartIndex] = useState<number>(0);
  const [currentEntryCharts, setCurrentEntryCharts] = useState<any[]>([]);
  const [currentExitCharts, setCurrentExitCharts] = useState<any[]>([]);

  const indexEntryNumbersRef = useRef<number[]>([0]);
  const indexExitNumbersRef = useRef<number[]>([0]);

  const [updateState, updateFn] = useUpdate();

  useEffect(() => {
    if (activeKey && currentItem) {
      scrollRef.current?.scrollTo({ top: 0 });
    }
  }, [activeKey, currentItem]);

  useEffect(() => {
    if (itemSize && currentItem) {
      scrollRef.current?.addEventListener('scroll', handleScroll);
    }
    return () => {
      scrollRef.current?.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemSize, activeKey, currentItem]);

  useEffect(() => {
    if (currentItem && currentItem?.key) {
      setCurrentEntryCharts([]);
      setCurrentExitCharts([]);
      setCurrentEntryChartIndex(0);
      setCurrentExitChartIndex(0);
      indexEntryNumbersRef.current = [0];
      indexExitNumbersRef.current = [0];
      updateFn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem]);

  useEffect(() => {
    if (currentItem && currentItem?.key && currentItem.type === EnergyMediumTreeFormatType.PROCESS) {
      const entryProcess = currentItem.cardData?.filter(item => item.type === EnergyMediumTreeFormatType.ENTRY);
      if (
        activeKey === ENTRY &&
        !(currentEntryCharts ?? []).map(i => i.key).includes(currentEntryChartIndex) &&
        entryProcess &&
        entryProcess.length > 0
      ) {
        setCurrentEntryCharts(list => {
          let arr = [...list];
          arr.push({
            key: currentEntryChartIndex,
            chartData: undefined,
            loading: true,
          });
          return arr;
        });
        getMediumIndicatorProcessPointData({
          processId: currentItem.processId,
          timeResolution: currentItem.timeResolution,
          from: currentItem.from!,
          to: currentItem.to!,
          processEntryExitType: entryProcess[currentEntryChartIndex].type,
          mediumId: entryProcess[currentEntryChartIndex].energyMediumId,
          indicatorId: entryProcess[currentEntryChartIndex].indicatorId,
        }).then(res => {
          setCurrentEntryCharts(list => {
            let arr = [...list];
            const findIndex = arr.findIndex(i => i.key === currentEntryChartIndex);
            const find = arr.find(i => i.key === currentEntryChartIndex);
            arr.splice(findIndex, 1, {
              ...find,
              chartData: {
                ...res,
                energyMediumName: entryProcess[currentEntryChartIndex].energyMediumName,
              },
              loading: false,
            });
            return arr;
          });
        });
      } else {
        const exitProcess = currentItem.cardData?.filter(item => item.type === EnergyMediumTreeFormatType.EXIT);
        if (
          activeKey === EXIT &&
          !(currentExitCharts ?? []).map(i => i.key).includes(currentExitChartIndex) &&
          exitProcess &&
          exitProcess.length > 0
        ) {
          setCurrentExitCharts(list => {
            let arr = [...list];
            arr.push({
              key: currentExitChartIndex,
              chartData: undefined,
              loading: true,
            });
            return arr;
          });
          getMediumIndicatorProcessPointData({
            processId: currentItem.processId,
            timeResolution: currentItem.timeResolution,
            from: currentItem.from!,
            to: currentItem.to!,
            processEntryExitType: exitProcess[currentExitChartIndex].type,
            mediumId: exitProcess[currentExitChartIndex].energyMediumId,
            indicatorId: exitProcess[currentExitChartIndex].indicatorId,
          }).then(res => {
            setCurrentExitCharts(list => {
              let arr = [...list];
              const findIndex = arr.findIndex(i => i.key === currentExitChartIndex);
              const find = arr.find(i => i.key === currentExitChartIndex);
              arr.splice(findIndex, 1, {
                ...find,
                chartData: {
                  ...res,
                  energyMediumName: exitProcess[currentExitChartIndex].energyMediumName,
                },
                loading: false,
              });
              return arr;
            });
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEntryChartIndex, currentExitChartIndex, activeKey, updateState]);

  const handleScroll = () => {
    const scrollTop = scrollRef.current?.scrollTop;
    if (activeKey === ENTRY) {
      const entryProcess = currentItem!.cardData?.filter(item => item.type === EnergyMediumTreeFormatType.ENTRY);
      if (entryProcess && entryProcess.length) {
        for (let i = 0; i < entryProcess?.length; i++) {
          if (
            scrollTop! + itemSize!.height > itemSize!.height * i &&
            scrollTop! + itemSize!.height <= itemSize!.height * (i + 1)
          ) {
            if (!indexEntryNumbersRef.current.includes(i)) {
              indexEntryNumbersRef.current = Array.from(new Set([...indexEntryNumbersRef.current, i]));
              setCurrentEntryChartIndex(i);
            }
          }
        }
      }
    } else {
      const entryProcess = currentItem!.cardData?.filter(item => item.type === EnergyMediumTreeFormatType.EXIT);
      if (entryProcess && entryProcess.length) {
        for (let i = 0; i < entryProcess?.length; i++) {
          if (
            scrollTop! + itemSize!.height > itemSize!.height * i &&
            scrollTop! + itemSize!.height <= itemSize!.height * (i + 1)
          ) {
            if (!indexExitNumbersRef.current.includes(i)) {
              indexExitNumbersRef.current = Array.from(new Set([...indexExitNumbersRef.current, i]));
              setCurrentExitChartIndex(i);
            }
          }
        }
      }
    }
  };

  const tabData = [
    {
      key: EnergyMediumTreeFormatType.ENTRY,
      label: (
        <>输入（{currentItem?.cardData?.filter(item => item.type === EnergyMediumTreeFormatType.ENTRY).length}）</>
      ),
    },
    {
      key: EnergyMediumTreeFormatType.EXIT,
      label: <>输出（{currentItem?.cardData?.filter(item => item.type === EnergyMediumTreeFormatType.EXIT).length}）</>,
    },
  ];

  const items: TabsProps['items'] = useMemo(() => {
    return tabData?.map(item => ({
      key: item.key.toString(),
      label: item.label,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabData, activeKey]);

  return (
    <div className={styles.ProcessChartsTabStyle}>
      <Tabs onChange={onTabChange} activeKey={activeKey} items={items} />
      {activeKey && (
        <div
          ref={d => {
            if (d) {
              scrollRef.current = d;
            }
          }}
          style={{ height: 'calc(100vh - 470px)', overflowY: 'auto' }}
        >
          {currentItem?.cardData?.filter(item => item.type === (Number(activeKey) as EnergyMediumTreeFormatType))
            .length === 0 ? (
            <Empty description={'暂无数据'} className={styles.empty} />
          ) : (
            currentItem?.cardData
              ?.filter(item => item.type === (Number(activeKey) as EnergyMediumTreeFormatType))
              .map((item, index) => (
                <Card key={item.energyMediumId!.toString() + item.indicatorId!.toString()} className={styles.CardStyle}>
                  <div
                    ref={d => {
                      if (d) {
                        itemRef.current = d;
                      }
                    }}
                    className={styles.chartContainer}
                  >
                    <Chart
                      loading={
                        activeKey === ENTRY
                          ? currentEntryCharts.find(i => i.key === index)?.loading
                          : currentExitCharts.find(i => i.key === index)?.loading
                      }
                      chartData={
                        activeKey === ENTRY
                          ? currentEntryCharts.find(i => i.key === index)?.chartData
                          : currentExitCharts.find(i => i.key === index)?.chartData
                      }
                      option={Object.assign(
                        {},
                        currentItem?.getChartOption?.({
                          chartData:
                            activeKey === ENTRY
                              ? currentEntryCharts.find(i => i.key === index)?.chartData
                              : currentExitCharts.find(i => i.key === index)?.chartData,
                          query,
                          unit: configValue?.find(i => i.energyMediumIndicatorId === item.indicatorId)
                            ?.displayPhysicalUnitGeneralName,
                        })
                      )}
                    />
                  </div>
                </Card>
              ))
          )}
        </div>
      )}
    </div>
  );
};

export default ProcessChartsTab;
