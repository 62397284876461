import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { Key } from 'react';
import { DeviceType, Operator } from '@/shared/types';
import { Device } from './device';
import { PageRequest, PageResponse } from './page';

export interface ThermalRequest {
  // 用汽账户户号
  number: string;
  // 区域code
  regionCode: string;
  // 全域能源拓扑id
  uetId: number;
  // 管线id
  linePipeId: number;
  // 组织mcid
  customerMcids: Array<string>;
}

export function createThermal(request: ThermalRequest) {
  return fetch<void>(`/api/thermal/account/add`, {
    method: 'POST',
    body: JSON.stringify(request),
  });
}

export function updateThermal(id: number, request: ThermalRequest) {
  return fetch<void>(`/api/thermal/account/update/${id}`, {
    method: 'PUT',
    body: JSON.stringify(request),
  });
}

export interface ThermalResponse extends ThermalRequest {
  id: number;
  createByUserId: string;
  updateByUserId: number;
  createByUsername: string;
  updateSource: Operator;
  createTime: string;
  updateTime: string;
  uetName: string;
  linePipeName: string;
  customerMcidNames: string[];
}

export interface ThermalPageRequest extends PageRequest {
  number?: string;
  regionCode?: string;
  username?: string;
}

export function getThermalPage(request: ThermalPageRequest) {
  return fetch<PageResponse<ThermalResponse>>(`/api/thermal/account/page?${qs.stringify(request, { indices: false })}`);
}

export interface UnBoundDevicePageRequest extends PageRequest {
  deviceTypeId?: string;
  thermalAccountId: Key;
  codeOrName?: string;
  deviceTag?: string;
}

export function getThermalUnBoundDevicePage(request: UnBoundDevicePageRequest) {
  return fetch<PageResponse<Device>>(
    `/api/thermal/account/unbound-device/page?${qs.stringify(request, { indices: false })}`
  );
}

export function getThermalInfo(id: Key) {
  return fetch<ThermalResponse>(`/api/thermal/account/info/${id}`);
}

export function thermalBindDevices(id: Key, request: Array<number>) {
  return fetch<void>(`/api/thermal/account/device/bind/${id}`, {
    method: 'POST',
    body: JSON.stringify({
      deviceIds: request,
    }),
  });
}

export function thermalUnBindDevices(id: Key, deviceId: number) {
  return fetch<void>(`/api/thermal/account/device/unbind/${id}`, {
    method: 'POST',
    body: JSON.stringify({
      deviceId,
    }),
  });
}

export interface ThermalBoundDeviceRequest extends PageRequest {
  deviceCode?: string;
  deviceName?: string;
}

export function getThermalBoundDevicePage(id: Key, request: ThermalBoundDeviceRequest) {
  return fetch<PageResponse<Device>>(
    `/api/thermal/account/bound-device/page?${qs.stringify({ ...request, thermalAccountId: id }, { indices: false })}`
  );
}

export function getThermalList() {
  return fetch<Array<ThermalResponse>>(`/api/thermal/account/list`);
}

// 获取一级类目为积算仪的二级类目list
export function getThermalDeviceTypeList() {
  return fetch<DeviceType[]>(`/api/thermal/account/deviceType/integratorList`);
}
