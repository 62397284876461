import { CheckOutlined, CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Select, Switch, Tooltip } from '@maxtropy/components';
import { MediumIndicatorDashboardDashboardConfigPostRequest } from '@maxtropy/device-customer-apis';

import React, { FC, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import ShowInput from '@/shared/components/ShowInput';
import {
  apiV2MediumIndicatorDashboardGetAllProcessPost,
  V2MediumIndicatorDashboardGetAllProcessPostResponse,
  V2MediumIndicatorDashboardGetDashboardConfigPostResponse,
} from '@maxtropy/device-customer-apis-v2';

export enum BtnConfigAlarmSwitchType {
  ENABLE = 0,
  UNABLE = 1,
}

interface IDashBoardModalConfig {
  handleEditBtn: (v: MediumIndicatorDashboardDashboardConfigPostRequest | undefined) => void;
  row?: V2MediumIndicatorDashboardGetDashboardConfigPostResponse;
  cref?: React.MutableRefObject<IFieldForm | undefined>;
  modalOpen: boolean;
}

export interface IFieldForm {
  reset: () => void;
}

const DashBoardModalConfig: FC<IDashBoardModalConfig> = props => {
  const { row, handleEditBtn, modalOpen } = props;
  const [editform] = Form.useForm();
  const [processList, setProcessList] = useState<V2MediumIndicatorDashboardGetAllProcessPostResponse['list']>();

  useEffect(() => {
    if (row && row?.energyMediumTopologyId && modalOpen) {
      apiV2MediumIndicatorDashboardGetAllProcessPost({ id: row?.energyMediumTopologyId.toString() }).then(res =>
        setProcessList(res.list)
      );
    }
  }, [row, modalOpen]);

  useImperativeHandle(props.cref, () => ({
    // 重载数据
    reset: () => {
      editform.resetFields();
    },
  }));

  useEffect(() => {
    if (row && row?.energyMediumTopologyId && modalOpen) {
      editform.setFieldsValue({
        energyMediumTopologyName: row.energyMediumTopologyName,
        alarmSwitch: row.alarmSwitch === BtnConfigAlarmSwitchType.ENABLE ? true : false,
        usageCountSwitch: row.usageCountSwitch === BtnConfigAlarmSwitchType.ENABLE ? true : false,
        processIds: row.processDtos?.map(i => i.id),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row, modalOpen]);

  const onFinish = (val: Omit<MediumIndicatorDashboardDashboardConfigPostRequest, 'energyMediumTopologyId'>) => {
    handleEditBtn({
      ...val,
      alarmSwitch: val.alarmSwitch ? BtnConfigAlarmSwitchType.ENABLE : BtnConfigAlarmSwitchType.UNABLE,
      usageCountSwitch: val.usageCountSwitch ? BtnConfigAlarmSwitchType.ENABLE : BtnConfigAlarmSwitchType.UNABLE,
      energyMediumTopologyId: row?.energyMediumTopologyId!,
    });
  };

  const processOptions = useMemo(() => {
    if (processList?.length !== 0) {
      return processList?.map(item => ({ label: item.name, value: item.id }));
    }
  }, [processList]);

  return (
    <Modal
      size="normal"
      destroyOnClose
      open={modalOpen}
      onCancel={() => handleEditBtn(undefined)}
      footer={[
        <Button
          key="back"
          style={{ marginRight: 12 }}
          onClick={() => {
            handleEditBtn(undefined);
            editform.resetFields();
          }}
        >
          取消
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            editform.submit();
          }}
        >
          确认
        </Button>,
      ]}
      title={`拓扑看板配置`}
    >
      <Form
        style={{ marginTop: 32, padding: '0 32px' }}
        form={editform}
        labelCol={{ flex: '110px' }}
        labelAlign="right"
        onFinish={(val: any) => onFinish(val)}
        initialValues={{
          alarmSwitch: true,
          usageCountSwitch: false,
        }}
      >
        <Form.Item name="energyMediumTopologyName" label="当前拓扑">
          <ShowInput />
        </Form.Item>
        <Form.Item valuePropName="checked" name="alarmSwitch" label="设备报警">
          <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
        </Form.Item>
        <Form.Item valuePropName="checked" name="usageCountSwitch" label="用量统计">
          <Switch disabled={!row?.right} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
        </Form.Item>
        <Form.Item noStyle dependencies={['usageCountSwitch']}>
          {({ getFieldValue }) =>
            getFieldValue('usageCountSwitch') === true && (
              <div style={{ display: 'flex' }}>
                <Form.Item name="processIds" label="拓扑输入过程">
                  <Select
                    disabled={!row?.right}
                    style={{ width: 340 }}
                    placeholder="请选择"
                    options={processOptions}
                    showSearch
                    optionFilterProp="label"
                    mode="multiple"
                    allowClear
                  />
                </Form.Item>

                <Tooltip title={<span>选择多个过程输入加总用以计算整个拓扑的输入量</span>}>
                  <div style={{ marginTop: 5 }}>
                    <InfoCircleOutlined style={{ color: 'orange' }} />
                  </div>
                </Tooltip>
              </div>
            )
          }
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DashBoardModalConfig;
