import { InfoCircleOutlined } from '@ant-design/icons';
import { DatePicker, Form, Input, Modal, Radio, Select, TreeSelect } from '@maxtropy/components';
import { TreeSelectProps, Space } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { EnergyUnitTreeItem, getEnergyUnitTree } from '@/api/energyUnitMapping';
import dayjs, { Dayjs } from 'dayjs';
import ShowInput from '@/shared/components/ShowInput';
import {
  V2EnergyAnalysisListPostResponse,
  V2EnergyConsumptionOverviewMediumDataPropertyPostResponse,
  V2EnergyOptimizationProjectCreatePostRequest,
  apiV2EnergyAnalysisListByOuPost,
  apiV2EnergyConsumptionOverviewMediumDataPropertyPost,
  apiV2EnergyOptimizationProjectCreatePost,
  apiV2EnergyOptimizationProjectUpdatePost,
} from '@maxtropy/device-customer-apis-v2';
import { EnergyAnalysisOptimizePageRes } from '../..';

export type AnalysisUnit = Exclude<V2EnergyAnalysisListPostResponse['list'], undefined>[number];
interface Props {
  isEdit: boolean;
  setIsEdit: (v: boolean) => void;
  modalOpen: boolean;
  setModalOpen: (v: boolean) => void;
  currentDetail?: EnergyAnalysisOptimizePageRes;
  setCurrentDetail: (v: EnergyAnalysisOptimizePageRes | undefined) => void;
  updateFn: () => void;
}

const formLayout = {
  labelCol: { span: 5, offset: 1 },
  wrapperCol: { span: 16 },
};

function formatToNodeData(data: EnergyUnitTreeItem[]): TreeSelectProps['treeData'] {
  return data.map(i => {
    return {
      title: i.name,
      value: i.energyUnitId,
      children: i.children && i.children.length > 0 ? formatToNodeData(i.children) : [],
      disabled: i.disabled,
    };
  });
}

const { RangePicker } = DatePicker;
type RangeValue = [Dayjs | null, Dayjs | null] | null;

export type MediumDataPropertyItem = Exclude<
  V2EnergyConsumptionOverviewMediumDataPropertyPostResponse['list'],
  undefined
>[number];

interface FormProps extends Omit<V2EnergyOptimizationProjectCreatePostRequest, 'startTime' | 'endTime'> {
  time: [Dayjs, Dayjs];
  beforeTime: [Dayjs, Dayjs];
}

const CreateAnalysis: React.FC<Props> = ({
  setCurrentDetail,
  currentDetail,
  isEdit = false,
  setIsEdit,
  modalOpen = false,
  setModalOpen,
  updateFn,
}) => {
  const [modalForm] = Form.useForm();

  const [unitGroup, setUnitGroup] = useState<AnalysisUnit[]>([]); // 用能分析组
  const [treeNodeData, setTreeNodeData] = useState<TreeSelectProps['treeData']>([]);
  const energyUnitGroupId = Form.useWatch('energyUnitGroupId', modalForm);
  const [energyList, setEnergyList] = useState<MediumDataPropertyItem[]>([]);
  const energyUnitIds = Form.useWatch('energyUnitIds', modalForm);
  const time = Form.useWatch('time', modalForm);
  const [hasOu, setHasOu] = useState<boolean>(true);

  useEffect(() => {
    // 获取用能分析组
    apiV2EnergyAnalysisListByOuPost({}).then(res => {
      setUnitGroup((res.list ?? []) as AnalysisUnit[]);
    });
  }, []);

  useEffect(() => {
    if (currentDetail && isEdit) {
      modalForm.setFieldsValue({
        name: currentDetail.name,
        time: [dayjs(currentDetail.optimizationStartTime), dayjs(currentDetail.optimizationEndTime)],
        beforeTime: [dayjs(currentDetail.initialBaselineStartTime), dayjs(currentDetail.initialBaselineEndTime)],
        type: currentDetail.type,
        energyUnitGroupId: currentDetail.energyUnitGroupId,
        energyUnitIds: (currentDetail.energyUnits ?? []).map(i => ({ label: i.name, value: i.id })),
        energyMediumIds: (currentDetail.energyMediums ?? []).map(i => i.id),
        remark: currentDetail?.remark,
      });
      if (unitGroup.length > 0) {
        unitGroup.map(i => i.id).includes(currentDetail.energyUnitGroupId as number) ? setHasOu(true) : setHasOu(false);
      }
    }
  }, [isEdit, currentDetail, modalForm, unitGroup]);

  // 获取用能分析组下的单元树
  useEffect(() => {
    if (energyUnitGroupId) {
      getEnergyUnitTree(energyUnitGroupId).then(res => {
        const tree = formatToNodeData(res);
        setTreeNodeData(tree);
      });
    }
  }, [energyUnitGroupId]);

  // 获取单元下的用能项
  useEffect(() => {
    if (energyUnitIds && energyUnitIds.length > 0) {
      apiV2EnergyConsumptionOverviewMediumDataPropertyPost({ ids: energyUnitIds.map((i: any) => i.value) }).then(
        res => {
          setEnergyList((res.list ?? []) as MediumDataPropertyItem[]);
        }
      );
    }
  }, [energyUnitIds]);

  const unitOptions = useMemo(() => {
    return (unitGroup ?? []).map(item => ({ label: item.name, value: item.id }));
  }, [unitGroup]);

  const energyOptions = useMemo(() => {
    return (energyList ?? []).map(item => ({ label: item.energyName, value: item.energyNameId }));
  }, [energyList]);

  const onFinish = (v: FormProps) => {
    const body = {
      ...v,
      optimizationStartTime: v.time[0].format('YYYY-MM-DD'),
      optimizationEndTime: v.time[1].format('YYYY-MM-DD'),
      initialBaselineStartTime: v.beforeTime[0].format('YYYY-MM-DD'),
      initialBaselineEndTime: v.beforeTime[1].format('YYYY-MM-DD'),
      energyUnitIds: (v.energyUnitIds ?? []).map((i: any) => i.value),
    };
    if (isEdit) {
      // 编辑
      apiV2EnergyOptimizationProjectUpdatePost({
        id: currentDetail?.id,
        ...body,
      }).then(() => {
        setModalOpen(false);
        setIsEdit(false);
        setCurrentDetail(undefined);
        updateFn();
      });
    } else {
      // 新建
      apiV2EnergyOptimizationProjectCreatePost(body).then(() => {
        setModalOpen(false);
        updateFn();
      });
    }
  };

  const [dates, setDates] = useState<RangeValue>(null);
  const [value, setValue] = useState<RangeValue>(null);

  const onOpenChange = (open: boolean) => {
    if (open) {
      modalForm.setFieldsValue({
        beforeTime: undefined,
      });
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  return (
    <Modal
      size="large"
      title={`${isEdit ? '编辑' : '新建'}优化项目`}
      open={modalOpen}
      contentClassName="modal-form-content"
      onOk={() => {
        modalForm.submit();
      }}
      onCancel={() => {
        setModalOpen(false);
        setIsEdit(false);
        setCurrentDetail(undefined);
      }}
    >
      <Form
        form={modalForm}
        {...formLayout}
        onFinish={(v: FormProps) => onFinish(v)}
        initialValues={{
          type: 1,
        }}
      >
        <Form.Item
          label="优化项目"
          name="name"
          rules={[
            { required: true, message: '请输入优化项目' },
            { max: 15, message: '优化项目最多可输入15个字符' },
          ]}
        >
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item
          label="优化时间"
          name="time"
          rules={[{ required: true, message: '请选择优化时间' }]}
          extra={
            <Space>
              <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
              <span>优化分析将以开始时间前和结束时间后进行对比</span>
            </Space>
          }
        >
          <RangePicker
            style={{
              width: '100%',
            }}
            onChange={() => {
              modalForm.setFieldsValue({
                beforeTime: undefined,
              });
              setDates([null, null]);
              setValue([null, null]);
            }}
            allowClear={false}
          />
        </Form.Item>
        <Form.Item
          label="优化前基准时间"
          name="beforeTime"
          rules={[{ required: true, message: '请选择优化前基准时间' }]}
          extra={
            <Space>
              <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
              <span>优化前能耗基准将以该时段日均基准计算</span>
            </Space>
          }
        >
          <RangePicker
            style={{
              width: '100%',
            }}
            disabledDate={date => {
              // 不能选time的结束时间之后的日期 前后时间间隔不能超过90天
              if (dates && time && time[1]) {
                const tooLate = dates[0] && date.diff(dates[0], 'days') >= 90;
                const tooEarly = dates[1] && dates[1].diff(date, 'days') >= 90;
                return date.isSameOrAfter(time[0], 'day') || !!tooLate || !!tooEarly;
              }
              return false;
            }}
            value={dates || value}
            onCalendarChange={val => setDates(val)}
            onChange={val => setValue(val)}
            onOpenChange={onOpenChange}
            allowClear={false}
          />
        </Form.Item>
        <Form.Item label="选择类型" name="type" rules={[{ required: true, message: '请选择类型' }]}>
          <Radio.Group>
            <Radio value={1}>单元用能分析</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="用能分析组"
          name="energyUnitGroupId"
          rules={[{ required: true, message: '请选择用能分析组' }]}
        >
          {isEdit && !hasOu ? (
            <ShowInput value={currentDetail?.energyUnitGroupName} />
          ) : (
            <Select
              options={unitOptions}
              placeholder="请选择"
              onChange={() => {
                modalForm.setFieldsValue({ energyUnitIds: undefined, energyMediumIds: undefined });
                setTreeNodeData([]);
                setEnergyList([]);
              }}
            />
          )}
        </Form.Item>
        <Form.Item
          label="收益计量单元"
          name="energyUnitIds"
          rules={[{ required: true, message: '请选择收益计量单元' }]}
          extra={
            <Space>
              <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
              <span>单独计算单元用能成本加总计算收益</span>
            </Space>
          }
        >
          <TreeSelect
            treeData={treeNodeData}
            treeCheckStrictly
            treeCheckable
            showCheckedStrategy="SHOW_ALL"
            treeDefaultExpandAll
            placeholder="请选择"
            style={{
              width: '100%',
            }}
            onChange={() => {
              modalForm.setFieldsValue({ energyMediumIds: undefined });
              setEnergyList([]);
            }}
          />
        </Form.Item>
        <Form.Item label="优化用能项" name="energyMediumIds" rules={[{ required: true, message: '请选择优化用能项' }]}>
          <Select mode="multiple" options={energyOptions} placeholder="请选择" />
        </Form.Item>
        <Form.Item label="备注" name="remark" rules={[{ max: 100, message: '最多可输入100个字符' }]}>
          <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateAnalysis;
