import { V2StationSignboardNodeDetailIndicatorChartValuePostResponse } from '@maxtropy/device-customer-apis-v2';
import { ChartsDataReq } from '.';
import { ChartItem } from '@/pages/HVACDashBoard/type';

export interface DateBtn {
  aggrby: DatePickerType;
}

export enum DatePickerType {
  MINUTE_1 = '1M',
  MINUTE_15 = '15M',
  HOUR = 'H',
  DAY = 'D',
  MONTH = 'M',
}

export const datePickerTypeDisplay = {
  [DatePickerType.MINUTE_1]: '1分钟',
  [DatePickerType.MINUTE_15]: '15分钟',
  [DatePickerType.HOUR]: '按小时',
  [DatePickerType.DAY]: '按日',
  [DatePickerType.MONTH]: '按月',
};

export interface ParamsOption {
  chartData?: ChartItem[];
  query?: ChartsDataReq;
  stateData?: any;
}

// 单设备指标枚举
export enum SingleIndicatorType {
  COP = 1, // COP
  PowerConsumption1 = 2, // 用电量
  CoolingCapacity = 3, // 制冷量
  WaterTemperature = 4, // 水温
  InstantaneousFlow1 = 5, // 瞬时流量
  Pressure1 = 6, // 压力
  PowerConsumption2 = 7, // 用电量
  FanFrequency = 8, // 风机频率
  PowerConsumption3 = 9, // 用电量
  InstantaneousFlow2 = 10, // 瞬时流量
  Frequency = 11, // 频率
  Temperature1 = 12, // 温度
  InstantaneousFlow3 = 13, // 瞬时流量
  LiquidLevel = 14, // 液位
  Temperature2 = 15, // 温度
  Humidity = 16, //湿度
  Temperature3 = 17, // 温度
  Pressure2 = 18, // 压力
  InstantaneousFlow4 = 19, // 瞬时流量
  CumulativeTraffic = 20, // 累计流量
  CurrentLoad = 21, // 电流负载率
}

export interface SingleCurrentItemType {
  id?: SingleIndicatorType;
  btnName?: string;
  unit?: string;
  name?: string; // tab名称
  dateBtn: DateBtn[];
  defaultSelectBtn?: DateBtn;
  getChartOption?: (value: ParamsOption) => any;
}
