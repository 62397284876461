import { useLocation, useNavigate } from 'react-router-dom';
import { AdjustmentMeterRecordsRes } from '@/api/electricity';
import { useEffect, useState } from 'react';
import { EleSearchParams } from '..';
import {
  Button,
  SubContent,
  Key,
  Paging,
  Wrapper,
  useBreadcrumbRoutes,
  Empty,
  useSearchParams,
  DatePicker,
  Form,
  Tabs,
  FormTitle,
  BaseInfoContent,
} from '@maxtropy/components';
import { getElectricityPurPage } from '@/api/elePurRecordQuery';
import { Divider, Space, Spin } from 'antd';
import ShowInput from '@/shared/components/ShowInput';
import dayjs, { Dayjs } from 'dayjs';
import styles from './index.module.scss';
import { getDeviceBySaleClientId } from '@/api/sale';
import type { TabsProps } from 'antd';
import ReportCard from './components/RecordCard';

const { RangePicker } = DatePicker;
type RangeValue = [Dayjs | null, Dayjs | null] | null;

const ElectricityPurchaseRecordList: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [filterForm] = Form.useForm();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const url_saleClientId = urlSearchParams.get('saleClientId') || undefined;
  const url_saleClientCode = urlSearchParams.get('saleClientCode') || undefined;
  const url_saleClientName = urlSearchParams.get('saleClientName') || undefined;
  const pagingInfo = useSearchParams<EleSearchParams>(50);
  const { searchParams, setTotalCount, finish } = pagingInfo;
  const [loading, setLoading] = useState<boolean>(false);
  const [electricityPurList, setElectricityPurList] = useState<AdjustmentMeterRecordsRes[]>([]);
  const [deviceList, setDeviceList] = useState<Array<{ id: Key; name: string }>>([]);
  const [deviceId, setDeviceId] = useState<Key>();
  const navigate = useNavigate();

  const time = Form.useWatch('time', filterForm);

  const [dates, setDates] = useState<RangeValue>(null);
  const [value, setValue] = useState<RangeValue>(null);
  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'year') >= 1;
    const tooEarly = dates[1] && dates[1].diff(current, 'year') >= 1;
    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      filterForm.setFieldsValue({
        time: undefined,
      });
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  //获取设备列表
  useEffect(() => {
    if (url_saleClientId) {
      getDeviceBySaleClientId(url_saleClientId).then(res => {
        if (res.length > 0) {
          setDeviceList(res);
          setDeviceId(res[0].id);
        }
      });
    }
  }, [url_saleClientId]);

  useEffect(() => {
    setLoading(true);
    if (deviceId && url_saleClientCode) {
      getElectricityPurPage({
        ...searchParams,
        deviceId,
        saleClientCode: url_saleClientCode,
        meterReadingStartTs: time ? dayjs(time[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss') : undefined,
        meterReadingEndTs: time ? dayjs(time[1]).startOf('day').format('YYYY-MM-DD HH:mm:ss') : undefined,
        page: searchParams.pageOffset,
        size: searchParams.pageSize,
      })
        .then(res => {
          setElectricityPurList(res.list);
          setTotalCount(res.total);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, setTotalCount, deviceId, url_saleClientCode]);

  const Cards = (
    <>
      {electricityPurList && electricityPurList.length > 0 ? (
        <>
          {electricityPurList.map(item => {
            return <ReportCard data={item} />;
          })}
          <div style={{ position: 'sticky', bottom: 0, right: 0 }}>
            <Paging pagingInfo={pagingInfo} className={styles.pagingStyle} />
          </div>
        </>
      ) : null}
    </>
  );

  const tabItems: TabsProps['items'] = deviceList.map((i, index) => {
    return {
      key: String(i.id),
      label: `计量${index + 1}`,
      // label: i.name,
      children: (
        <Spin spinning={loading}>
          {electricityPurList && electricityPurList.length > 0 ? (
            <>
              <div style={{ display: 'flex' }}>
                <p>
                  设备名称：
                  {electricityPurList && electricityPurList.length > 0 ? electricityPurList[0].deviceName : '--'}
                </p>
                <p style={{ marginLeft: 52 }}>
                  倍率：
                  {electricityPurList && electricityPurList.length > 0 ? electricityPurList[0].magnification : '--'}
                </p>
              </div>
              {Cards}
            </>
          ) : (
            <div>
              <Empty />
            </div>
          )}
        </Spin>
      ),
    };
  });

  const onFinish = () => {
    finish({
      saleClientCode: url_saleClientCode,
    });
  };

  const onReset = () => {
    filterForm.resetFields();
    finish({ meterReadingStartTs: undefined, meterReadingEndTs: undefined });
  };

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={styles.wrapper}>
      <FormTitle
        title="用电记录查询"
        extraContent={
          <Button
            onClick={() => {
              navigate('/assetsOperation/elePurEnergyQuery/elePurEleRecordQuery');
            }}
          >
            返回
          </Button>
        }
      />
      <Form>
        <SubContent className="mb-8">
          <BaseInfoContent colNum={3} layout="horizontal">
            <Form.Item label="客户编号">
              <ShowInput value={url_saleClientCode} />
            </Form.Item>
            <Form.Item label="客户名称">
              <ShowInput value={url_saleClientName} />
            </Form.Item>
          </BaseInfoContent>
        </SubContent>
      </Form>
      <Divider />
      <Form
        form={filterForm}
        onFinish={onFinish}
        isOrigin={true}
        initialValues={{
          time: [dayjs().subtract(30, 'day'), dayjs()],
        }}
      >
        <SubContent>
          <Space size={8}>
            <Form.Item label="选择日期" name="time" className={styles.time}>
              <RangePicker
                style={{ width: 380 }}
                value={dates || value}
                disabledDate={disabledDate}
                onCalendarChange={val => setDates(val)}
                onChange={val => setValue(val)}
                onOpenChange={onOpenChange}
                allowClear={false}
              />
            </Form.Item>
            <Form.Item>
              <Space size={8}>
                <Button type="primary" htmlType="submit">
                  查询
                </Button>

                <Button onClick={onReset}>重置</Button>
              </Space>
            </Form.Item>
          </Space>
        </SubContent>
      </Form>
      {tabItems.length > 0 ? (
        <Tabs
          className={styles.tabsStyle}
          style={{ marginLeft: 40 }}
          defaultActiveKey="1"
          items={tabItems}
          tabBarGutter={85}
          onChange={v => {
            setDeviceId(v);
          }}
        />
      ) : (
        <Empty />
      )}
    </Wrapper>
  );
};

export default ElectricityPurchaseRecordList;
