import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { Key } from 'react';
import { ReportStrategyStatisticalCycleEnum, ReportStrategyStatisticalTypeEnum, StrategyStatus } from '@/shared/types';
import { PageRequest, PageResponse } from './page';
import { ReportStrategyTime, ReportStrategyRes } from '../pages/ReportStrategy/Create/ExecuteTimePicker';
import { ReportStrategyExecuteCycle, ReportStrategyValues } from '../pages/ReportStrategy/Create';

export interface ReportStrategyDataPropertyListRequest {
  deviceId: number;
  dataPropertyIdList: number[];
}

export interface CreateReportStrategyRequest {
  name: string;
  ouId: number;
  measurementType: ReportStrategyStatisticalTypeEnum;
  statisticalResolution: ReportStrategyStatisticalCycleEnum;
  reportType?: ReportStrategyExecuteCycle;
  computeType?: ReportStrategyValues;
  executionTime?: ReportStrategyTime;
  remark?: string;
  dataPropertyLinkList: ReportStrategyDataPropertyListRequest[];
}

export interface UpdateReportStrategyRequest
  extends Omit<CreateReportStrategyRequest, 'statisticalType' | 'dataPropertyLinkList' | 'ouId'> {
  id: number;
  oldDataPropertyLinkList: ReportStrategyDataPropertyListRequest[];
  newDataPropertyLinkList: ReportStrategyDataPropertyListRequest[];
}

export interface DataProperty {
  id?: number;
  name?: string;
  physicalUnitId?: number;
  physicalUnitName?: string;
  key?: string;
  deviceId?: number;
}

export interface ReportStrategyDataPropertyList {
  deviceId: number;
  deviceCode: string;
  deviceName: string;
  dataPropertyList?: DataProperty[];
}

export interface ReportStrategy
  extends Omit<CreateReportStrategyRequest, 'dataPropertyLinkList' | 'startTime' | 'endTime'> {
  beginTime: string;
  endTime: string;
  id: number;
  code?: string;
  dataPropertyLinkList: ReportStrategyDataPropertyList[];
  ouName: string;
  statisticalBeginTime?: ReportStrategyRes;
  statisticalEndTime?: ReportStrategyRes;
}

export function getReportStrategy(id: Key): Promise<ReportStrategy> {
  return fetch(`/api/report-strategy/detail?id=${id}`);
}

export interface ReportStrategyDevicePageRequest extends PageRequest {
  nameOrCode?: string;
  ouId: number;
  measurementType: ReportStrategyStatisticalTypeEnum;
  deviceTypeIds?: Array<number> | undefined;
  tagName?: string;
}

export interface ReportStrategyDevicePageResponse {
  id?: number;
  code?: string;
  name?: string;
  tagList?: string[];
  deviceType?: string;
  customerName?: string;
  ouName?: string;
  dataPropertyList?: DataProperty[];
  checkedPropertyList?: DataProperty[];
}

interface ReportStrategyPageReq extends PageRequest {
  nameOrCode?: string;
  status?: StrategyStatus;
  ouId?: number;
  single: boolean;
}

export interface ReportStrategyPageProps {
  reportId?: number;
  id: number;
  name: string;
  ouId: number;
  code: string;
  measurementType: ReportStrategyStatisticalTypeEnum;
  status: StrategyStatus;
  updateTime: string;
  updateUserName: string;
  remark: string;
  ouName: string;
  statisticalBeginTime: ReportStrategyTime;
  statisticalEndTime: ReportStrategyTime;
  statisticalResolution: number;
}

export function getReportStrategyPage(query: ReportStrategyPageReq) {
  return fetch<PageResponse<ReportStrategyPageProps>>(
    `/api/report-strategy/page?${qs.stringify(query, { indices: false })}`
  );
}

export function deleteReportStrategy(id: number) {
  return fetch(`/api/report-strategy/delete?id=${id}`, {
    method: 'DELETE',
  });
}

export function executingReportStrategy(id: number) {
  return fetch(`/api/report-strategy/execute?id=${id}`);
}

export function getReportStrategyDeviceDataProperty(
  deviceId: Key,
  statisticalType: ReportStrategyStatisticalTypeEnum
): Promise<{ deviceDataPropertyLinkList: DataProperty[] }> {
  return fetch(`/api/report-strategy/device-data-property?deviceId=${deviceId}&measurementType=${statisticalType}`);
}

export const changeReportStrategyStatus = (id: number, used: boolean) =>
  fetch<boolean>(`/api/report-strategy/status?id=${id}&used=${used}`, { method: 'PUT' });

export const getReportStrategyList = (ouIds?: number[]) => {
  return fetch<ReportStrategy[]>(`/api/report-strategy/list?${qs.stringify({ ouIds }, { indices: false })}`);
};
