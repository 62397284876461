import { FC, useEffect, useState } from 'react';
import {
  Wrapper,
  Form,
  useUpdate,
  useBreadcrumbRoutes,
  Button,
  BaseInfoContent,
  FormTitle,
} from '@maxtropy/components';
import styles from './index.module.scss';
import { Col, Space } from 'antd';
import ShowInput from '@/shared/components/ShowInput';
import { Link, useParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { BalanceAlarmLogDetailResponse, getBalanceAlarmLogDetail } from '../../../../api/balanceAlarm';
import { BalanceAlarmalarmTypeType, BalanceAlarmalarmTypeTypeValue } from '../../../../api/balanceAlarmManagement';

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const routes = [{ name: '记录详情' }];

const BalanceAlarmRecordDetail: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [updateState] = useUpdate();

  const [data, setData] = useState<BalanceAlarmLogDetailResponse>();

  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    if (id) {
      getBalanceAlarmLogDetail(id).then(res => {
        setData(res);
      });
    }
  }, [id, updateState]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title="记录详情" style={{ marginBottom: 32 }}></FormTitle>
      <BaseInfoContent className={styles.contentWrap}>
        <Form.Item label="报警规则">
          <Space>
            <ShowInput value={data?.deleteFlag ? `${data?.ruleName}（报警规则已被删除）` : data?.ruleName} />
            <Button type="link">
              <Link to={`/operation/balanceAlarm/management/details/${data?.baseServerAlarmRuleId}`}>查看</Link>
            </Button>
          </Space>
        </Form.Item>
        <Form.Item label="规则编号">
          <ShowInput value={data?.deleteFlag ? `${data?.ruleCode}（报警规则已被删除）` : data?.ruleCode} />
        </Form.Item>
        <Form.Item label="报警时间">
          <ShowInput value={dayjs(data?.alarmTime).format(dateFormat)} />
        </Form.Item>
        <Form.Item label="恢复时间">
          <ShowInput value={data?.recoverTime ? dayjs(data?.recoverTime).format(dateFormat) : '未恢复'} />
        </Form.Item>
        <Form.Item label="客户账户编号">
          <Space>
            <ShowInput value={data?.saleClientAccountCode} />
            <Button type="link">
              <Link to={`/operation/client-related/client-account/detail/${data?.saleClientAccountId}`}>查看</Link>
            </Button>
          </Space>
        </Form.Item>
        <Form.Item label="客户账户名称">
          <ShowInput value={data?.attribute?.saleClientAccountName} />
        </Form.Item>
        <Form.Item label="报警逻辑">
          <ShowInput
            value={
              data?.alarmType === BalanceAlarmalarmTypeType.BalanceLow
                ? BalanceAlarmalarmTypeTypeValue[BalanceAlarmalarmTypeType.BalanceLow]
                : data?.alarmType === BalanceAlarmalarmTypeType.EstimateBalanceLow
                ? BalanceAlarmalarmTypeTypeValue[BalanceAlarmalarmTypeType.EstimateBalanceLow]
                : '--'
            }
          />
        </Form.Item>
        <Col span={8} className={styles.col}>
          <Form.Item
            label={
              data?.alarmType === BalanceAlarmalarmTypeType.BalanceLow
                ? '触发时实际余额'
                : data?.alarmType === BalanceAlarmalarmTypeType.EstimateBalanceLow
                ? '触发时暂估余额'
                : '触发余额'
            }
          >
            <ShowInput value={data?.attribute?.balance ?? '--' + '元'} />
          </Form.Item>
        </Col>
      </BaseInfoContent>
      <Space className="sticky-footer">
        <Button
          className={styles.button}
          onClick={() => {
            navigate(`/operation/balanceAlarm/record`);
          }}
        >
          返回
        </Button>
      </Space>
    </Wrapper>
  );
};

export default BalanceAlarmRecordDetail;
