import {
  EllipsisSpan,
  Paging,
  Table,
  useBreadcrumbRoutes,
  usePaging,
  useTenantPermissions,
  useUpdate,
  Wrapper,
  Button,
  useAsync,
  Form,
  DatePicker,
  Select,
  Checkbox,
  PopConfirm,
  message,
  CustomFilter,
} from '@maxtropy/components';
import { Divider, Space } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { PermissionsType } from '../../common/permissionsConst';
import { getReportPage, removeReport, Report, ReportType, ReportTypeDisplay } from '../../api/report';
import { useQuery } from '@/shared/utils/utils';
import qs from 'qs';
import { getReportStrategyList } from '../../api/reportStrategy';
import { getOuListOwned } from '../../api/ou';

// const routes = [
//   {name: '数据中心'},
//   {name: '自定义报表'},
//   {name: '报表列表'},
// ];

interface FilterProps {
  ouIds?: number[];
  reportStrategyIds?: number[];
  statisticalBeginTimeFrom?: number;
  statisticalBeginTimeTo?: number;
  statisticalEndTimeFrom?: number;
  statisticalEndTimeTo?: number;
  reportType?: ReportType;
}

const ReportList: React.FC = () => {
  const [form] = Form.useForm();
  const [updateState, update] = useUpdate();
  const [searchParams, setSearchParams] = useState<FilterProps>();
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const ouIds = Form.useWatch('ouIds', form);
  const [ready, setReady] = useState(false);
  const [ouSearchValue, setOuSearchValue] = useState<string>('');
  const reportStrategyIds = Form.useWatch('reportStrategyIds', form);
  const [strategySearchValue, setStrategySearchValue] = useState<string>('');

  const reportStrategies = useAsync(useCallback(() => getReportStrategyList(ouIds), [ouIds]));
  const allReportStrategySelected = useMemo(
    () => reportStrategyIds?.length === reportStrategies?.length,
    [reportStrategies?.length, reportStrategyIds?.length]
  );
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    const query: any = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    form.setFieldsValue({
      ouIds: query?.ouIds ? query.ouIds.split(',').map((item: string) => Number(item)) : [],
      reportStrategyIds: query?.reportStrategyId ? [+query.reportStrategyId] : undefined,
    });
    // setOuIds(query?.ouId ? [+query.ouId] : []);
    setSearchParams({ ouIds: [query?.ouId], reportStrategyIds: [query?.reportStrategyId] });
    window.history.replaceState(null, document.title, window.location.pathname);
    setReady(true);
  }, [form]);

  const ou = useAsync(getOuListOwned);
  const ouOptions = useMemo(() => {
    if (ou && ou.length !== 0) {
      return ou.map(i => ({ label: i.name, value: i.id }));
    }
  }, [ou]);
  const allOuSelected = useMemo(() => ouIds?.length === ou?.length, [ou?.length, ouIds?.length]);

  const permission = useTenantPermissions();

  const { data, isLoading } = useQuery(
    useCallback(() => {
      if (!ready) {
        return Promise.resolve([]);
      }
      return getReportPage({
        ...searchParams,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        if (res) setTotalCount(res.total);
        return res.list;
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageOffset, pageSize, searchParams, setTotalCount, updateState])
  );

  const onFinish = (v: any) => {
    const statisticalBeginTimeFrom = v.statisticalBeginTime?.[0]?.valueOf();
    const statisticalBeginTimeTo = v.statisticalBeginTime?.[1]?.valueOf();
    const statisticalEndTimeFrom = v.statisticalEndTime?.[0]?.valueOf();
    const statisticalEndTimeTo = v.statisticalEndTime?.[1]?.valueOf();
    setSearchParams({
      ouIds: v.ouIds,
      reportStrategyIds: v.reportStrategyIds,
      statisticalBeginTimeFrom,
      statisticalBeginTimeTo,
      statisticalEndTimeFrom,
      statisticalEndTimeTo,
      reportType: v.reportType,
    });
    setPageOffset(1);
  };

  const onReset = () => {
    const params = {
      reportStrategyIds: [],
      statisticalBeginTimeFrom: undefined,
      statisticalBeginTimeTo: undefined,
      statisticalEndTimeFrom: undefined,
      statisticalEndTimeTo: undefined,
      ouIds: [],
      reportType: undefined,
    };
    // setOuIds([]);
    setSearchParams(params);
    setPageOffset(1);
  };

  const filters = (
    <>
      <CustomFilter form={form} onFinish={v => onFinish(v)} onReset={onReset}>
        <Form.Item name="ouIds" label="所属运营单元">
          <Select
            maxTagCount={2}
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="全部"
            options={ouOptions}
            showSearch
            optionFilterProp="label"
            searchValue={ouSearchValue}
            onChange={(value: number[]) => {
              // 清空对应策略表单项里的数据
              form.setFieldValue('reportStrategyIds', []);
              // 选择后清空搜索框
              setOuSearchValue('');
            }}
            onSearch={value => {
              setOuSearchValue(value);
            }}
            dropdownRender={menu => (
              <>
                {!ouSearchValue.length && ou?.length ? (
                  <div style={{ padding: '4px 8px 8px 12px', cursor: 'pointer' }}>
                    <Checkbox
                      indeterminate={ouIds?.length && ouIds?.length < (ou?.length ?? 0)}
                      checked={allOuSelected}
                      onChange={e => {
                        // 点击后也要清空策略
                        form.setFieldValue('reportStrategyIds', []);
                        if (e.target.checked) {
                          // 全选
                          form.setFieldValue(
                            'ouIds',
                            ou?.map(v => v.id)
                          );
                        } else {
                          // 反选
                          form.setFieldValue('ouIds', []);
                        }
                      }}
                    >
                      全选
                    </Checkbox>
                  </div>
                ) : null}
                <Divider style={{ margin: 0 }} />
                {menu}
              </>
            )}
          />
        </Form.Item>

        <Form.Item name="reportStrategyIds" label="策略">
          <Select
            optionFilterProp="label"
            maxTagCount={2}
            mode="multiple"
            disabled={!ouIds?.length}
            style={{ width: '100%' }}
            placeholder="全部"
            options={reportStrategies?.map(r => ({ value: r.id, label: r.name }))}
            searchValue={strategySearchValue}
            onChange={(value: number) => {
              // 清空搜索
              setStrategySearchValue('');
            }}
            onSearch={value => {
              setStrategySearchValue(value);
            }}
            dropdownRender={menu => (
              <>
                {!strategySearchValue.length && reportStrategies?.length ? (
                  <div style={{ padding: '4px 8px 8px 12px', cursor: 'pointer' }}>
                    <Checkbox
                      indeterminate={
                        reportStrategyIds?.length && reportStrategyIds?.length < (reportStrategies?.length ?? 0)
                      }
                      checked={allReportStrategySelected}
                      onChange={e => {
                        if (e.target.checked) {
                          // 全选
                          form.setFieldValue(
                            'reportStrategyIds',
                            reportStrategies?.map(v => v.id)
                          );
                        } else {
                          // 反选
                          form.setFieldValue('reportStrategyIds', []);
                        }
                      }}
                    >
                      全选
                    </Checkbox>
                  </div>
                ) : null}
                <Divider style={{ margin: 0 }} />
                {menu}
              </>
            )}
          />
        </Form.Item>

        <Form.Item name="reportType" label="执行周期">
          <Select
            allowClear
            placeholder="全部"
            style={{ width: '100%' }}
            options={Object.entries(ReportTypeDisplay).map(([k, v]) => ({ label: v, value: k }))}
          />
        </Form.Item>

        <Form.Item name="statisticalBeginTime" label="统计起始时间">
          <DatePicker.RangePicker style={{ width: '100%' }} placeholder={['开始时间', '结束时间']} />
        </Form.Item>

        <Form.Item name="statisticalEndTime" label="统计截止时间">
          <DatePicker.RangePicker style={{ width: '100%' }} placeholder={['开始时间', '结束时间']} />
        </Form.Item>
      </CustomFilter>
    </>
  );

  const deleteAction = (id: number) => {
    removeReport(id).then(() => {
      update();
      message.success('删除成功！');
    });
  };

  const downLoadAction = (id: number) => {
    window.open(`/api/report-strategy/downLoad?reportRecordId=${id}`);
  };

  const columns = [
    {
      title: '报表名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '所属运营单元',
      key: 'ouNames',
      ellipsis: { showTitle: true },
      render: (_v: any, record: Report) => <EllipsisSpan value={record.ous.map((item: any) => item.name).join(',')} />,
    },
    {
      title: '策略名称',
      dataIndex: 'reportStrategyName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '执行周期',
      dataIndex: 'reportType',
      ellipsis: { showTitle: true },
      render: (v: ReportType) => <EllipsisSpan value={ReportTypeDisplay[v]} />,
    },
    {
      title: '统计起始时间',
      dataIndex: 'statisticalBeginTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
    },
    {
      title: '统计截止时间',
      dataIndex: 'statisticalEndTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
    },
  ];

  const actionColumns = {
    title: '操作',
    width: 120,
    fixed: 'right' as const,
    render: (v: Report) => (
      <>
        <Space size={16}>
          {(permission ?? []).includes(PermissionsType.B_REPORT_RECORD_DELETE) && (
            <PopConfirm
              title={`确定删除${v.name}?`}
              onConfirm={() => deleteAction(v.id)}
              okText="确定"
              cancelText="取消"
            >
              <Button type="link">删除</Button>
            </PopConfirm>
          )}
          {(permission ?? []).includes(PermissionsType.B_REPORT_RECORD_DOWNLOAD) && (
            <Button type="link" onClick={() => downLoadAction(v.id)}>
              下载
            </Button>
          )}
        </Space>
      </>
    ),
  };

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} filters={filters} className="page_wrapper">
      <Table scroll={{ x: 1400 }} columns={[...columns, actionColumns]} dataSource={data} loading={isLoading} />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default ReportList;
