import ShowInput from '@/shared/components/ShowInput';
import {
  ConfigurtionListItem,
  CreateDataLinkParams,
  getDeiveList,
  getPropertyList,
  IndicatorEnumDisplay,
} from '@/api/quality-monitor';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import styles from '../index.module.scss';
import { DatePicker, Form, Modal, Radio, Select, TimePicker } from '@maxtropy/components';
import { apiV2IndicatorAddDataLinkPost } from '@maxtropy/device-customer-apis-v2';
interface Iprops {
  cancel?: () => void;
  confirm?: () => void;
  data?: ConfigurtionListItem;
}
const QuotaEditModal: React.FC<Iprops> = ({ cancel, confirm, data }) => {
  const [form] = Form.useForm<CreateDataLinkParams>();
  const [loading, setLoading] = useState(false);
  const deviceId = Form.useWatch('deviceId', form);
  // 获取设备列表
  const { data: deviceList } = useRequest(
    () => {
      if (data) {
        return getDeiveList({
          importAndExportId: data.importAndExportId,
          importAndExportType: data.importAndExportType,
        }).then(res =>
          res.map(item => ({
            label: item.name,
            value: item.id,
          }))
        );
      }
      return Promise.resolve([]);
    },
    { refreshDeps: [data] }
  );

  // 获取属性列表
  const { data: propertyList } = useRequest(
    () => {
      if (data && deviceId) {
        return getPropertyList({
          deviceId,
          // basePhysicalQuantityId: data.basePhysicalQuantityId,
          // type: data.type,
          indicatorId: data.indicatorId,
        }).then(res =>
          res.map(item => ({
            label: item.name,
            value: item.id,
          }))
        );
      }
      return Promise.resolve([]);
    },
    { refreshDeps: [data, deviceId] }
  );
  useEffect(() => {
    if (data) {
      let timeObj: Record<string, any> = {};
      if (data.startTime) {
        timeObj.date = dayjs(data.startTime);
        timeObj.time = dayjs(data.startTime);
      }
      form.setFieldsValue({
        ...data,
        timePointType: data.startTime ? 2 : 1,
        timeObj,
      });
    }
  }, [data, form]);
  const onOk = () => {
    form
      .validateFields()
      .then(res => {
        let date = res.timeObj?.date;
        let time = res.timeObj?.time;
        if (res.timePointType === 1) {
          let now = dayjs();
          let minus = now.minute();
          let int = Math.ceil(minus / 15);
          let finalMinutes = int * 15;
          let hours = now.hour();
          res.startTime = dayjs(now.format('YYYY-MM-DD') + ` ${hours}:${finalMinutes}`).valueOf();
        }
        if (date && time) {
          let timeStr = dayjs(date).format('YYYY-MM-DD') + ' ' + dayjs(time).format('HH:mm');
          res.startTime = dayjs(timeStr).valueOf();
        }
        setLoading(true);
        apiV2IndicatorAddDataLinkPost({
          ...data,
          energyMediumMonitoringIndicatorsId: data?.indicatorId,
          ...res,
        })
          .then(_ => {
            confirm?.();
          })
          .finally(() => setLoading(false));
      })
      .catch(() => {});
  };
  const onCancel = () => {
    cancel?.();
  };
  return (
    <>
      <Modal
        title="编辑指标"
        confirmLoading={loading}
        open
        size="large"
        contentClassName="modal-form-content"
        onOk={onOk}
        onCancel={onCancel}
      >
        <Form
          labelAlign="right"
          labelCol={{ style: { width: 90 } }}
          form={form}
          initialValues={{
            timePointType: 1,
          }}
        >
          <Row>
            <Col span={8}>
              <Form.Item name="indicatorName" label="指标名称">
                <ShowInput></ShowInput>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="指标类型">
                <ShowInput value={data?.type ? IndicatorEnumDisplay[data.type] : '--'}></ShowInput>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="basePhysicalQuantityName" label="物理量">
                <ShowInput></ShowInput>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item name="deviceId" label="设备选择">
                <Select
                  onChange={() => {
                    form.setFieldsValue({
                      dataPropertyId: undefined,
                    });
                  }}
                  showSearch
                  optionFilterProp="label"
                  placeholder="请选择"
                  allowClear
                  options={deviceList}
                />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item name="dataPropertyId" label="数据属性" style={{ marginBottom: 10 }}>
                <Select showSearch optionFilterProp="label" placeholder="请选择" allowClear options={propertyList} />
              </Form.Item>
            </Col>
            <Col span={1}></Col>
            <Col span={24}>
              <div className={styles.tips_line} style={{ paddingBottom: 5 }}>
                <ExclamationCircleOutlined style={{ color: '#fdbe13' }} />
                &nbsp;<span className="warning_color">可选择符合指标类型和物理量的数据属性。</span>
              </div>
            </Col>

            <Col>
              <Form.Item label="生效时间" name="timePointType" rules={[{ required: true }]}>
                <Radio.Group>
                  <Row>
                    <Col span={24} style={{ paddingTop: 6 }}>
                      <Radio value={1}>立即生效</Radio>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} style={{ paddingTop: 10 }}>
                      <Radio value={2}>从指定时间生效</Radio>
                    </Col>
                  </Row>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col flex={1} style={{ paddingTop: 33 }}>
              <Form.Item noStyle dependencies={['timePointType']}>
                {({ getFieldValue }) => {
                  const val = getFieldValue('timePointType');
                  return val === 2 ? (
                    <Form.Item style={{ marginBottom: 15 }}>
                      <Space.Compact>
                        <Form.Item
                          name={['timeObj', 'date']}
                          noStyle
                          rules={[{ required: true, message: '请选择日期' }]}
                        >
                          <DatePicker showToday={false} format="YYYY-MM-DD" />
                        </Form.Item>
                        <Form.Item
                          name={['timeObj', 'time']}
                          noStyle
                          rules={[{ required: true, message: '请选择时间' }]}
                        >
                          <TimePicker showNow={false} format="HH:mm" minuteStep={15} />
                        </Form.Item>
                      </Space.Compact>
                    </Form.Item>
                  ) : (
                    <></>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={24}>
              <div className={styles.tips_line}>
                <ExclamationCircleOutlined style={{ color: '#fdbe13' }} />
                <span className="warning_color">&nbsp;从该日期起监控指标数据将从新的设备/数据属性查询新的数据。</span>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default QuotaEditModal;
