import React, { CSSProperties } from 'react';
import ShowInput from '../ShowInput';
import { BaseInfoContent, Form } from '@maxtropy/components';

export interface InfoDisplayProps {
  data?: any;
  className?: string;
  style?: CSSProperties;
}

function InfoDisplay(props: InfoDisplayProps) {
  const { data } = props;
  return (
    <Form>
      <BaseInfoContent layout="horizontal" colNum={2}>
        <Form.Item label="设备名称">{data?.name}</Form.Item>
        <Form.Item label="设备S/N码">{data?.sn ?? '--'}</Form.Item>
        <Form.Item label="所属类目">{data?.typeName}</Form.Item>
        <Form.Item label="物模型型号">
          <ShowInput
            value={
              data?.physicalModelId
                ? `${data?.physicalModelNo ?? data.modelNo}-${data?.manufacturerName}`
                : data?.physicalModelId
            }
          />
        </Form.Item>
        <Form.Item label="所属型号">{data?.modelName}</Form.Item>
      </BaseInfoContent>
    </Form>
  );
}

export default InfoDisplay;
