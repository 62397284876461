import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { PageRequest, PageResponse } from './page';

export interface ImplementPageRequest extends PageRequest {
  nameOrSerialNum?: string;
}

export interface Implement {
  id: number;
  serialNumber: string;
  name: string;
  ouTypeName: string;
  tenantName: string;
  adminNum: number;
  memberNum: number;
  updateByUsername: string;
  updateTime: string;
}

export type ImplementPageResponse = PageResponse<Implement>;

export const getImplementPage = (params: ImplementPageRequest): Promise<ImplementPageResponse> =>
  fetch<ImplementPageResponse>(`/api/ou/managePage?${qs.stringify(params, { indices: false })}`);

export interface ImplementAdmin {
  humanCode: string;
  id: number;
  name: string;
  orgName: string;
  phone: number;
  staffCode: string;
  staffType: number;
  username: string;
}

export type ImplementAdminListResponse = Array<ImplementAdmin>;

export const getImplementAdminList = (id: number): Promise<ImplementAdminListResponse> =>
  fetch(`/api/ou/loadManageStaffInfoList/${id}`);

interface ImplementDetail extends Implement {
  directorName: string;
}

export const getImplementDetail = (implementId: number) => fetch<ImplementDetail>(`/api/ou/manage/${implementId}`);

export interface AdminStaffPageRequest extends PageRequest {
  name?: string;
  username?: string;
  staffCode?: string;
  phone?: string;
  humanCode?: string;
}

export interface AdminStaff {
  humanCode: string;
  id: number;
  name: string;
  orgName: string;
  phone: number;
  staffCode: string;
  staffType: number;
  username: string;
}

export type AdminStaffPageResponse = PageResponse<AdminStaff>;

export const getAdminStaffPage = (params: AdminStaffPageRequest): Promise<AdminStaffPageResponse> =>
  fetch(`/api/ou/user/managePage?${qs.stringify(params, { indices: false })}`);

export interface UnbindOuRequest {
  ouId: number;
  staffId: number;
}

export const unbindOu = (params: UnbindOuRequest): Promise<void> =>
  fetch(`/api/ou/${params.ouId}/unbind?staffId=${params.staffId}`, { method: 'PUT' });

export interface SaveOuAdminRequest {
  ouId: number;
  memberIds?: Array<number>;
  adminIds?: Array<number>;
}

export const saveOuAdmin = (params: SaveOuAdminRequest) =>
  fetch(`/api/ou/manage/${params.ouId}`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });

export interface CheckOuIdsBoundRequest {
  addOuIds: Array<number> | undefined;
  oldOuIds: Array<number> | undefined;
}

export interface CheckOuIsBoundResponse {
  deviceId: Array<number>;
}

export const checkOuIsBound = (params: CheckOuIdsBoundRequest): Promise<CheckOuIsBoundResponse> =>
  fetch(`/api/ou/checkOuDevice?${qs.stringify(params, { indices: false })}`);
