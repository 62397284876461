import { Modal } from '@maxtropy/components';
import { Form, Table, Button as AntdButton, Col, Row } from 'antd';

import React, { useEffect, useState } from 'react';
import { Input, Select, Button, PopConfirm, EllipsisSpan, InputNumber } from '@maxtropy/components';
import { PlusOutlined } from '@ant-design/icons';
import ShowInput from '@/shared/components/ShowInput';
import { validateText } from '../../../ProductCarbonFootprint/NewModel/utils';
import { isNil } from 'lodash';
import styles from './index.module.scss';
import type { ColumnsType } from 'antd/es/table';
import { v4 as uuidv4 } from 'uuid';
import { Rule } from 'antd/es/form';
import type { SelectProps } from 'antd';
import debounce from 'lodash/debounce';
import { UnitTypeWithUnitList, getUnits, OutputUnitType } from '@/api/outputConfig';

import {
  apiV2CarbonFootprintEntryAddPost,
  apiV2CarbonFootprintSupplierListPost,
  apiV2CustomerUserCurrentPost,
  apiV2MaterialListPost,
} from '@maxtropy/device-customer-apis-v2';
import { cloneDeep } from 'lodash-es';
export type listType = {
  id: number;
  name: string;
  spec: string;
  unitCode: string;
  inventoryQuantity: number;
  carbonFootPrint: number;
  materialId: number;
};
interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  updateFn: () => void;
}

const MaterialWarehousing: React.FC<Props> = ({ open, setOpen, updateFn }) => {
  const [form] = Form.useForm();
  const [serachData, setSerachData] = useState<SelectProps['options']>([]);
  const [originData, setOriginData] = useState<any>([]);
  const [supplierMapList, setSupplierMapList] = useState<any>([]);
  const [units, setUnits] = useState<UnitTypeWithUnitList[]>([]); // 所有单位

  useEffect(() => {
    apiV2CustomerUserCurrentPost({}).then(res => {
      form.setFieldsValue({ customerUser: res?.customerUser?.name });
    });
    debounceSuppilerList('');
    getUnits().then(res => setUnits(res.list));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debounceFetcher = debounce((value: string) => {
    materialOrEnergyList(value);
  }, 500);

  const materialOrEnergyList = (value: string) => {
    apiV2MaterialListPost({ codeOrNameOrSpec: value }).then(res => {
      const mapList = res?.list?.map(i => {
        return {
          label: `${i.code}/${i.name}${!isNil(i.spec) ? '/' : ''}${!isNil(i.spec) ? i.spec : ''}`,
          value: i.id,
        };
      });
      setSerachData(mapList ?? []);
      setOriginData(res?.list ?? []);
    });
  };

  const debounceSuppilerList = debounce(val => {
    apiV2CarbonFootprintSupplierListPost({ name: val }).then(res => {
      const mapList = res?.list?.map(i => {
        return {
          label: `${i.name}`,
          value: i.id,
        };
      });
      setSupplierMapList(mapList ?? []);
    });
  }, 500);

  const columns: ColumnsType<listType> = [
    {
      title: '物料号',
      dataIndex: 'materialId',
      width: '15%',
      render(value, record, index) {
        return (
          <Form.Item name={['list', index, 'materialId']} style={{ marginBottom: 0 }}>
            <Select
              options={serachData}
              onSearch={debounceFetcher}
              optionFilterProp="label"
              showSearch
              placeholder={'请选择物料'}
              onFocus={() => {
                materialOrEnergyList('');
              }}
              onChange={value => {
                const list = form.getFieldsValue().list;
                const findData = originData.find((i: any) => i.id === value);
                const unitCode =
                  (findData.unitTypeCode === OutputUnitType.PIECE
                    ? units
                        .find(u => u.unitTypeCode === OutputUnitType.PIECE)
                        ?.outputProductUnitList?.find(e => e?.unitCode === findData.unitCode)?.unitName
                    : findData.unitCode) ?? '--';
                const cloneRoads = cloneDeep(list);
                const newRoads = [...cloneRoads];
                newRoads[index] = {
                  ...cloneRoads[index],
                  spec: findData.spec,
                  name: findData.name,
                  unitCode: unitCode,
                  carbonFootPrint: findData.carbonFootPrint,
                };
                form.setFieldsValue({ list: newRoads });
              }}
            ></Select>
          </Form.Item>
        );
      },
    },
    {
      title: '物料名称',
      dataIndex: 'name',
      width: '8%',
      ellipsis: true,
      render(value, record, index) {
        return (
          <Form.Item name={['list', index, 'name']} style={{ marginBottom: 0 }}>
            <ShowInput></ShowInput>
          </Form.Item>
        );
      },
    },
    {
      title: '规格型号',
      width: '8%',
      ellipsis: true,
      dataIndex: 'spec',
      render(value, record, index) {
        return (
          <Form.Item name={['list', index, 'spec']} style={{ marginBottom: 0 }}>
            <ShowInput />
          </Form.Item>
        );
      },
    },

    {
      title: '入库数量',
      dataIndex: 'inventoryQuantity',
      width: '12%',
      render(value, record, index) {
        return (
          <>
            <Form.Item
              name={['list', index, 'inventoryQuantity']}
              style={{ marginBottom: 0 }}
              rules={[
                { message: '请输入数字', required: true },
                {
                  validator: (_, value) => {
                    if (value <= 0) {
                      return Promise.reject('入库数量必须大于0');
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <InputNumber placeholder="请输入" style={{ width: '100%' }} max={999999999}></InputNumber>
            </Form.Item>
          </>
        );
      },
    },
    {
      title: '单位',
      dataIndex: 'unitCode',
      ellipsis: true,
      width: '8%',
      render(value, record, index) {
        return (
          <>
            <Form.Item name={['list', index, 'unitCode']} style={{ marginBottom: 0 }}>
              <ShowInput />
            </Form.Item>
          </>
        );
      },
    },
    {
      title: '产品碳足迹',
      dataIndex: 'carbonFootPrint',
      width: '12%',
      render(value, record, index) {
        return (
          <>
            <Form.Item
              name={['list', index, 'carbonFootPrint']}
              style={{ marginBottom: 0 }}
              rules={[
                { message: '请输入', required: true },
                {
                  validator: (_, value) => {
                    if (value <= 0) {
                      return Promise.reject('产品碳足迹必须大于0');
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <InputNumber placeholder="请输入" style={{ width: '100%' }}></InputNumber>
            </Form.Item>
          </>
        );
      },
    },
    {
      title: '产品碳足迹单位',
      dataIndex: 'factorId',
      width: '10%',
      render(value, record, index) {
        return <EllipsisSpan value={`kgCO2e/${record.unitCode ?? '--'}`}></EllipsisSpan>;
      },
    },
    {
      title: '操作',
      ellipsis: true,
      width: '10%',
      render(value, record, index) {
        return (
          <>
            {!isNil(record.materialId) ? (
              <PopConfirm
                placement="top"
                title={'您是否确认移除?'}
                onConfirm={() => {
                  onDelete(record);
                }}
              >
                <Button type="link">移除</Button>
              </PopConfirm>
            ) : (
              <Button
                type="link"
                onClick={() => {
                  onDelete(record);
                }}
              >
                移除
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const onDelete = (record: listType) => {
    const prevList = form.getFieldValue('list');
    const removeId = prevList.findIndex((item: listType) => {
      return item.id === record.id;
    });
    prevList.splice(removeId, 1);
    form.setFieldsValue({ list: prevList });
  };

  const onFinish = () => {
    form.validateFields().then(res => {
      const newList = res.list.map((item: any) => ({
        carbonFootPrint: item.carbonFootPrint,
        materialId: item.materialId,
        inventoryQuantity: item.inventoryQuantity,
      }));
      const params = {
        entryNumber: res.entryNumber,
        supplierId: res.supplierId,
        list: newList,
      };
      apiV2CarbonFootprintEntryAddPost(params).then(() => {
        setOpen(false);
        updateFn();
        form.setFieldsValue({
          entryNumber: undefined,
          supplierId: undefined,
          list: [
            {
              id: uuidv4(),
            },
          ],
        });
      });
    });
  };

  const addRow = () => {
    const newRow = {
      id: uuidv4(),
    };

    const prevList = form.getFieldValue('list') || [];
    prevList.push(newRow);
    form.setFieldsValue({ list: prevList });
  };

  return (
    <Modal
      size="big"
      title="物料入库"
      open={open}
      onOk={onFinish}
      onCancel={() => {
        form.setFieldsValue({
          entryNumber: undefined,
          supplierId: undefined,
          list: [
            {
              id: uuidv4(),
            },
          ],
        });
        setOpen(false);
      }}
    >
      <div className={styles.content}>
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            list: [
              {
                id: uuidv4(),
              },
            ],
          }}
        >
          <Row gutter={[24, 48]}>
            <Col span={8}>
              <Form.Item
                label="入库单号"
                rules={[{ required: true, message: '入库单号不能为空' }, validateText(50) as Rule]}
                labelAlign="left"
                name="entryNumber"
              >
                <Input placeholder={'请输入名称'}></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="供应商"
                rules={[{ required: true, message: '请选择供应商' }]}
                labelAlign="left"
                name="supplierId"
              >
                <Select
                  showSearch
                  placeholder={'请搜索并选择'}
                  optionFilterProp="label"
                  options={supplierMapList}
                  onSearch={val => {
                    debounceSuppilerList(val);
                  }}
                ></Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="入库人" labelAlign="left" name="customerUser">
                <ShowInput></ShowInput>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item labelAlign="left" name="list" style={{ marginBottom: 0 }} valuePropName="dataSource">
            <Table rowKey="id" columns={columns} pagination={false} scroll={{ y: 340 }} />
          </Form.Item>
          <AntdButton
            type="default"
            icon={<PlusOutlined />}
            className={styles.addRowBtn}
            onClick={() => {
              addRow();
            }}
          >
            新增一行数据
          </AntdButton>
        </Form>
      </div>
    </Modal>
  );
};

export default MaterialWarehousing;
