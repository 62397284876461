import { Key, Modal, Tag, Form } from '@maxtropy/components';
import { Col, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { OutputUnitType, OutputUnitTypeDisplay, UnitTypeWithUnitList, getUnits } from '@/api/outputConfig';
import {
  CarbonFootprintSourceDisplay,
  MaterielManagementListRes,
  MaterielStatus,
  MaterielStatusDisplay,
  MaterielTypeDisplay,
  SystemBoundaryDisplay,
  getMaterielDetail,
} from '@/api/materielManagement';
import ShowInput from '@/shared/components/ShowInput';
import { isNil } from 'lodash';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedId?: Key;
}

const DetailMaterielModal: React.FC<Props> = ({ open, setOpen, selectedId }) => {
  const [units, setUnits] = useState<UnitTypeWithUnitList[]>([]); // 所有单位
  const [detail, setDetail] = useState<MaterielManagementListRes>(); // 物料详情

  useEffect(() => {
    // 获取所有单位
    getUnits().then(res => setUnits(res.list));

    if (selectedId) {
      getMaterielDetail(selectedId).then(res => {
        setDetail(res);
      });
    }
  }, [selectedId]);

  // 根据选择计量单位还是计件单位展示不同的单位选项
  const unitNameOptions = useMemo(() => {
    if (isNil(detail)) return [];
    const matchedUnit = units.find(u => u.unitTypeCode === detail.unitTypeCode);
    if (matchedUnit) {
      return (
        matchedUnit?.outputProductUnitList?.map(v => ({
          value: v?.unitCode,
          label: detail.unitTypeCode === OutputUnitType.PIECE ? v.unitName : `${v.unitCode} (${v?.unitName})`,
        })) ?? []
      );
    } else {
      return [];
    }
  }, [detail, units]);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Modal size="large" title="物料详情" open={open} onOk={onClose} onCancel={onClose}>
      <Form layout="vertical" isOrigin>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item label={<span style={{ color: 'var(--mx-text-secondary-color)' }}>物料类型</span>}>
              <ShowInput
                style={{ color: 'var(--mx-text-base-color)' }}
                value={detail ? MaterielTypeDisplay[detail.type] : null}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<span style={{ color: 'var(--mx-text-secondary-color)' }}>物料编码</span>}>
              <ShowInput style={{ color: 'var(--mx-text-base-color)' }} value={detail?.code} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<span style={{ color: 'var(--mx-text-secondary-color)' }}>物料名称</span>}>
              <ShowInput style={{ color: 'var(--mx-text-base-color)' }} value={detail?.name} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<span style={{ color: 'var(--mx-text-secondary-color)' }}>规格型号</span>}>
              <ShowInput style={{ color: 'var(--mx-text-base-color)' }} value={detail?.spec} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<span style={{ color: 'var(--mx-text-secondary-color)' }}>物料单位</span>}>
              <Row>
                <Col span={6}>
                  <Form.Item noStyle>
                    <ShowInput
                      style={{ color: 'var(--mx-text-base-color)' }}
                      value={detail ? OutputUnitTypeDisplay[detail.unitTypeCode] : null}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item noStyle>
                    <ShowInput
                      style={{ color: 'var(--mx-text-base-color)' }}
                      value={unitNameOptions.find(i => i.value === detail?.unitCode)?.label}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<span style={{ color: 'var(--mx-text-secondary-color)' }}>单位重量（kg）</span>}>
              <ShowInput style={{ color: 'var(--mx-text-base-color)' }} value={detail?.unitWeight} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<span style={{ color: 'var(--mx-text-secondary-color)' }}>碳足迹</span>}>
              <ShowInput style={{ color: 'var(--mx-text-base-color)' }} value={detail?.carbonFootPrint} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<span style={{ color: 'var(--mx-text-secondary-color)' }}>碳足迹单位</span>}>
              <Row>
                <Col span={5}>
                  <span style={{ color: 'var(--mx-text-base-color)' }}>kgCO2e /</span>
                </Col>
                <Col span={19}>
                  <ShowInput
                    style={{ color: 'var(--mx-text-base-color)' }}
                    value={unitNameOptions.find(i => i.value === detail?.unitCode)?.label}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<span style={{ color: 'var(--mx-text-secondary-color)' }}>碳足迹来源</span>}>
              <ShowInput
                style={{ color: 'var(--mx-text-base-color)' }}
                value={detail ? CarbonFootprintSourceDisplay[detail.footPrintOrigin] : null}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<span style={{ color: 'var(--mx-text-secondary-color)' }}>系统边界</span>}>
              <ShowInput
                style={{ color: 'var(--mx-text-base-color)' }}
                value={detail ? SystemBoundaryDisplay[detail.boundary] : null}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<span style={{ color: 'var(--mx-text-secondary-color)' }}>状态</span>}>
              {detail ? (
                <Tag border="solid" color={detail.state === MaterielStatus.ENABLE ? 'success' : 'default'}>
                  {MaterielStatusDisplay[detail.state]}
                </Tag>
              ) : (
                '--'
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={<span style={{ color: 'var(--mx-text-secondary-color)' }}>备注</span>}>
              <ShowInput style={{ color: 'var(--mx-text-base-color)' }} value={detail?.remark} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default DetailMaterielModal;
