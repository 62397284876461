import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Divider, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ShowInput from '@/shared/components/ShowInput';
import styles from '../index.module.scss';
import { checkUnique, UetInfoProps, updateUet } from '../../../../api/uet';
import { getOuListByMcid, OuInfoProps } from '../../../../api/ou';
import { Button, Form, Input, Select, Tooltip } from '@maxtropy/components';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

interface TopProps {
  info?: UetInfoProps;
  forceUpdate: () => void;
}

const Top: React.FC<TopProps> = ({ info, forceUpdate }) => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const [edit, setEdit] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [ouList, setOuList] = useState<OuInfoProps[]>([]);

  useEffect(() => {
    if (info) {
      form.setFieldsValue({
        ...info,
        ouIds: info.ous.map(i => i.id),
      });
      getOuListByMcid(info.mcid).then(r => {
        setOuList(r);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  const onFinish = async (v: { name: string; ouIds: number[] }) => {
    if (id) {
      if (info?.name === v.name) {
        form.setFields([
          {
            name: 'name',
            value: v.name,
            errors: undefined,
          },
        ]);
        // 如果名字没有变化
        updateUet(id, { ouIds: v.ouIds, name: v.name }).then(res => {
          setEdit(false);
          forceUpdate();
        });
      } else {
        // 如果名字有变化
        const r = await checkUnique(v.name);
        if (r) {
          form.setFields([
            {
              name: 'name',
              errors: ['拓扑名称重复！'],
            },
          ]);
        } else {
          form.setFields([
            {
              name: 'name',
              value: v.name,
              errors: undefined,
            },
          ]);
          updateUet(id, { ouIds: v.ouIds, name: v.name }).then(res => {
            setEdit(false);
            forceUpdate();
          });
        }
      }
    }
  };

  return (
    <>
      <Form form={form} layout="vertical" {...formLayout} className={styles.top} onFinish={onFinish}>
        <div style={{ position: 'relative', padding: '15px 30px' }}>
          <p className={styles.title}>全域能源拓扑基础信息</p>
          {open ? (
            <Row style={{ marginTop: 15 }}>
              <Col span={6}>
                <Form.Item name="serialNumber" label="拓扑ID" required>
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="name" label="拓扑名称" required>
                  {edit ? <Input placeholder="请输入" /> : <ShowInput />}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="customerCode" label="组织编号" required>
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="customerName" label="组织名称" required>
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="ouIds"
                  label="全域能源拓扑包含运营单元"
                  rules={[{ required: true, message: '请选择全域能源拓扑包含运营单元' }]}
                >
                  <Select placeholder="请选择" mode="multiple" optionFilterProp="children" disabled={!edit}>
                    {ouList.map(i => (
                      <Select.Option value={i.id} key={i.id}>
                        {i.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ) : (
            <Space split={<Divider type="vertical" />} className={styles.topTags}>
              <Tooltip title={`拓扑ID:${info?.serialNumber ?? '--'}`}>
                <p className={styles.hint}>拓扑ID:{info?.serialNumber ?? '--'}</p>
              </Tooltip>
              <Tooltip title={`拓扑名称:${info?.name ?? '--'}`}>
                <p className={styles.hint}>拓扑名称:{info?.name ?? '--'}</p>
              </Tooltip>
              <Tooltip title={`组织编号:${info?.customerCode ?? '--'}`}>
                <p className={styles.hint}>组织编号:{info?.customerCode ?? '--'}</p>
              </Tooltip>
              <Tooltip title={`组织名称:${info?.customerName ?? '--'}`}>
                <p className={styles.hint}>组织名称:{info?.customerName ?? '--'}</p>
              </Tooltip>
            </Space>
          )}
          <div className={styles.btns}>
            {open ? (
              <>
                {edit ? (
                  <Space size={8}>
                    <Button type="primary" htmlType="submit">
                      保存
                    </Button>
                    <Button
                      onClick={() => {
                        setEdit(false);
                        form.setFieldsValue({
                          ...info,
                          ouIds: info?.ous.map(i => i.id),
                        });
                      }}
                    >
                      取消
                    </Button>
                  </Space>
                ) : (
                  <Button type="primary" onClick={() => setEdit(true)}>
                    编辑
                  </Button>
                )}
                <Button type="link" onClick={() => setOpen(false)}>
                  收起
                  <DownOutlined />
                </Button>
              </>
            ) : (
              <>
                <Button type="link" onClick={() => setOpen(true)}>
                  展开
                  <RightOutlined />
                </Button>
              </>
            )}
          </div>
        </div>
      </Form>
    </>
  );
};

export default Top;
