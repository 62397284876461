import { Divider, Spin } from 'antd';
import { Empty, Key, Form, Input, Modal, Select, Tree, Button } from '@maxtropy/components';

import type { DataNode } from 'antd/es/tree';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import _, { difference } from 'lodash';
import { BindUnit, EnergyUnitTreeItem, getEnergyUnitTree, getHasBindUnitList } from '@/api/energyUnitMapping';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { apiV2EnergyAnalysisListByOuPost } from '@maxtropy/device-customer-apis-v2';
import { V2EnergyAnalysisListPostResponse } from '@maxtropy/device-customer-apis-v2';

export type AnalysisUnit = Exclude<V2EnergyAnalysisListPostResponse['list'], undefined>[number];

interface UnitDataNode extends DataNode {
  parentId: string;
}

function formatToNodeData(data: EnergyUnitTreeItem[]): UnitDataNode[] {
  return data.map(i => {
    return {
      title: i.name,
      key: i.energyUnitId,
      children: i.children && i.children.length > 0 ? formatToNodeData(i.children) : [],
      disabled: i.disabled,
      parentId: i.parentId,
    };
  });
}

const dataList: { key: React.Key; title: string }[] = [];

const generateList = (data: DataNode[]) => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const { key, title } = node;
    dataList.push({ key, title: title as string });
    if (node.children) {
      generateList(node.children);
    }
  }
};

const getParentKey = (key: React.Key, tree: DataNode[]): React.Key => {
  let parentKey: React.Key;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some(item => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey!;
};

const getParentIdsByNodeId = (data: DataNode[], nodeId: React.Key, path: React.Key[] = []): React.Key[] => {
  for (const node of data) {
    const id = node.key;
    path.push(node.key);
    if (id === nodeId) return path;
    if (node.children) {
      const findChildren = getParentIdsByNodeId(node.children, nodeId, path);
      if (findChildren?.length) return findChildren;
    }
    path.pop();
  }
  return [];
};

const getIds = (data: DataNode, ids: React.Key[]) => {
  if (!data.disabled) {
    ids.push(data.key);
  }

  if (data.children) {
    for (const child of data.children) {
      getIds(child, ids);
    }
  }
  return ids;
};

interface Props {
  open: boolean;
  setOpen: (v: boolean) => void;
  workStationLinkId?: Key;
  updateFn: () => void;
  infoUpdateFn: () => void;
  setPageOffset: (val: number) => void;
}

const BindUnitModal: React.FC<Props> = ({
  setPageOffset,
  infoUpdateFn,
  updateFn,
  open,
  setOpen,
  workStationLinkId,
}) => {
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>();
  const [searchValue, setSearchValue] = useState('');
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [treeNodeData, setTreeNodeData] = useState<UnitDataNode[]>([]);
  const [form] = Form.useForm();
  const energyGroupId = Form.useWatch('energyGroupId', form);
  const [loading, setLoading] = useState<boolean>(false);

  const [unitGroup, setUnitGroup] = useState<AnalysisUnit[]>([]);
  const [unitCheckedKeys, setUnitCheckedKeys] = useState<Key[]>([]);
  const [oldCheckedKeys, setOldCheckedKeys] = useState<Key[]>([]);

  useEffect(() => {
    // 获取用能分析组
    apiV2EnergyAnalysisListByOuPost({}).then(res => {
      setUnitGroup((res.list ?? []) as AnalysisUnit[]);
    });
  }, []);

  useEffect(() => {
    if (workStationLinkId) {
      getHasBindUnitList({ procedureStationLinkId: workStationLinkId }).then(res => {
        setUnitCheckedKeys(res.map(({ energyUnitId }) => energyUnitId));
        setOldCheckedKeys(res.map(({ energyUnitId }) => energyUnitId));
      });
    }
  }, [workStationLinkId]);

  const unitGroupOption = useMemo(() => {
    if (unitGroup.length > 0) {
      return unitGroup.map(i => ({
        label: i.name,
        value: i.id,
      }));
    }
  }, [unitGroup]);

  const checkAllChildren = (data: DataNode) => {
    const unitIds = getIds(data, []);
    setUnitCheckedKeys(unitCheckedKeys => [...unitCheckedKeys, ...unitIds]);
  };

  const treeData = useMemo(() => {
    const loop = (data: UnitDataNode[]): DataNode[] =>
      data.map(item => {
        const strTitle = item.title as string;
        const index = strTitle.indexOf(searchValue);
        const beforeStr = strTitle.substring(0, index);
        const afterStr = strTitle.slice(index + searchValue.length);
        let title: ReactNode =
          index > -1 ? (
            <span>
              {beforeStr}
              <span className={styles.siteTreeSearchValue}>{searchValue}</span>
              {afterStr}
            </span>
          ) : (
            <span>{strTitle}</span>
          );

        if ((item.parentId as unknown) === 0) {
          title = (
            <>
              {title}
              <Button disabled={item.disabled} type="link" size="small" onClick={() => checkAllChildren(item)}>
                全选子节点
              </Button>
            </>
          );
        }
        if (item.children) {
          return {
            title,
            key: item.key,
            children: loop(item.children as UnitDataNode[]),
            disabled: item.disabled,
            selectable: true,
          };
        }
        return {
          title,
          key: item.key,
          disabled: item.disabled,
          selectable: true,
        };
      });

    return loop(treeNodeData);
  }, [searchValue, treeNodeData]);

  const onExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const newExpandedKeys = dataList
      .map(item => {
        if (item.title.indexOf(value) > -1) {
          return getParentKey(item.key, treeNodeData);
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);
    const allParentKeys = (newExpandedKeys as React.Key[]).map(key => {
      return getParentIdsByNodeId(treeNodeData, key);
    });
    setExpandedKeys(_.uniq(allParentKeys.flat()));
    setSearchValue(value);
    setAutoExpandParent(true);
  };

  const onCheck = (keys: any) => {
    setUnitCheckedKeys(keys.checked);
  };

  useEffect(() => {
    if (energyGroupId) {
      setLoading(true);
      getEnergyUnitTree(energyGroupId)
        .then(res => {
          setTreeNodeData(formatToNodeData(res));
          generateList(formatToNodeData(res));
          setExpandedKeys(dataList.map(i => i.key));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [energyGroupId]);

  const onBind = () => {
    const addUnitIds = difference(unitCheckedKeys, oldCheckedKeys);
    const delUnitIds = difference(oldCheckedKeys, unitCheckedKeys);
    if (addUnitIds.length === 0 && delUnitIds.length === 0) {
      setOpen(false);
      return;
    } else {
      if (workStationLinkId) {
        BindUnit({
          procedureStationLinkId: workStationLinkId,
          addUnitIds,
          delUnitIds,
        }).then(() => {
          setOpen(false);
          updateFn();
          setPageOffset(1);
          infoUpdateFn();
        });
      }
    }
  };

  return (
    <>
      <Modal
        title="绑定用能单元"
        size="small"
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
        onOk={onBind}
      >
        <Form form={form}>
          <Form.Item label="用能分析组" name="energyGroupId">
            <Select placeholder="请选择" options={unitGroupOption} />
          </Form.Item>
          <div className={styles.content}>
            <Form.Item
              extra={
                treeData.length > 0 && (
                  <p>
                    <ExclamationCircleOutlined style={{ color: 'var(--warning-color)' }} />
                    灰色选择框表示无该用能单元权限
                  </p>
                )
              }
            >
              <div>
                <Input.Search
                  // style={{ margin: '8px 0px', width: 449 }}
                  placeholder="请输入关键字"
                  onChange={onInputChange}
                />
                <Divider style={{ borderColor: 'rgba(255,255,255,0.15)' }} />

                <Spin spinning={loading}>
                  {treeData.length > 0 ? (
                    <div className={styles.treeBackground} style={{ overflow: 'auto', maxHeight: 367, maxWidth: 433 }}>
                      <Tree
                        checkable
                        checkStrictly
                        // showLine
                        checkedKeys={unitCheckedKeys}
                        onExpand={onExpand}
                        expandedKeys={expandedKeys}
                        autoExpandParent={autoExpandParent}
                        treeData={treeData}
                        onCheck={onCheck}
                      />
                    </div>
                  ) : (
                    <Empty style={{ maxWidth: 433 }} />
                  )}
                </Spin>
              </div>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default BindUnitModal;
