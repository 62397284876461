import { PlusOutlined } from '@ant-design/icons';
import {
  EllipsisSpan,
  Table,
  useUpdate,
  Button,
  Form,
  Modal,
  Radio,
  InputNumber,
  SubContent,
} from '@maxtropy/components';
import { Divider, Space, Row } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import ShowInput from '@/shared/components/ShowInput';
import { PricingMethod, PricingMethodDisplay } from '@/shared/types';
import {
  createBasicPrice,
  deleteBasicPrice,
  ElectricityAccountVo,
  ElectricityContractVo,
  ElectricityType,
  getBasicPriceList,
  getElectricityAccount,
  RateBasicElectricityPriceRequest,
  RateBasicElectricityPriceVo,
  updateBasicPrice,
} from '../../../../../api/electricity';
import Day, { TimeSpace } from '../components/Day';
import Month from '../components/Month';
import { toPrecision } from '../util';

import styles from './index.module.scss';

const formLayout = {
  labelCol: { flex: '100px' },
};
const formatString = 'YYYY-MM-DD';

export interface CreatePriceFormProps {
  pricingMethod: PricingMethod;
  capacityUnitPrice?: string;
  agreedDemand?: number;
  demandUnitPrice?: string;
  penaltyCoefficient?: number;
  penaltyUnitPrice?: string;
}

export interface CreateMonthFormProps extends CreatePriceFormProps {
  months: number[];
}

export interface CreateDayFormProps extends CreatePriceFormProps {
  day: TimeSpace;
}

export interface ElectricRateBasicPriceProps {
  contract?: ElectricityContractVo;
  disabled?: boolean;
}

const columns: ColumnType<RateBasicElectricityPriceVo>[] = [
  {
    title: '日期',
    dataIndex: 'startDate',
    width: 200,
    render: (v: string, record) => (
      <EllipsisSpan value={`${dayjs(v).format(formatString)} ~ ${dayjs(record.endDate).format(formatString)}`} />
    ),
  },
  {
    title: '计价方式',
    dataIndex: 'pricingMethod',
    ellipsis: { showTitle: true },
    render: (v: PricingMethod, record) => {
      return (
        <Space direction="vertical">
          <div className={styles.pricingMethod}>{PricingMethodDisplay[v]}</div>
          {v === PricingMethod.TRANSFORMERCAPACITY ? (
            <Row>
              变压器容量值：<span className={styles.color}>--</span> kVA 容量单价:{' '}
              <span className={styles.color}>{record.capacityUnitPrice}</span> 元/kVA
            </Row>
          ) : v === PricingMethod.AGREEDDEMAND ? (
            <Row>
              约定需量值: <span className={styles.color}>{record.agreedDemand}</span> kW 需量单价:{' '}
              <span className={styles.color}>{record.demandUnitPrice}</span> 元/kVA 惩罚系数:{' '}
              <span className={styles.color}>{record.penaltyCoefficient}</span> % 惩罚单价：
              <span className={styles.color}>{record.penaltyUnitPrice}</span> 元/kVA
            </Row>
          ) : (
            <Row>
              需量单价: <span className={styles.color}>{record.demandUnitPrice}</span> 元/kVA
            </Row>
          )}
        </Space>
      );
    },
  },
];

const ElectricRateBasicPrice: FC<ElectricRateBasicPriceProps> = ({ contract, disabled = false }) => {
  const [electricityAccound, setElectricityAccound] = useState<ElectricityAccountVo>();

  useEffect(() => {
    if (contract) {
      getElectricityAccount(contract.electricityAccountId).then(setElectricityAccound);
    }
  }, [contract]);

  const [loading, setLoading] = useState<boolean>(false);
  const [updateState, updateFn] = useUpdate();

  const [prices, setPrices] = useState<RateBasicElectricityPriceVo[]>([]);

  useEffect(() => {
    if (contract) {
      setLoading(true);
      getBasicPriceList(contract.id).then(res => {
        setPrices(res.list || []);
        setLoading(false);
      });
    }
  }, [contract, updateState]);

  const onDelete = (record: RateBasicElectricityPriceVo) => {
    deleteBasicPrice(record.id).then(() => {
      updateFn();
    });
  };

  const buildColumns = [
    ...columns,
    ...(!disabled
      ? [
          {
            title: '操作',
            fixed: 'right' as 'right',
            width: 280,
            render: (record: RateBasicElectricityPriceVo) => (
              <Space size={16}>
                <Button
                  type="link"
                  onClick={() => {
                    onDelete(record);
                  }}
                >
                  删除
                </Button>
                <Button
                  type="link"
                  onClick={() => {
                    onEdit(record);
                  }}
                >
                  编辑
                </Button>
              </Space>
            ),
          },
        ]
      : []),
  ];

  const [monthVisible, setMonthVisible] = useState<boolean>(false);
  const [monthsForm] = Form.useForm();

  const confirm = () => {
    Modal.warning({
      title: '当前关联户号非“大工业”类型，无基本电价信息。',
    });
  };

  const onAddMonths = () => {
    if (electricityAccound) {
      if (electricityAccound.type === ElectricityType.INDUSTRY) {
        setMonthVisible(true);
      } else {
        confirm();
      }
    }
  };

  const onMonthCancel = () => {
    setMonthVisible(false);
    monthsForm.resetFields();
  };

  const onMonthSubmit = () => {
    monthsForm.validateFields().then((value: CreateMonthFormProps) => {
      if (contract) {
        const request = value.months.map(i => {
          const end = dayjs(i);
          let start = dayjs(end).startOf('month').valueOf();
          start = dayjs(start).isAfter(dayjs(contract.startDate).startOf('date'))
            ? start
            : dayjs(contract.startDate).startOf('date').valueOf();
          return {
            electricityContractId: contract.id,
            pricingMethod: value.pricingMethod,
            capacityUnitPrice: toPrecision(value.capacityUnitPrice, contract?.precision ?? 0),
            agreedDemand: value.agreedDemand,
            demandUnitPrice: toPrecision(value.demandUnitPrice, contract?.precision ?? 0),
            penaltyCoefficient: value.penaltyCoefficient,
            penaltyUnitPrice: toPrecision(value.penaltyUnitPrice, contract?.precision ?? 0),
            startDate: dayjs(start).format(formatString),
            endDate: dayjs(i).format(formatString),
          } as RateBasicElectricityPriceRequest;
        });

        createBasicPrice(request).then(() => {
          onMonthCancel();
          updateFn();
        });
      }
    });
  };

  const [daysForm] = Form.useForm();
  const [rateBasicPrice, setRateBasicPrice] = useState<Partial<RateBasicElectricityPriceVo>>();

  const onAddDay = () => {
    if (electricityAccound) {
      if (electricityAccound.type === ElectricityType.INDUSTRY) {
        setRateBasicPrice({});
      } else {
        confirm();
      }
    }
  };

  const onEdit = (record: RateBasicElectricityPriceVo) => {
    setRateBasicPrice(record);
  };

  const onDayCancel = () => {
    daysForm.resetFields();
    setRateBasicPrice(undefined);
  };

  useEffect(() => {
    if (rateBasicPrice && rateBasicPrice.id) {
      daysForm.setFieldsValue({
        pricingMethod: rateBasicPrice.pricingMethod,
        capacityUnitPrice: rateBasicPrice.capacityUnitPrice,
        agreedDemand: rateBasicPrice.agreedDemand,
        demandUnitPrice: rateBasicPrice.demandUnitPrice,
        penaltyCoefficient: rateBasicPrice.penaltyCoefficient,
        penaltyUnitPrice: rateBasicPrice.penaltyUnitPrice,
        day: [
          dayjs(rateBasicPrice.startDate).startOf('date').valueOf(),
          dayjs(rateBasicPrice.endDate).endOf('date').valueOf(),
        ],
      });
    }
  }, [rateBasicPrice, daysForm]);

  const onDaySubmit = () => {
    daysForm.validateFields().then((value: CreateDayFormProps) => {
      if (contract) {
        const request: RateBasicElectricityPriceRequest = {
          electricityContractId: contract.id,
          pricingMethod: value.pricingMethod,
          capacityUnitPrice: toPrecision(value.capacityUnitPrice, contract?.precision ?? 0),
          agreedDemand: value.agreedDemand,
          demandUnitPrice: toPrecision(value.demandUnitPrice, contract?.precision ?? 0),
          penaltyCoefficient: value.penaltyCoefficient,
          penaltyUnitPrice: toPrecision(value.penaltyUnitPrice, contract?.precision ?? 0),
          startDate: dayjs(value.day[0]).format(formatString),
          endDate: dayjs(value.day[1]).format(formatString),
        };

        if (rateBasicPrice && rateBasicPrice.id) {
          request.rateBasicElectricityPriceId = rateBasicPrice.id;
          // 编辑
          updateBasicPrice(request).then(() => {
            onDayCancel();
            updateFn();
          });
        } else {
          createBasicPrice([request]).then(() => {
            // 更新
            onDayCancel();
            updateFn();
          });
        }
      }
    });
  };

  const utilForms = (
    <>
      <Form.Item
        name="pricingMethod"
        label="计价方式"
        rules={[{ required: true, message: '请选择计价方式' }]}
        initialValue={PricingMethod.AGREEDDEMAND}
      >
        <Radio.Group>
          <Radio value={PricingMethod.TRANSFORMERCAPACITY}>
            {PricingMethodDisplay[PricingMethod.TRANSFORMERCAPACITY]}
          </Radio>
          <Radio value={PricingMethod.AGREEDDEMAND}>{PricingMethodDisplay[PricingMethod.AGREEDDEMAND]}</Radio>
          <Radio value={PricingMethod.ACTUALDEMAND}>{PricingMethodDisplay[PricingMethod.ACTUALDEMAND]}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item noStyle dependencies={['pricingMethod']}>
        {({ getFieldValue }) => {
          const pricingMethod: PricingMethod | undefined = getFieldValue('pricingMethod');
          if (!pricingMethod) return null;
          return pricingMethod === PricingMethod.TRANSFORMERCAPACITY ? (
            <>
              <Form.Item name="transformerCapacity" label="变压器容量值">
                <ShowInput />
              </Form.Item>
              <Form.Item
                name="capacityUnitPrice"
                label="容量单价"
                rules={[{ required: true, message: '请输入容量单价' }]}
              >
                <InputNumber stringMode min={0} precision={contract?.precision ?? 0} addonAfter="元/kVA" />
              </Form.Item>
            </>
          ) : pricingMethod === PricingMethod.AGREEDDEMAND ? (
            <>
              <Form.Item
                name="agreedDemand"
                label="约定需量值"
                rules={[{ required: true, message: '请输入约定需量值' }]}
              >
                <InputNumber min={0} addonAfter="kW" precision={2} />
              </Form.Item>
              <Form.Item
                name="demandUnitPrice"
                label="需量单价"
                rules={[{ required: true, message: '请输入需量单价' }]}
              >
                <InputNumber stringMode min={0} addonAfter="元/kW" precision={contract?.precision ?? 0} />
              </Form.Item>
              <Form.Item
                name="penaltyCoefficient"
                label="惩罚系数"
                rules={[{ required: true, message: '请输入惩罚系数' }]}
              >
                <InputNumber min={0} addonAfter="%" precision={2} />
              </Form.Item>
              <Form.Item
                name="penaltyUnitPrice"
                label="惩罚单价"
                rules={[{ required: true, message: '请输入惩罚单价' }]}
              >
                <InputNumber stringMode min={0} addonAfter="元/kW" precision={contract?.precision ?? 0} />
              </Form.Item>
            </>
          ) : (
            <Form.Item name="demandUnitPrice" label="需量单价" rules={[{ required: true, message: '请输入需量单价' }]}>
              <InputNumber stringMode min={0} addonAfter="元/kW" precision={contract?.precision ?? 0} />
            </Form.Item>
          );
        }}
      </Form.Item>
    </>
  );

  return (
    <>
      <SubContent
        title={
          <Space size={8}>
            <span>基本电价</span>
            {!disabled && (
              <Space size={8}>
                <Button type="primary" onClick={onAddMonths} icon={<PlusOutlined />}>
                  <span>按月新增</span>
                </Button>
                <Button type="primary" onClick={onAddDay} icon={<PlusOutlined />}>
                  <span>按日新增</span>
                </Button>
              </Space>
            )}
          </Space>
        }
      >
        <Table rowKey="id" scroll={{ x: 1300 }} loading={loading} columns={buildColumns} dataSource={prices} />
      </SubContent>
      <Modal
        size="large"
        title="按月新增-基本电价"
        contentClassName="modal-form-content"
        open={monthVisible}
        onCancel={onMonthCancel}
        onOk={onMonthSubmit}
        bodyStyle={{
          maxHeight: 400,
          overflowY: 'auto',
        }}
        okText="保存"
      >
        {!!contract && (
          <Form labelAlign="left" labelWrap {...formLayout} form={monthsForm}>
            <Form.Item name="months" label="选择日期" rules={[{ required: true, message: '请选择合同生效日期' }]}>
              <Month
                startTime={dayjs(contract.startDate).startOf('date')}
                endTime={dayjs(contract.endDate).endOf('date')}
                disabledTimes={prices.map(i => [
                  dayjs(i.startDate).startOf('date').valueOf(),
                  dayjs(i.endDate).endOf('date').valueOf(),
                ])}
              />
            </Form.Item>
            <Divider />
            {utilForms}
          </Form>
        )}
      </Modal>
      <Modal
        size="large"
        title={rateBasicPrice?.id ? '基本电价-编辑' : '按日新增-基本电价'}
        open={!!rateBasicPrice}
        onCancel={onDayCancel}
        contentClassName="modal-form-content"
        onOk={onDaySubmit}
        bodyStyle={{
          maxHeight: 400,
          overflowY: 'auto',
        }}
        okText="保存"
      >
        {!!contract && (
          <Form labelAlign="left" labelWrap {...formLayout} form={daysForm}>
            <Form.Item name="day" label="选择日期" rules={[{ required: true, message: '请选择合同生效日期' }]}>
              <Day
                startTime={dayjs(contract.startDate).startOf('date')}
                endTime={dayjs(contract.endDate).endOf('date')}
                disabledTimes={prices
                  .filter(i => i.id !== rateBasicPrice?.id)
                  .map(i => [dayjs(i.startDate).startOf('date').valueOf(), dayjs(i.endDate).endOf('date').valueOf()])}
              />
            </Form.Item>
            <Divider />
            {utilForms}
          </Form>
        )}
      </Modal>
    </>
  );
};

export default ElectricRateBasicPrice;
