import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, Form, Input, Select, MultiSelect, useAsync, Upload } from '@maxtropy/components';
import { Row, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useMemo, useState } from 'react';
import ShowInput from '@/shared/components/ShowInput';
import { TableRow } from '..';
import { useRequest } from 'ahooks';
import { getOrganizationWithCodeWithCurrent, OrganizationResponse } from '@/api/device';
import { getOuListOwned } from '@/api/ou';
import {
  apiV2ProductionBaseAddPost,
  apiV2ProductionBaseCheckCodeUniquePost,
  apiV2ProductionBaseCheckNameUniquePost,
  apiV2ProductionBaseDetailPost,
  apiV2ProductionBaseEditPost,
} from '@maxtropy/device-customer-apis-v2';
import { debounce, isNil } from 'lodash-es';
import ImageBase from '../../../assets/productionBase.png';
interface Iprops {
  type?: string;
  data?: TableRow;
  cancel?: () => void;
  confirm?: () => void;
}
interface flattenNode {
  key: string;
  title: string;
  disabled: boolean;
}
interface nodeItem extends flattenNode {
  children: nodeItem[];
}
const formatTreeData = (data: OrganizationResponse[]) => {
  return data.map(item => {
    const res: nodeItem = {
      key: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children || []),
      disabled: !item.data.hasPermission,
    };
    return res;
  });
};
const flattenTreeData = (data: nodeItem[]) => {
  let treeList: nodeItem[] = [];
  let flatten = function (data: nodeItem[]) {
    for (let i = 0; i < data.length; i++) {
      treeList.push(data[i]);
      if (data[i].children.length) {
        flatten(data[i].children);
      }
    }
  };
  flatten(data);
  return treeList;
};

const CreateBase = ({ type, data, cancel, confirm }: Iprops) => {
  const [form] = useForm();
  const [selectedOuList, setSelectedOuList] = useState<
    {
      id?: number;
      name?: string;
    }[]
  >([]);
  const [Flag, setFlag] = useState<boolean>(false);
  // 已被选中的禁用的运营单元
  const [selectedDisOuIds, setSelectedDisOuIds] = useState<number[]>([]);

  useEffect(() => {
    if (type !== 'add' && data && data.id) {
      apiV2ProductionBaseDetailPost({ id: data.id }).then(res => {
        form.setFieldsValue({
          ...res,
          ouNames: type === 'view' ? (res.ouDtoV2s ?? []).map(i => i.name).join('，') : undefined,
          ouIds: (res.ouDtoV2s ?? []).map(i => i.id),
        });
        if (res.ouDtoV2s) setSelectedOuList(res.ouDtoV2s);
        setFlag(isNil(res.iconKey) ? true : false);
      });
    }
  }, [type, data, form]);

  const ouList = useAsync(getOuListOwned, []);

  // 编辑时：有权限与已选中的并集，新建时：有权限
  const ouOptions = useMemo(() => {
    if (type === 'edit' && ouList && selectedOuList) {
      const noPermission = (selectedOuList ?? [])
        .filter(item => !ouList.some(i => i.id === item.id))
        .map(item => ({
          label: item.name,
          value: item.id,
          disabled: true,
        }));
      setSelectedDisOuIds((noPermission ?? []).map(item => item.value as number));
      const hasPermission = ouList.map(item => ({
        label: item.name,
        value: item.id,
        disabled: false,
      }));
      return [...hasPermission, ...noPermission];
    }
    return (ouList ?? []).map(item => ({
      label: item.name,
      value: item.id,
    }));
  }, [ouList, selectedOuList, type]);

  const title = useMemo(() => {
    return type === 'add' ? '新建' : type === 'edit' ? '编辑' : '查看';
  }, [type]);

  const confirmBtn = () => {
    if (type === 'view') {
      cancel?.();
      return;
    }
    form
      .validateFields()
      .then(res => {
        if (data && data.id) {
          apiV2ProductionBaseEditPost({ id: data.id, ...res }).then(res => {
            confirm?.();
          });
          return;
        }
        apiV2ProductionBaseAddPost({
          id: data?.id,
          ...res,
        }).then(res => {
          confirm?.();
        });
      })
      .catch(e => {});
  };

  const { data: organization } = useRequest(getOrganizationWithCodeWithCurrent);
  const mcidTreeOptions = useMemo(() => {
    if (organization) {
      let tree = formatTreeData([organization]);
      return flattenTreeData(tree)
        ?.filter((m: nodeItem) => !m.disabled)
        ?.map(k => {
          return { value: k.key, label: k.title };
        });
    } else {
      return [];
    }
  }, [organization]);

  // 是否被映射
  const codeCanChange = useMemo(() => {
    return data?.userFlag || type === 'view';
  }, [data, type]);

  return (
    <>
      <Modal
        title={`${title}生产基地信息`}
        open
        onCancel={() => cancel?.()}
        onOk={() => {
          const cannotSubmit =
            form.getFieldsError(['code'])[0].errors.length > 0 || form.getFieldsError(['name'])[0].errors.length > 0;
          if (cannotSubmit) return;
          form.submit();
        }}
      >
        <Form
          form={form}
          style={{ padding: '8px 48px' }}
          labelAlign="left"
          labelCol={{ flex: '128px' }}
          onFinish={confirmBtn}
        >
          <Form.Item
            label="生产基地编码"
            name="code"
            rules={[{ required: !codeCanChange }]}
            extra={
              type !== 'view' && (
                <Row>
                  <Space>
                    <ExclamationCircleOutlined style={{ color: 'var(--warning-color)' }} />
                    编码在能耗单元映射后不可被修改
                  </Space>
                </Row>
              )
            }
          >
            {codeCanChange ? (
              <ShowInput />
            ) : (
              <Input
                maxLength={32}
                onChange={debounce((e: any) => {
                  if (isNil(e.target.value) || e.target.value === '') return;

                  apiV2ProductionBaseCheckCodeUniquePost({
                    id: data?.id,
                    code: e.target.value,
                  }).then(res => {
                    if (isNil(res)) return;
                    if (!res.unique) {
                      form.setFields([
                        {
                          name: 'code',
                          errors: ['同租户下的生产基地编码重复'],
                        },
                      ]);
                    } else {
                      form.setFields([
                        {
                          name: 'code',
                          errors: [],
                        },
                      ]);
                    }
                  });
                }, 500)}
              ></Input>
            )}
          </Form.Item>
          <Form.Item label="生产基地名称" name="name" rules={[{ required: type !== 'view' }]}>
            {type === 'view' ? (
              <ShowInput />
            ) : (
              <Input
                maxLength={32}
                onChange={debounce((e: any) => {
                  if (isNil(e.target.value) || e.target.value === '') return;
                  apiV2ProductionBaseCheckNameUniquePost({
                    id: data?.id,
                    name: e.target.value,
                  }).then(res => {
                    if (isNil(res)) return;
                    if (!res.unique) {
                      form.setFields([
                        {
                          name: 'name',
                          errors: ['同租户下的生产基地名称重复'],
                        },
                      ]);
                    } else {
                      form.setFields([
                        {
                          name: 'name',
                          errors: [],
                        },
                      ]);
                    }
                  });
                }, 500)}
              ></Input>
            )}
          </Form.Item>
          {type === 'view' ? (
            <Form.Item label="所属组织" name="customerName">
              <ShowInput />
            </Form.Item>
          ) : (
            <Form.Item label="所属组织" name="customerMcid" rules={[{ required: true }]}>
              <Select options={mcidTreeOptions} optionFilterProp="label" showSearch />
            </Form.Item>
          )}
          {type === 'view' ? (
            <Form.Item label="所属运营单元" name="ouNames">
              <ShowInput />
            </Form.Item>
          ) : (
            <Form.Item
              name="ouIds"
              label="所属运营单元"
              rules={[
                {
                  required: true,
                  message: '请选择所属运营单元',
                },
              ]}
            >
              <MultiSelect
                placeholder=""
                options={ouOptions}
                onChange={(value, options) => {
                  // 是否全选
                  // const isAllSel = ouOptions.length === value.length;
                  // const ouIds =
                  //   type === 'add'
                  //     ? ouOptions?.filter((item: DefaultOptionType) => !item.disabled)?.map(item => item.value)
                  //     : selectedDisOuIds?.concat(
                  //         ouOptions
                  //           ?.filter((item: DefaultOptionType) => !item.disabled)
                  //           ?.map(item => item.value as number)
                  //       );
                  // console.log(ouIds);
                  // if (isAllSel) {
                  //   form.setFieldsValue({
                  //     ouIds,
                  //   });
                  // }
                }}
              />
            </Form.Item>
          )}
          {type === 'view' && Flag ? (
            <Form.Item name="iconKey" label="生产基地图标">
              <img src={ImageBase} alt="" style={{ width: '64px', height: '64px' }}></img>
            </Form.Item>
          ) : (
            <Form.Item name="iconKey" label="生产基地图标" extra="支持jpg、jpeg、png格式，文件小于1MB">
              <Upload disabled={type === 'view'} fileSize={1} />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default CreateBase;
