import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { DeviceStatus } from '@/shared/types';
import { PageRequest, PageResponse } from './page';

export interface DeviceTypes {
  id: number;
  name: string;
  allowAttribute?: boolean;
  children?: Array<DeviceTypes>;
  deviceTypes?: Array<DeviceTypes>;
  parentId?: number;
  parentName?: string;
  rootId?: number;
}

export interface DevicePageRequest extends PageRequest {
  nameOrCode?: string;
  deviceType?: Array<number>;
}

export interface DeviceTypeTree {
  aliasName: Array<string>;
  tree: Array<DeviceTypes>;
}

export interface Device {
  id: number;
  deviceId: number;
  name: string;
  sn: string;
  createTime: string;
  code: string;
  modelName: string;
  typeName: string;
  typeId: number;
  status: DeviceStatus;
}

export interface OUDeviceListRequest extends PageRequest {
  nameOrCode?: string;
  status?: DeviceStatus;
  deviceType?: number[];
}

export const getOUDevices = (id: number, params?: OUDeviceListRequest) =>
  fetch<PageResponse<Device>>(`/api/ou/${id}/device/page?${qs.stringify(params, { indices: false })}`);

export const getDevicePage = (id: number, params?: DevicePageRequest) =>
  fetch<PageResponse<Device>>(`/api/ou/${id}/addDevice/page?${qs.stringify(params, { indices: false })}`);

export const removeDevice = (id: number, deviceId: number) =>
  fetch<void>(`/api/ou/${id}/removeDevice?deviceId=${deviceId}`, {
    method: 'PUT',
  });

export const addDevice = (id: number, params: { deviceIds: number[] }) =>
  fetch<void>(`/api/ou/${id}/addDevice?deviceIds=${params.deviceIds.join(',')}`, {
    method: 'PUT',
  });

export const checkDeviceBind = (id: number, params: { deviceIds: number[] }) =>
  fetch<boolean>(`/api/ou/${id}/checkDevice`, {
    method: 'POST',
    body: JSON.stringify(params),
  });

export const checkPassword = (params: { password: string }) =>
  fetch(`/api/ou/checkPassword`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });

export const getStaffType = (ouId: number): Promise<number> => fetch<number>(`/api/ou/${ouId}/getStaffType`);
